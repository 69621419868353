<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container">
          <!-- ----------ラウンドプラス----種類選択------ -->
          <div v-if="categoryCode=='2'" class="tab border-none">
            <div class="flex items-center">
              <a v-for="tab in rlsPlustabs" 
                :key="tab.id" 
                class="flex items-center mx-1 cursor-pointer"
                :class="[tab.active ? 'bg-light-blue-800' : 'hover:bg-light-blue-800', 'bg-gray-300 rounded-md py-2 px-3 text-sm font-medium text-white']" 
                @click="tabChanged(tab)"
              >
                {{ tab.name }}
              </a>
            </div>
          </div>
          
          <!-- 計算結果を即画面に反映するためだけの変数 -->
          <span class="text-transparent">{{forBind}}</span>

          <!-- ----------ラウンドプラス--end-------- -->

          <div v-if="buildingInfo.memo.rlsPlus!=''" class="memo font-medium text-gray-500 mb-3 mt-3">{{buildingInfo.memo.rlsPlus}}</div>

          <!-- 項目表 -->
          <div class="formula-container">
            <!-- ----------RLS---------- -->
            <div id="rls-form">
              <h3 class="kind-name rls-title">ラウンドサービス</h3>
              <div class="msg">単価は 「シングル」 単価を使用します。</div>
              <table class="table-fixed table">
                <thead>
                  <tr v-show="input(item, 'rls') || radio(item, 'rls') || dropDown(item, 'rls')" v-for="(item, index) in rls" :key="index">
                    <th class="text-center text-xs font-medium uppercase tracking-wider border-gray table-width" :class="setEmptyColor(item.value)">
                      {{ item.name }}
                    </th>
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height table-width">
                      <!-- 数値入力 -->
                      <NumericTextInput
                        v-if="input(item, 'rls')"
                        :name="item.item"
                        v-model="rls[index].value"
                        @change="setItem(item, index, rls[index].value, rls, 'rls')"
                        class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full input" 
                      />

                      <!-- ラジオボタン -->
                      <div class="m-auto select-form flex justify-around" v-if="radio(item, 'rls')">
                        <form v-for="(key, radioIndex) in Object.keys(item.itemCode)" :key='key.id'>
                          <input
                            type="radio"
                            :name="item.item + radioIndex"
                            v-model="rls[index].value"
                            :id="item.item + radioIndex + 'rls'"
                            :value="key"
                            :disabled="setDisabled(index, 'rls')"
                            @change="setItem(item, index, item.itemCode[key], rls, 'rls')"
                            class="mt-3 mb-3 radio-width"
                            :class="setDisabled(index, 'rls')?'cursor-not-allowed':''"
                          />
                          <label :for="item.item + radioIndex + 'rls'" class="radio-range">{{ item.itemCode[key] }}</label>
                        </form>
                      </div>

                      <!-- ドロップダウン -->
                      <div class="m-auto select-form" v-if="dropDown(item, 'rls')">
                        <form>
                          <select
                            :name="item.item"
                            v-model="rls[index].value"
                            @change="setItem(item, index, item.itemCode, rls, 'rls')"
                            class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop"
                          >
                            <option
                              v-for="key in Object.keys(item.itemCode)" :key='key.id'
                              :value="key"
                              class="bg-white">
                              {{ item.itemCode[key] }}
                            </option>
                          </select>
                        </form>
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
              <!-- 単価取得ボタン -->
              <PrimaryButton text="単価を取得" size="sm bg-indigo-200 text-indigo-700 hover:bg-indigo-300 hover:text-indigo-900 px-10 py-3 place-items-end block mt-3 btn" @click="getPriceMasterData('rls')" />

              <!-- ----------RLS計算式---------- -->
              <div class="formula-left">
                <!-- トイレ回数 -->
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ回数</div>
                  <span class="pt-5 number">
                    <span v-if="rls[5]">
                      {{ rls[5].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <!-- トイレ個数 -->
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ個数</div>
                  <span class="pt-5 number">
                    <span v-if="rls[6]">
                      {{ rls[6].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div class="inline">
                  <div class="cof-price-width text-xs text-gray-500">トイレ単価</div>
                  <NumericTextInput
                    name="toiletRls"
                    v-model="selectedDetails.conf['toilet']"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width"
                    :class="setBgColor(selectedDetails.conf['toiletBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'toilet', 'rls')"
                  />
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
                  <span class="pt-5 number font-bold">
                    4.3
                  </span>
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="cof-price-width text-xs text-gray-500">トイレ合計</div>
                  <NumericTextInput
                    name="totalUnitToilet"
                    v-model="selectedDetails.price['toilet']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                  />
                </div>
              </div>
              <!-- メーター -->
              <div class="formula-left">
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">メーター回数</div>
                  <span class="pt-5 number">
                    <span v-if="rls[7]">
                      {{ rls[7].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">メーター箇所</div>
                  <span class="pt-5 number">
                    <span v-if="rls[8]">
                      {{ rls[8].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500">メーター単価</div>
                  <NumericTextInput
                    name="meter"
                    v-model="selectedDetails.conf['meter']"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['meterBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'meter', 'rls')"
                  />
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="cof-price-width text-xs text-gray-500">メーター合計</div>
                  <NumericTextInput
                    name="totalUnitMeter"
                    v-model="selectedDetails.price['meter']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                  />
                </div>
              </div>
              <!-- 写真報告 -->
              <div class="formula-left">
                <!-- 月1回の場合 -->
                <div v-if="rls[9] && rls[9].value=='m1'">
                  <div class="cof-price-width text-xs text-gray-500 mb-2">写真回数</div>
                  <span class="pt-5 number">
                    1
                  </span>
                </div>
                <!-- 月1回以外の場合 -->
                <div v-else>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">写真回数</div>
                  <span class="pt-5 number">
                    <span v-if="rls[9]">
                      {{ rls[9].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500">写真単価</div>
                  <NumericTextInput
                    name="photo"
                    v-model="selectedDetails.conf['photo']"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['photoBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'photo', 'rls')"
                  />
                </div>
                <span class="pt-5">×</span>
                <!-- 月1回の場合 -->
                <div v-if="rls[9] && rls[9].value=='m1'">
                  <div class="cof-price-width text-xs text-gray-500 mb-2">1回/月</div>
                  <span class="pt-5 number">
                    1
                  </span>
                </div>
                <!-- 月1回以外の場合 -->
                <div v-else>
                  <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
                  <span class="pt-5 number font-bold">
                    4.3
                  </span>
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="cof-price-width text-xs text-gray-500">写真合計</div>
                  <NumericTextInput
                    name="totalUnitPhoto"
                    v-model="selectedDetails.price['photo']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                  />
                </div>
              </div>
              <!-- 最終金額 -->
              <div class="formula-left border-none">
                <span class="symbol">&#040;</span>
                <div class="inline">
                  <div class="w-90px text-xs text-gray-500">トイレ合計</div>
                  <NumericTextInput
                    name="totalUnitToilet"
                    v-model="selectedDetails.price['toilet']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back w-90px mb-2"
                  />
                </div>
                <span class="pt-5">+</span>
                <div class="inline">
                  <div class="w-90px text-xs text-gray-500">メーター合計</div>
                  <NumericTextInput
                    name="totalUnitMeter"
                    v-model="selectedDetails.price['meter']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back w-90px mb-2"
                  />
                </div>
                <span class="pt-5">+</span>
                <div class="inline">
                  <div class="w-90px text-xs text-gray-500">写真合計</div>
                  <NumericTextInput
                    name="totalUnitPhoto"
                    v-model="selectedDetails.price['photo']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back w-90px mb-2"
                  />
                </div>
                <span class="pt-5">+</span>
                <div>
                  <div class="price-width text-xs text-pink-400">単価</div>
                  <NumericTextInput
                    name="unitPriceRls"
                    v-model="selectedDetails.unitPrice.rls"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-pink-300 price-width"
                    :class="setBgColor(selectedDetails.unitPriceRlsBgColor)"
                    @change="changeCalcAndPrice(selectedDetails, 'unitPriceRls', 'rls')"
                  />
                </div>
                <span class="symbol mr-4">&#041;</span>
                <span class="pt-5">÷</span>
                <div>
                  <div class="w-90px text-xs text-gray-500 mb-2">協力費として</div>
                  <span class="pt-5 number">
                    <span v-if="rls[12]">
                      {{ selectedDetails.conf['compeRls'] }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="w-90px text-xs text-gray-500">竣工係数</div>
                  <NumericTextInput
                    name="finishPointRls"
                    v-model="selectedDetails.conf['finishPointRls']"
                    :decimalPlace="decimal2"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray w-90px mb-2"
                    :class="setBgColor(selectedDetails.conf['finishPointRlsBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'finishPointRls', 'rls')"
                  />
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="w-90px text-xs text-gray-500">オーナー契約</div>
                  <NumericTextInput
                    name="ownerRls"
                    v-model="selectedDetails.conf['ownerRls']"
                    :decimalPlace="decimal2"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray w-90px mb-2"
                    :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'owner', 'rls')"
                  />
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-400">*10円単位 切上*</div>
                  <NumericTextInput
                    name="rls"
                    v-model="totalUnitPrice.rls"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
              </div>
              <!-- 発注明細の元値用（オーナーなし金額） -->
              <div v-if="!rlsApollo" class="mt-2 text-xs text-blue-700">
                <div>
                  自社以外の発注単価の元値（オーナー契約なしの単価）： <span class="text-sm font-bold">{{ numberToLocale(selectedDetails.price['rlsPurchase']) }} 円</span>
                </div>
              </div>
              <!-- ----------RLS計算式---end---------- -->
            </div>
            
            <!-- ----------日常清掃---------- -->
            <div v-if="rlsPlusKind=='clean' || rlsPlusKind=='double'" id="normal-form">
              <h3 class="kind-name normal-title">固定清掃</h3>
              <div class="msg">
              <div >単価は 「時間指定なし」「1人」 単価を使用します。</div>
              <div>※清掃回数は、週1回 or 週2回 </div>
              <div>※清掃時間は、2時間 or 3時間 or 4時間</div>
              <div>※単独発注・曜日・時間帯は、クリーンプラスのみ選択可</div>
              </div>
              <table class="table-fixed table">
                <thead>
                  <tr v-show="input(item, 'normal') || radio(item, 'normal') || dropDown(item, 'normal')" v-for="(item, index) in normal" :key="index">
                    <th class="text-center text-xs font-medium tracking-wider border-gray table-width" :class="setEmptyColor(item.value)">
                      {{ item.name }}
                    </th>
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height table-width">
                      <!-- 数値入力 -->
                      <NumericTextInput
                        v-if="input(item, 'normal')"
                        :name="item.item"
                        v-model="normal[index].value"
                        :disabled="setDisabled(index, 'normal')"
                        @change="setItem(item, index, normal[index].value, normal, 'normal')"
                        class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full input" 
                      />

                      <!-- ラジオボタン -->
                      <div class="m-auto select-form flex justify-around" v-if="radio(item, 'normal')">
                        <form v-for="(key, radioIndex) in Object.keys(item.itemCode)" :key='key.id'>
                          <input
                            type="radio"
                            :name="item.item + radioIndex"
                            v-model="normal[index].value"
                            :id="item.item + radioIndex + 'normal'"
                            :value="key"
                            :disabled="setDisabled(index, 'normal')"
                            @change="setItem(item, index, item.itemCode[key], normal, 'normal')"
                            class="mt-3 mb-3 radio-width"
                            :class="setDisabled(index, 'normal')?'cursor-not-allowed':''"
                          />
                          <label :for="item.item + radioIndex + 'normal'" class="radio-range" :class="setDisabled(index, 'normal')?'cursor-not-allowed':''">{{ item.itemCode[key] }}</label>
                        </form>
                      </div>

                      <!-- ドロップダウン -->
                      <div class="m-auto select-form" v-if="dropDown(item, 'normal')">
                        <form>
                          <select
                            :name="item.item"
                            v-model="normal[index].value"
                            :disabled="setDisabled(index, 'normal')"
                            @change="setItem(item, index, item.itemCode, normal, 'normal')"
                            class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop"
                            :class="setDisabled(index, 'normal')?'cursor-not-allowed':''"
                          >
                            <option
                              v-for="key in Object.keys(item.itemCode)" :key='key.id'
                              :value="key"
                              class="bg-white">
                              {{ item.itemCode[key] }}
                            </option>
                          </select>
                        </form>
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
                  
              <!-- 特記事項や契約書で使用する内容 クリーンプラスの場合のみ -->
              <div v-if="rlsPlusKind=='clean'">
                <div class="text-xs text-gray-500 remark-msg">特記事項などで使用（必須ではありません）</div>
                <div class="text-sm text-blue-600 remark-msg2" v-if="isSunday">※「日」を選択しても、日曜割増の計算はされません</div>
                <div v-for="(r , i) in selectedDetails.remarks" :key=i>
                  <table class="border border-gray-200 rounded remark-table mb-2">
                    <thead>
                      <th class="b-right-gray px-10 text-center text-xs font-medium tracking-wider" :class="setEmptyColorWeek(r)">
                        曜日
                      </th>
                      <td class="b-top-gray bg-white text-center px-3 py-3 text-xs font-medium text-gray-500 tracking-wider">
                        <div class="m-auto flex justify-around">
                          <div class="flex items-center">
                            <a v-for="tab in week[i]" 
                              :key="tab.id" 
                              class="flex items-center mx-1 cursor-pointer"
                              :class="[tab.active ? 'bg-light-blue-800' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-2 px-3 text-sm font-medium text-white']" 
                              @click="weekChanged(tab, i)"
                            >
                              {{ tab.name }}
                            </a>
                          </div>
                        </div>
                      </td>
                    </thead>

                    <thead>
                      <th class="b-top-gray b-right-gray px-10 text-center text-xs font-medium tracking-wider" :class="setEmptyColorTime(r['start'], r['end'])">
                        時間帯
                      </th>
                      <td class="b-top-gray bg-white px-3 pb-3 pt-1 text-xs font-medium text-gray-500 tracking-wider flex justify-center">
                        <WmsTimeInput2
                          name="start" 
                          v-model="r.start"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                        />
                        
                        <span class="remark-text">~</span>

                        <WmsTimeInput2
                          name="end" 
                          v-model="r.end"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                        />
                      </td>
                    </thead>
                  </table>
                </div>
              </div>
              <!-- 単価取得ボタン -->
              <PrimaryButton text="単価を取得" size="sm bg-indigo-200 text-indigo-700 hover:bg-indigo-300 hover:text-indigo-900 px-10 py-3 place-items-end block mt-3 btn" @click="getPriceMasterData('normal')" />
              
              <!-- ----------日常清掃---------- -->

                <!-- 月の合計時間 -->
              <div class="formula-left">
                <div>
                  <div class="price-width text-xs text-gray-400 mb-2">清掃時間</div>
                  <span class="pt-5 number font-bold">
                    <span v-if="normal[6]">
                      {{ normal[6].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="price-width text-xs text-gray-500 mb-2">清掃回数</div>
                  <span class="pt-5 number">
                    <span v-if="normal[7]">
                      {{ normal[7].value }}
                    </span>
                  </span>
                </div>
                <!-- <span class="pt-5">×</span>
                <div>
                  <div class="price-width text-xs text-gray-400 mb-2">人数</div>
                  <span class="pt-5 number font-bold">
                    <span v-if="normal[8]">
                      {{ normal[8].value }}
                    </span>
                  </span>
                </div> -->
                <span class="pt-5">×</span>
                <div>
                  <div class="price-width text-xs text-gray-400 mb-2">週/月</div>
                  <span class="pt-5 number font-bold">
                    4.3
                  </span>
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-teal-500">月の合計時間</div>
                  <NumericTextInput
                    name="hours"
                    v-model="selectedDetails.price['hours']"
                    :decimalPlace="decimal2"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
              </div>

              <!-- トイレ金額 -->
              <div class="formula-left">
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ回数</div>
                  <span class="pt-5 number">
                    <span v-if="normal[2]">
                      {{ normal[2].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ箇所</div>
                  <span class="pt-5 number">
                    <span v-if="normal[3]">
                      {{ normal[3].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500">トイレ単価</div>
                  <NumericTextInput
                    name="toiletNormal"
                    v-model="selectedDetails.conf['toiletNormal']"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['toiletNormalBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'toiletNormal', 'normal')"
                  />
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
                  <span class="pt-5 number font-bold">
                    4.3
                  </span>
                </div>
                <span class="pt-5">÷</span>
                <div class="inline">
                  <div class=" cof-price-width text-xs text-teal-500">月の合計時間</div>
                  <NumericTextInput
                    name="hours"
                    v-model="selectedDetails.price['hours']"
                    :decimalPlace="decimal2"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                  />
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="cof-price-width text-xs text-pink-700">トイレ代/時間</div>
                  <NumericTextInput
                    name="toiletNormalPrice"
                    v-model="selectedDetails.price['toiletNormal']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                  />
                </div>
              </div>

              <!-- バス代 -->
              <div class="formula-left">
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">バス代単価</div>
                  <NumericTextInput
                    name="busUnit"
                    v-model="selectedDetails.conf['bus']"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['busBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'bus', 'normal')"
                  />
                </div>
                <span class="pt-5">÷</span>
                <div>
                  <div class="price-width text-xs text-gray-400 mb-2">清掃時間</div>
                  <span class="pt-5 number font-bold">
                    <span v-if="normal[6]">
                      {{ normal[6].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">バス代/時間</div>
                  <NumericTextInput
                    name="totalUnitBus"
                    v-model="selectedDetails.price['bus']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                  </div>
                </div>

                <!-- 定期概算 -->
                <div v-if="normal[11] && normal[11].value=='4'">
                  <div class="formula-left">
                    <div>
                      <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                      <span class="pt-5 number">
                        <span v-if="selectedDetails.conf">
                          {{ selectedDetails.conf['normalHouse'] }}
                        </span>
                      </span>
                    </div>
                    <span class="pt-5">×</span>
                    <div class="inline">
                      <div class="price-width-sm text-xs text-gray-500">長さ</div>
                      <NumericTextInput
                        name="normalWide"
                        v-model="selectedDetails.conf['normalWide']"
                        :disabled="true"
                        class="text-center py-1 border-none input disabled-back price-width-sm mb-2"
                      />
                    </div>
                    <span class="pt-5">×</span>
                    <div>
                      <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                      <span class="pt-5 number font-bold">
                        1.5
                      </span>
                    </div>
                    <span class="pt-5">+</span>
                    <div>
                      <div class="price-width-sm text-xs text-gray-500 mb-2">階数</div>
                      <span class="pt-5 number">
                        <span v-if="selectedDetails.conf">
                          {{ selectedDetails.conf['normalFloor'] }}
                        </span>
                      </span>
                    </div>
                    <span class="pt-5">×</span>
                    <div>
                      <div class="price-width-sm text-xs text-gray-500 mb-2">本数</div>
                      <span class="pt-5 number">
                        <span v-if="selectedDetails.conf">
                          {{ selectedDetails.conf['normalNum'] }}
                        </span>
                      </span>
                    </div>
                    <span class="pt-5">×</span>
                    <div>
                      <div class="price-width-sm text-xs text-gray-400 mb-2">長さ</div>
                      <span class="pt-5 number font-bold">
                        10
                      </span>
                    </div>
                    <span class="pt-5">×</span>
                    <div>
                      <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                      <span class="pt-5 number font-bold">
                        1.2
                      </span>
                    </div>
                    <span class="pt-5">+</span>
                    <div>
                      <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                      <span class="pt-5 number">
                        <span v-if="selectedDetails.conf">
                          {{ selectedDetails.conf['normalHouse'] }}
                        </span>
                      </span>
                    </div>
                    <span class="pt-5">＝</span>
                    <div class="inline">
                    <div class="cof-price-width text-xs text-blue-400">概算</div>
                    <NumericTextInput
                      name="approx"
                      v-model="selectedDetails.price['normalApproxPre']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                    />
                    </div>
                  </div>
                  <!-- どの式を使用するかメッセージ表示 -->
                  <div class="text-center text-xs text-blue-500">
                    {{ selectedDetails.conf['normalMsg'] }}
                  </div>
                </div>

                <!-- 追加料金（その他、業務なし） -->
                <div v-if="rlsPlusKind=='clean'" class="formula-left">
                  <span class="symbol">&#040;</span>

                  <!-- 300㎡超え -->
                  <div v-if="(normal[11] && normal[11].value=='3')" class="inline">
                    <div class="cof-price-width text-xxs text-gray-500 mb-2">㎡数（300㎡超え）</div>
                      {{ selectedDetails.price['approx'] }}
                  </div>
                  <!-- ㎡数不明で300㎡超え -->
                  <div v-else-if="selectedDetails.conf['normalMeter']=='3'" class="inline">
                    <div class="cof-price-width text-xs text-blue-400">概算</div>
                    <NumericTextInput
                      name="approx"
                      v-model="selectedDetails.price['approx']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                    />
                  </div>
                  <!-- ㎡数その他 -->
                  <div v-else class="inline">
                    <div v-if="(normal[11] && normal[11].value=='1') || selectedDetails.conf['normalMeter']=='1'" class="cof-price-width text-xs text-blue-400">200㎡以下</div>
                    <div v-else-if="(normal[11] && normal[11].value=='2') || selectedDetails.conf['normalMeter']=='2'" class="cof-price-width text-xs text-blue-400">300㎡以下</div>
                    <div v-else class="cof-price-width text-xs text-blue-400">定期概算</div>
                    <NumericTextInput
                      name="approx"
                      v-model="selectedDetails.price['approx']"
                      class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray  cof-price-width mb-2"
                      :class="setBgColor(selectedDetails.price['approxBgColor'])"
                      @change="changeCalcAndPrice(selectedDetails.price, 'approx', 'normal')"
                    />
                  </div>

                  <span class="pt-5">×</span>
                  <div>
                    <div class="price-width-sm text-xs text-gray-400 mb-2">回/年</div>
                    <span class="pt-5 number font-bold">
                      4
                    </span>
                  </div>
                  <span class="pt-5">×</span>
                  <div class="inline">
                    <div class=" cof-price-width text-xs text-gray-500">エリア単価</div>
                    <NumericTextInput
                      name="eria"
                      v-model="selectedDetails.conf['eria']"
                      class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray  cof-price-width mb-2"
                      :class="setBgColor(selectedDetails.conf['eriaBgColor'])"
                      @change="changeCalcAndPrice(selectedDetails.conf, 'eria', 'normal')"
                    />
                  </div>
                  <span class="symbol mr-4">&#041;</span>
                  <span class="pt-5">÷</span>
                  <div class="pt-4">
                    <div class="price-width-sm text-xs text-gray-400 mb-2"></div>
                    <span class="pt-5 number font-bold">
                      12
                    </span>
                  </div>
                  <span class="pt-5">×</span>
                  <div class="pt-4">
                    <div class="price-width-sm text-xs text-gray-400 mb-2"></div>
                    <span class="pt-5 number font-bold">
                      0.3
                    </span>
                  </div>
                  <span class="pt-5">÷</span>
                  <div class="inline">
                    <div class=" cof-price-width text-xs text-teal-500">月の合計時間</div>
                    <NumericTextInput
                      name="hours"
                      v-model="selectedDetails.price['hours']"
                      :decimalPlace="decimal2"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                    />
                  </div>
                  <span class="pt-5">＝</span>
                  <div class="inline">
                    <div class="cof-price-width text-xss text-indigo-600">追加料金(単独発注)</div>
                    <NumericTextInput
                      name="add"
                      v-model="selectedDetails.price['add']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                    />
                  </div>
                </div>

              <!-- 日常清掃 料金決定 -->
              <div class="formula-left">
                <span class="symbol">&#040;</span>
                <div>
                  <div class="price-width text-xs text-pink-400">単価</div>
                  <NumericTextInput
                    name="unitPricenormal"
                    v-model="selectedDetails.unitPrice.normal"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-pink-300 price-width mb-2"
                    :class="setBgColor(selectedDetails.unitPriceNormalBgColor)"
                    @change="changeCalcAndPrice(selectedDetails, 'unitPriceNormal', 'normal')"
                  />
                </div>
                <span class="pt-5">+</span>
                <div class="inline">
                  <div class="price-width text-xs text-pink-700">トイレ代/時間</div>
                    <NumericTextInput
                      name="toiletNormalPrice"
                      v-model="selectedDetails.price['toiletNormal']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back price-width mb-2"
                    />
                </div>
                <span class="pt-5">+</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">バス代/時間</div>
                    <NumericTextInput
                      name="totalUnitBus"
                      v-model="selectedDetails.price['bus']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back price-width mb-2"
                    />
                </div>
                <span class="pt-5">+</span>
                <div class="inline">
                  <div class="cof-price-width text-indigo-600 text-xss">追加料金(単独発注)</div>
                  <NumericTextInput
                    name="add"
                    v-model="selectedDetails.price['add']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                  />
                </div>
                <span class="symbol mr-4">&#041;</span>
                <span class="pt-5">×</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">オーナー契約</div>
                  <NumericTextInput
                    name="ownerNormal"
                    v-model="selectedDetails.conf['ownerNormal']"
                    :decimalPlace="decimal2"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['ownerNormalBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'ownerNormal', 'normal')"
                  />
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-purple">合計単価</div>
                  <NumericTextInput
                    name="totalUnitPricePre"
                    v-model="selectedDetails.price['totalUnitPricePre']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
              </div>

              <!-- 最終金額 -->
              <div class="formula-left3 border-none">
                <div class="inline">
                  <div class="price-width text-xs text-purple">合計単価</div>
                  <NumericTextInput
                    name="totalUnitPricePre"
                    v-model="selectedDetails.price['totalUnitPricePre']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
                <span class="pt-5">÷</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">協力費として</div>
                  <span class="pt-5 number">
                    <span v-if="normal[20]">
                      {{ selectedDetails.conf['compeNormal'] }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
                  <NumericTextInput
                    name="finishPointNormal"
                    v-model="selectedDetails.conf['finishPointNormal']"
                    :decimalPlace="decimal2"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['finishPointNormalBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'finishPointNormal', 'normal')"
                  />
                </div>
                <span class="pt-5">×</span>
                <div class="inline">
                  <div class="price-width text-xs text-teal-500">月の合計時間</div>
                  <NumericTextInput
                    name="hours"
                    v-model="selectedDetails.price['hours']"
                    :decimalPlace="decimal2"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">*10円単位 切上*</div>
                  <NumericTextInput
                    name="normal"
                    v-model="totalUnitPrice.normal"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
              </div>
              <!-- 発注明細の元値用（オーナーなし金額） -->
              <div v-if="!normalApollo" class="mt-2 text-xs text-blue-700">
                <div>
                  自社以外の発注単価の元値（オーナー契約なしの単価）： <span class="text-sm font-bold">{{ numberToLocale(selectedDetails.price['normalPurchase']) }} 円</span>
                </div>
              </div>
              <!-- ----------日常清掃---end---------- -->
            </div>
            
            <!-- ----------定期清掃---------- -->
            <div v-if="rlsPlusKind=='wash' || rlsPlusKind=='double'" id="fixed-form">
              <h3 class="kind-name fixed-title">定期清掃</h3>
              <div class="msg">単価は 「6回/年」 単価を使用します。</div>
              <table class="table-fixed table">
                <thead>
                  <tr v-show="input(item, 'fixed') || radio(item, 'fixed') || dropDown(item, 'fixed')" v-for="(item, index) in fixed" :key="index">
                    <th class="text-center text-xs font-medium uppercase tracking-wider border-gray table-width" :class="setEmptyColorFixed(index)">
                      {{ item.name }}
                    </th>
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height table-width2">
                      <!-- 数値入力 -->
                      <NumericTextInput
                        v-if="input(item, 'fixed')"
                        :name="item.item"
                        v-model="fixed[index].value"
                        :disabled="setDisabled(index, 'fixed')"
                        @change="setItem(item, index, fixed[index].value, fixed, 'fixed')"
                        class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full input" 
                      />

                      <!-- ラジオボタン -->
                      <div class="m-auto select-form flex justify-around" v-if="radio(item, 'fixed')">
                        <form v-for="(key, radioIndex) in Object.keys(item.itemCode)" :key='key.id'>
                          <input
                            type="radio"
                            :name="item.item + radioIndex"
                            v-model="fixed[index].value"
                            :id="item.item + radioIndex + 'fixed'"
                            :value="key"
                            @change="setItem(item, index, item.itemCode[key], fixed, 'fixed')"
                            class="mt-3 mb-3 radio-width"
                          />
                          <label :for="item.item + radioIndex + 'fixed'" class="radio-range">{{ item.itemCode[key] }}</label>
                        </form>
                      </div>

                      <!-- ドロップダウン -->
                      <div class="m-auto select-form" v-if="dropDown(item, 'fixed')">
                        <form>
                          <select
                            :name="item.item"
                            v-model="fixed[index].value"
                            @change="setItem(item, index, item.itemCode, fixed, 'fixed')"
                            class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop"
                          >
                            <option
                              v-for="key in Object.keys(item.itemCode)" :key='key.id'
                              :value="key"
                              class="bg-white">
                              {{ item.itemCode[key] }}
                            </option>
                          </select>
                        </form>
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
              <!-- 単価取得ボタン -->
              <PrimaryButton text="単価を取得" size="sm bg-indigo-200 text-indigo-700 hover:bg-indigo-300 hover:text-indigo-900 px-10 py-3 place-items-end block mt-3 btn" @click="getPriceMasterData('fixed')" />
              
              <!-- ---------------定期清掃--------------- -->
              
              <!-- ㎡数が不明の場合 -->
              <div v-if="fixed[1]&&fixed[1].value=='4'" class="formula border-none mr-2">
                  <div>
                    <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                    <span class="pt-5 number">
                      <span v-if="selectedDetails.conf">
                        {{ selectedDetails.conf['fixedHouse'] }}
                      </span>
                    </span>
                  </div>
                  <span class="pt-5">×</span>
                  <div class="inline">
                    <div class="price-width-sm text-xs text-gray-500">長さ</div>
                    <NumericTextInput
                      name="wide"
                      v-model="selectedDetails.conf['fixedWide']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                      />
                  </div>
                  <span class="pt-5">×</span>
                  <div>
                    <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                    <span class="pt-5 number font-bold">
                      1.5
                    </span>
                  </div>
                  <span class="pt-5">+</span>
                  <div>
                    <div class="price-width-sm text-xs text-gray-500 mb-2">階数</div>
                    <span class="pt-5 number">
                      <span v-if="selectedDetails.conf">
                        {{ selectedDetails.conf['fixedFloor'] }}
                      </span>
                    </span>
                  </div>
                  <span class="pt-5">×</span>
                  <div>
                    <div class="price-width-sm text-xs text-gray-500 mb-2">本数</div>
                    <span class="pt-5 number">
                      <span v-if="selectedDetails.conf">
                        {{ selectedDetails.conf['fixedNum'] }}
                      </span>
                    </span>
                  </div>
                  <span class="pt-5">×</span>
                  <div>
                    <div class="price-width-sm text-xs text-gray-400 mb-2">長さ</div>
                    <span class="pt-5 number font-bold">
                      10
                    </span>
                  </div>
                  <span class="pt-5">×</span>
                  <div>
                    <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                    <span class="pt-5 number font-bold">
                      1.2
                    </span>
                  </div>
                  <span class="pt-5">+</span>
                  <div>
                    <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                    <span class="pt-5 number">
                      <span v-if="selectedDetails.conf">
                        {{ selectedDetails.conf['fixedHouse'] }}
                      </span>
                    </span>
                  </div>
                  <span class="pt-5">＝</span>
                  <div class="inline">
                  <div class="cof-price-width text-xs text-blue-500">概算</div>
                  <NumericTextInput
                    name="fixedApprox"
                    v-model="selectedDetails.price['fixedApprox']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                    />
                </div>
              </div>

              <div v-if="fixed[1] && fixed[1].value == '4' && selectedDetails.conf['fixedMsg']" class="text-center text-xs text-blue-500">
                {{ selectedDetails.conf['fixedMsg'] }}
              </div>

              <div v-if="!exceed300 && selectedDetails.conf['fixedMeter'] != '3'" class="formula border-none">
                <div class="inline">
                  <div class="price-width text-xs text-pink-400">単価</div>
                  <NumericTextInput
                    name="wash"
                    v-model="selectedDetails.unitPrice.fixed"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-pink-300 price-width mb-2"
                    :class="setBgColor(selectedDetails.unitPriceFixedBgColor)"
                    @change="changeCalcAndPrice(selectedDetails, 'unitPriceFixed', 'fixed')"
                  />
                </div>
                <span class="pt-3">×</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">オーナー契約</div>
                  <NumericTextInput
                    name="ownerFixed"
                    v-model="selectedDetails.conf['ownerFixed']"
                    :decimalPlace="decimal2"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['ownerFixedBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'ownerFixed', 'fixed')"
                  />
                </div>
                <span class="pt-3">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-purple-500">合計単価</div>
                  <NumericTextInput
                    name="fixed"
                    v-model="selectedDetails.unitPrice.fixedTotal"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
              </div>

              <div v-if="!exceed300 && selectedDetails.conf['fixedMeter'] != '3'" class="formula border-none">
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">発電機 単価</div>
                  <NumericTextInput
                    name="priceGenerator"
                    v-model="selectedDetails.conf['generator']"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['generatorBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'generator', 'fixed')"
                  />
                </div>
                <span class="pt-3">×</span>
                <div>
                  <div class="price-width text-xs text-gray-500 mb-2">台数</div>
                  <span class="pt-5 number">
                    <span v-if="fixed[10]">
                      {{ fixed[10].value }}
                    </span>
                  </span>
                </div>
                <span class="pt-3">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-green-600">発電機代</div>
                  <NumericTextInput
                    name="generator"
                    v-model="selectedDetails.price['generator']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
              </div>

              <div v-if="!exceed300 && selectedDetails.conf['fixedMeter'] != '3'" class="formula border-none">
                <span class="symbol2">&#040;</span>
                <div class="inline">
                  <div class="price-width text-xs text-purple-500">合計単価</div>
                  <NumericTextInput
                    name="fixed"
                    v-model="selectedDetails.unitPrice.fixedTotal"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
                <span class="pt-3">+</span>
                <div class="inline">
                  <div class="price-width text-xs text-green-600">発電機代</div>
                  <NumericTextInput
                    name="fixed"
                    v-model="selectedDetails.price['generator']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
                <span class="pt-3">+</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">駐車場代</div>
                  <NumericTextInput
                    name="parkValue"
                    v-model="selectedDetails.conf['parkValue']"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['parkValueBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'parkValue', 'fixed')"
                  />
                </div>
                <span class="symbol2 mr-4">&#041;</span>

                <span class="pt-3">÷</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500 mb-2">協力費として</div>
                  <span class="pt-5 number">
                    <span v-if="fixed[11]">
                      {{ selectedDetails.conf['compeFixed'] }}
                    </span>
                  </span>
                </div>

                <span class="pt-3">×</span>
                <div>
                  <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
                  <NumericTextInput
                    name="finishPointFixed"
                    v-model="selectedDetails.conf['finishPointFixed']"
                    :decimalPlace="decimal2"
                    class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                    :class="setBgColor(selectedDetails.conf['finishPointFixedBgColor'])"
                    @change="changeCalcAndPrice(selectedDetails.conf, 'finishPointFixed', 'fixed')"
                  />
                </div>

                <span class="pt-3">＝</span>
                <div class="inline">
                  <div class="price-width text-xs text-gray-500">*10円単位 切上*</div>
                  <NumericTextInput
                    name="fixed"
                    v-model="totalUnitPrice.fixed"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width mb-2"
                  />
                </div>
              </div>
              
              <!-- 発注明細の元値用（オーナーなし金額） -->
              <div v-if="!fixedApollo && !exceed300 && selectedDetails.conf['fixedMeter'] != '3'" class="text-xs text-blue-700 mt-2">
                <div>
                  自社以外の発注単価の元値（オーナー契約なしの単価）： <span class="text-sm font-bold">{{ numberToLocale(selectedDetails.price['fixedPurchase']) }} 円</span>
                </div>
              </div>

              <!-- 定期清掃 300㎡超えの場合 -->
              <div v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'">
                <form class="exceed">
                  <div class="formula border-none">
                    <div class="inline">
                      <div class="price-width text-xs text-pink-400">単価</div>
                      <NumericTextInput
                        name="wash"
                        v-model="selectedDetails.unitPrice.fixed"
                        class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-pink-300 price-width mb-2"
                        :class="setBgColor(selectedDetails.unitPriceFixedBgColor)"
                        @change="changeForFixedExeed300UnitPrice()"
                      />
                    </div>
                    <span class="pt-3">×</span>
                    <div class="inline">
                      <div class="price-width text-xs text-gray-500">オーナー契約</div>
                      <NumericTextInput
                        name="ownerFixed"
                        v-model="selectedDetails.conf['ownerFixed']"
                        :decimalPlace="decimal2"
                        class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                        :class="setBgColor(selectedDetails.conf['ownerFixedBgColor'])"
                        @change="changeForFixedExeed300Owner()"
                      />
                    </div>
                    <span class="pt-3">＝</span>
                    <div class="inline">
                      <div class="price-width text-xs text-purple-500">基本単価</div>
                      <NumericTextInput
                        name="fixed"
                        v-model="selectedDetails.unitPrice.fixed300"
                        :disabled="true"
                        class="text-center py-1 border-none input disabled-back price-width mb-2"
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div class="list mt-7">
                <div class="text-xs font-medium text-gray-500">作業範囲（特記事項にも使用）</div>
                <div class="text-xs font-medium text-gray-400 text-center mt-2">※未記入時は「アプローチ・エントランス・共用廊下・共用階段・管理室・エレベーター」を特記事項に記載します。</div>

                <!-- テーブル -->
                <table class="table-fixed table mt-2">
                  <thead>
                    <tr>
                      <th class="col-ll py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                        作業部位
                      </th>
                      <th colspan="2" class="col-130 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                        数量
                      </th>
                      <!-- 定期清掃 300㎡超えの場合のみ表示 -->
                      <th v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="col-smm py-2 text-center text-xs font-medium text-purple-400 uppercase tracking-wider bg-gray-50 border-gray">
                        基本単価
                      </th>
                      <!-- 定期清掃 300㎡超えの場合のみ表示 -->
                      <th v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="col-l py-2 text-center text-xs font-medium text-blue-300 uppercase tracking-wider bg-gray-50 border-gray">
                        項目による係数
                      </th>
                      <th v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="col-midle py-2 text-center text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-50 border-gray">
                        単価
                      </th>
                      <th v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="col-midle py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                        合計（数量×単価）
                      </th>
                      <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                        <!-- プラスボタン -->
                        <PlusCircleIcon class="text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions" @click="addRowDetailFixed" />
                      </th>
                    </tr>
                  </thead>

                  <tr v-for="(display, index) in selectedDetails.displayFixed" :key="index">
                    <!-- 内容 定期清掃 -->
                    <td class="col-ll text-center px-1 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height">
                      <!-- ドロップダウン -->
                      <div class="m-auto">
                        <WmsSuggestInput
                          name="fixedName"
                          :embed="true"
                          :dynamic="false"
                          displayField="name"
                          :incrementalSearch="false"
                          :selectionItems="fixedNameList"
                          class="embed"
                          v-model="selectedDetails.displayFixed[index].name"
                          @selected="getFixedRange($event, index)"
                          @blur="getFixedRange($event, index)"
                        />
                      </div>
                    </td>
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-small">
                      <NumericTextInput
                        name="qty"
                        v-model="selectedDetails.displayFixed[index].qty"
                        :decimalPlace="selectedDetails.displayFixed[index].qty&&!Number.isInteger(Number(selectedDetails.displayFixed[index].qty))?decimal1:0"
                        class="w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                      />
                    </td>
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-sm">
                      <!-- ドロップダウン -->
                      <div class="m-auto">
                        <WmsSuggestInput
                          name="unitName"
                          :embed="true"
                          :dynamic="false"
                          displayField="name"
                          :incrementalSearch="false"
                          :selectionItems="unitNameList"
                          class="embed"
                          v-model="selectedDetails.displayFixed[index].unitName"
                        />
                      </div>
                    </td>
                    <!-- 定期清掃 300㎡超えの場合のみ表示（基本単価） -->
                    <td v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-smm">
                      <NumericTextInput
                        name="exceed300PriceKind"
                        v-model="selectedDetails.displayFixed[index].priceKind"
                        :disabled="true"
                        class="text-gray-700 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-none cursor-not-allowed"
                        :class="setBgColor(display.priceKindBgColor)"
                        @change="changeFixedPrePrice(display)"
                      />
                    </td>
                    <!-- 定期清掃 300㎡超えの場合のみ表示（項目による係数） -->
                    <td v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-l">
                      <!-- ドロップダウン -->
                      <div class="m-auto">
                        <select
                          :name="'exceed300KindCoef' + index"
                          v-model="selectedDetails.displayFixed[index].kindCoef"
                          class="text-xs border-0 w-full"
                        >
                          <option
                            v-for="key in selectedDetails.conf.kindCoef" :key='key.id'
                            :value="key.name"
                            class="bg-white">
                            {{ key.name }}
                          </option>
                        </select>
                        <!-- <WmsSuggestInput
                          name="'exceed300KindCoef'"
                          :embed="true"
                          :dynamic="false"
                          displayField="name"
                          :incrementalSearch="false"
                          :selectionItems="selectedDetails.conf.kindCoef"
                          class="embed"
                          v-model="selectedDetails.displayFixed[index].kindCoef"
                        /> -->
                      </div>
                    </td>
                    <td v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-midle">
                      <NumericTextInput
                        name="price"
                        v-model="selectedDetails.displayFixed[index].price"
                        :disabled="true"
                        class="text-right py-1 border-none input disabled-back col-midle"
                      />
                    </td>
                    <td v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-midle">
                      <NumericTextInput
                        name="total"
                        v-model="selectedDetails.displayFixed[index].total"
                        :disabled="true"
                        class="text-right py-1 border-none input disabled-back col-midle"
                      />
                    </td>
                    <td class="px-0.5 py-0.5 whitespace-nowrap text-center text-sm font-medium border-gray">
                      <div class="flex justify-center">
                        <a href="#" @click="deleteFixedRow(index)">
                          <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </table>

                <div v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="formula">
                  <span class="symbol2">&#040;</span>
                  <div class="inline">
                    <div class="price-width text-xs text-purple-500">単価</div>
                    <NumericTextInput
                      name="fixed"
                      v-model="selectedDetails.exceed300Price"
                      class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                      :class="setBgColor(selectedDetails.exceed300PriceBgColor)"
                      @change="changeCalcAndPrice(selectedDetails, 'exceed300Price', 'fixed')"
                    />
                  </div>
                  <span class="pt-4">×</span>
                  <div class="pt-4">
                    <div class="price-width-sm text-xs text-gray-400 mb-2"></div>
                    <span class="pt-5 number font-bold">
                      1.8
                    </span>
                  </div>
                  <span class="symbol2 mr-4">&#041;</span>
                  <span class="pt-4">÷</span>
                  <div class="pt-4">
                    <div class="price-width-sm text-xs text-gray-400 mb-2"></div>
                    <span class="pt-5 number font-bold">
                      2
                    </span>
                  </div>
                  <span class="pt-4">＝</span>
                  <div class="inline">
                    <div class="price-width text-xs text-purple-500">合計単価</div>
                    <NumericTextInput
                      name="fixed"
                      v-model="selectedDetails.unitPrice.fixedTotal"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back price-width mb-2"
                    />
                  </div>
                </div>

                <div v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="formula">
                  <div class="inline">
                    <div class="price-width text-xs text-gray-500">発電機 単価</div>
                    <NumericTextInput
                      name="priceGenerator"
                      v-model="selectedDetails.conf['generator']"
                      class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                      :class="setBgColor(selectedDetails.conf['generatorBgColor'])"
                      @change="changeCalcAndPrice(selectedDetails.conf, 'generator', 'fixed')"
                    />
                  </div>
                  <span class="pt-4">+</span>
                  <div>
                    <div class="price-width text-xs text-gray-500 mb-2">台数</div>
                    <span class="pt-5 number">
                      <span v-if="fixed[10]">
                        {{ fixed[10].value }}
                      </span>
                    </span>
                  </div>
                  <span class="pt-4">＝</span>
                  <div class="inline">
                    <div class="price-width text-xs text-green-600">発電機代</div>
                    <NumericTextInput
                      name="fixed"
                      v-model="selectedDetails.price['generator']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back price-width mb-2"
                    />
                  </div>
                </div>

                <div v-if="exceed300 || selectedDetails.conf['fixedMeter'] == '3'" class="formula">
                  <span class="symbol2">&#040;</span>
                  <div class="inline">
                    <div class="price-width text-xs text-purple-500">合計単価</div>
                    <NumericTextInput
                      name="fixed"
                      v-model="selectedDetails.unitPrice.fixedTotal"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back price-width mb-2"
                    />
                  </div>
                  <span class="pt-4">+</span>
                  <div class="inline">
                    <div class="price-width text-xs text-green-600">発電機代</div>
                    <NumericTextInput
                      name="fixed"
                      v-model="selectedDetails.price['generator']"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back price-width mb-2"
                    />
                  </div>
                  <span class="pt-4">+</span>
                  <div class="inline">
                    <div class="price-width text-xs text-gray-500">駐車場代</div>
                    <NumericTextInput
                      name="parkValue"
                      v-model="selectedDetails.conf['parkValue']"
                      class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                      :class="setBgColor(selectedDetails.conf['parkValueBgColor'])"
                      @change="changeCalcAndPrice(selectedDetails.conf, 'parkValue', 'fixed')"
                    />
                  </div>
                  <span class="symbol2 mr-4">&#041;</span>
                  <span class="pt-4">÷</span>
                  <div>
                    <div class="cof-price-width text-xs text-gray-500 mb-2">協力費として</div>
                    <span class="pt-5 number">
                      <span v-if="fixed[11]">
                        {{ selectedDetails.conf['compeFixed'] }}
                      </span>
                    </span>
                  </div>
                  <span class="pt-4">×</span>
                  <div>
                    <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
                    <NumericTextInput
                      name="finishPointFixed"
                      v-model="selectedDetails.conf['finishPointFixed']"
                      :decimalPlace="decimal2"
                      class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                      :class="setBgColor(selectedDetails.conf['finishPointFixedBgColor'])"
                      @change="changeCalcAndPrice(selectedDetails.conf, 'finishPointFixed', 'fixed')"
                    />
                  </div>
                  <span class="pt-4">＝</span>
                  <div class="inline">
                    <div class="price-width text-xs text-gray-500">*10円単位 切上*</div>
                    <NumericTextInput
                      name="fixed"
                      v-model="totalUnitPrice.fixed"
                      :disabled="true"
                      class="text-center py-1 border-none input disabled-back price-width mb-2"
                    />
                  </div>
                </div>
              
                <!-- 発注明細の元値用（オーナーなし金額） -->
                <div v-if="!fixedApollo && (exceed300 || selectedDetails.conf['fixedMeter'] == '3')" class="mt-7 text-xs text-blue-700">
                  <div>
                    自社以外の発注単価の元値（オーナー契約なしの単価）： <span class="text-sm font-bold">{{ numberToLocale(selectedDetails.price['fixedPurchase']) }} 円</span>
                    <span class="text-gray-500"> ＜(合計単価 ÷ {{selectedDetails.conf['ownerFixed']}} + 発電機 + 駐車場) ÷ {{selectedDetails.conf['compeFixed']||1}} × {{selectedDetails.conf['finishPointFixed']||1}}＞</span>
                  </div>
                </div>
                
                <div class="mt-10 text-sm font-medium text-gray-500"> 合計作業範囲：{{range}} ㎡</div>

                <table class="border border-gray-200 rounded sp-range">
                  <thead>
                    <th class="px-10 text-center text-xs font-medium tracking-wider border-gray text-gray-500 bg-purple-50">
                      作業部位の表示
                    </th>
                    <td class="border-left bg-white text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray">
                      <div class="m-auto flex justify-around">
                        <div class="ml-4 pr-4">
                          <input
                            type="radio"
                            id="hidden"
                            value="2"
                            class="mt-3 mb-3 radio-width"
                            v-model="selectedDetails['fixedContent']"
                            @change="changeFixedContent()"
                          />
                          <label for="hidden" class="radio-range">非表示</label>
                        </div>
                        <div class="ml-4">
                          <input
                            type="radio"
                            id="no"
                            value="0"
                            class="mt-3 mb-3 radio-width"
                            v-model="selectedDetails['fixedContent']"
                            @change="changeFixedContent()"
                          />
                          <label for="no" class="radio-range">合計作業範囲のみ</label>
                        </div>
                        <div class="mx-4">
                          <input
                            type="radio"
                            id="yes"
                            value="1"
                            class="mt-3 mb-3 radio-width"
                            v-model="selectedDetails['fixedContent']"
                            @change="changeFixedContent()"
                          />
                          <label for="yes" class="radio-range">作業部位も表示</label>
                        </div>
                      </div>
                    </td>
                  </thead>
                </table>
              </div>
              <!-- ---------------定期清掃--end------------- -->
            </div>
          </div>
          <!-- 発注 -->
          <div class="sm:col-span-full pt-4 table2">
            <div class="flex items-end">
              <label class="text-xs font-bold text-gray-700 flex items-center">発注明細</label>
              <div class="text-xs text-gray-500 ml-96">自社以外の発注金額はオーナー契約なしの金額（切上なし）から求めています</div>
              <button class="w-auto py-0 text-sm ml-auto mr-4 text-indigo-800 hover:text-indigo-500" @click="openPurchaseModal()">発注先マスタ編集</button>
            </div>
            <div class="mt-1">
              <EstimatePurchase
                :purchase="purchase"
                :allDisabled="false"
                :purchaseModalShow="purchaseModalShow"
                :purchaseReload="purchaseReload"
                :typeName="true"
                :detailModal="true"
                @getPurchasePrice="getPurchasePrice"
                @changeInitRate="changeInitRate"
              >
              </EstimatePurchase>
            </div>
          </div>

          <!-- 手数料 -->
          <div class="sm:col-span-full pt-4 table2">
            <div v-if="commissionMst" class="text-xs font-bold text-center text-commission">{{ commissionMst.msg }}</div>
            <div class="flex justify-between items-end">
              <label class="text-xs font-bold text-gray-700 flex justify-between">手数料明細</label>
                  <button class="w-auto py-0 text-sm ml-auto mr-3 text-indigo-800 hover:text-indigo-500" @click="commissionModalShow=true">手数料マスタ編集</button>
            </div>
            <div class="mt-1">
              <EstimateCommission
                :commission="commission"
                :commissionReload="commissionReload"
                @rateSelected="rateSelected"
                @rateBlur="rateBlur"
              >
              </EstimateCommission>
            </div>
          </div>

          <div class="list">
            <!-- テーブル -->
            <div class="flex items-center mt-8">
              <span class="text-gray-500 font-bold text-xs">並べ替え</span>
              <Toggle v-model="sortableDetail" size="small" class="ml-1 -mt-0.5"/>

              <!-- 見積りに各種別明細を表示 -->
              <div class="ml-auto mr-8">
                <input
                  type="checkbox"
                  id="isUnitPrice"
                  v-model="selectedDetails.isUnitPrice"
                  @change="setIsUnitPriceHtml()"
                >
                <label for="isUnitPrice" class="text-gray-500 font-bold text-xs ml-1"> 見積りに各単価を表示</label>
              </div>
            </div>
            <div class="text-xs text-indigo-400 msg-coef2" v-if="rlsPlusKind=='wash' || rlsPlusKind=='double'">定期清掃作業範囲の内容・数量は上の表で編集してください</div>

            <table class="table-fixed table2 mt-2">
              <thead>
                <tr>
                  <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    No.
                  </th>
                  <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    内容
                  </th>
                  <th colspan="2" class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    数量
                  </th>
                  <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    単価
                  </th>
                  <th colspan="2" class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    数量
                  </th>
                  <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    単価
                  </th>
                  <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    <!-- プラスボタン -->
                    <PlusCircleIcon class="text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions" @click="addRowDetail" />
                  </th>
                </tr>
              </thead>

              <draggable 
                :key="renderKey"
                ref="tableBody"
                :list="selectedDetails.display"
                direction="vertical"
                handle=".row-drag-handle"
                tag="tbody" 
                class="bg-white divide-y divide-gray-200"
              >

                <tr v-for="(display, index) in selectedDetails.display" :key="index">
                  <td :class="setDisableColor(false, display, true)" class="text-center px-0.5 py-0.5 whitespace-nowrap text-sm font-medium text-gray-900 border-gray col-no">
                    <template v-if="sortableDetail">
                      <div class="flex justify-center items-center row-drag-handle cursor-pointer">
                        <MenuIcon class="text-gray-400" />
                      </div>
                    </template>
                    <template v-else>
                      {{ index + 1 }}
                    </template>
                  </td>
                  <td :class="setDisableColor(false, display, true)" class="col-xl text-center px-1 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height">
                    <input
                      type="text" 
                      name="itemName"
                      v-model="display.name"
                      :class="setDisableColor(false, display, true)"
                      :disabled="setDisabledDisp(display, true)"
                      class="w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    />
                  </td>
                  <!-- <td v-if="index==0" :rowspan="selectedDetails.display.length" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-small">
                    <NumericTextInput
                      name="qty"
                      v-model="display.qty"
                      @change="calcTotal"
                      class="w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    />
                  </td>
                  <td v-if="index==0" :rowspan="selectedDetails.display.length" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-sm">
                    <input
                      type="text" 
                      name="unitName"
                      v-model="display.unitName"
                      class="w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent text-center"
                    />
                  </td> -->
                  <td :class="display.type=='fixedRange'?'bg-gray-200':''" :rowspan="setRowspan(index, display.type)" v-if="setRowspan(index, display.type)>0" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-small">
                    <NumericTextInput
                      name="qty"
                      v-model="display.qty"
                      class="max-h w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                      :class="setQtyDisableColor(display.qty=='', display.type)"
                      :decimalPlace="display.qty&&!Number.isInteger(Number(display.qty))?decimal1:0"
                      :disabled="display.type=='fixedRange'"
                    />
                  </td>

                  <td :class="display.type=='fixedRange'?'bg-gray-200':'bg-gray-100'" :rowspan="setRowspan(index, display.type)" v-if="setRowspan(index, display.type)>0" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-sm">
                    <!-- ドロップダウン -->
                    <div class="m-auto">
                      <WmsSuggestInput
                        name="unitName"
                        :embed="true"
                        :dynamic="false"
                        displayField="name"
                        :incrementalSearch="false"
                        :selectionItems="unitNameList"
                        :disabled="display.type=='fixedRange'"
                        class="embed"
                        v-model="display.unitName"
                      />
                    </div>
                  </td>
                  
                  <td :class="setDisableColor(false, display, false)" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-midle">
                    <NumericTextInput
                      name="price"
                      v-model="display.price"
                      class="w-full text-right focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                      :class="[setBgColor(display.detailBgColor), setDisableColor(display.type!='fixedRange'&&display.price=='', display, false)]"
                      :disabled="true"
                      @change="setPriceChange(display, 1)"
                    />
                  </td>
                  
                  <!-- カテゴリー数 -->
                  <td v-if="index==0" :rowspan="selectedDetails.display.length" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-small">
                    <NumericTextInput
                      name="qty2"
                      v-model="display.qty2"
                      :class="display.qty2==''?'bg-blue-200':''"
                      class="max-h w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    />
                  </td>

                  <!-- カテゴリー単位 -->
                  <td v-if="index==0" :rowspan="selectedDetails.display.length" class="bg-gray-100 text-center text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-sm">
                    <!-- ドロップダウン -->
                    <div class="m-auto">
                      <WmsSuggestInput
                        name="unitName2"
                        :embed="true"
                        :dynamic="false"
                        displayField="name"
                        :incrementalSearch="false"
                        :selectionItems="unitNameList"
                        class="embed"
                        v-model="display.unitName2"
                      />
                    </div>
                  </td>
                  
                  <!-- カテゴリー金額 -->
                  <td v-if="index==0" :rowspan="selectedDetails.display.length" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-midle">
                    <NumericTextInput
                      name="price2"
                      v-model="display.price2"
                      :class="setBgColor(display.categoryBgColor)"
                      :disabled="true"
                      @change="setPriceChange(display, 2)"
                      class="max-h w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    />
                  </td>
                  <td class="px-0.5 py-0.5 whitespace-nowrap text-center text-sm font-medium border-gray">
                    <div class="flex justify-center">
                      <a href="#" @click="deleteDetailRow(index)">
                        <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                      </a>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <div class="mt-5 mx-auto note" @copy="onCopyNote()">
              <EstimateRichTextInput
                name="note" 
                caption="特記事項"
                :value="selectedDetails.noteHTML"
                :pasteHtml="noteStyle"
                @change="noteChange($event)"
              >
                <template #additionalToolbar>
                  <button class="w-auto px-2 py-1 m-auto rounded text-xs" :class="noteStyle?'bg-indigo-600 text-white':'bg-gray-300 text-white'" @click="noteStyle=!noteStyle">スタイルを維持してコピペ</button>
                  <button class="w-auto py-0 text-sm ml-auto text-indigo-800 hover:text-indigo-500" @click="selectNote">特記事項を追加</button>
                </template>
              </EstimateRichTextInput>
            </div>

            <!-- 完了ボタン -->
            <PrimaryButton text="完了" size="sm px-10 py-3 place-items-end block mt-3 btn" @click="saveDetail" />
            
          </div>
          <a href="#rls-form" id="page-r" :class="setPosition('rls')">ﾗｳﾝﾄﾞ</a>
          <a href="#normal-form" id="page-n" :class="setPosition('normal')" v-if="rlsPlusKind!='wash'">固定</a>
          <a href="#fixed-form" id="page-f" v-if="rlsPlusKind!='clean'">定期</a>

          <!-- 特記事項を追加 -->
          <EstimateNoteSelectorModal
            v-model="noteSelectorShow" 
            @apply="applyNotes"
          />
          
          <!-- 発注マスタ編集 -->
          <EstimatePurchaseModal 
            v-model="purchaseModalShow"
            :purchaseModalShow="purchaseModalShow"
            @closePurchaseModal="closePurchaseModal"
          />

          <!-- 手数料マスタ編集 -->
          <EstimateCommissionModal 
            v-model="commissionModalShow"
            :open="commissionModalShow"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import EstimateRichTextInput from './EstimateRichTextInput.vue'
import draggable from "vuedraggable"
import Modal from '@components/Modal.vue'
import Toggle from '@components/Toggle.vue'
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import NumericTextInput from '@components/NumericTextInput.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
import EstimateNoteSelectorModal from '../components/EstimateNoteSelectorModal.vue'
import EstimatePurchase from '../components/EstimatePurchase.vue'
import EstimatePurchaseModal from '../components/EstimatePurchaseModal.vue'
import EstimateCommissionModal from '../components/EstimateCommissionModal.vue'
import EstimateCommission from '../components/EstimateCommission.vue'
import * as eriaManager from '@managers/eriaManager'
import * as constants from '@libs/constantsEstimate'
import * as dialogs from '@libs/dialogs'
import * as estimateManager from '@managers/estimateManager'
import * as utils from '@libs/utils'
import 'quill2/dist/quill.core.css'
import 'quill2/dist/quill.snow.css'
import BigNumber from 'bignumber.js'

const ClEAN_NOTE = 'ラウンドクリーンプラス単独ではお受けできません。ご了承下さい。'

export default {
  components: {
    EstimateRichTextInput,
    WmsSuggestInput,
    WmsTimeInput2,
    draggable,
    Modal,
    Toggle,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    NumericTextInput,
    PrimaryButton,
    EstimateNoteSelectorModal,
    EstimatePurchase,
    EstimatePurchaseModal,
    EstimateCommission,
    EstimateCommissionModal
  },

  inheritAttrs: false,

  props: {
    categoryCode: {
      type: String,
      default: null
    },
    categoryName: {
      type: String,
      default: null
    },
    buildingInfo: {
      type: Object,
      default: null
    },
    modalData: {
      type: Object,
      default: null
    },
    row: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    open: {
      type: Number,
      default: null
    },
    selectCallback: {
      type: Function,
      default: () => {}
    },
    purchaseData: {
      type: Array,
      default: null
    },
    commissionData: {
      type: Object,
      default: null
    },
    commissionMst: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      renderKey: 0,
      active: false,
      forBind: 0,

      selectedDetails: {
        // 親画面表示内容
        display:[
          {
            // 内容
            name: '巡回清掃',
            // 数量
            qty: '1',
            // 単位
            unitName: 'ヶ月',
            // 金額
            price: '0',
            // タイプ どの種類の表示をしているか
            type: 'rls',
            // 単価を手動で変更フラグ
            detailBgColor: false,
            // カテゴリー数量
            qty2: '12',
            // カテゴリ－単位
            unitName2: 'ヶ月',
            // カテゴリー金額
            price2: '',
            // カテゴリー金額変更
            categoryBgColor: false
          },
          {
            name: '固定清掃（週　回・3時間以内）',
            qty:'1',
            unitName: 'ヶ月',
            price: '0',
            type: 'normal',
            detailBgColor: false,
            qty2: '12',
            unitName2: '',
            price2: '',
            categoryBgColor: false
          },
        ],
        // 選択内容履歴(ツールチップ用)
        toolTip: {},
        // 係数
        conf: {},
        // 特記事項
        noteCode: [],
        // 単価表の単価
        unitPrice:{
          rls: '0',
          normal: '0',
          fixed: '0',
          fixed300: '0'
        },
        // その他、計算結果の金額
        price: {},
        // 上の項目表の選択、入力した値
        selectedRls: [],
        selectedNormal: [],
        selectedFixed: [],
        displayFixed:[],
        // 選択したタグ
        tabId: 'clean',
        // 固定清掃の時間帯・曜日
        remarks: [
          {start: '', end: '', week: []}
        ],
        // 定期清掃の表
        // 手動で価格変更フラグ
        unitPriceRlsBgColor: false,
        unitPriceNormalBgColor: false,
        unitPriceFixedBgColor: false,
        // 定期清掃 300㎡超え 合計
        exceed300Price: '0',
        exceed300PriceBgColor: false,
        // 定期清掃 部位の表示
        fixedContent: '2',
        // RLS 一度でも単価取得したか
        getRlsPrice: false,
        // 日常清掃 一度でも単価取得したか
        getNormalPrice: false,
        // 定期清掃 一度でも単価取得したか
        getFixedPrice: false,
        // 特記事項
        noteHTML: '',
        // 定期清掃の作業部位の行を増やしたか
        addFixedRows: false,
        // 発注金額を手動で変更
        changePurchasePrice: false,
        // 発注金額を手動で変更
        changePurchasePriceType: [],
        // 見積りに単価を表示するか否か
        isUnitPrice: false
      },
      // 表示する項目 ラウンドサービス
      rls: [],
      // 表示する項目 日常清掃
      normal: [],
      // 表示する項目 定期清掃
      fixed: [],
      // 計算単価結果
      totalUnitPrice: {
        rls: '0',
        normal: '0',
        fixed: '0'
      },
      // 合計金額
      total: {
        rls: '0',
        normal: '0',
        fixed: '0'
      },
      // 並べ替え
      sortableDetail: false,
      // 計算式表示フラグ
      formulaDispFlag: false,
      // 小数の表示
      decimal1: 1,
      decimal2: 2,
      // 画面にバインドするための変数
      valueArr: [],
      // 定期清掃作業範囲 合計
      range: '0',
      
      // ラウンドプラスのタブ
      rlsPlustabs: [
        {id: 'clean', name: 'クリーンプラス', active: true},
        {id: 'wash', name: 'ウォッシュプラス', active: false},
        {id: 'double', name: 'ダブルプラス', active: false},
      ],
      // 単位
      unitNameList: [
        {name: '㎡'},
        {name: '回'},
        {name: '個'},
        {name: '式'},
        {name: 'ｍ'},
        {name: '箇所'},
        {name: '系統'},
        {name: 'ヶ月'},
        {name: '回/年'},
        {name: '回/2年'},
        {name: '回/3年'}
      ],
      // 日曜が含まれる
      isSunday: false,
      // 曜日選択
      week: [[
        {id: '0', name: '月', active: false},
        {id: '1', name: '火', active: false},
        {id: '2', name: '水', active: false},
        {id: '3', name: '木', active: false},
        {id: '4', name: '金', active: false},
        {id: '5', name: '土', active: false},
        {id: '6', name: '日', active: false},
      ]],
      // 時間帯
      hourItems: [
        { text: '7' },
        { text: '8' },
        { text: '9' },
        { text: '10' },
        { text: '11' },
        { text: '12' },
        { text: '13' },
        { text: '14' },
        { text: '15' },
        { text: '16' },
        { text: '17' },
        { text: '18' },
        { text: '19' },
        { text: '20' },
        { text: '21' },
        { text: '22' },
        { text: '23' }
      ],
      minuteItems: [
        { text: '00' },
        { text: '15' },
        { text: '30' },
        { text: '45' }
      ],
      // 特記事項追加
      noteSelectorShow: false,
      // 発注モーダル
      purchaseModalShow: false,
      // 発注明細（過去の見積りにはないため、ここで宣言）
      purchase: [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        supplier: '',
        rate: '',
        price: '',
        priceBgColor: false,
        rateBgColor: false,
        type: ''
      }],
      // カテゴリ金額合計
      categoryAmount: 0,
      // 発注明細表示のための変数
      purchaseReload: 0,
      // 発注金額手動で変更後、自動計算直後
      changePurchasePrice: false,
      // 発注率超えた
      overPurchaseRate: [],
      // 初期発注率
      initRate: 0,
      // モーダルを開いたばかり
      openInit: true,
      // 手数料モーダル
      commissionModalShow: false,
      // 手数料明細（過去の見積りにはないため、ここで宣言）
      commission: [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        estimatePrice: '',
        rate: '',
        commissionPrice: '',
        price: ''
      }],
      // 手数料明細表示のための変数
      commissionReload: 100,
      // 発注先がアポロか否か
      rlsApollo: true,
      normalApollo: true,
      fixedApollo: false,
      // 特記事項スタイルそのままコピペ
      noteStyle: false,
    }
  },

  watch: {
    async open() {
      if (this.categoryCode == null) {
        return
      }
      this.fetchEstimateSubItem()
      // 発注金額
      this.categoryAmountCalc()
      this.purchase = utils.clone(this.purchaseData)
      // await this.getInitPurchaseRate()
      this.getCommissionData(this.modalData)
      this.purchaseReload++
    },

    'selectedDetails.display': {
      handler: function() {
        if (this.changePurchasePrice) {
          return
        }
        this.calcTotal()
      },
      deep: true
    },

    'rls': {
      handler: async function() {
        if (!this.rls.length) {
          return
        }
        await this.calc('rls')
      },
      deep: true
    },

    'normal': {
      handler: async function() {
        if (!this.normal.length) {
          return
        }
        await this.calc('normal')
      },
      deep: true
    },

    'fixed': {
      handler: async function() {
        if (!this.fixed.length) {
          return
        }
        await this.calc('fixed')
      },
      deep: true
    },
    
    'selectedDetails.displayFixed': {
      handler: async function() {
        // 再計算
        if (this.active) {
          await this.calc('fixed')
          this.noteForWax()
        }
        if (this.rlsPlusKind=='wash' || this.rlsPlusKind=='double') {
          this.changeFixedContent()
        }
      },
      deep: true
    },

    'categoryAmount': {
      handler: async function() {
        // this.getPurchasePrice(null, false, false)
        this.getCommissionPrice()
        
        this.openInit = false
      }
    }
  },

  computed: {
    // 計算式の詳細の表示、非表示CSSクラス
    formulaDisp() {
      if (this.formulaDispFlag) {
          return 'formula-open-normal'
      } else {
        return 'formula-close'
      }
    },

    // 定期清掃_300㎡超えの場合のテーブル表示
    exceed300() {
      if (this.fixed[1]) {
        if (this.fixed[1].value == '3') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    /**
     * ラウンドプラス種類
     */
    rlsPlusKind() {
      return this.rlsPlustabs.find((r) => {
        return r.active == true
      }).id
    },

    /**
     * 定期清掃 「内容」候補
     */
    fixedNameList() {
      let list = []
      list = [
        {name: 'エントランス'},
        {name: '廊下'},
        {name: '階段'},
        {name: '管理室'}
      ]
      return list
    },
  },

  methods: {

    /**
     * 詳細選択項目を取得
     */
    fetchEstimateSubItem() {
      this.active = true
      this.openInit = true
      this.rls = []
      this.normal = []
      this.fixed = []

      this.selectedDetails.changePurchasePrice = this.row.changePurchasePrice

      // 親に関する中項目を取得
      const list = this.$store.getters.estimateSubItemList

      this.rls = list.filter((s) => {
        return s.parentCode == '1'
      })

      this.normal = list.filter((s) => {
        return s.parentCode == '3'
      })
      
      this.fixed = list.filter((s) => {
        return s.parentCode == '5'
      })

      // オブジェクトに変換
      this.rls = this.parseObj(this.rls)
      
      this.normal = this.parseObj(this.normal)
      // 清掃回数は2択のみ
      this.normal[7].itemCode = {"1":"週 1 回","2":"週 2 回"}
      // 清掃時間は3択のみ
      this.normal[6].itemCode = {"2":"2 時間","3":"3 時間","4":"4 時間"}
      
      this.fixed = this.parseObj(this.fixed)

      // 編集であれば、内容を反映
      if (Object.keys(this.modalData).length) {
        this.selectedDetails = utils.clone(this.modalData)
        // this.calc()
        for (let i = 0; i < this.selectedDetails.selectedRls.length; i++) {
          this.rls[i].value = this.selectedDetails.selectedRls[i]
          
        }
        for (let i = 0; i < this.selectedDetails.selectedNormal.length; i++) {
          this.normal[i].value = this.selectedDetails.selectedNormal[i]
          
        }
        for (let i = 0; i < this.selectedDetails.selectedFixed.length; i++) {
          this.fixed[i].value = this.selectedDetails.selectedFixed[i]
          
        }

        this.rlsPlustabs.forEach((t) => {
          if (t.id == this.selectedDetails.tabId) {
            t.active = true
          } else {
            t.active =false
          }
        })
        // 人数分のremarksをセット
        this.setRemarks()

        // 曜日セット
        this.setWeeks()

        // 日曜があるか
        this.checkSunday()

        // 定期清掃の㎡数不明なら、戸数の文言変更
        if (this.fixed[1].value == '4') {
          this.fixed[4].name = '戸数（作業時間の目安・概数算出）'
        }

        return
      }

      this.selectedDetails.selectedRls = new Array(this.rls.length)
      this.selectedDetails.selectedNormal = new Array(this.normal.length)
      this.selectedDetails.selectedFixed = new Array(this.fixed.length)
      
      // 初期値設定
      this.setValue()
    },

    /**
     * オブジェクトに変換
     * @list データ
     */
    parseObj(list) {
      let parse = list.map((original) => {
        if (original.selectValue != '') {
          return {...original, itemCode: JSON.parse(original.selectValue.replace(/\//g, ''))}
        } else {
          return {...original}
        }
      })
      return parse
    },

    /**
     * 初期値を設定
     */
    async setValue() {
      // RLS
      for (let i = 0; i < this.rls.length; i++) {
        // オーナー契約
        if (this.rls[i].item == 'owner') {
          this.setInitValue(this.rls[i], this.buildingInfo.owner)

        // 竣工
        } else if (this.rls[i].item == 'finishPoint' && this.buildingInfo.finishPoint) {
          this.setInitValue(this.rls[i], this.buildingInfo.finishPoint)
        }
      }

      // 日常清掃
      for (let i = 0; i < this.normal.length; i++) {
        // オーナー契約
        if (this.normal[i].item == 'owner') {
          this.setInitValue(this.normal[i], this.buildingInfo.owner)

        // 単独発注
        } else if (this.normal[i].item == 'other') {
          this.setInitValue(this.normal[i], this.buildingInfo.other)

        // 竣工
        } else if (this.normal[i].item == 'finishPoint' && this.buildingInfo.finishPoint) {
          this.setInitValue(this.normal[i], this.buildingInfo.finishPoint)
        }
      }

      // 定期清掃
      for (let i = 0; i < this.fixed.length; i++) {
        // オーナー契約
        if (this.fixed[i].item == 'owner') {
          this.setInitValue(this.fixed[i], this.buildingInfo.owner)

        // 竣工
        } else if (this.fixed[i].item == 'finishPoint' && this.buildingInfo.finishPoint) {
          this.setInitValue(this.fixed[i], this.buildingInfo.finishPoint)
        }
      }

    // RLS-------
      // 戸数をセット
      this.setInitValue(this.rls[0], this.buildingInfo.households)

      // 居住タイプをセット＿シングル_変更可
      this.setInitValue(this.rls[1], 'シングル')

      // 清掃回数をセット
      this.setInitValue(this.rls[2], this.buildingInfo.plusCleaningTimes)

      // ゴミ出し回数をセット
      this.setInitValue(this.rls[3], this.buildingInfo.rlsPlusTrash)

      // トイレ回数をセット
      this.setInitValue(this.rls[5], this.buildingInfo.plusToiletTimes)

      // トイレ箇所をセット
      this.setInitValue(this.rls[6], this.buildingInfo.plusToiletPlace)

      // 検針、写真
      // ツールチップ生成
      this.selectedDetails.toolTip[this.rls[7].name] = this.rls[7].value
      this.selectedDetails.toolTip[this.rls[8].name] = this.rls[8].value
      this.selectedDetails.toolTip[this.rls[9].name] = this.rls[9].itemCode[this.rls[9].value]

    // 日常清掃-------
      // エリア
      let eriaN = await eriaManager.getEria(this, this.buildingInfo.address, 'normal')
      if (eriaN) {
        // 値からキーを取得し保存、ツールチップ生成
        this.setInitValue(this.normal[0], eriaN)
      }

      // トイレ使用
      this.setInitValue(this.normal[1], this.buildingInfo.availableToilet)

      // トイレ回数
      if (this.buildingInfo.plusToiletTimesNormal) {
        this.setInitValue(this.normal[2], this.buildingInfo.plusToiletTimesNormal)
      }

      // トイレ箇所
      if (this.buildingInfo.plusToiletPlaceNormal) {
        this.setInitValue(this.normal[3], this.buildingInfo.plusToiletPlaceNormal)
      }

      // 時間指定＿なし固定！
      this.setInitValue(this.normal[4], 'なし')

      // 清掃時間＿3時間固定！
      this.setInitValue(this.normal[6], '3 時間')

      // 人数をセット＿1人固定！
      this.setInitValue(this.normal[8], '1 人')

      // 単独発注＿他あり固定！➡さらに仕様変更で単独発注変更可能2023.03.20
      // this.normal[10].value = '1'
      
      // 単独発注
      if (this.buildingInfo.other == '単独') {
        this.normal[11].value = ''
      }

      // タイプ
      this.setInitValue(this.normal[12], this.buildingInfo.residenceType)

      // 戸数
      this.setInitValue(this.normal[13], this.buildingInfo.households)

      // 階数
      this.setInitValue(this.normal[14], this.buildingInfo.stairs)

      // 年末年始＿なし固定!金額が高くなる
      this.normal[18].value = '0'

    // 定期清掃-------
      // エリア
      let eriaF = await eriaManager.getEria(this, this.buildingInfo.address, 'fixed')
      if (eriaF) {
        // 値からキーを取得し保存、ツールチップ生成
        this.setInitValue(this.fixed[0], eriaF)
      }

      // 清掃回数をセット＿6回/年固定!
      this.fixed[2].value = '6'
      
      // 駐車場代をセット
      this.setInitValue(this.fixed[3], this.buildingInfo.parking)

      // 戸数をセット
      this.setInitValue(this.fixed[4], this.buildingInfo.households)
      
      if (Number(this.fixed[4].value) <= 20) {
        this.setInitValue(this.fixed[5], '1~2時間(300㎡以下)')
      } else if (Number(this.fixed[4].value) <= 60) {
        this.setInitValue(this.fixed[5], '半日')
      } else if (Number(this.fixed[4].value) > 60) {
        this.setInitValue(this.fixed[5], '1日')
      }

      // タイプ
      this.setInitValue(this.fixed[7], this.buildingInfo.residenceType)

      // 階数
      this.setInitValue(this.fixed[8], this.buildingInfo.stairs)

      // 300㎡超え用のテーブル項目追加
      // 単価の種類
      this.selectedDetails.conf.priceKind = {}
      this.selectedDetails.price.priceKind = new Array(3)
      // 項目による係数
      // this.selectedDetails.display[0].kindCoef = 'なし (×1)'
      this.selectedDetails.conf.kindCoef = []
      // 画面のバインド用変数
      this.valueArr = ['', '', '']
    },

    /**
     * 初期値のセット、ツールチップの保存
     * @param item 対象項目
     * @param value 値
     */
    setInitValue(item, value) {
      // ラジオボタン、ドロップダウンの場合（値からキーを取得）
      if (item.selectType == 'radio' || item.selectType == 'dropDown') {
        if (value == null || value == undefined) {
          item.value = ''
          return
        }
        let keyArry = Object.keys(item.itemCode)
        for (let i = 0; i < keyArry.length; i++) {
          let key = keyArry[i]
          if (value == item.itemCode[key]) {
            item.value = key

            if (item.item == 'eriaNormalManagement') {
              this.selectedDetails.toolTip[item.name + '_固定清掃'] = value
              return
            } else if (item.item == 'eriaFixed') {
              this.selectedDetails.toolTip[item.name + '_定期清掃'] = value
              return
            } else if (item.item == 'cleaningTimes' && item.order == '02') {
              this.selectedDetails.toolTip[item.name + '_RLS'] = value
              return
            } else if (item.name == '清掃回数' && item.order == '07') {
              this.selectedDetails.toolTip[item.name + '_固定清掃'] = value
              return
            } else if (item.name == '清掃回数（回/年）') {
              this.selectedDetails.toolTip[item.name + '_定期清掃'] = value
              return
            } else if (item.item == 'metre' && item.order == '11') {
              this.selectedDetails.toolTip[item.name + '_固定清掃'] = value
              return
            } else if (item.item == 'metre' && item.order == '01') {
              this.selectedDetails.toolTip[item.name + '_定期清掃'] = value
              return
            } 
            // ツールチップ生成
            this.selectedDetails.toolTip[item.name] = value
            return
          }
        }
      } else if (item.selectType == 'input') {
        item.value = value
        // ツールチップ生成
        this.selectedDetails.toolTip[item.name] = value
      }
    },

    /**
     * 入力・選択した値をツールチップ用に保存
     * @param item 対象項目
     * @param index 番号
     * @param value 値 ドロップボックスの場合は、itemCodeオブジェクト
     */
    setItem(item, index, value, target, name) {
      let code =target[index].value
      if (code != '') {
        
        // 日常清掃の㎡数3を選択した場合
        if (name == 'normal' && index == 11 && code == '4') {
          // 本数が初期値の0のままなら、反映されたのと勘違いしないように空文字にする
          if (this.normal[15].value == '0') {
            this.normal[15].value = ''
          }

        // 定期の300㎡を選択した場合
        } else if (name == 'fixed' && index == 1 && code == '3') {
          // 5行にする
          if (this.selectedDetails.addFixedRows != undefined && !this.selectedDetails.addFixedRows) {
            // 定期清掃の表を増やす
            for (let i = 0; i < 4; i++) {
              this.addRowDetailFixed()
            }
            this.selectedDetails.addFixedRows = true
          }
        }

        this.restriction(item, index, name)

        // ツールチップ用
        if (item.selectType == 'dropDown') {
          if (item.item == 'eriaNormalManagement') {
            this.selectedDetails.toolTip[item.name + '_固定清掃'] = value[code]
            return
          } else if (item.item == 'eriaFixed') {
            this.selectedDetails.toolTip[item.name + '_定期清掃'] = value[code]
            return
          } else if (item.item == 'cleaningTimes' && item.order == '02') {
            this.selectedDetails.toolTip[item.name + '_RLS'] = value[code]
            return
          } else if (item.name == '清掃回数' && item.order == '07') {
            this.selectedDetails.toolTip[item.name + '_固定清掃'] = value[code]
            return
          } else if (item.item == 'metre' && item.order == '11') {
            this.selectedDetails.toolTip[item.name + '_固定清掃'] = value[code]
            return
          }
          this.selectedDetails.toolTip[item.name] = value[code]
        } else {
          if (item.name == '清掃回数（回/年）') {
            this.selectedDetails.toolTip[item.name + '_定期清掃'] = value
            return
          } else if (item.item == 'metre' && item.order == '01') {
            this.selectedDetails.toolTip[item.name + '_定期清掃'] = value
            return
          }
          this.selectedDetails.toolTip[item.name] = value
        }
      }
    },

    /**
     * 選択した項目によって他の項目の値が決まる
     * @param item 対象項目
     * @param index 番号
     * **ドロップダウンの場合は、値はitem.valueで取得**
     */
    restriction(item, index, name) {
      let target = this[name]
      let tip = this.selectedDetails.toolTip

      switch (name) {

        // 日常清掃
        case 'normal':
          // 人数分の曜日を生成
          this.setWeekArr(index)

          // 他、業務あり
          if (index == 10 & item.value == '1') {
            delete tip['戸数']
            delete tip['階数']
            delete tip['本数']
            delete tip['㎡数']
            target[11].value = ''

          // 他、業務なし
          } else if (index == 10 && item.value == '0') {
            target[11].value = ''

          // ㎡数が300㎡以下 定期概算のツールチップ削除
          } else if (index == 11 && item.value != '4') {
            delete tip['戸数']
            delete tip['階数']
            delete tip['本数']
          }

          break;

          // 定期清掃
          case 'fixed':
            // ㎡数不明の場合、戸数の文言を変更
            if (index == 1 && item.value == '4') {
              target[4].name = '戸数（作業時間の目安・概数算出）'
            } else if (index == 1 && item.value != '4') {
              target[4].name = '戸数（作業時間の目安）省略可'
            }

            // 駐車場代ありの場合、戸数によって作業時間の目安を決める
            if ((index == 3 || index == 4) && target[3].value == '1') {
              if (Number(target[4].value) <= 20) {
                this.setInitValue(target[5], '1～2時間')
              } else if (Number(target[4].value) <= 60) {
                this.setInitValue(target[5], '半日')
              } else if (Number(target[4].value) > 60) {
                this.setInitValue(target[5], '1日')
              }
            } else if ((index == 3 || index == 4) && target[3].value == '0') {
              target[5].value == '0'
              delete tip['作業時間（駐車場代）']
            }
            break;

        default:
          break;
      }
    },

    /**
     * 曜日配列セット
     * @param 選択肢インデックス
     */
    setWeekArr(index) {
      // 人数の選択の場合
      if (index == 8) {
        // 人数-1回分、曜日配列生成
        let num = Number(this.normal[8].value)
        // 曜日の配列数
        let numW = this.week.length
        if (!isNaN(num)) {
          // 人数と曜日配列数が同じなら何もしない
          if (num == numW) {
            return
          
          // 曜日が多い場合
          } else if (num < numW) {
            // 曜日配列削除
            this.week.splice(num, numW - num)
            this.selectedDetails.remarks.splice(num, numW - num)

            // 人数が多い場合
          } else if (num > numW) {
            // 曜日追加
            for (let i = 0; i < num - numW; i++) {
              this.week.push([
                {id: '0', name: '月', active: false},
                {id: '1', name: '火', active: false},
                {id: '2', name: '水', active: false},
                {id: '3', name: '木', active: false},
                {id: '4', name: '金', active: false},
                {id: '5', name: '土', active: false},
                {id: '6', name: '日', active: false},
              ])
              this.selectedDetails.remarks.push({start: '', end: '', week: []})
            }
          }
        }
      }
    },

    /**
     * 空文字なら0をセット
     * @param target 対象データ
     */
    setZeroValue(target) {
      if (target.value == '') {
        target.value = '0'
      }
    },

    /**
     * 入力タイプ表示条件
     * @param item 列データ
     */
    input(item, name) {
      if (item.selectType == 'input') {
        // 日常清掃、定期清掃_前項で選択した値によって表示
        if (name != 'rls') {
          return this.JudgmentDisp(item, name)
        } else {
          return true
        }
      } else {
        return false
      }
    },

    /**
     * ラジオボタン表示条件
     * @param item 列データ
     */
    radio(item, name) {
      if (item.selectType == 'radio') {
        // 日常清掃の祝日対応非表示
        if (name == 'normal' && item.item == 'holiday') {
          return false
        } else if (name == 'normal' && this.rlsPlusKind=='double' && item.item == 'other') {
          return false
        }

        // RLS_点検_戸数&清掃回数に応じて表示
        if (name == 'rls' && item.item == 'inspection') {
          if (this.rls[0].value <= Number(item.disable) && ['m1', 'm2', '1', '2'].includes(this.rls[2].value)) {
            return true
          } else {
            return false
          }

        // 日常清掃、定期清掃_前項で選択した値によって表示
        } else if (name != 'rls') {
          return this.JudgmentDisp(item, name)
        } else {
          return true
        }
      } else {
        return false
      }
    },

    /**
     * ドロップダウン表示条件
     * @param item 列データ
     */
    dropDown(item, name) {
      if (item.selectType == 'dropDown') {
        
        // 日常清掃、定期清掃_前項で選択した値によって表示
        if (name != 'rls'){
          return this.JudgmentDisp(item, name)
        } else {
          return true
        }
      }
    },

    /**
     * 選択肢を項目表に表示するか判断
     * @param item 中項目
     */
    JudgmentDisp (item, name) {
      let result = false
      let No
      let value

      if (name == 'normal' && item.item == 'newYearHoliday') {
        return false
      }

      if (item.disable != '') {
        // データは【選択項目index】-【値】の形になっている
        let disp = item.disable.split('-')
        No = Number(disp[0])
        value = String(disp[1])

        // 前項で選択した値が該当するなら、表示する
        if (name == 'rls') {
          if (this.rls[No].value == value) {
            result = true
          }
        } else if (name == 'normal') {
          if (this.normal[No].value == value) {
            result = true
          }
        } else if (name == 'fixed') {
          if (this.fixed[No].value == value) {
            result = true
          }
          // ㎡数不明の場合、戸数も表示
          if (item.item == 'households') {
            if (this.fixed[1].value == '4') {
              result = true
            }
          }
        }
      } else {
        result = true
      }
      return result
    },

    /**
     * 定期の基本単価変更フラグ
     * @param display 対象データ
     */
    changeFixedPrePrice(display) {
      display.priceKindBgColor = true
    },

    /**
     * 単価表の値段を手動で変更したらフラグを立てる
     * @param display
     * @param kind 1：詳細、2：カテゴリー
     */
    async setPriceChange(display, kind) {
      if (kind == 1) {
        display.detailBgColor = true
        await dialogs.showWarningDialog('発注金額', '単価を手動で変更した場合、発注金額は自動計算されません。')
      } else if (kind == 2) {
        display.categoryBgColor = true
        // カテゴリ－総合計金額計算
        this.categoryAmountCalc()
      }
    },

    /**
     * 再計算＆単価変更フラグを立てる
     * @param target フィールド
     * @param value キー
     * @param name 清掃タイプ
     */
    async changeCalcAndPrice(target, value, name) {
      let v = value + 'BgColor'
      target[v] = true
      this.selectedDetails.iconBgColor = true
      this.forBind++
      await this.calc(name)
    },

    changeForFixedExeed300UnitPrice() {
      this.changeCalcAndPrice(this.selectedDetails, 'unitPriceFixed', 'fixed')
      if (this.exceed300 || this.selectedDetails.conf['fixedMeter'] == '3') {
        // 行がない、1行しかない場合は行を追加
        if (!this.selectedDetails.displayFixed || !this.selectedDetails.displayFixed.length || this.selectedDetails.displayFixed.length == 1) {
          let addNum = 4 - this.selectedDetails.displayFixed.length
          // 定期清掃の表を増やす
          for (let i = 0; i < addNum; i++) {
            this.addRowDetailFixed()
          }
          this.selectedDetails.addFixedRows = true
        }
      }
    },
    

    changeForFixedExeed300Owner() {
      this.changeCalcAndPrice(this.selectedDetails.conf, 'ownerFixed', 'fixed')
      if (this.exceed300 || this.selectedDetails.conf['fixedMeter'] == '3') {
        // 行がない、1行しかない場合は行を追加
        if (!this.selectedDetails.displayFixed || !this.selectedDetails.displayFixed.length || this.selectedDetails.displayFixed.length == 1) {
          let addNum = 4 - this.selectedDetails.displayFixed.length
          // 定期清掃の表を増やす
          for (let i = 0; i < addNum; i++) {
            this.addRowDetailFixed()
          }
          this.selectedDetails.addFixedRows = true
        }
      }
    },

    /**
     * 単価を手動で変更されていたら色を変える
     * @param flag 変更フラグ
     */
    setBgColor(flag) {
      if (flag) {
        return 'bg-yellow-200'
      }
    },

    /**
     * 削除ボタンイベント
     */
    deleteDetailRow(index) {
      // セル結合しているため、1行目が削除されたら内容を2行目に書き換える
      if (index == 0 && this.selectedDetails.display.length > 1 && this.categoryCode != '14') {
        let d = this.selectedDetails.display
        d[1].qty2 = d[0].qty2
        d[1].unitName2 = d[0].unitName2
        d[1].price2 = d[0].price2
        d[1].categoryBgColor = d[0].categoryBgColor
      }

      this.selectedDetails.display.splice(index, 1)
      if (!this.selectedDetails.display.length) {
        this.addRowDetail()
      }
    },

    /**
     * 削除ボタンイベント 定期清掃
     */
    deleteFixedRow(index) {
      this.selectedDetails.displayFixed.splice(index, 1)
      this.getFixedRange(null, index)
      this.noteForWax()
    },

    /**
     * プラスボタン押下イベント
     */
    addRowDetail() {
      let row = {name: '', qty: '', unitName: 'ヶ月', price: '', type: '', detailBgColor: false, qty2: '12', unitName2: 'ヶ月', price2: '', categroyBgColor: false}

      this.selectedDetails.display.push(row)
      this.calcTotal()
    },

    /**
     * プラスボタン押下イベント
     */
    addRowDetailFixed() {
      let row = {name: '', qty: '', unitName: '㎡', price: '', priceKind: '', kindCoef: 'なし (×1)', init: true, detailBgColor: false, qty2: '12', unitName2: 'ヶ月', price2: '', categroyBgColor: false}
      if (this.exceed300 || this.selectedDetails.conf['fixedMeter'] == '3') {
        if (this.selectedDetails.unitPriceFixedBgColor || this.selectedDetails.conf['ownerFixedBgColor']) {
          row.priceKindBgColor = true
        }
      }
      this.selectedDetails.displayFixed.push(row)
    },

    /**
     * 完了ボタン入力チェック
     */
    async validate() {
      for (let i = 0; i < this.purchase.length; i++) {
        const p = this.purchase[i].supplier
        if (p == '') {
          await dialogs.showErrorDialog('発注先未入力', '発注先を入力してください。')
          return false
        }
      }

      if (this.buildingInfo.commission && this.buildingInfo.commission == 'あり') {
        if (['', '0'].includes(this.commission[0].commissionPrice) || ['', '0'].includes(this.commission[0].rate)) {
          let comMsg = await dialogs.showWarningConfirmDialog('手数料の確認', 'サブタスクでは手数料「あり」になっていますが、手数料なしでよろしいでしょうか？')
          if (comMsg != 'YES') {
            return false
          }
        }
      }
      return true
    },

    /**
     * 完了ボタンイベント
     */
    async saveDetail() {

      // バリデート
      if (!await this.validate()) {
        return
      }

      // 特記事項未記入
      if (this.selectedDetails.noteHTML.indexOf('●●') != -1) {
        await dialogs.showErrorDialog('特記事項確認', '特記事項に記載されていない箇所があります。')
        return
      }

      // 一旦初期値に戻す（後で判定するため）
      this.selectedDetails.display.forEach((d) => {
        delete d.iconBgColor2
      })

      let type = 'ラウンドウォッシュプラス'
      let tab = this.rlsPlustabs.find((r) => {
        return r.active
      }).id
      let tip = this.selectedDetails.toolTip


      if (tab == 'clean') {
        type = 'ラウンドクリーンプラス'

        // 不要なツールチップ削除_定期清掃関係
        delete tip['エリア_定期清掃']
        delete tip['清掃回数（回/年）_定期清掃']
        delete tip['駐車場代追加']
        delete tip['戸数（作業時間の目安）省略可']
        delete tip['作業時間（駐車場代）']
        delete tip['㎡数_定期清掃']

      } else if (tab == 'double') {
        type = 'ラウンドダブルプラス'

      } else {
        // 不要なツールチップ削除_固定清掃関係
        delete tip['エリア_固定清掃']
        delete tip['トイレ使用']
        delete tip['時間指定']
        delete tip['清掃時間(指定なし)']
        delete tip['清掃回数_固定清掃']
        delete tip['人数']
        delete tip['バス利用']
        delete tip['㎡数_固定清掃']
        delete tip['階数']
        delete tip['本数']
      }

      this.selectedDetails.rlsPlus = type

      for (let i = 0; i < this.rls.length; i++) {
        this.selectedDetails.selectedRls[i] = this.rls[i].value
      }
      for (let i = 0; i < this.normal.length; i++) {
        this.selectedDetails.selectedNormal[i] = this.normal[i].value
      }
      for (let i = 0; i < this.fixed.length; i++) {
        this.selectedDetails.selectedFixed[i] = this.fixed[i].value
      }

      // 何も入力されていない行は親画面に表示しない
      let arr = []
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display.length != 1) {
          
          let target = this.selectedDetails.display[i]
          if (target.name != '' || (target.price != '' && target.price == '0')) {
            arr.push(target)
          }
        }
      }
      if (this.selectedDetails.length != 1) {
        this.selectedDetails.display = arr
      }

      if (!this.selectedDetails.display.length) {
        this.addRowDetail()
      }

      if (tab != 'clean' && (this.exceed300 || this.selectedDetails.conf['fixedMeter'] == '3')) {
        let empty = []
        for (let i = 0; i < this.selectedDetails.displayFixed.length; i++) {
          let d = this.selectedDetails.displayFixed[i]
          if (d.name != '' && (!d.kindCoef || d.kindCoef == undefined || d.kindCoef == '') && this.selectedDetails.getFixedPrice) {
            empty.push(i+1)
          }
        }
        if (empty.length) {
          const dialogResult = await dialogs.showWarningConfirmDialog('係数チェック', empty.join(',') + '行目の定期清掃の「係数」が選択されていませんがよろしいでしょうか。')
          if (dialogResult != 'YES') {
            return
          }
        }
      }

      let icon = false

      // 編集アイコンを黄色にするか判定
      if (this.selectedDetails) {
        if (this.selectedDetails.conf) {
          let con = Object.keys(this.selectedDetails.conf)
          if (con.length) {
            for (let i = 0; i < con.length; i++) {
              const c = con[i];
              if (c.indexOf('BgColor') != -1) {
                if (this.selectedDetails.conf[c]) {
                  icon = true
                  break
                }
              }
            }
          }
        }

        if (!icon) {
          let arr = Object.keys(this.selectedDetails)
          if (arr.length) {
            for (let i = 0; i < arr.length; i++) {
              const a = arr[i];
              if (a.indexOf('BgColor') != -1) {
                if (this.selectedDetails[a] && a != 'iconBgColor') {
                  icon = true
                  break
                }
              }
            }
          }
        }
      }
      if (!icon) {
        if (this.selectedDetails.display) {
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            const d = this.selectedDetails.display[i]
            if (d.detailBgColor) {
              icon = true
              break
            }
          }
        }
      }
      if (!icon) {
        if (this.selectedDetails.displayFixed) {
          for (let i = 0; i < this.selectedDetails.displayFixed.length; i++) {
            const d = this.selectedDetails.displayFixed[i]
            if (d.priceKindBgColor) {
              icon = true
              break
            }
          }
        }
      }
      this.selectedDetails.iconBgColor = icon
      // 発注明細
      for (let i = 0; i < this.purchase.length; i++) {
        const p = this.purchase[i]
        if (p.type) {
          // カッコから後ろを削除
          p.typeName = utils.deleteKana(p.typeName)
          p.typeName = p.typeName + '（' + type.replace('ラウンド', '') + '）'
          if (i == 0) {
            p.rowspan = this.purchase.length
          } else {
            p.rowspan = -1
          }
        }
      }
      this.selectedDetails.purchase = this.purchase
      // 手数料率が空文字なら0にする
      if (this.commission[0].rate == '') {
        this.commission[0].rate = '0'
      }
      this.selectedDetails.commission = this.commission[0]

      // 単価を表示する場合、背景色もそれぞれを黄色にする
      if (this.selectedDetails.isUnitPrice) {
        let c = this.selectedDetails.conf
        let p = this.selectedDetails.price
        let disp = this.selectedDetails.display
        // 巡回清掃の式中で手動変更あるもの
        if (c.toiletBgColor || c.meterBgColor || c.photoBgColor || c.ownerBgColor || this.selectedDetails.unitPriceRlsBgColor) {
          disp.forEach((d) => {
            if (d.type == 'rls') {
              d.iconBgColor2 = true
            }
          })
        }
        // 固定清掃の式中で手動変更あるもの
        if (c.toiletNormalBgColor || c.busBgColor || c.ownerNormalBgColor || c.eriaBgColor || p.approxBgColor || this.selectedDetails.unitPriceNormalBgColor) {
          disp.forEach((d) => {
            if (d.type == 'normal') {
              d.iconBgColor2 = true
            }
          })
        }
        // 定期清掃の式中で手動変更あるもの
        let priceKind = false
        if (this.selectedDetails.conf.priceKind && this.selectedDetails.conf.priceKind.length) {
          for (let i = 0; i < this.selectedDetails.conf.priceKind.length; i++) {
            let p = this.selectedDetails.conf.priceKind[i]
            
            for (let item in p) {
              if (item == 'BgColor') {
                if (p.BgColor) {
                  priceKind = true
                  break
                }
              }
            }
          }
        }
        if (priceKind || c.parkValueBgColor || c.ownerFixedBgColor || this.selectedDetails.unitPriceFixedBgColor || this.selectedDetails.exceed300PriceBgColor) {
          disp.forEach((d) => {
            if (d.type == 'fixedName') {
              d.iconBgColor2 = true
            }
          })
        }
      }

      this.selectCallback(this.selectedDetails)
      this.closed()
    },

    /**
     * 定期清掃の部位を取得
     * @param e 選択した項目
     * @param i インデックス
     */
    getFixedRange(e, i) {
      let valueArr = []
      let value = ''

      if (this.selectedDetails.noteHTML == '') {
        return
      }

      let list = JSON.parse(JSON.stringify(this.selectedDetails.displayFixed))
      if (e) {
        list[i].name = e.name
      }
      valueArr = list.map((d) => {
        return d.name
      }).filter((f) => {
        if (f && f != '') {
          return f
        }
      })

      if (!valueArr.length) {
        value = 'アプローチ・エントランス・共用廊下・共用階段・管理室・エレベーター'
      } else {
        value = valueArr.join('・')
      }

      let text = ''
      let a = ''
      let b = ''
      a = this.selectedDetails.noteHTML.slice(0, this.selectedDetails.noteHTML.indexOf('床面通常洗浄：'))
      b = this.selectedDetails.noteHTML.slice(this.selectedDetails.noteHTML.indexOf('床面簡易洗浄：'))
      text = a + '床面通常洗浄：<br>' + value + 'の機械洗浄を実施致します。<br><br>' + b

      this.selectedDetails.noteHTML = text
    },

    /**
     * 初期化
     */
    closed() {
      this.active = false
      this.selectedDetails = {
        // 親画面表示内容
        display:[
          {
            // 内容
            name: '巡回清掃',
            // 数量
            qty: '1',
            // 単位
            unitName: 'ヶ月',
            // 金額
            price: '0',
            // 合計
            total: '0',
            // タイプ どの種類の表示をしているか
            type: 'rls',
            // 単価を手動で変更フラグ
            detailBgColor: false,
            // カテゴリー数量
            qty2: '12',
            // カテゴリ－単位
            unitName2: 'ヶ月',
            // カテゴリー金額
            price2: '',
            // カテゴリー金額変更
            categoryBgColor: false
          },
          {
            name: '固定清掃（週　回・ 時間以内）',
            qty:'1',
            unitName: 'ヶ月',
            price: '0',
            total: '0',
            type: 'normal',
            detailBgColor: false,
            // カテゴリー数量
            qty2: '12',
            // カテゴリ－単位
            unitName2: 'ヶ月',
            // カテゴリー金額
            price2: '',
            // カテゴリー金額変更
            categoryBgColor: false
          },
        ],
        // 選択内容履歴(ツールチップ用)
        toolTip: {},
        // 係数
        conf: {},
        // 特記事項
        noteCode: [],
        // 単価表の単価
        unitPrice: {
        rls: '0',
        normal: '0',
        fixed: '0',
        fixed300: '0'
        },
        // その他、計算結果
        price: {},
        // 上の項目表の選択、入力した値
        selectedRls: [],
        selectedNormal: [],
        selectedFixed: [],
        displayFixed: [],
        tabId: 'clean',
        // 固定清掃の時間帯・曜日
        remarks: [
          {start: '', end: '', week: []}
        ],
        // 手動で価格変更フラグ
        unitPriceRlsBgColor: false,
        unitPriceNormalBgColor: false,
        unitPriceFixedBgColor: false,
        // 定期清掃 300㎡超え 合計
        exceed300Price: '0',
        exceed300PriceBgColor: false,
        // 定期清掃 部位の表示
        fixedContent: '2',
        // RLS 一度でも単価取得したか
        getRlsPrice: false,
        // 日常清掃 一度でも単価取得したか
        getNormalPrice: false,
        // 定期清掃 一度でも単価取得したか
        getFixedPrice: false,
        // 作業部位の行を増やしたか
        addFixedRows: false,
        // 発注金額を手動で変更
        changePurchasePrice: false,
        // 発注金額を変更したタイプ名
        changePurchasePriceType: [],
        // 見積りに単価を表示するか否か
        isUnitPrice: false
      },
      // 表示する項目 ラウンドサービス
      this.rls = [],
      // 表示する項目 日常清掃
      this.normal = [],
      // 表示する項目 定期清掃
      this.fixed = [],
      // 計算結果
      this.totalUnitPrice = {
        rls: '0',
        normal: '0',
        fixed: '0'
      },
      // 合計金額
      this.total = {
        rls: '0',
        normal: '0',
        fixed: '0'
      },
      // 並べ替え
      this.sortableDetail = false,
      // 計算式の詳細表示
      this.formulaDispFlag = false
      // 画面にバインドするための変数
      this.valueArr = [],
      // 定期清掃作業範囲 合計
      this.range = '0',
      
      // ラウンドプラスのタブ
      this.rlsPlustabs = [
        {id: 'clean', name: 'クリーンプラス', active: true},
        {id: 'wash', name: 'ウォッシュプラス', active: false},
        {id: 'double', name: 'ダブルプラス', active: false},
      ],
      // 日曜が含まれる
      this.isSunday = false
      // 曜日選択
      this.week = [[
        {id: '0', name: '月', active: false},
        {id: '1', name: '火', active: false},
        {id: '2', name: '水', active: false},
        {id: '3', name: '木', active: false},
        {id: '4', name: '金', active: false},
        {id: '5', name: '土', active: false},
        {id: '6', name: '日', active: false},
      ]],
      // 発注モーダル
      this.purchaseModalShow = false,
      // 発注明細（過去の見積りにはないため、ここで宣言）
      this.purchase = [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        supplier: '',
        rate: '',
        price: '',
        priceBgColor: false,
        rateBgColor: false
      }]
      // カテゴリ金額合計
      this.categoryAmount = 0
      // 発注金額手動で変更後、自動計算直後
      this.changePurchasePrice = false
      // 発注率を超えた
      this.overPurchaseRate = []
      // 発注明細表示のための変数
      this.purchaseReload = 0
      // 初期発注率
      this.initRate = 0,
      // モーダルを開いたばかり
      this.openInit = true,
      // 手数料明細（過去の見積りにはないため、ここで宣言）
      this.commission = [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        estimatePrice: '',
        rate: '',
        commissionPrice: '',
        price: '',
      }]
    },

    /**
     * アラート表示
     * @param target 取得データ
     * @param errMsg エラーメッセージ
     */
    setAlert(target, errMsg) {

      if (Array.isArray(target)) {
        if (!target.length) {
          alert(errMsg)
          return true
        }
      } else {
        if (!target && target != 0) {
          alert(errMsg)
          return true
        }
      }
      return false
    },

    /**
     * 係数などの単価を取得
     * @param name 取得する単価コード
     * @param category カテゴリコード
     */
    getUnitPrice(priceCode, category) {
      let priceList = this.$store.getters.estimateItemPriceList
      let list = priceList.find((p) => {
        return p.parentCode == category && p.priceCode == priceCode
      })

      if (!list || !list.price) {
        alert('取得できなかった単価があります。')
        return ''
      }

      return list.price
    },

    /**
     * DBにないデータをconstantsEstimate.jsから取得
     */
    getConstantsData(name, code, listName) {
      let list = constants[listName]
      if (this.setAlert(list, '単価取得できませんでした。')) return

      let result = list.filter((l) => {
        return l[name] == code
      })
      if (result.length) {
        return result[0].item
      } else {
        return null
      }
    },

    /**
     * 未入力の背景を青くする
     * @param value 値
     */
    setEmptyColor(value) {
      if (value == null || value == undefined || value == '') {
        return 'text-white bg-blue-800'
      } else {
        return 'text-gray-500 bg-gray-50'
      }
    },

    /**
     * 未入力の背景を青くする
     * @param i 番号
     */
    setEmptyColorFixed(i) {
      if (i == 4) {
        return 'text-gray-500 bg-gray-50'
      }
      if (!this.fixed[i].value || this.fixed[i].value == '') {
        return 'text-white bg-blue-800'
      } else {
        return 'text-gray-500 bg-gray-50'
      }
    },

    /**
     * 曜日選択 色設定
     * @param r 対象
     */
    setEmptyColorWeek(r) {
      if (r == undefined || !r || !r.week.length) {
        return 'text-white bg-blue-800'
      } else {
        return 'text-gray-500 bg-gray-50'
      }
    },

    /**
     * 特記事項の時間 色設定
     */
    setEmptyColorTime(s, e) {
      if (!s || !e) {
        return 'text-white bg-blue-800'
      }
      let start = s.split(':')
      let end = e.split(':')
      if (start.length == 2 && end.length == 2 && start[0] != '' && start[1] != '' && end[0] != '' && end[1] != '') {
        return 'text-gray-500 bg-gray-50'
      } else {
        return 'text-white bg-blue-800'
      }
    },

    /**
     * 単価取得
     * @param name 種類
     */
    async getPriceMasterData(name) {
      this.openInit = false
      // 日常の値チェック
      if(!await this.checkNormalAndMgm(name)) {
        return
      }

      let zero = []

      // 全て入力されているかチェック
      for (let i = 0; i < this[name].length; i++) {
        let target = this[name]

        // 定期清掃で㎡数不明の場合「戸数・階数・本数」は空文字でもOk、日常清掃の祝日対応、年末年始は不要
        if ((name == 'fixed' && [4, 7, 8, 9].includes(i) && target[1].value != '4') || (name == 'normal' && i == 16) || (name == 'normal' && i == 18)) {
          continue
        }
        // 日常
        if (name == 'normal') {
          // 他ありでは、㎡数は未記入でOK
          if (i == 11 && target[10].value == '1') {
            continue
          }
          // ㎡数不明以外は、タイプ・戸数・回数・本数は未記入でOK
          if ([12, 13, 14, 15].includes(i) && target[11].value != '4') {
            continue
          }
          // ㎡数300㎡超え以外は、㎡数記入は未記入でOK
          if (i == 19 && target[11].value != '3') {
            continue
          }
        }
        // 定期清掃
        if (name == 'fixed') {
          // ㎡数不明以外は「戸数」「タイプ」「階数」「本数」は空文字でもOk
          if ([4, 7, 8, 9].includes(i) && target[1].value != '4') {
            continue
          } else if (target[1].value == '4') {
            if (i == 4 && target[4].value == '0') {
              zero.push('戸数')
            } else if (i == 8 && target[8].value == '0') {
              zero.push('階数')
            } else if (i == 9 && target[9].value == '0') {
              zero.push('本数')
            }
          }
        }
        if ( this[name][i].value == '') {
          await dialogs.showErrorDialog('入力確認', this[name][i].name + 'が入力されていません。')
          return
        }
      }
      if (zero && zero.length) {
        let uncertainDlog = await dialogs.showConfirmDialog(zero.join('・'), `㎡数不明ですが、${zero.join('・')}が0です。\r\nよろしいですか？`)
        if (uncertainDlog != 'YES') {
          return
        }
      }

      // クリーンプラスの曜日・時間帯のチェック
      if (!await this.checkWeekAndTime(name)) {
        return
      }

      // 発注初期化
      await this.setInitPurchase(name)
      // 初期化
      this.selectedDetails.changePurchasePriceType = []

      let c = this.selectedDetails.conf
      let p = this.selectedDetails.price
      
      switch (name) {
        // RLS
        case 'rls':
          
          this.selectedDetails.getRlsPrice = true
          c.toiletBgColor = false
          c.meterBgColor = false
          c.photoBgColor = false
          c.ownerBgColor = false
          this.selectedDetails.unitPriceRlsBgColor = false
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            let disp = this.selectedDetails.display[i]
            if (disp.type == 'rls') {
              disp.detailBgColor = false
              this.selectedDetails.display[0].categoryBgColor = false
              break
            }
          }
          await this.getRlsCleaning()
          break;

        // 日常清掃
        case 'normal':

          this.selectedDetails.getNormalPrice = true
          c.toiletNormalBgColor = false
          c.busBgColor = false
          c.ownerNormalBgColor = false
          c.eriaBgColor = false
          p.approxBgColor = false
          this.selectedDetails.unitPriceNormalBgColor = false
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            let disp = this.selectedDetails.display[i]
            if (disp.type == 'normal') {
              disp.detailBgColor = false
              this.selectedDetails.display[0].categoryBgColor = false
              break
            }
          }
          await this.getDailyCleaningOrMgt()
          break;
          
        // 定期清掃
        case 'fixed':

          this.selectedDetails.getFixedPrice = true
          c.parkValueBgColor = false
          c.ownerFixedBgColor = false

          if (this.selectedDetails.conf.priceKind && this.selectedDetails.conf.priceKind.length) {
            for (let i = 0; i < this.selectedDetails.conf.priceKind.length; i++) {
              let p = this.selectedDetails.conf.priceKind[i]
              
              for (let item in p) {
                if (item == 'BgColor') {
                  p.BgColor = false
                }
              }
            }
          }

          this.selectedDetails.unitPriceFixedBgColor = false
          this.selectedDetails.exceed300PriceBgColor = false
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            let disp = this.selectedDetails.display[i]
            if (disp.type == 'fixedName') {
              disp.detailBgColor = false
              this.selectedDetails.display[0].categoryBgColor = false
              break
            }
          }

          await this.getFixedCleaning()
          break;
      
        default:
          break;
      }
      // 特記事項設定
      let tab = this.rlsPlustabs.find((r) => {
        return r.active
      }).id

      let rlsTitle = ''
      let normalTitle = ''
      let fixedTitle = '年6回床面通常洗浄、年6回床面簡易洗浄'
      let noteCode = ''
      let arr = []
      let title = ''
      let uncertain = false

      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == 'rls') {
          rlsTitle = this.selectedDetails.display[i].name
          continue
        } else if (this.selectedDetails.display[i].type == 'normal') {
          normalTitle = this.selectedDetails.display[i].name
          continue
        }
      }
      
      // クリーンプラス
      if (tab == 'clean') {
        if (this.selectedDetails.getRlsPrice && this.selectedDetails.getNormalPrice) {
          noteCode = '2-2'
          title = 'ラウンドクリーンプラス<br>' + rlsTitle + '+ ' + normalTitle + '<br>'
          arr.push({code: noteCode, title: title})
          // if (this.normal[11].value == '4') {
          //   uncertain = true
          // }
        } else {
          return
        }
      
      // ダブルプラス
      } else if (tab == 'double') {
        if (this.selectedDetails.getRlsPrice && this.selectedDetails.getNormalPrice && this.selectedDetails.getFixedPrice) {
          noteCode = '2-3'
          title = 'ラウンドダブルプラス<br>' + rlsTitle + '+ ' + normalTitle + '+ ' + fixedTitle + '<br>'
          arr.push({code: noteCode, title: title})
          if (this.fixed[1].value == '4') {
            uncertain = true
          }
        } else {
          return
        }

      // ウォッシュプラス
      } else {
        if (this.selectedDetails.getRlsPrice && this.selectedDetails.getFixedPrice) {
          noteCode = '2-1'
          title = 'ラウンドウォッシュプラス<br>' + rlsTitle + '+ ' + fixedTitle + '<br>'
          arr.push({code: noteCode, title: title})
          if (this.fixed[1].value == '4') {
            uncertain = true
          }
        } else {
          return
        }
      }

      // バイク駐車 不明
      if (this.rls[11].value == '0') {
        arr.push({code: '99-2', title: ''})
      
      // バイク駐車 あり
      } else {
        arr.push({code: '99-1', title: ''})
      }
      
      // トイレ清掃の特記事項
      if (this.selectedDetails.price['toilet'] && Number(this.selectedDetails.price['toilet']) > 0) {
        arr.push({code: '100-1', title: 'トイレ清掃：' + this.rls[6].value + '箇所想定'})
      }

      // 検針の特記事項
      if (this.selectedDetails.price['meter'] && Number(this.selectedDetails.price['meter']) > 0) {
        arr.push({code: '100-2', title: '検針業務：共用部' + this.rls[8].value + '箇所想定'})
      }

      // ウォッシュプラス以外
      if (tab != 'wash') {
        // 固定清掃のトイレ清掃の特記事項
        if (this.selectedDetails.price['toiletNormal'] && Number(this.selectedDetails.price['toiletNormal']) > 0) {
          arr.push({code: '100-1', title: 'トイレ清掃：' + this.normal[3].value + '箇所想定'})
        }
      }

      // 特記事項取得
      await this.getEstimateNote(arr)

      // クリーンプラス以外
      if (tab != 'clean') {
        this.getFixedRange()
        this.noteForWax()
      
      // クリーンプラスの場合
      } else {
        // 曜日・時間帯が選択されている
        let note = '<br>'
        let isRemark = false
        for (let i = 0; i < this.selectedDetails.remarks.length; i++) {
          const r = this.selectedDetails.remarks[i]
          if (note != '<br>') {
            note += '<br>'
          }
          // 曜日
          if (r.week && r.week.length) {
            isRemark = true
            note += `${i+1}人目：${r.week.join('・')}`
          } else {
            note += (i+1) + '人目：曜日指定なし'
          }

          // 時間帯
          if (r.start != '' || r.end != '') {
            isRemark = true
            note += ` （${r.start}～${r.end}）`
          } else {
            note += ' （開始時間指定なし）'
          }
        }
        if (!isRemark) {
          note = '開始時間・曜日指定なし。'
        
        // 清掃員1人の場合
        } else if (this.selectedDetails.remarks.length == 1) {
          note = note.replace('1人目：', '')
        }

        this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('開始時間・曜日指定無し。', note)
      }
      // クリーンプラス以外
      if (tab != 'clean') {
        // ㎡数が不明の場合
        if (uncertain) {
          await this.addEstimateNote('5-3')
        // 定期清掃の「現地調査の有無」がなし、かつ、㎡数不明以外の場合
        } else if (this.fixed[12] && this.fixed[12].value == '0' && this.fixed[1].value != '4') {
          await this.addEstimateNote('5-5')
        }
      }
      this.forBind++
    },



    /**
     * 計算する
     */
    async calc(name) {
      switch (name) {
        case 'rls':
          await this.calcRlsCleaning()
          break;

        case 'normal':
          await this.calcDailyCleaningOrMgt()
          break;

        case 'fixed':
          await this.calcFixedCleaning()
          break;
      
        default:
          break;
      }

      // カテゴリー単価計算
      this.calcTotal()
    },

    /**
     * 竣工係数取得
     */
    getFinishPointConf(type, key) {
      if (this[type]) {
        let idx = null
        let value = ''
        this.selectedDetails.conf[key] = '1'

        for (let i = 0; i < this[type].length; i++) {
          const d = this[type][i]
          if (d.item == 'finishPoint') {
            idx = i
            value = d.value
            break
          }
        }
        if (idx != null && value && value != '0') {
          // 1年ごとに5％増
          let res = BigNumber(0.05).times(Number(value)).plus(1)
          this.selectedDetails.conf[key] = String(res)
        }
      }
    },

    /**
     * RLS計算
     */
    async calcRlsCleaning() {
      let conf = this.selectedDetails.conf
      let price = this.selectedDetails.price

      // 閉じるボタン押下時のエラー対処
      if (!this.selectedDetails.unitPrice.rls || !this.rls.length) {
        return
      }

      // トイレ ＝ 回数 × 箇所 × トイレ単価 × 4.3
      price['toilet'] = String(Math.floor(BigNumber(Number(this.rls[5].value || 0)).times(Number(this.rls[6].value || 0)).times(Number(conf['toilet'] || 0)).times(4.3)))

      // メーター ＝ 回数 × 箇所 × メーター単価
      price['meter'] = String(Number(this.rls[7].value || 0) * Number(this.rls[8].value || 0) * Number(conf['meter'] || 0))

      // 写真 月1回の場合
      if (this.rls[9].value == 'm1') {
        price['photo'] = conf['photo']
      
      // 写真 月1回以外の場合
      } else {
        // 写真 ＝ 回数 × 写真単価 × 4.3
        price['photo'] = String(BigNumber(Number(this.rls[9].value || 0)).times(Number(conf['photo'] || 0)).times(4.3))
      }

      // 協力費
      let compe = 1
      if (this.rls[12] && this.rls[12].value) {
        let compe2 = BigNumber(Number(this.rls[12].value)).div(100)
        compe = BigNumber(1).minus(compe2)
      }
      conf['compeRls'] = String(compe)

      // 金額 ＝ （トイレ + メーター + 写真 + 単価）
      let v1 = BigNumber(Number(price['toilet'] || 0)).plus(Number(price['meter'] || 0)).plus(Number(price['photo'] || 0)).plus(Number(this.selectedDetails.unitPrice.rls || 0))
      // 金額 ＝ 金額 ÷ 協力費
      if (compe != 1) {
        v1 = BigNumber(v1).div(compe)
      }
      // さらに竣工係数をかける
      v1 = BigNumber(v1).times(Number(conf['finishPointRls'] || 1))

      // オーナー係数をかけて、最終金額とする
      this.totalUnitPrice.rls = String(Math.ceil((BigNumber(v1).times(Number(conf['ownerRls'] || 0)).div(100))) * 100)

      // 発注金額元値用のオーナーなし金額計算
      price['rlsPurchase'] = String(Math.floor(BigNumber(v1)))

      let q = '0'
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == 'rls' && !this.selectedDetails.display[i].detailBgColor) {

          // 表示内容
          this.selectedDetails.display[i].price = this.totalUnitPrice.rls
          q = this.selectedDetails.display[i].qty
          break
        }
      }

      this.total.rls = String(Number(this.totalUnitPrice.rls) * Number(q))
      await this.getPurchasePrice(null, false, false, 'rls') 
    },

    /**
     * RLS単価取得
     */
    async getRlsCleaning() {
      let cleaningTimes = this.rls[2].value

      let h = Number(this.rls[0].value)
      if (h >= 51) {
        h = 50
      }

      let ins = this.rls[4].value

      if (!['m1', 'm2', '1', '2'].includes(cleaningTimes)) {
        ins = '0'
      }

      // 単価コードを生成(タイプ-清掃回数-ゴミ出し回数-点検)
      let priceCode = '1' + '-' + cleaningTimes + '-' + this.rls[3].value + '-' + ins
      // 単価表から単価を取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == '1' && l.priceCode == priceCode && Number(l.minHouseholds) <= h && Number(l.maxHouseholds) >= h) {
          return true
        }
      })
      // if (this.setAlert(list, '単価取得できませんでした。\n組み合わせを確認してください。')) return

      // 組み合わせが単価表にない場合
      if (!list.length) {
        alert('単価表にない組み合わせです。単価は直接ご記入ください。')

      // 単価表にあった場合
      } else {
        if (list[0].price == '') {
          alert('単価表にない組み合わせです。単価は直接ご記入ください。')
        }
        
        if (h >= 16 && h <= 20 && ['m1', '1', '2'].includes(cleaningTimes)) {
          alert('16～20戸 かつ ' + this.selectedDetails.toolTip['清掃回数_RLS'] + 'は特例です。\r\n15戸までの単価を表示しています。\r\n必要に応じて単価を書き換えてください。')
        } else if (Number(this.rls[0].value) >= 51) {
          alert('51戸以上の単価はデータにありません。\r\n50戸の単価を表示しています。\r\n必要に応じて単価を書き換えてください。')
        }
        
        // 単価
        this.selectedDetails.unitPrice.rls = list[0].price
      }

      let name1 = ''
      if (cleaningTimes == 'm1') {
        name1 = '月1回'
      } else {
        name1 = '週' + cleaningTimes + '回'
      }

      let name2 = '）'
      if (this.rls[3].value != '0') {
        name2 = '・週' + this.rls[3].value + '回ゴミ搬出）'
      }

      let flag = false

      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == 'rls') {
          // 表示内容
          this.selectedDetails.display[i].name = '巡回清掃（'+ name1 +'清掃' + name2
          flag = true
          break
        }
      }

      // 対象の行がなければ、プッシュ
      if (!flag) {
        let row = {
            // 内容
            name: '巡回清掃（'+ name1 +'清掃' + name2,
            // 数量
            qty: '1',
            // 単位
            unitName: 'ヶ月',
            // 金額
            price:'',
            // タイプ どの種類の表示をしているか
            type: 'rls',
            // 単価を手動で変更フラグ
            detailBgColor: false,
            // カテゴリー数量
            qty2: '12',
            // カテゴリ－単位
            unitName2: 'ヶ月',
            // カテゴリー金額
            price2: '',
            // カテゴリー金額変更
            categoryBgColor: false
          }
        this.selectedDetails.display.push(row)
      }

      // *--その他の単価を取得--* //

      // トイレ
      this.selectedDetails.conf['toilet'] = this.getUnitPrice('toilet', '0')
      // メーター
      this.selectedDetails.conf['meter'] = this.getUnitPrice('meter', '0')
      // 写真
      this.selectedDetails.conf['photo'] = this.getUnitPrice('photo', '0')
      // オーナー契約
      this.selectedDetails.conf['ownerRls'] = this.getUnitPrice('owner' + this.rls[10].value, '0')
      // 竣工係数
      this.getFinishPointConf('rls', 'finishPointRls')

      // 計算
      await this.calc('rls')
    },

    /**
     * 日常清掃 計算 
     */
    async calcDailyCleaningOrMgt() {
      if (!this.selectedDetails.unitPrice.normal || this.selectedDetails.unitPrice.normal == '0') {
        return
      }
      let price = this.selectedDetails.price
      let conf = this.selectedDetails.conf
      let target = this.normal
      
      // 月の合計時間 ＝ 清掃時間 × 清掃回数 × 人数(1人固定) × 週/月
      price['hours'] = String(BigNumber(Number(target[6].value)).times(Number(target[7].value)).times(1).times(4.3))

      // トイレ合計 ＝ トイレ回数 × トイレ箇所 × トイレ単価
      let toilet = Number(target[2].value) * Number(target[3].value) * Number(conf['toiletNormal'])
      // トイレ合計 × 4.3
      toilet = BigNumber(toilet).times(4.3)
      // さらに × 月の合計時間
      toilet = BigNumber(toilet).div(Number(price['hours']))
      // 小数以下切り捨て
      price['toiletNormal'] = String(Math.floor(toilet))

      // バス代 ＝ バス代単価 ÷ 清掃時間
      price['bus'] =  String(Math.floor(BigNumber(Number(conf['bus'])).div(Number(target[6].value))))

      // 追加料金 ＝ （定期概算 × 4 × エリア単価）÷ 12 × 0.3 ÷ 月の合計時間
      price['add'] = String(Math.floor(BigNumber(BigNumber(price['approx'] || 0).times(4).times(Number(conf['eria']))).div(12).times(0.3).div(Number(price['hours']))))

      // ダブルプラスの場合は追加料金なし（単独発注は他アリ固定）
      if (this.selectedDetails.tabId == 'double') {
        price['approx'] = '0'
        price['add'] = '0'
      }
      
      // 合計単価 ＝ （単価 + トイレ代/時間 + バス代/時間）
      let v1 = BigNumber(Number(this.selectedDetails.unitPrice.normal)).plus(Number(price['toiletNormal'])).plus(Number(price['bus']) + Number(price['add']))
      // 合計単価 × オーナー契約
      price['totalUnitPricePre'] = String(Math.floor(BigNumber(v1).times(Number(conf['ownerNormal']))))

      // 協力費
      let compe = 1
      if (target[20] && target[20].value) {
        let compe2 = BigNumber(Number(target[20].value)).div(100)
        compe = BigNumber(1).minus(compe2)
      }
      conf['compeNormal'] = String(compe)

      // 最終金額 ＝ (合計単価 ÷ 協力費として × 竣工係数 × 月の合計時間)
      this.totalUnitPrice.normal = String(Math.ceil(BigNumber(Number(price['totalUnitPricePre'])).div(compe).times(Number(conf['finishPointNormal'] || 1)).times(Number(price['hours'])).div(100)) * 100)

      // 発注金額元値用のオーナーなし金額計算
      // オーナー契約ありの場合
      price['normalPurchase'] = String(Math.floor(BigNumber(v1).div(compe).times(Number(conf['finishPointNormal'] || 1)).times(Number(price['hours']))))
      // console.log(price['normalPurchase'])

      let q = '0'
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == 'normal' && !this.selectedDetails.display[i].detailBgColor) {

          // 表示内容
          this.selectedDetails.display[i].price = this.totalUnitPrice.normal
          q = this.selectedDetails.display[i].qty
          break
        }
      }
      this.total.normal = String(BigNumber(Number(this.totalUnitPrice.normal)).times(Number(q)))
      await this.getPurchasePrice(null, false, false, 'normal') 
    },

    /**
     * 日常清掃 単価取得
     */
    async getDailyCleaningOrMgt() {
      let target = this.normal
      let tip = this.selectedDetails.toolTip
      let conf = this.selectedDetails.conf
      
      // 単価コード生成 （エリア-トイレ有無-時間指定-時間-回数）
      let priceCode = target[0].value + '-' + target[1].value + '-' + target[4].value + '-' + target[6].value + '-' + target[7].value

      let parentCode = '3'
      // 日常清掃で「トイレ使用不可」は、管理員業務の単価を取得する
      if (target[1].value == '0') {
        parentCode = '4'
      }
      // 単価表から単価を取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == parentCode && l.priceCode == priceCode) {
          return true
        }
      })
      if (this.setAlert(list, '単価取得できませんでした。\n組み合わせを確認してください。')) return
      
      // 単価
      this.selectedDetails.unitPrice.normal = list[0].price

      // *--その他の単価を取得--* //

      // エリア
      // ツールチップ確認、無ければ生成
      if (!tip['エリア_固定清掃']) {
        this.selectedDetails.toolTip[`${target[0].name}_固定清掃`] = target[0].itemCode[target[0].value]
      }
      this.selectedDetails.conf['eria'] = this.getUnitPrice(tip['エリア_固定清掃'], '3')
      // トイレ単価
      this.selectedDetails.conf['toiletNormal'] = this.getUnitPrice('toilet', '0')
      // バス代単価
      this.selectedDetails.conf['bus'] = this.getUnitPrice('bus' + target[9].value, '0')
      // オーナー契約
      this.selectedDetails.conf['ownerNormal'] = this.getUnitPrice('owner' + target[17].value, '0')
      // 竣工係数
      this.getFinishPointConf('normal', 'finishPointNormal')

      // 長さ
      this.selectedDetails.conf['normalWide'] = '0'
      // 定期概算係数
      this.selectedDetails.price['approx'] = '0'

      conf['normalMeter'] = null
      conf['normalMsg'] = null


      // 単独発注の場合
      if (target[10].value == '0') {
        // ㎡数が不明
        if (target[11].value == '4') {
          // ツールチップ生成
          this.selectedDetails.toolTip[`${target[12].name}_固定清掃`] = target[12].itemCode[target[12].value]
          // 単価取得ボタンを押してもらうために、変数に入れる（式に即時反映すると、計算されていると勘違いされる）
          conf['normalHouse'] = Number(target[13].value)
          conf['normalFloor'] = Number(target[14].value)
          conf['normalNum'] = Number(target[15].value)

          // 長さ
          this.selectedDetails.conf['normalWide'] = this.getUnitPrice(tip['タイプ_固定清掃'], '3')
          
          // 概算を出す
          // 戸数 × 長さ × 幅
          let value1 = BigNumber(Number(conf['normalHouse'])).times(Number(this.selectedDetails.conf['normalWide'])).times(1.5)
          // 階数 × 本数 × 長さ × 幅
          let value2 = BigNumber(Number(conf['normalFloor'])).times(Number(conf['normalNum'])).times(10).times(1.2)

          // 定期概算 ＝ value1 + value2 + 戸数
          let un1 =  BigNumber(value1).plus(value2).plus(Number(conf['normalHouse']))
          this.selectedDetails.price['normalApproxPre'] = String(Math.floor(un1))

          // どの式にするか判定
          this.setApproxform('normal', conf, un1)
          
        // ㎡数、もしくは概算結果が200・300㎡以下
        } if (target[11].value == '1' || target[11].value == '2' || conf['normalMeter'] == '1' || conf['normalMeter'] == '2') {
          let m = '300㎡以下'
          if (target[11].value == '1' || conf['normalMeter'] == '1') {
            m = '200㎡以下'
          }
          this.selectedDetails.price['approx'] = this.getUnitPrice(m, '3')

        // ㎡数が300㎡超え
        } else if (target[11].value == '3') {
          this.selectedDetails.price['approx'] = target[19].value

        // 概算結果が300㎡超え
        } else if (conf['normalMeter'] == '3') {
          // 概算をそのまま使用
          this.selectedDetails.price['approx'] = this.selectedDetails.price['normalApproxPre']
        }
      }

      let flag = false
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == 'normal') {

          // 表示内容
          this.selectedDetails.display[i].name = '固定清掃（週'+ target[7].value +'回・' + target[6].value + '時間以内）'
          flag = true
          break
        }
      }
      // 対象の行がなければ、プッシュ
      if (!flag) {
        let row = {
            // 内容
            name:  '固定清掃（週'+ target[7].value +'回・' + target[6].value + '時間以内）',
            // 数量
            qty: '1',
            // 単位
            unitName: 'ヶ月',
            // 金額
            price:'',
            // タイプ どの種類の表示をしているか
            type: 'normal',
            // 単価を手動で変更フラグ
            detailBgColor: false,
            // カテゴリー数量
            qty2: '12',
            // カテゴリ－単位
            unitName2: 'ヶ月',
            // カテゴリー金額
            price2: '',
            // カテゴリー金額変更
            categoryBgColor: false
          }
        this.selectedDetails.display.push(row)
      }

      // 計算
      await this.calc('normal')
    },

    /**
     * 概算結果により、どの式を利用するか判定
     * @param name 対象
     * @param conf 結果値を収納
     * @param num 概算値
     */
    setApproxform(name, conf, num) {
      // どの式にするか判定
      if (num <= 200) {
        conf[`${name}Meter`] = '1'
        conf[`${name}Msg`] = '概算結果より200㎡以下の単価を使用'
      } else if (num <= 300) {
        conf[`${name}Meter`] = '2'
        conf[`${name}Msg`] = '概算結果より300㎡以下の単価を使用'
      } else {
        conf[`${name}Meter`] = '3'
        if (name == 'fixed') {
          conf[`${name}Msg`] = '概算結果より300㎡超えの計算式を使用'
        } else {
          conf[`${name}Msg`] = null
        }
      }
    },

    /**
     * 定期清掃_単価取得
     */
    async getFixedCleaning() {
      let target = this.fixed
      let conf = this.selectedDetails.conf
      
      // 清掃回数判定 6回より多い場合は6回と同じ
      let times = Number(target[2].value)
      if (times > 6) {
        times = '6'
      }
      
      conf['fixedMeter'] = '0'
      conf['fixedMsg'] = null
      this.selectedDetails.price['fixedApprox'] = '0'

      // ㎡数不明
      if (target[1].value == '4') {
        let tip = this.selectedDetails.toolTip
        // 単価取得ボタンを押してもらうために、変数に入れる（式に即時反映すると、計算されていると勘違いされる）
        conf['fixedHouse'] = Number(target[4].value)
        conf['fixedFloor'] = Number(target[8].value)
        conf['fixedNum'] = Number(target[9].value)
        // 長さ
        conf['fixedWide'] = this.getUnitPrice(tip['タイプ'], '3')
        // 戸数 × 長さ × 幅
        let un1 = BigNumber(conf['fixedHouse']).times(Number(conf['fixedWide'])).times(1.5)
        // 階数 × 本数 × 長さ × 幅
        let un2 = BigNumber(conf['fixedFloor']).times(conf['fixedNum']).times(10).times(1.2)      
        // 定期概算 ＝ un1 + un2 + 戸数
        let un3 = Math.floor(BigNumber(un1).plus(un2).plus(conf['fixedHouse']))
        this.selectedDetails.price['fixedApprox'] = String(un3)

        // どの式にするか判定
        this.setApproxform('fixed', conf, un3)
      }

      // 300㎡超えの場合
      if (this.exceed300 || this.selectedDetails.conf['fixedMeter'] == '3') {
        // 行がない、1行しかない場合は行を追加
        if (!this.selectedDetails.displayFixed || !this.selectedDetails.displayFixed.length || this.selectedDetails.displayFixed.length == 1) {
          let addNum = 4 - this.selectedDetails.displayFixed.length
          // 定期清掃の表を増やす
          for (let i = 0; i < addNum; i++) {
            this.addRowDetailFixed()
          }
        }

        // 定期清掃_300㎡超え 基本単価 コード生成
        let code = target[0].value + '-' + times + '-exceed300'

        this.selectedDetails.unitPrice.fixed = this.getUnitPrice(code, '5')
        this.selectedDetails.conf['kindCoef'] = this.getConstantsData('kindCoef', 'kindCoef', 'FIXED_EXCEED300')

      // 200㎡以下、300㎡以下の場合
      } else {

        let meter = target[1].value
        // 不明の場合
        if (meter == '4') {
          meter = conf['fixedMeter']
        }

        // ウォッシュ追加料金
        // 単価コードを生成（エリア-㎡数-wash-f）
        let washCode = target[0].value + '-' + meter + '-wash-f'
        let washList = this.$store.getters.estimateItemPriceList.filter((p) => {
          if (p.parentCode == '5' && p.priceCode == washCode) {
            return true
          }
        })
        if (washList.length) {
          this.selectedDetails.unitPrice.fixed = washList[0].price
        } else {
          this.selectedDetails.unitPrice.fixed = '0'
        }
      }

      // 駐車場代
      if (target[3].value == '1') {
        // 単価コードを生成（エリア-作業時間）
        let parkCode = target[0].value + '-' + target[5].value
        let parkList = this.$store.getters.estimateItemPriceList.filter((p) => {
          if (p.parentCode == '5' && p.priceCode == parkCode) {
            return true
          }
        })
        this.selectedDetails.conf['parkValueSt'] = Number(parkList[0].price).toLocaleString() + ' 円'
        this.selectedDetails.conf['parkValue'] = parkList[0].price

      } else {
        this.selectedDetails.conf['parkValueSt'] = 'なし'
        this.selectedDetails.conf['parkValue'] = '0'
      }
      // オーナー契約
      this.selectedDetails.conf['ownerFixed'] = this.getUnitPrice('owner' + target[6].value, '0')
      // 発電機
      this.selectedDetails.conf['generator'] = this.$store.getters.estimateItemPriceList.find((p) => {
        if (p.parentCode == '5' && p.priceCode == 'generator') {
          return true
        }
      }).price
      // 竣工係数
      this.getFinishPointConf('fixed', 'finishPointFixed')
      await this.calc('fixed')
    },

    /**
     * 定期清掃_計算
     */
    async calcFixedCleaning() {
      this.range = '0'
      if ((!this.selectedDetails.unitPrice.fixed || this.selectedDetails.unitPrice.fixed == '0') && !this.exceed300 && this.selectedDetails.conf['fixedMeter'] != '3') {
        return
      }
      let disp = this.selectedDetails.displayFixed

      // 協力費
      let compe = 1
      if (this.fixed[11] && this.fixed[11].value) {
        let compe2 = BigNumber(Number(this.fixed[11].value)).div(100)
        compe = BigNumber(1).minus(compe2)
      }
      this.selectedDetails.conf['compeFixed'] = String(compe)

      // 合計単価 ＝ 単価 × オーナー契約
      let value = BigNumber(Number(this.selectedDetails.unitPrice.fixed)).times(Number(this.selectedDetails.conf['ownerFixed']))

      // 発電機代
      let generator = 0
      if (this.selectedDetails.conf['generator']) {
        generator = Number(this.selectedDetails.conf['generator'])
      }
      let generatorNum = 0
      if (this.fixed[10] && this.fixed[10].value) {
        generatorNum = Number(this.fixed[10].value)
      }
      generator = generator * generatorNum
      this.selectedDetails.price['generator'] = String(generator)

      // 300㎡超え
      if ((this.exceed300 || this.selectedDetails.conf['fixedMeter'] == '3') && this.selectedDetails.conf.kindCoef) {
        
        this.selectedDetails.unitPrice.fixed300 = String(Math.floor(value))

        // 手動で価格を変更されていない場合のみ
        if (!this.selectedDetails.exceed300PriceBgColor) {
          this.selectedDetails.exceed300Price = '0'
        }
      
        this.totalUnitPrice.fixed = '0'

        for (let i = 0; i < disp.length; i++) {
          let priceKind = '0'
          let kind = '1'
          let qty = '0'

          // 基本単価に初期値設定
          disp[i].priceKind = this.selectedDetails.unitPrice.fixed300

          if (disp[i].priceKind == '') {
            priceKind = '0'
          } else {
            priceKind = disp[i].priceKind
          }

          if (disp[i].kindCoef != '') {
            kind = disp[i].kindCoef.substring(disp[i].kindCoef.indexOf('×')+1, disp[i].kindCoef.indexOf(')'))
          }

          if (disp[i].qty == '') {
            qty = '0'
          } else {
            qty = disp[i].qty
          }
          
          // 単価、オーター契約を手動で変更している場合はセル背景を黄色
          if (this.selectedDetails.unitPriceFixedBgColor || this.selectedDetails.conf['ownerFixedBgColor']) {
            disp[i].priceKindBgColor = true
          } else {
            disp[i].priceKindBgColor = false
          }
          // 最終単価 ＝ 基本単価 × 項目による係数
          let v2 = BigNumber(Number(priceKind)).times(Number(kind))
          disp[i].price = String(Math.floor(v2))

          // 合計金額 ＝ 最終単価 × 数量
          let v3 =  BigNumber(Number(disp[i].price)).times(Number(qty))
          disp[i].total = String(Math.floor(v3))

          // 作業範囲 合計
          this.range = String(Number(BigNumber(Number(this.range)).plus(Number(qty))))

          // 手動で価格を変更されていない場合のみ
          if (!this.selectedDetails.exceed300PriceBgColor) {
            // 金額合計
            this.selectedDetails.exceed300Price = String(Number(this.selectedDetails.exceed300Price) + Number(disp[i].total))
          }

          // 最終金額 ＝ （合計金額×1.8）÷ 2 
          let v5 = BigNumber(Number(this.selectedDetails.exceed300Price)).times(1.8)
          this.selectedDetails.unitPrice.fixedTotal = String(Math.floor(BigNumber(v5).div(2)))
          // 合計金額 ＝ 合計単価 + 発電機代 + 駐車場代
          let v6 = Number(this.selectedDetails.unitPrice.fixedTotal) + generator + Number(this.selectedDetails.conf['parkValue'])
          // 最後に、÷ 協力費 × 竣工係数
          v6 = BigNumber(v6).div(compe).times(Number(this.selectedDetails.conf['finishPointFixed'] || 1))
          // 10円単位を切上
          this.totalUnitPrice.fixed = String(Math.ceil(BigNumber(v6).div(100)) * 100)
        }

        // 発注金額元値用のオーナーなし金額計算
        // 合計単価 ÷ オーナ契約
        let fixedPurchase = BigNumber(Number(this.selectedDetails.unitPrice.fixedTotal)).div(Number(this.selectedDetails.conf['ownerFixed']))
        // 発電機代 + 駐車場代 を加える
        fixedPurchase = BigNumber(fixedPurchase).plus(generator).plus(Number(this.selectedDetails.conf['parkValue']))
        // ÷ 協力費 × 竣工係数
        this.selectedDetails.price['fixedPurchase'] = String(Math.floor(BigNumber(fixedPurchase).div(compe).times(Number(this.selectedDetails.conf['finishPointFixed'] || 1))))

      // 300㎡以下
      } else {
        // 合計単価 = 単価 × オーナー契約
        this.selectedDetails.unitPrice.fixedTotal = String(value)
        // 合計金額 ＝ 合計単価 + 発電機代 + 駐車場代
        let tp = Number(this.selectedDetails.unitPrice.fixedTotal) + generator + Number(this.selectedDetails.conf['parkValue'])
        // ÷ 協力費 × 竣工係数
        tp = BigNumber(tp).div(compe).times(Number(this.selectedDetails.conf['finishPointFixed'] || 1))
        // 10円単位で切上げて100円単位にする
        tp = Math.ceil(BigNumber(tp).div(100)) * 100
        this.totalUnitPrice.fixed = String(tp)

        // 発注金額元値用のオーナーなし金額計算
        // 単価 + 発電機代 + 駐車場
        let fixedPurchase = Number(this.selectedDetails.unitPrice.fixed) + generator + Number(this.selectedDetails.conf['parkValue'])
        // さらに ÷ 協力費 × 竣工係数
        this.selectedDetails.price['fixedPurchase'] = Math.floor(BigNumber(fixedPurchase).div(compe).times(Number(this.selectedDetails.conf['finishPointFixed'] || 1)))

        let qty = '0'
        for (let i = 0; i < disp.length; i++) {
          if (disp[i].qty == '') {
            qty = '0'
          } else {
            qty = disp[i].qty
          }
          // 作業範囲 合計
          this.range = String(Number(this.range) + Number(qty))
        }
      }
      if (isNaN(this.totalUnitPrice.fixed)) {
        this.totalUnitPrice.fixed = '0'
      }

      let q = '0'
      let flag = false
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == 'fixedName' && !this.selectedDetails.display[i].detailBgColor) {

          // 表示内容
          this.selectedDetails.display[i].price = this.totalUnitPrice.fixed
          q = this.selectedDetails.display[i].qty
          flag = true
          break
        }
      }
      // 対象の行がなければ、プッシュ
      if (!flag) {
        let row = {name: '床面通常洗浄（年6回）・床面簡易洗浄（年6回）', qty:'1', unitName: 'ヶ月', price: this.totalUnitPrice.fixed, type: 'fixedName', detailBgColor: false, qty2: '12', unitName2: 'ヶ月', price2: '', categoryBgColor: false}
        this.selectedDetails.display.push(row)
      }

      this.total.fixed = String(Number(this.totalUnitPrice.fixed) * Number(q))
      await this.getPurchasePrice(null, false, false, 'fixed')
    },

    /**
     * ラウンドプラス タブ変更イベント
     */
    async tabChanged(tab) {
      this.selectedDetails.noteHTML = ''

      this.selectedDetails.getRlsPrice = false
      this.selectedDetails.getNormalPrice = false
      this.selectedDetails.getFixedPrice = false

      this.rlsPlustabs.forEach((t) => {
        t.active = false
      })
      this.selectedDetails.tabId = tab.id
      tab.active = true

      this.selectedDetails.display[0].name = '巡回清掃'
      this.selectedDetails.display[0].price = '0'
      this.selectedDetails.display[0].type = 'rls'
      this.selectedDetails.display.splice(1)

      let normal = {name: '固定清掃（週　回・3時間以内）', qty:'1', unitName: 'ヶ月', price: '0', type: 'normal', detailBgColor: false, qty2: '12', unitName2: 'ヶ月', price2: '', categoryBgColor: false}
      let fix = {name: '床面通常洗浄（年6回）・床面簡易洗浄（年6回）', qty:'1', unitName: 'ヶ月', price: '0', type: 'fixedName', detailBgColor: false, qty2: '12', unitName2: 'ヶ月', price2: '', categoryBgColor: false}

      if (tab.name == 'クリーンプラス') {
        this.selectedDetails.display.push(normal)
        // 単独発注
        this.setInitValue(this.normal[10], this.buildingInfo.other)
        // タイプ
        this.setInitValue(this.normal[12], this.buildingInfo.residenceType)
      } else if (tab.name == 'ウォッシュプラス') {
        this.selectedDetails.display.push(fix)
      } else {
        this.selectedDetails.display.push(normal)
        this.selectedDetails.display.push(fix)
        // 清掃員人数＿1人固定！ダブルプラスの場合のみ
        this.setInitValue(this.normal[8], '1 人')
        this.normal[8].value = '1'
        this.setWeekArr(8)
        // 単独発注＿他あり固定！ダブルプラスの場合のみ
        this.setInitValue(this.normal[10], '他あり')
        this.normal[11].value = '0'
      }

      if (tab.name != 'クリーンプラス') {
        this.changeFixedContent()
      }

      // 初期化
      this.selectedDetails.conf = {}
      this.selectedDetails.unitPrice = {
        rls: '0',
        normal: '0',
        fixed: '0'
      }
      this.totalUnitPrice = {
        rls: '0',
        normal: '0',
        fixed: '0'
      }

      // 発注初期化
      this.getDefaultPurchaseAll()

      // await this.getInitPurchaseRate()
      // 初期化
      this.selectedDetails.changePurchasePriceType = []
    },

    /**
     * 上の表の選択肢、活性・非活性
     * @param index インデックス
     * @param name 種類
     */
    setDisabled(index, name) {

      if (name == 'normal') {
        if (index == 4) {
          return true
        }
        if (this.rlsPlusKind == 'double') {
          if (index == 8) {
            return true
          }
        }
      } else if (name == 'fixed') {
        if (index == 2) {
          return true
        }
      }
      return false
    },

    /**
     * 合計金額を計算
     */
    calcTotal() {
      // 発注金額が変更されていたら、モーダル開いた時点では計算しない todo
      if (this.openInit && this.selectedDetails.changePurchasePrice) {
        return
      }

      let price = '0'
      let d = this.selectedDetails.display
      // カテゴリー単価が手動で変更されていたら計算しない
      if (d[0].categoryBgColor) {
        return
      }
      for (let i = 0; i < d.length; i++) {
        if (d[i].price != '' && d[i].qty != '') {
          price = String((Number(d[i].qty) * Number(d[i].price)) + Number(price))
        }
      }
      d[0].price2 = price
      
      // カテゴリ－総合計金額計算
      this.categoryAmountCalc()
    },

    /**
     * カテゴリ－総合計金額計算
     */
    categoryAmountCalc() {
      const p = Number(this.selectedDetails.display[0].price2)
      if (p && !isNaN(p)) {
        this.categoryAmount = p
      } else {
        this.categoryAmount = 0
      }
    },

    /**
     * 遷移ボタンのCSS
     * @param name 種類名
     */
    setPosition(name) {
      if (name == 'rls') {
        if (this.rlsPlusKind == 'double') {
          return 'b-1'
        } else {
          return 'b-2'
        }
      } else if (name == 'normal') {
        if (this.rlsPlusKind == 'double') {
          return 'b-2'
        } else {
          return 'b-3'
        }
      }
    },

    /**
     * 単価表にない値を変更_changeイベントでは変更できないため
     */
    // checkRadio(index) {
    //   if (index == 1) {
    //     return
    //   }
    //   let target = this.rls
    //   if (target[1].value != '1' && Number(target[0].value) <= 20 && target[2].value == 'm1') {
    //     alert('月1回の場合、単価は「シングル」単価しかありません。「シングル」にします。')
    //     this.setInitValue(target[1], 'シングル')
    //   }
    // },

    /**
     * 日常清掃・管理員業務の値チェック
     */
    async checkNormalAndMgm(name) {
      if (name != 'normal') {
        return true
      }
      let target = this.normal
      let tip = this.selectedDetails.toolTip

      // 時間指定なし
      if (target[4].value == '0') {
        this.setZeroValue(target[5])
        delete tip['清掃時間(指定あり)']

      // 時間指定あり
      } else if (target[4].value == '1') {
        this.setZeroValue(target[6])
        delete tip['清掃時間(指定なし)']
      }

      // 単独＆㎡不明
      if (target[11].value == '4') {
        delete tip['㎡数（㎡数を記入してください）']
        target[19].value = ''

        // タイプが選択されていない
        if (target[12].value == '0') {
          target[12].value = ''
        }
        let zero = []
        // 戸数が0のまま
        if (target[13].value == '0') {
          zero.push(target[13].name)
        }
        // 階数が0のまま
        if (target[14].value == '0') {
          zero.push(target[14].name)
        }
        // 本数が0のまま
        if (target[15].value == '0') {
          zero.push(target[15].name)
        }

        if (zero.length) {
          let dlogR = await dialogs.showConfirmDialog(zero.join('・'), `㎡数不明ですが、${zero.join('・')}が0です。\r\nよろしいですか？`)
          if (dlogR != 'YES') {
            return false
          }
        }
      }
      
      // 300超えで300以上でない場合
      else if (target[11].value == '3' && target[19] && target[19].value != '' && 300 > Number(target[19].value)) {
        let dlogR = await dialogs.showConfirmDialog('㎡数確認', '「300㎡超え」を選択していますが、300以下の値を記入しています。\r\nよろしいですか？')
        if (dlogR != 'YES') {
          return false
        }
      }

      return true
    },

    /**
     * 曜日・時間帯チェック
     * @param name ボタンを押した種類
     */
    async checkWeekAndTime(name) {
      if (name != 'normal') {
        return true
      }

      // クリーンプラスでない場合、チェックしない
      if (this.rlsPlusKind != 'clean') {
        return true
      }
      let target = this.normal
      let remarks = this.selectedDetails.remarks
      
      let weekEmp = []
      let weekKind = []
      let timeEmp = []
      let timeSum = 0
      for (let i = 0; i < remarks.length; i++) {
        const r = remarks[i]

        // 清掃員1人の場合
        // if (remarks.length == 1) {
        //   // 曜日選択無し
        //   if (!r.week || !r.week.length) {
        //     weekEmp.push(i+1)

        //   // 曜日の数が合わない
        //   } else if (r.week.length != Math.round(Number(target[7].value))) {
        //     let errR = await dialogs.showWarningConfirmDialog('曜日', '週' + target[7].value + '回ですが、曜日は' + r.week.length + '個選んでいます。\r\nよろしいですか？')
        //     if (errR != 'YES') {
        //       return false
        //     }
        //   }
        
        // // 清掃員複数の場合
        // } else {
          // 曜日未入力
          if (!r.week.length) {
            weekEmp.push(i+1)
          } else {
            weekKind = weekKind.concat(r.week)
          }
        // }

        // 時間
        if (r.start && r.start != '' && r.end && r.end != '') {
          let start = r.start.split(':')
          let end = r.end.split(':')
          if (start.length == 2 && end.length == 2 && start[0] != '' && start[1] != '' && end[0] != '' && end[1] != '') {
            let dif = 0
            let s = 0
            let e = 0
            s = Number(start[0]) + (Number(start[1]) / 60)
            e = Number(end[0]) + (Number(end[1]) / 60)
            dif = e - s
            let hour = this.normal[6].value
            if (Number(hour) >= 6) {
              hour = String(Number(hour) + 1)
            }
              // 開始時間の方が遅い場合
              if (dif < 0) {
                await dialogs.showErrorDialog('時間確認', `${i+1}番目の時間帯が不正です。`)
                return false
              } else {
                timeSum += dif
              }
            // }
            
          } else {
            timeEmp.push(i+1)
          }
        } else {
          timeEmp.push(i+1)
        }
      }

      // 複数人の場合
      // if (remarks.length && remarks.length != 1) {
        let weekF = false
        let timeF = false
        let msg = ''
        // 未入力がある場合
        if (weekEmp.length || timeEmp.length) {
          if (weekEmp.length) {
            weekF = true
            msg = `${weekEmp.join('・')}番目の曜日`
          }
          if (timeEmp.length) {
            timeF = true
            if (weekF) {
              msg += `、`
            }
            msg += `${timeEmp.join('・')}番目の時間帯`
          }

          msg += 'が未入力ですがよろしいですか？'
        }

        let set = new Set(weekKind)
        if (weekEmp.length != remarks.length && set.size != Math.round(Number(target[7].value))) {
          weekF = true
          if (msg != '') {
            msg += '\r\n'
          }
          msg += '清掃回数は週' + target[7].value + '回です。\r\n全員合わせて週' + set.size + '回の曜日選択になっていますがよろしいですか？'
          if (remarks.length == 1) {
            msg = msg.replace('全員合わせて', '')
          }
        }
        
        let errTime = `${this.normal[6].value}ｈ`
        let hour2 = Number(this.normal[6].value)
        if (hour2 >= 6) {
          hour2 = hour2 + 1
          errTime = `${hour2}ｈ（休憩含む）`
        }

        let sum = hour2 * remarks.length
        if (timeEmp.length != remarks.length && sum != timeSum) {
          timeF = true
          if (msg != '') {
            msg += '\r\n'
          }
          if (remarks.length == 1) {
            msg += '「清掃時間」と「時間帯」が一致しませんがよろしいですか？\r\n清掃時間：' + sum + '\r\n時間帯：' + timeSum + '時間'
          } else {
            msg += `清掃時間は${errTime}×${remarks.length}人＝${sum}ｈですが、全員合わせて${timeSum}ｈになっています。\r\nよろしいですか？`
          }
        }
        let title = ''
        if (weekF) {
          title = '曜日'
        }
        if (timeF) {
          if (title != '') {
            title += '・'
          }
          title += '時間帯'
        }
        if (title != '') {
          // 清掃員1人の場合
          if (remarks.length == 1) {
            msg = msg.replaceAll('1番目の', '')
          }
          let errR = await dialogs.showWarningConfirmDialog(title, msg)
          if (errR != 'YES') {
            return false
          }
        }
      // }

      return true
    },

    /**
     * 作業部位表示変更イベント
     */
    changeFixedContent() {
      let value = this.selectedDetails.fixedContent
      let index = []
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
          let d = this.selectedDetails.display[i]
          if (d.type == 'fixedRange') {
            index.push(i)
          }
        }

      for (let i = index.length-1; i >= 0; i--) {
        this.selectedDetails.display.splice(index[i], 1)
      }
      if (value == '0') {
        this.selectedDetails.display.push({name: '定期清掃作業範囲', qty:this.range, unitName: '㎡', price: '', type: 'fixedRange', detailBgColor: false, qty2: '12', unitName2: 'ヶ月', price2: '', categoryBgColor: false})
      } else if (value == '1') {
        for (let i = 0; i < this.selectedDetails.displayFixed.length; i++) {
          let target = this.selectedDetails.displayFixed[i]
          
          let row = {name: target.name, qty:target.qty, unitName: target.unitName, price: '', type: 'fixedRange', detailBgColor: false, qty2: '12', unitName2: 'ヶ月', price2: '', categoryBgColor: false}
          this.selectedDetails.display.push(row)
        }
      }
    },


    /**
     * 数量が同じならセル結合
     * @param index 行番号
     * @param type 行タイプ
     */
    setRowspan(index, type) {
      let rowspan = 1
      let row = this.selectedDetails.display
      if (type == 'fixedRange') {
        row[index]['plusRowspan'] = rowspan
        return rowspan
      }
      if (row[index].qty == '') {
        return rowspan
      }
      if (index != 0) {
        if (row[index-1].qty == row[index].qty && row[index-1].unitName == row[index].unitName) {
          rowspan = -1
        } else {
          let count = 1
          for (let i = index; i < row.length; i++) {
            if (row[i+1] && row[i+1].qty == row[index].qty && row[i+1].unitName == row[index].unitName) {
              count++
            } else {
              break
            }
          }
          rowspan = count
        }
      } else {
        let count = 1
        for (let i = index; i < row.length; i++) {
          if (row[i+1] && row[i+1].qty == row[index].qty && row[i+1].unitName == row[index].unitName) {
            count++
          } else {
            break
          }
        }
        rowspan = count
      }
      
      row[index]['plusRowspan'] = rowspan
      return rowspan
    },

    /* 非活性の色設定
     * @param blue 背景色を青にするか判定
     */
    setDisableColor(blue, row, isNameOrQty) {
      if (row.type == 'fixedRange' && isNameOrQty) {
        return 'bg-gray-200'
      }
      if (!this.selectedDetails.getRlsPrice && row.type == 'rls') {
        return 'bg-gray-200'
      } else if (!this.selectedDetails.getNormalPrice && row.type == 'normal') {
        return 'bg-gray-200'
      }if (!this.selectedDetails.getFixedPrice && row.type == 'fixedName') {
        return 'bg-gray-200'
      } else if (blue) {
        return 'bg-blue-200'
      } else {
        return ''
      }
    },

    /**
     * 非活性判定
     */
    setDisabledDisp(row, isNameOrQty) {
      if (row.type == 'fixedRange' && isNameOrQty) {
        return true
      }
      if (!this.selectedDetails.getRlsPrice && row.type == 'rls') {
        return true
      } else if (!this.selectedDetails.getNormalPrice && row.type == 'normal') {
        return true
      }if (!this.selectedDetails.getFixedPrice && row.type == 'fixedName') {
        return true
      } else {
        return false
      }
    },

    /**
     * 数量の色設定
     * @param blue 数量が未入力
     * @param type タイプ
     */
    setQtyDisableColor(blue, type) {
      if (type == 'fixedRange') {
        return 'bg-gray-200'
      } else if (blue) {
        return 'bg-blue-200'
      } else {
        return ''
      }
    },

    /**
     * 特記事項を追加イベント
     */
    selectNote() {
      this.noteSelectorShow = true
    },

    /**
     * 見積特記事項を特記事項欄に反映
     */
    applyNotes(notes) {
      this.noteSelectorShow = false
      const notesText = notes.map((note) => {
        note.note = note.note.replaceAll('●●', '<span style="background-color: rgb(217 107 107);">●●</span>')
        return `${note.note.split(/\r\n|\n/).join('<br>')}`
      }).join('')
      this.selectedDetails.noteHTML += notesText
    },

    /**
     * 特記事項コピーイベント
     */
    async onCopyNote() {
      if (this.noteStyle) {
        return
      }
      await utils.onCopyNoStyle()
    },

    /**
     * 特記事項変更
     */
    noteChange({html}) {
      this.selectedDetails.noteHTML = html
    },

    /**
     * 選択した見積内容に関する特記事項を取得
     */
    async getEstimateNote(code) {
      // 特記事項を取得
      this.selectedDetails.noteHTML = ''

      for (let i = 0; i < code.length; i++) {
        let text = ''
        if (code[i].code != '') {
          text = await estimateManager.getEstimateNoteText(this, code[i].code)

          if (code[i].title != '') {
            text = code[i].title + '<br>' + text
          }
        }
        
        text = text.replaceAll('●●', '<span style="background-color: rgb(217 107 107);">●●</span>')
        text = text.split(/\r\n|\n/).join('<br>') + '<br>'

        if (i != 0 && text != '') {
          text = '<br>' + text
        }

        if (code[i].code == '99-1' || code[i].code == '99-2') {
          if (this.selectedDetails.tabId != 'wash') {
            text = text.replace('<br>', '')
          }
          this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('バイク:', text)
        } else {
          this.selectedDetails.noteHTML = this.selectedDetails.noteHTML + text
        }
      }
      // 見積りに単価を表示・非表示による特記事項
      this.setIsUnitPriceHtml()

      // 定期清掃の発電機
      if (this.rlsPlusKind != 'clean') {
        // 発電機利用の場合は、特記事項から削除
        console.log(this.fixed)
        if (this.fixed && this.fixed[10] && Number(this.fixed[10].value) > 0) {
          this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('共用部の電源が使用出来ない場合、発電機使用料として追加費用がかかります。', '')
        }
      }
    },

    /**
     * 特記事項に追加
     * @param code 特記事項コード
     */
    async addEstimateNote(code) {
      let text = ''
      if (code) {
        text = await estimateManager.getEstimateNoteText(this, code)
      }
      if (text) {
        text = text.split(/\r\n|\n/).join('<br>') + '<br>'
        this.selectedDetails.noteHTML += '<br>' + text
      }
    },

    /**
     * 過去データでremarksがないものを人数分増やす
     */
    setRemarks() {
      // 曜日
      // 仕様変更前のデータなら、人数分の配列を生成
      if (!this.selectedDetails.remarks || !this.selectedDetails.remarks.length) {
        // 1人分のremarksを生成
        this.selectedDetails.remarks = [{start: '', end: '', week: []}]
      }
    },
    
    /**
     * 曜日選択イベント
     * @param tab 対象曜日
     * @param i インデックス
     */
    weekChanged(tab, i) {
      tab.active = !tab.active

      let result = []

      result = this.week[i].map((w) => {
        if (w.active) {
          return w.name
        }
      }).filter(v => v)
      this.selectedDetails.remarks[i]['week'] = result
      if (result.includes('日')) {
        this.isSunday = true
      }
    },

    /**
     * 曜日選択を人数分＆見た目をセット
     */
    setWeeks() {
      // 人数分の曜日を作成
      if (this.normal[8] && this.normal[8].value != '1') {
        let num = Number(this.normal[8].value)
        for (let i = 0; i < num-1; i++) {
          this.week.push([
              {id: '0', name: '月', active: false},
              {id: '1', name: '火', active: false},
              {id: '2', name: '水', active: false},
              {id: '3', name: '木', active: false},
              {id: '4', name: '金', active: false},
              {id: '5', name: '土', active: false},
              {id: '6', name: '日', active: false},
            ])
        }
      }
      // 該当する曜日を活性
      if (this.selectedDetails.remarks && this.selectedDetails.remarks.length) {
        for (let wIdx = 0; wIdx < this.selectedDetails.remarks.length; wIdx++) {
          const r = this.selectedDetails.remarks[wIdx]
          this.week[wIdx].forEach((w) => {
            if (r.week.includes(w.name)) {
              w.active = true
            }
          })
        }
      }
    },

    /**
     * 日曜の選択が含まれているか
     */
    checkSunday() {
      if (!this.selectedDetails.remarks) {
        return this.isSunday = false
      } else {
        for (let i = 0; i < this.selectedDetails.remarks.length; i++) {
          const r = this.selectedDetails.remarks[i]
          if (r.week && r.week.includes('日')) {
            return this.isSunday = true
          }
        }
      }
      return this.isSunday = false
    },

    /**
     * 発注マスタオープン
     */
    openPurchaseModal() {
      this.purchaseModalShow = true
    },

    /**
     * 発注マスタクローズ
     */
    closePurchaseModal() {
      this.purchaseModalShow = false
    },
    
    /**
     * 発注金額を算出
     * @param gName グループ名
     * @param changePrice 発注金額変更
     * @param changeRate 発注率変更
     * @param type 種別
     */
    async getPurchasePrice(gName, changePrice, changeRate, type) {
      if (!this.active) {
        return
      }
      // モーダルを開いてすぐは何も計算しない
      if (this.openInit) {
        return
      }
      
      // 過去データか確認
      await this.isOldPurchase()
      
      await this.getPurchasePriceNew(gName, changePrice, changeRate, type)
    },
    
    /**
     * 発注金額を算出_新（発注明細が複数ある）
     * @param gName グループ名
     * @param changePrice 発注金額変更
     * @param changeRate 発注率変更
     * @param type 種別
     */
    async getPurchasePriceNew(gName, changePrice, changeRate, type) {
      if (!this.active) {
        return
      }
      // モーダルを開いてすぐは何も計算しない
      if (this.openInit) {
        return
      }

      if (!type) {
        return
      }
      
      this.judgeSuppriorApollo()

      // 対象発注データ
      let p = null
      // 率
      let rate = 0
      // 発注金額
      let price = 0
      // 元値
      let ePrice = 0
      // 手数料率
      let commissionRate = Number(this.commission[0].rate)
      if (isNaN(commissionRate)) {
        commissionRate = 0
      }

      // 対象の発注データを見つける
      for (let i = 0; i < this.purchase.length; i++) {
        const pur = this.purchase[i]
        if (pur.type == type) {
          p = pur
          break
        }
      }

      // 発注先がアポロ以外はもとにする金額は、オーナー契約なしの金額
      let t = type
      if (type == 'fixed') {
        t = 'fixedName'
      }
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == t) {
          let price = '0'

          // 発注先名がアポロの場合
          if (p.supplier.indexOf('アポロ') > -1) {
            price = this.totalUnitPrice[type]

          // オーナー契約なしの金額単価
          } else {
            price = this.selectedDetails.price[type + 'Purchase']
          }
          
          if (isNaN(price)) {
            price = '0'
          }
          // 数量1
          let num = this.selectedDetails.display[i].qty
          if (isNaN(num)) {
            num = '0'
          }
          // 数量は1だが、念のため数量×単価をして単価2を出す
          ePrice = Number(price) * Number(num)
          // 手数料分を差し引く
          let cr = 100 - commissionRate
          cr = BigNumber(cr).div(100)
          ePrice = Math.floor(BigNumber(ePrice).times(cr))
          break
        }
      }

      // 発注金額がない&発注率がない場合は何もしない
      if (((!p.rate || p.rate == '' || p.rate == '0') && (!p.price || p.price == '' || p.price == '0'))) {
        return
      }

      let categoryCode = ''
      let name = ''
      switch (type) {
        case 'rls':
          name = '巡回清掃'
          categoryCode = '1'
          break;

        case 'normal':
          name = '固定清掃'
          categoryCode = '3'
          break;

        case 'fixed':
          name = '定期清掃'
          categoryCode = '5'
          break;
      }

      rate = Number(p.rate)
      price = Number(p.price)

      // 発注率、発注金額が変更の場合
      if (gName) {
        // 率を変更
        if (changeRate) {
          // 発注金額変更
          price = Math.floor(ePrice * (rate / 100))
        }
        // 発注金額を手動で変更
        if (changePrice) {
          // 発注金額を変更されても手数料なしは変更しない

          if (!this.selectedDetails.changePurchasePriceType.includes(name)) {
            this.selectedDetails.changePurchasePriceType.push(name)
          }
        }
        p.rate = String(rate)
        p.price = String(price)
        
      // 見積金額が変更になった場合
      } else {
        // 発注金額がない場合
        if (p.price == '' || p.price == '0' || (type == 'fixed' && (this.exceed300 || this.selectedDetails.conf['fixedMeter'] == '3'))) {
          let r = BigNumber(rate).div(100)
          price = Math.floor(BigNumber(ePrice).times(r))
          p.price = String(price)

        // 発注金額がすでにある場合は発注率を変更
        } else {
          if (ePrice && ePrice != 0 && !isNaN(ePrice)) {
            rate = BigNumber(price).div(ePrice)
            rate = Math.floor(rate * 1000) / 10
            p.rate = String(rate)
          } else {
            p.rate = ''
            p.price = ''
            rate = 0
          }
        }
      }

      // 発注先によって初期発注率が変わる
      p.initRate = utils.getPurchaseRate(categoryCode, p.supplier, this.$store.getters.purchaseRate, null)
      // マスタ値を超えている場合
      if (p.initRate < rate) {
        // 何度も警告しないようにする
        if (!this.overPurchaseRate.includes(type)) {
          await dialogs.showErrorDialog('発注率オーバー', p.typeName + 'の発注率が設定値を超えています。\r\n設定値：' + p.initRate + '%')
          this.overPurchaseRate.push(type)
        }
        p.priceBgColor = true
        p.rateBgColor = true
      } else {
        p.priceBgColor = false
        p.rateBgColor = false
        let over = this.overPurchaseRate.filter((o) => {
          return o != type
        })
        this.overPurchaseRate = utils.clone(over)
      }
      this.purchaseReload++
    },

    /**
     * 発注先がアポロだったらフラグを立てる
     */
    judgeSuppriorApollo() {
      for (let i = 0; i < this.purchase.length; i++) {
        const p = this.purchase[i]
        if (p.supplier.indexOf('アポロ') > -1) {
          this[p.type + 'Apollo'] = true
        } else {
          this[p.type + 'Apollo'] = false
        }
      }
    },
    
    /**
     * 発注金額を算出_過去データ（発注明細が複数行になる前）
     * @param gName グループ名
     * @param changePrice 発注金額変更
     * @param changeRate 発注率変更
     */
    async getPurchasePriceOld(gName, changePrice, changeRate) {
      if (!this.active) {
        return
      }
      // モーダルを開いてすぐは何も計算しない
      if (this.openInit) {
        return
      }
      let rate = 0
      let price = 0
      let ePrice = 0

      // 発注金額手動変更計算直後、もしくは発注率,発注金額がない場合は何もしない
      if (this.changePurchasePrice || ((!this.purchase[0].rate || this.purchase[0].rate == '' || this.purchase[0].rate == '0') && (!this.purchase[0].price || this.purchase[0].price == '' || this.purchase[0].price == '0'))) {
        this.changePurchasePrice = false
        return
      }
      rate = Number(this.purchase[0].rate)
      price = Number(this.purchase[0].price)
      // もとにする金額は、手数料なしの金額
      ePrice = Number(this.commission[0].estimatePrice)
      
      if (!ePrice || isNaN(ePrice)) {
        ePrice = 0
      }

      // 発注率、発注金額が変更の場合
      if (gName) {
        if (changePrice || changeRate) {
          // 過去データのため新フォームに変更
          await this.isOldPurchase()
        }
      // 見積金額が変更になった場合
      } else {
        this.selectedDetails.changePurchasePrice = false
        // 発注金額がない場合
        if (this.purchase[0].price == '' ||this.purchase[0].price == '0') {
          price = Math.floor(ePrice * (rate / 100))
          this.purchase[0].price = String(price)

        // 発注金額がすでにある場合は発注率を変更
        } else {
          if (ePrice && ePrice != 0 && !isNaN(ePrice)) {
            rate = price / ePrice
            rate = Math.floor(rate * 1000) / 10
            this.purchase[0].rate = String(rate)
          } else {
            this.purchase[0].rate = ''
            this.purchase[0].price = ''
            rate = 0
          }
        }
      }
        
      // 発注先によって初期発注率が変わる
      this.initRate = utils.getPurchaseRate(this.categoryCode, this.purchase[0].supplier, this.$store.getters.purchaseRate, null)
      // マスタ値を超えている場合
      if (this.initRate < rate) {
        await dialogs.showErrorDialog('発注率オーバー', '発注率が設定値を超えています。\r\n設定値：' + this.initRate + '%')
        this.purchase[0].priceBgColor = true
        this.purchase[0].rateBgColor = true
      } else {
        this.purchase[0].priceBgColor = false
        this.purchase[0].rateBgColor = false
      }
      this.purchaseReload++
    },

    /**
     * 単価を取得ボタン押下時の発注初期化
     */
    setInitPurchase(name) {
      let NA = ''

      // タブがクリーンプラスなら定期清掃の行は不要
      if (this.selectedDetails.tabId == 'clean') {
        NA = 'fixed'

      // タブがウォッシュプラスなら固定清掃の行は不要
      } else if (this.selectedDetails.tabId == 'wash') {
        NA = 'normal'
      }

      // 過去データで、typeがないか確認
      let old = false
      for (let i = 0; i < this.purchase.length; i++) {
        const pur = this.purchase[i]
        if (!pur.type) {
          old = true
          break
        }
      }
      // 過去の場合、全て作り直す
      if (old) {
        this.getDefaultPurchaseAll()
      }

      // 必要な発注の行のみ取得
      for (let i = 0; i < this.purchase.length; i++) {
        const p = this.purchase[i]
        if (p.type && p.type != '' && p.type != NA) {
          // 該当の行が既にある場合、フラグを立てる
          if (name == p.type) {
            // 初期化
            this.purchase[i] = this.getDefaultPurChaseByType(p)
          }
        }
      }

      // 並べ替え
      const type = ['rls', 'normal', 'fixed']
      let pu = []
      for (let i = 0; i < type.length; i++) {
        const t = type[i]
        for (let j = 0; j < this.purchase.length; j++) {
          const n = this.purchase[j]
          if (t == n.type) {
            pu.push(n)
            break
          }
        }
      }
      this.purchase = utils.clone(pu)
    },

    /**
     * デフォルトの発注率・名をセット
     */
    getDefaultPurChaseByType(target) {
      // RLS
      let categoryCode = '1'
      target.typeName = '巡回清掃'

      // 固定清掃
      if (target.type == 'normal') {
        categoryCode = '3'
        target.typeName = '固定清掃'

      // 定期清掃
      } else if (target.type == 'fixed') {
        categoryCode = '5'
        target.typeName = '定期清掃'
      }

      // 発注率マスタ
      let rateData
        
      rateData = this.$store.getters.purchaseRate.find((p) => {
        return p.categoryCode == categoryCode
      })

      // 率
      target.rate = rateData.rate
      // 基本率
      target.initRate = rateData.rate
      // 金額
      target.price = ''
      // 発注先
      if (rateData.supplier && rateData.supplier != '') {
        target.supplier = rateData.supplier
      }

      return target
    },

    /**
     * 発注明細表全て初期化
     */
    getDefaultPurchaseAll() {
      let row = {
        categoryCode: this.purchaseData[0].categoryCode,
        categoryName: this.purchaseData[0].categoryName,
        groupName: this.purchaseData[0].groupName,
        supplier: '',
        rate: '',
        price: '',
        priceBgColor: false,
        rateBgColor: false,
        type: '',
        typeName: ''
      }
      this.purchase = []

      // RLS
      let rls = utils.clone(row)
      rls.type = 'rls'
      rls = this.getDefaultPurChaseByType(rls)
      this.purchase.push(rls)

      // 固定清掃
      let nor = utils.clone(row)
      nor.type = 'normal'
      nor = this.getDefaultPurChaseByType(nor)

      // 定期清掃
      let fix = utils.clone(row)
      fix.type = 'fixed'
      fix = this.getDefaultPurChaseByType(fix)

      // クリーンプラス,ダブルプラスの場合
      if (['clean', 'double'].includes(this.selectedDetails.tabId)) {
        this.purchase.push(nor)
      }
      // ウォッシュプラス,ダブルプラスの場合
      if (['wash', 'double'].includes(this.selectedDetails.tabId)) {
        this.purchase.push(fix)
      }
      this.purchaseReload++
    },

    /**
     * 発注明細が古いか確認し、古かったら新フォームに変更
     */
    async isOldPurchase() {
      for (let i = 0; i < this.purchase.length; i++) {
        const p = this.purchase[i]
        if (!p.type) {
          await dialogs.showWarningDialog('発注明細を変更します', 'こちらの発注明細データは仕様変更前のものです。\r\n新しい発注明細の形に変更します。')
          this.getDefaultPurchaseAll()
          await this.calcPurchaseForEmpty()
          return
        }
      }
    },

    /**
     * 発注先変更イベント
     * 発注先によって初期発注率が変更となる
     */
    async changeInitRate(val) {
      // 過去データか確認
      await this.isOldPurchase()
      
      this.judgeSuppriorApollo()

      // 対象の種別を探す
      let target = {}
      for (let i = 0; i < this.purchase.length; i++) {
        const pu = this.purchase[i]
        if (pu.type == val.type) {
          target = pu
          break
        }
      }
      target.supplier = val.sup

      let code = ''
      switch (val.type) {
        case 'rls':
          code = '1'
          break;
          
        case 'normal':
          code = '3'
          break;
          
        case 'fixed':
          code = '5'
          break;
      
        default:
          break;
      }
      // 発注先によって初期発注率が変わる
      target.initRate = utils.getPurchaseRate(code, val.sup, this.$store.getters.purchaseRate, null)
      // 100%になった時は発注率を変更
      if (target.initRate == 100) {
        target.rate = String(target.initRate)
        await this.getPurchasePrice(this.purchaseData[0].groupName, false, true, target.type)

      // 発注先が変更になり、初期発注率も変わったら計算し直す（アラート表示をするため）
      } else if(target.rate != target.initRate) {
        await this.getPurchasePrice(this.purchaseData[0].groupName, false, true, target.type)
      }
      this.purchaseReload++
    },

    /**
     * 発注率、見積り単価があって、発注金額がない場合、計算する
     */
    async calcPurchaseForEmpty() {
      for (let i = 0; i < this.purchase.length; i++) {
        const p = this.purchase[i]
        // 発注金額なし＆発注率あり
        if (!p.price && p.rate && p.type) {
          await this.getPurchasePriceNew(null, false, false, p.type)
        }
      }
    },

    /**
     * 親画面の手数料明細取得
     */
    getCommissionData(modalData) {
      this.commission[0] = utils.clone(this.commissionData)

      // まだ詳細画面を開いて1度も編集していない場合
      if (!Object.keys(modalData).length) {
        if (this.commission[0].rate == '') {
          // 手数料の初期値セット
          this.setInitCommission()
        }
      }
      this.commissionReload++
    },

    /**
     * 手数料の初期値セット
     */
    setInitCommission() {
      if (this.buildingInfo.commission && this.buildingInfo.commission == 'なし') {
        this.commission[0].rate = '0'
        this.commission[0].commissionPrice = '0'
        this.commissionReload++
      } else if (this.buildingInfo.commission && this.buildingInfo.commission == 'あり' && this.commissionMst.rate) {
        this.commission[0].rate = String(this.commissionMst.rate)
        this.commissionReload++
      }
    },

    /**
     * 手数料率変更イベント
     */
    async rateSelected(rate) {
      console.log('rateSelected')
      this.commission[0].rate = rate
      // 手数料計算
      this.getCommissionPrice()
      // 発注計算
      await this.getPurchasePrice(null, false, false, 'rls')
      if (['clean', 'double'].includes(this.selectedDetails.tabId)) {
        await this.getPurchasePrice(null, false, false, 'normal')
      }
      if (['wash', 'double'].includes(this.selectedDetails.tabId)) {
        await this.getPurchasePrice(null, false, false, 'fixed')
      }
    },

    /**
     * 手数料率変更イベント
     */
    async rateBlur() {
      console.log('rateBlur')
      // 手数料計算
      this.getCommissionPrice()

      // 発注計算
      await this.getPurchasePrice(null, false, false, 'rls')
      if (['clean', 'double'].includes(this.selectedDetails.tabId)) {
        await this.getPurchasePrice(null, false, false, 'normal')
      }
      if (['wash', 'double'].includes(this.selectedDetails.tabId)) {
        await this.getPurchasePrice(null, false, false, 'fixed')
      }
    },

    /**
     * 手数料計算
     */
    getCommissionPrice() {
      if (!this.active) {
        return
      }
      let commissionPrice = 0
      // 率
      let rate = Number(utils.hankaku(this.commission[0].rate))
      // アポロ金額
      let ePrice = 0
      // 手数料込
      let price = Number(this.categoryAmount)

      // 率なし
      if (!rate || isNaN(rate)) {
        // this.commission[0].rate = '0'
        rate = 0
        commissionPrice = 0
        ePrice = price

      // 元値なし
      } else if (!price || isNaN(price)) {
        commissionPrice = 0
        ePrice = 0

      // 率あり＆元値あり
      } else {
        rate = rate / 100
        // 手数料＝手数料込×率
        commissionPrice = BigNumber(price).times(rate)
        commissionPrice = Math.floor(commissionPrice)
        // アポロ金額
        ePrice = price - commissionPrice
      }

      if (commissionPrice == 0) {
        this.commission[0].commissionPrice = '0'
      } else {
        this.commission[0].commissionPrice = String(commissionPrice)
      }
      if (ePrice == 0) {
        this.commission[0].estimatePrice = '0'
      } else {
        this.commission[0].estimatePrice = String(ePrice)
      }
      this.commission[0].price = String(this.categoryAmount)
      this.commissionReload++
    },

    /**
     * 3桁区切り
     */
    numberToLocale(val) {
      return utils.numberToLocale(val)
    },

    /**
     * 見積りに単価を表示、非表示の特記事項
     */
    setIsUnitPriceHtml() {
      // console.log(this.selectedDetails.isUnitPrice)
      let note = this.selectedDetails.noteHTML
      // 特記事項がある場合に限る
      if (note) {  
        // 単価明細を見積にも表示する場合、特記事項に追加
        if (this.selectedDetails.isUnitPrice) {
          // 似た文言は削除
          note = note.replace(ClEAN_NOTE, '')
          // 文言がない場合は追加
          if (note.indexOf('※セット料金の内訳表記となります。') == -1) {
            note += '<br>※セット料金の内訳表記となります。単独でご発注頂く場合にはこちらの金額ではお請けできませんので予めご了承下さい。'
          }
        // 単価を表示しない場合
        } else {
          // 文言削除
          note = note.replace('<p><br></p><p>※セット料金の内訳表記となります。単独でご発注頂く場合にはこちらの金額ではお請けできませんので予めご了承下さい。</p>', '')
          // クリーンプラスの場合、文言なければ追加
          if (this.selectedDetails.tabId == 'clean' && note.indexOf(ClEAN_NOTE) == -1) {
            note += ClEAN_NOTE
          }
        }
        // 単独発注の場合
        if (this.normal[10] && this.normal[10].value == '0') {
          note = note.replace(ClEAN_NOTE, '')
        }
        this.selectedDetails.noteHTML = note
      }
    },

    /**
     * 定期清掃WAX用の特記事項
     */
    noteForWax() {
      if (!this.selectedDetails.noteHTML) {
        return
      }
      const d = this.selectedDetails.displayFixed
      let wax = {}
      wax = d.find((row) => {
        return (row.kindCoef && utils.hankaku(row.kindCoef).indexOf('WAX') > -1)
      })

      const text = '<p>剥離作業は含まれておりません。別途お見積りとさせて頂きます。</p>'

      if (wax && Object.keys(wax).length) {
        // 特記事項にWAXがなければ追記
        if (this.selectedDetails.noteHTML.indexOf('剥離作業は含まれておりません') == -1) {
          const num = this.selectedDetails.noteHTML.indexOf('<p>※セット料金の内訳表記となります')
          if (num > 0) {
            this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.slice(0, num) + text + this.selectedDetails.noteHTML.slice(num)
          } else {
            this.selectedDetails.noteHTML += text
          }
        }
      } else {
        // WAX係数がない場合は、特記事項から剥離作業を削除
        this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace(text, '')
      }

    }
  }
}
</script>
<style scoped>
.content-container {
  min-width: 1040px;
  max-width: calc(100vw - 175px);
  height: calc(100vh - 125px);
  overflow: auto;
}

.border-gray {
  border: solid #d3cfcf 1px;
}

.td-height {
  height: 40px;
}

.input {
  height: 40px;
}

.formula-container {
  margin-top: 30px;
  text-align:center;
  /* overflow-x: auto; */
  padding-bottom: 30px;
}

.formula {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
  margin-left: 56px;
  margin-right: 56px;
}

.tab {
  display: flex;
  justify-content: center;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
}

.formula-left {
  margin-top: 30px;
  display: flex;
  margin-left: 56px;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
  margin-right: 56px;
  /* overflow-x: auto; */
}

.formula-left2 {
  margin-top: 30px;
  display: flex;
  margin-left: 100px;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
  margin-right: 56px;
}

.formula-left3 {
  margin-top: 30px;
  display: flex;
  margin-left: 63px;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
}

.formula-left div {
  margin: 0 7px;
}

.formula-left2 div {
  margin: 0 7px;
}

.formula-left3 div {
  margin: 0 7px;
}

.formula span {
  font-size: 17px;
  margin: 8px 15px;
}

.price-width {
  width: 120px;
}

.cof-price-width {
  width: 100px;
}

.price-width-sm {
  width: 70px;
}

.radio-width {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.table {
  margin: auto;
}

.table2 {
  width: auto;
  margin: auto;
}

.table-width {
  width: 350px;
}

.table-width2 {
  width: 400px;
}

.number {
  width: 40px;
}

.btn {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.col-no {
  width: 35px;
}

.col-small {
  min-width: 60px;
  max-width: 80px;
}

.col-sm {
  width: 70px;
}

.col-smm {
  width: 100px
}

.col-midle {
  width: 120px;
}

.col-l {
  width: 150px;
}

.col-ll {
  width: 300px;
}

.col-xl {
  width: 400px;
}

.col-130 {
  width: 130px;
}

.drop {
  padding-left: 33px;
}

.symbol {
  margin-top: 15px;
  margin-bottom: auto;
  font-size: larger
}

.symbol2 {
  padding-top: 14px;
  font-size: larger
}

.text-xxs {
  font-size: 0.5rem;
}

.formula-open-normal {
  height: 654px;
  opacity: 1;
  transition: all .5s ease;
}

.formula-close {
  height: 0;
  width: 0;
  opacity: 0;
  transition: all .5s ease;
}

.border-purple {
  border: 1px solid rgb(185, 142, 241);
}

.text-purple {
  color: rgb(185, 142, 241);
}

.disabled-back {
  background: #f6f8fb;
}

.exceed {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}

.container {
  width: 700px;
}

.kind-name {
  font-weight: bold;
  /* color: #262661; */
}

.list {
  margin-bottom: 20px;
  min-width: 1010px;
}
/* 
.sort {
  margin-left: 9%;
}

.double {
  margin-left: 9%;
} */

.radio-range {
  padding: 10px 15px;
  cursor: pointer;
}

#rls-form {
  border: solid 1px rgb(92 168 187);
  margin: 0px 10px 5px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-width: 1010px;
}

.rls-title {
  color: rgb(68 126 141);
}

#normal-form {
  border: solid 1px rgb(151, 166, 231);
  margin: 10px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-width: 1010px;
}

.normal-title {
  color: rgb(69, 78, 121);
}

#fixed-form {
  border: solid 1px rgb(168, 223, 230);
  margin: 5px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-width: 1010px;
}

.fixed-title {
  color: rgb(85, 123, 128);
}

#page-r {
  position: fixed;
  width: 80px;
  padding: 6px;
  /* background: #e76868a1; */
  background: #5aa335a1;
  border-radius: 7%;
  color: #fff;
  text-align: center;
}

#page-n {
  position: fixed;
  width: 80px;
  padding: 6px;
  /* background: #c552a7a6; */
  background: #407e5ca6;
  border-radius: 7%;
  color: #fff;
  text-align: center;
}

#page-f {
  position: fixed;
  bottom: 33px;
  width: 80px;
  padding: 6px;
  /* background: #c5527da6; */
  background: #0a8b37a6;
  border-radius: 7%;
  color: #fff;
  text-align: center;
}

.b-1 {
  bottom: 117px;
}

.b-2 {
  bottom: 75px;
}

.b-3 {
  bottom: 33px;
}

.msg {
  font-size: small;
  margin-bottom: 15px;
  margin-top: 5px;
  color: dimgrey;
}

.memo { 
  margin-left: auto;
  margin-right: auto;
  width: 700px;
}

.sp-range {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
}

.max-h {
  height: -webkit-fill-available;
}

.msg-coef2  {
  text-align: center;
}

.note {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.remark-msg {
  width: max-content;
  margin: 30px auto 0px auto;
}

.remark-msg2 {
  width: max-content;
  margin: 0px auto;
}

.remark-text {
  font-size: x-large;
  width: max-content;
  margin-top: auto;
  margin-bottom: auto;
  margin: auto 10px;
}

.remark-table {
  width: 710px;
  margin: auto;
}

.b-right-gray {
  border-right: solid 1px #dfdfe1;
}

.b-top-gray {
  border-top: solid 1px #dfdfe1;
}

.text-commission {
  color: rgb(224 130 130);
}

@keyframes appear {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@media screen and (max-width: 1040px) {
  .content-container {
    min-width: 90vw;
  }

  #page-top {
    right: calc(100vw - 96vw);
  }
}

</style>