<template>
  <div>
    <PageHeader title="Request Post" :isUserMenu="!forCustomers" class="requestPostPageHeader-contents">
      <template #title-header-content>
        <div class="ml-auto flex items-center gap-2"  v-tooltip="!canEdit?'担当者と同じ部署・依頼主でないため、更新・完了はできません':''">
          <div v-if="isAlreadySent" class="text-white font-bold text-sm mr-10">
            発注メール送信済み
          </div>
          
          <PrimaryButton v-if="!referenceMode && (mainAuth || (requestPost.category2[0] && requestPost.category2[0] == 'お客様直'))" :disabled="isSaving || saved || !canEdit" class="w-44 bg-green-600" :text="editMode ? '更新' : '登録'" @click="save(false)">
            <LoadingIcon v-if="isSaving" slot="before" class="h-4 w-4" />
            <Icon v-if="!isSaving" slot="before" class="h-4 w-4" />
          </PrimaryButton>

          <PrimaryButton :disabled="(isSaving || saved) || !isShowCategoryItem('見積依頼') || !canEdit" class="w-44" :class="isShowCategoryItem('見積依頼')?'bg-blue-600':'bg-gray-400'" :text="'仮登録'" @click="save(true)" v-if="!forCustomers && mainAuth && !referenceMode">
            <LoadingIcon v-if="isSaving" slot="before" class="h-4 w-4" />
            <Icon v-if="!isSaving" slot="before" class="h-4 w-4" />
          </PrimaryButton>

          <div v-if="isOrder" v-tooltip="purchaseTooltip">
            <PrimaryButton :disabled="!activePurchaseBtn || !canEdit" text="発注" class="w-40" :class="activePurchaseBtn?'dark-yellow':'bg-gray-400'" @click="purchaseMail()" v-if="!forCustomers">
              <Icon slot="before" iconName="Mail" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            </PrimaryButton>
          </div>

          <PrimaryButton text="一覧" class="w-40" @click="toList" v-if="!forCustomers">
            <Icon slot="before" iconName="ClipboardList" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>

          <PrimaryButton :disabled="(isSaving || saved) || !isCompleteButtunActive || !canEdit" class="w-44" :class="isCompleteButtunActive ? 'bg-blue-600':'bg-gray-400'" :text="'完了' " @click="statusDone" v-if="!forCustomers && mainAuth && editMode && !isOrder && !referenceMode">
            <LoadingIcon v-if="isSaving" slot="before" class="h-4 w-4" />
            <Icon v-if="!isSaving" slot="before" class="h-4 w-4" />
          </PrimaryButton>
        </div>
      </template>
    </PageHeader>
    <main class="absolute w-screen bottom-4 -mt-2 page-contents requestPostMain-contents">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-gray-100 rounded-md border border-gray-300 overflow-auto h-full">

          <div v-if="initialized" class="px-0">

            <div class="flex">
              <div class="flex-1 p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 self-start">

                <div class="sm:col-span-full" v-if="!forCustomers">
                  <Category2Select
                    name="category2"
                    caption="社内/お客様"
                    v-model="requestPost.category2"
                    :required="isOutsideRequired"
                    :error="!validateCategory2.result"
                    :errorMessage="validateCategory2.message"
                    @change="category2Changed"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" id="inquiry-category">
                  <CategorySelect
                    name="category"
                    caption="お問い合わせカテゴリー(複数選択可)"
                    v-model="requestPost.category"
                    :required="true"
                    :error="!validateCategory.result"
                    :errorMessage="validateCategory.message"
                    @change="categoryChanged($event, true)"
                    :disabled="referenceMode || mode==6"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsTextInput 
                    name="requestNo" 
                    caption="お問い合わせ番号(登録時)"
                    :disabled="true"
                    v-model="requestPost.requestNo"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('追加依頼')">
                  <WmsTextInput 
                    name="lastRequestNo" 
                    caption="お問い合わせ番号(前回問い合わせ時の返信メールに記載がございます)"
                    v-model="requestPost.lastRequestNo"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!forCustomers && !isTransmission">
                  <WmsTextInput
                    name="inquiry_title" 
                    caption="タイトル"
                    :required="isOutsideRequired"
                    v-model="requestPost.inquiry_title"
                    :error="!validateInquiry_title.result"
                    :errorMessage="validateInquiry_title.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!isTransmission && !isOrder && !isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput 
                    name="note" 
                    caption="お問い合わせ内容"
                    :multiline="true"
                    :rows="5"
                    v-model="requestPost.note"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!isShowCategoryItem('雇い入れ')">
                  <WmsSuggestInput
                    name="clientName" 
                    :caption="isTransmission ? 'お客様会社名（物件名）' : 'お客様会社名'"
                    :required="((isClientRequired || requestPost.temporarySaveFlag) && isOutsideRequired) && (isShowCategoryItem('面接関連') && interviewsClientRequire)"
                    :selectionItems="clientLists"
                    :displayField="['clientName', 'clientDepartmentName']"
                    v-model="requestPost.clientName"
                    :disabled="forCustomers || referenceMode"
                    :error="!validateClientName.result"
                    :errorMessage="validateClientName.message"
                    @selected="clientSelected"
                    @change="clientNameChanged"
                  >
                    <template #addon-content v-if="!forCustomers">
                      <Icon
                        iconName="Search" 
                        :clickable="true" 
                        class="w-4 h-4 mr-0.5"
                        @click="openClientModal"
                      />
                    </template>
                  </WmsSuggestInput>
                </div>

                <div class="sm:col-span-full" v-if="!isTransmission && !isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput
                    name="clientAddress1" 
                    caption="お客様住所（都道府県・市区群・町村・番地）"
                    :disabled="referenceMode"
                    v-model="requestPost.clientAddress1"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!isTransmission && !isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput
                    name="clientAddress2" 
                    caption="お客様住所（建物名）"
                    :disabled="referenceMode"
                    v-model="requestPost.clientAddress2"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput
                    name="clientPersonName" 
                    :caption="isTransmission ? 'お客様名' : 'お客様担当者名'"
                    :required="isClientRequired && isOutsideRequired"
                    v-model="requestPost.clientPersonName"
                    :error="!validateClientPersonName.result"
                    :errorMessage="validateClientPersonName.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput 
                    name="employeeName" 
                    caption="従業員名"
                    v-model="requestPost.employeeName"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput 
                    name="telephoneNumber" 
                    caption="電話番号"
                    v-model="requestPost.telephoneNumber"
                    :required="true"
                    :error="!validateTelephoneNumber.result"
                    :errorMessage="validateTelephoneNumber.message"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput
                    name="inquiry_title" 
                    caption="お問い合わせ内容"
                    v-model="requestPost.inquiry_title"
                    :disabled="referenceMode"
                    :multiline="true"
                    :rows="5"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <WmsTextInput
                    name="clientMail" 
                    :caption="isTransmission ? 'メールアドレス' : 'メールアドレス(こちらのメールアドレスに回答が届きます)'"
                    :required="isClientRequired && isOutsideRequired"
                    v-model="requestPost.clientMail"
                    :error="!validateClientMail.result"
                    :errorMessage="validateClientMail.message"
                    :disabled="referenceMode"
                  />
                  <p v-if="emailInputCheck" class="emailInputCheck">メールアドレスの形式で入力してください。</p>
                </div>

                <div class="sm:col-span-full" v-if="!isOrder && !isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile" 
                        :value="requestPost.commonFile" 
                        style="max-width: none!important;"
                        @change="attachmentChange('commonFile', $event)" 
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <!-- 添付資料追加 -->
                <div class="sm:col-span-full" v-if="!referenceMode && !isOrder && !isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <AddFileBtnSelect
                    name="addFileBtn"
                    v-model="requestPost.addFileBtn"
                  />
                </div>

                <div class="sm:col-span-full" v-if="isShowAddFileBtnItem('添付資料追加')&&!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile2" 
                        :value="requestPost.commonFile2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('commonFile2', $event)" 
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-if="isShowAddFileBtnItem('添付資料追加')&&!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile3" 
                        :value="requestPost.commonFile3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('commonFile3', $event)" 
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-if="isShowAddFileBtnItem('添付資料追加')&&!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料4
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile4" 
                        :value="requestPost.commonFile4" 
                        style="max-width: none!important;"
                        @change="attachmentChange('commonFile4', $event)" 
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-if="isShowAddFileBtnItem('添付資料追加')&&!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料5
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile5" 
                        :value="requestPost.commonFile5" 
                        style="max-width: none!important;"
                        @change="attachmentChange('commonFile5', $event)" 
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-if="!forCustomers && !isOrder">
                  <WmsTextInput 
                    name="reason_note" 
                    caption="対応内容"
                    :multiline="true"
                    :rows="5"
                    v-model="requestPost.reason_note"
                    :disabled="referenceMode || isShowCategoryItem('雇い入れ')"
                  />
                </div>

                <div class="sm:col-span-3">
                  <WmsSuggestInput
                    name="toDepartment" 
                    caption="アポロ管財依頼先部署" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="toDepartmentList"
                    displayField="name"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    v-model="requestPost.toDepartment"
                    @selected="toDepartmentSelected"
                    @change="toDepartmentChanged"
                    :required="true"
                    :error="!validateToDepartment.result"
                    :errorMessage="validateToDepartment.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" style="margin-top: auto" v-if="!referenceMode">
                  <HelpBtnSelect
                    name="helpBtnSelect"
                    v-model="requestPost.helpBtnSelect"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!forCustomers">
                  <WmsSuggestInput
                    name="toStaffName" 
                    caption="担当者" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="toStaffNameList"
                    :displayField="isOrder ? 'userName' : 'dispName'"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    :required="true"
                    :error="!validateToStaffName.result"
                    :errorMessage="validateToStaffName.message"
                    v-model="requestPost.toStaffName"
                    @selected="selectedStaffName"
                    @change="changeStaffName"
                    :disabled="referenceMode"
                    v-if="isStaffSuggest"
                  />
                  <WmsSelect
                    name="toStaff" 
                    caption="担当者" 
                    v-model="selectedStaffValue"
                    :selections="toStaffSelections" 
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    :required="true"
                    :error="!validateToStaffName.result"
                    :errorMessage="validateToStaffName.message"
                    @change="changeToStaff"
                    v-if="!isStaffSuggest"
                  />
                </div>

                <div class="sm:col-span-full" v-show="!forCustomers">
                  <WmsSuggestInput
                    name="byUser" 
                    caption="依頼主" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="$store.getters.userList"
                    displayField="user_name"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    :required="true"
                    v-model="requestPost.byUser"
                    :error="!validateByUser.result"
                    :errorMessage="validateByUser.message"
                    @selected="byUserSelected"
                    @change="byUserNameChanged"
                    :disabled="referenceMode"
                    v-if="isStaffSuggest"
                  />
                  <WmsSelect
                    name="byUser" 
                    caption="依頼主" 
                    v-model="selectedByUserValue"
                    :selections="byUserSelections" 
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    :required="true"
                    :error="!validateByUser.result"
                    :errorMessage="validateByUser.message"
                    @change="changeByStaff"
                    v-if="!isStaffSuggest"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsDateInput
                    v-if="!referenceMode"
                    name="requestDate"
                    caption="依頼日" 
                    :required="true"
                    :error="!validateRequestDate.result"
                    :errorMessage="validateRequestDate.message"
                    v-model="requestPost.requestDate"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                  />

                  <WmsTextInput
                    v-else
                    name="requestDate"
                    caption="依頼日" 
                    :required="true"
                    v-model="dispRequestDate"
                    :disabled="true"
                  />
                </div>

                <div id="limitDate" class="sm:col-span-full">
                  <WmsDateInput
                    v-if="!referenceMode"
                    name="limitDate"
                    caption="期限" 
                    :required="true"
                    :error="!validateLimitDate.result"
                    :errorMessage="validateLimitDate.message"
                    v-model="requestPost.limitDate"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    :min="minLimitDate"
                  />

                  <WmsTextInput 
                    v-else
                    name="limitDate" 
                    caption="期限"
                    v-model="dispLimitDate"
                    :required="true"
                    :disabled="true"
                  />
                </div>

              </div>

              <div class="p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 self-start" :class="isCategory && !isShowCategoryItem('雇い入れ')  && !isShowCategoryItem('面接関連') ? 'rp-estimate' : 'rp-no-estimate'">
                <InputGroupSubLabel
                  name="dentatsuRequest"
                  caption="デンタツ依頼"
                  class="sm:col-span-full"
                  v-show="isShowCategoryItem('デンタツ')"
                />

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ')">
                  <DentatsuCategorySelect
                    name="dentatsuCategory"
                    caption="デンタツ詳細カテゴリー" 
                    v-model="requestPost.dentatsuCategory"
                  />
                </div>
                
                <div id="estimation-clean-category" class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <EstimationCleanCategorySelect
                    name="estimationCleanCategory"
                    caption="御見積_清掃カテゴリー(複数選択可)" 
                    v-model="requestPost.estimationCleanCategory"
                    :disabled="referenceMode"
                  />
                </div>

                <div id="estimation-clean-category" class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <EstimationInspectCategorySelect
                    name="estimationInspectCategory"
                    caption="御見積_点検カテゴリー(複数選択可)" 
                    v-model="requestPost.estimationInspectCategory"
                    :disabled="referenceMode"
                  />
                </div>

                <div id="estimation-clean-category" class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <EstimationOtherCategorySelect
                    name="estimationOtherCategory"
                    caption="御見積_その他カテゴリー" 
                    v-model="requestPost.estimationOtherCategory"
                    @change="estimationOtherCategoryChanged"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <NewNowFlagSelect
                    name="newNowFlag"
                    caption="契約無/契約有" 
                    v-model="requestPost.newNowFlag"
                    :required="true"
                    :error="!validateNewNowFlag.result"
                    :errorMessage="validateNewNowFlag.message"
                    @change="newNowFlagChanged"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <NewClassSelect
                    name="newClass"
                    caption="新規区分"
                    v-model="requestPost.newClass"
                    :required="true"
                    :error="!validateNewClass.result"
                    :errorMessage="validateNewClass.message"
                    :disabled="isDisabledNewClass || referenceMode"
                    @change="newClassChanged"
                  />
                </div>

                <InputGroupLabel
                  name="estimationBasicLabel"
                  caption="見積基本情報"
                  class="mt-4"
                  v-show="isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <!-- <WmsTextInput 
                    name="buildingName" 
                    caption="建物名"
                    :required="true"
                    v-model="requestPost.buildingName"
                    :error="!validateBuildingName.result"
                    :errorMessage="validateBuildingName.message"
                    @change="siteNameChanged"
                    :disabled="referenceMode"
                  /> -->
                  <WmsSuggestInput
                    name="buildingName" 
                    caption="建物名"
                    :required="true"
                    :displayField="['site1', 'site2', 'address1', 'address2', 'agreementFlg']"
                    :selectionItems="buildingListByClient"
                    v-model="requestPost.buildingName"
                    :error="!validateBuildingName.result"
                    :errorMessage="validateBuildingName.message"
                    @selected="siteSelected($event)"
                    @blur="siteNameChanged()"
                    :disabled="referenceMode"
                  >
                    <!-- 検索アイコン -->
                    <template #addon-content>
                      <Icon
                        iconName="Search" 
                        :clickable="true" 
                        class="w-4 h-4 mr-0.5"
                        @click="openSiteModal()"
                      />
                    </template>
                  </WmsSuggestInput>
                </div>

                <InputGroupLabel
                  name="buildingAddressLabel"
                  caption="所在地"
                  class="mt-4"
                  v-show="isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="postalCode" 
                    caption="郵便番号"
                    placeholder="000-0000"
                    v-model="requestPost.postalCode"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3 adressbutton" v-show="isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                  <PrimaryButton text="住所検索" class="w-40 adressbutton bg-green-500" @click="addressSearch()">
                  </PrimaryButton>

                  <PrimaryButton text="住所から取得" class="w-40 bg-green-500" @click="postalCodeSearch()">
                  </PrimaryButton>
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="state" 
                    caption="都道府県"
                    placeholder="都道府県"
                    :required="isBuildingDetailsRequired"
                    v-model="requestPost.state"
                    :error="!validateState.result"
                    :errorMessage="validateState.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="city" 
                    caption="市区郡"
                    placeholder="市区"
                    :required="isBuildingDetailsRequired"
                    v-model="requestPost.city"
                    :error="!validateCity.result"
                    :errorMessage="validateCity.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="street" 
                    caption="町村・番地"
                    placeholder="町村・番地"
                    v-model="requestPost.street"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="building" 
                    caption="建物名"
                    placeholder="ビル名"
                    v-model="requestPost.building"
                    @change="buildingChanged"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-2" v-show="isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="stairs" 
                    caption="階数"
                    v-model="requestPost.stairs"
                    :required="isBuildingDetailsRequired"
                    :error="!validateStairs.result"
                    :errorMessage="validateStairs.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-2" v-show="isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="basement" 
                    caption="地下"
                    v-model="requestPost.basement"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-2" v-show="isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="households" 
                    caption="戸数"
                    v-model="requestPost.households"
                    :required="isBuildingDetailsRequired"
                    :error="!validateHouseholds.result"
                    :errorMessage="validateHouseholds.message"
                    @change="houseHoldsChange"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="estimationReason" 
                    caption="見積理由(新規・業者の切り替え・リプレイスなど)"
                    :multiline="true"
                    :rows="5"
                    v-model="requestPost.estimationReason"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <AvailablePowerFlagSelect
                    name="availablePowerFlag"
                    caption="使用できる電源の有無" 
                    v-model="requestPost.availablePowerFlag"
                    :required="isBuildingEquipmentRequired"
                    :error="!validateAvailablePowerFlag.result"
                    :errorMessage="validateAvailablePowerFlag.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <ParkingFlagSelect
                    name="parkingFlag"
                    caption="車の駐車スペースの有無" 
                    v-model="requestPost.parkingFlag"
                    :required="isBuildingEquipmentRequired"
                    :error="!validateParkingFlag.result"
                    :errorMessage="validateParkingFlag.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <AvailableToiletFlagSelect
                    name="availableToiletFlag"
                    caption="使用可能なトイレの有無" 
                    v-model="requestPost.availableToiletFlag"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <ApartmentTypeSelect
                    name="apartmentType"
                    caption="マンションタイプ"
                    v-model="requestPost.apartmentType"
                    :disabled="referenceMode"
                  />
                </div>

                <InputGroupSubLabel
                  name="estimationRoundLabel"
                  caption="ラウンドサービス"
                  description="※バイク駐車スペースは見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="roundServiceMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.roundServiceMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼') && ((requestPost.households !='' && Number(requestPost.households) > 0) || !this.isBuildingDetailsRequired)">
                  <CleanCountSelect
                    name="cleanCount"
                    caption="清掃希望回数(月〇回、週〇回など)"
                    :required="true"
                    v-model="requestPost.cleanCount"
                    :house="requestPost.households"
                    :error="!validateCleanCount.result"
                    :errorMessage="validateCleanCount.message"
                    :category2="requestPost.category2"
                    @change="changeCleanCountRls(requestPost.cleanCount)"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')">

                  <DustRemovalSelect
                    name="dustRemoval"
                    :caption="dustCaptionRls"
                    :required="true"
                    v-model="requestPost.dustRemoval"
                    :error="!validateDustRemoval.result"
                    :errorMessage="validateDustRemoval.message"
                    :disabled="dustDisabledRls || referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼') && requestPost.dustRemoval.length && requestPost.dustRemoval[0]=='有'">
                  <DustCountSelect
                    name="dustCount"
                    caption="ゴミ搬出希望回数"
                    :required="true"
                    v-model="requestPost.dustCount"
                    :error="!validateDustCount.result"
                    :errorMessage="validateDustCount.message"
                    :category2="requestPost.category2"
                    :cleanCount="requestPost.cleanCount"
                    @change="changeCleanCountRls(requestPost.cleanCount)"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')">
                  <ToiletCleaningSelect
                    name="toiletCleaning"
                    caption="有料トイレ清掃あり/有料トイレ清掃無し"
                    v-model="requestPost.rlsToiletCleaning"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.rlsToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドサービス')">
                  <NumericTextInput2 
                    name="toiletTimes"
                    caption="有料トイレ清掃回数（回/週）" 
                    placeholder="4"
                    v-model="requestPost.rlsToiletTimes"
                    :required="true"
                    :error="!validateRlsToiletTimes.result"
                    :errorMessage="validateRlsToiletTimes.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.rlsToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドサービス')">
                  <NumericTextInput2 
                    name="toiletPlace" 
                    caption="有料トイレ清掃箇所数"
                    v-model="requestPost.rlsToiletPlace"
                    :required="true"
                    :error="!validateRlsToiletPlace.result"
                    :errorMessage="validateRlsToiletPlace.message"
                    :disabled="referenceMode"
                  />
                </div>

                <InputGroupSubLabel
                  name="estimationRoundPlusLabel"
                  caption="ラウンドプラス"
                  description="※バイク駐車スペースは見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="roundPlusMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.roundPlusMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼') && ((requestPost.households !='' && Number(requestPost.households) > 0) || !this.isBuildingDetailsRequired)">
                  <CleanCountSelect
                    name="plusCleanCount"
                    caption="清掃希望回数(月〇回、週〇回など)"
                    :required="true"
                    v-model="requestPost.plusCleanCount"
                    :house="requestPost.households"
                    :error="!validatePlusCleanCount.result"
                    :errorMessage="validatePlusCleanCount.message"
                    :category2="requestPost.category2"
                    @change="changeCleanCountPlus(requestPost.plusCleanCount)"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <PlusDustRemovalSelect
                    name="plusDustRemoval"
                    :caption="dustCaptionPlus"
                    :required="true"
                    v-model="requestPost.plusDustRemoval"
                    :error="!validatePlusDustRemoval.result"
                    :errorMessage="validatePlusDustRemoval.message"
                    :disabled="dustDisabledPlus || referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼') && requestPost.plusDustRemoval.length && requestPost.plusDustRemoval[0]=='有'">
                  <DustCountSelect
                    name="plusDustCount"
                    caption="ゴミ搬出希望回数"
                    :required="true"
                    v-model="requestPost.plusDustCount"
                    :error="!validatePlusDustCount.result"
                    :errorMessage="validatePlusDustCount.message"
                    :category2="requestPost.category2"
                    :cleanCount="requestPost.plusCleanCount"
                    @change="changeCleanCountPlus(requestPost.plusCleanCount)"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <ToiletCleaningSelect
                    name="toiletCleaning"
                    caption="巡回清掃"
                    v-model="requestPost.plusToiletCleaning"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="toiletTimes"
                    caption="有料トイレ清掃回数（回/週）（巡回清掃）" 
                    placeholder="4"
                    v-model="requestPost.plusToiletTimes"
                    :required="true"
                    :error="!validatePlusToiletTimes.result"
                    :errorMessage="validatePlusToiletTimes.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="toiletPlace" 
                    caption="有料トイレ清掃箇所数（巡回清掃）"
                    v-model="requestPost.plusToiletPlace"
                    :required="true"
                    :error="!validatePlusToiletPlace.result"
                    :errorMessage="validatePlusToiletPlace.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full mt-3" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <ToiletCleaningSelect
                    name="plusToiletCleaningNormal"
                    caption="固定清掃"
                    v-model="requestPost.plusToiletCleaningNormal"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="plusToiletTimesNormal"
                    caption="有料トイレ清掃回数（回/週）（固定清掃）" 
                    placeholder="4"
                    v-model="requestPost.plusToiletTimesNormal"
                    :required="true"
                    :error="!validatePlusToiletTimesNormal.result"
                    :errorMessage="validatePlusToiletTimesNormal.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="plusToiletPlaceNormal" 
                    caption="有料トイレ清掃箇所数（固定清掃）"
                    v-model="requestPost.plusToiletPlaceNormal"
                    :required="true"
                    :error="!validatePlusToiletPlaceNormal.result"
                    :errorMessage="validatePlusToiletPlaceNormal.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼') && (isShowEstimationCleanCategoryItem('ラウンドサービス') || isShowEstimationCleanCategoryItem('ラウンドプラス') || isShowEstimationCleanCategoryItem('ラウンドサブメニュー'))">
                  <ResidenceTypeSelect
                    name="residenceType"
                    caption="ファミリー/シングル"
                    v-model="requestPost.residenceType"
                    :disabled="referenceMode"
                  />
                </div>

                <InputGroupSubLabel
                  name="estimationEverydayLabel"
                  caption="日常清掃"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="everydayCleanMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.everydayCleanMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full">
                  <div v-for="(everydayClean, index) in requestPost.everydayCleans" v-bind:key="everydayClean.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <label
                        name="everydayCleanLabel"
                        class="text-sm font-bold"
                      >
                        日常清掃{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex mt-ec">
                      <div class="everydayCleanCount" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="everydayCleanCount" 
                          caption="清掃希望回数（週〇回）"
                          placeholder="3"
                          :decimalPlace="deci"
                          :required="true"
                          v-model="everydayClean.everydayCleanCount"
                          :error="!validateEverydayCleanCount(index).result"
                          :errorMessage="validateEverydayCleanCount(index).message"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="everydayCleanHours" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="everydayCleanHours" 
                          caption="清掃希望時間（〇時間）"
                          placeholder="2"
                          :decimalPlace="deci"
                          :required="true"
                          v-model="everydayClean.everydayCleanHours"
                          :error="!validateEverydayCleanHours(index).result"
                          :errorMessage="validateEverydayCleanHours(index).message"
                          :disabled="referenceMode"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <EverydayStartTimeFlagSelect
                        name="everydayStartTimeFlag"
                        caption="作業時間の指定の有無(開始時間は7:30から)"
                        :required="true"
                        v-model="everydayClean.everydayStartTimeFlag"
                        :error="!validateEverydayStartTimeFlag(index).result"
                        :errorMessage="validateEverydayStartTimeFlag(index).message"
                        :disabled="referenceMode"
                      />
                    </div>

                    <div class="flex mt-ec">
                      <div class="everydayStartTime" v-show="isShowEverydayStartTimeFlagItem('有', index) && isShowCategoryItem('見積依頼') && isShowEstimationCleanCategoryItem('日常清掃')">
                        <WmsTimeInput2
                          name="everydayStartTime" 
                          caption="指定作業開始時間"
                          :clearable="true"
                          :required="true"
                          v-model="everydayClean.everydayStartTime"
                          :error="!validateEverydayStartTime(index).result"
                          :errorMessage="validateEverydayStartTime(index).message"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="everydayEndTime" v-show="isShowEverydayStartTimeFlagItem('有', index) && isShowCategoryItem('見積依頼') && isShowEstimationCleanCategoryItem('日常清掃')">
                        <WmsTimeInput2
                          name="everydayEndTime" 
                          caption="指定作業終了時間"
                          :clearable="true"
                          v-model="everydayClean.everydayEndTime"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                          :disabled="referenceMode"
                        />
                      </div>
                    </div>
                    
                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <EverydayHolidayFlagSelect
                        name="everydayHolidayFlag"
                        caption="祝日対応の有無"
                        v-model="everydayClean.everydayHolidayFlag"
                        :disabled="referenceMode"
                      />
                    </div>

                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼') && isEverydayHolidayFlag(index)">
                      <EverydayHolidaySelect
                        name="everydayHoliday"
                        caption="祝日対応"
                        v-model="everydayClean.everydayHoliday"
                        :disabled="referenceMode"
                      />
                    </div>

                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <ToiletCleaningSelect
                        name="toiletCleaning"
                        caption="有料トイレ清掃あり/有料トイレ清掃無し"
                        v-model="everydayClean.toiletCleaning"
                        :disabled="referenceMode"
                      />
                    </div>

                    <div class="flex mt-ec">
                      <div class="toiletTimes" v-show="isShowCategoryItem('見積依頼') && isShowtToiletCleaningItem('有料トイレ清掃あり', index) && isShowEstimationCleanCategoryItem('日常清掃')">
                        <NumericTextInput2 
                          name="toiletTimes"
                          caption="有料トイレ清掃回数（回/週）" 
                          placeholder="4"
                          v-model="everydayClean.toiletTimes"
                          :required="true"
                          :error="!validateToiletTimes(index).result"
                          :errorMessage="validateToiletTimes(index).message"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="toiletPlace" v-show="isShowCategoryItem('見積依頼') && isShowtToiletCleaningItem('有料トイレ清掃あり', index) && isShowEstimationCleanCategoryItem('日常清掃')">
                        <NumericTextInput2 
                          name="toiletPlace" 
                          caption="有料トイレ清掃箇所数"
                          v-model="everydayClean.toiletPlace"
                          :required="true"
                          :error="!validateToiletPlace(index).result"
                          :errorMessage="validateToiletPlace(index).message"
                          :disabled="referenceMode"
                        />
                      </div>
                    </div>

                    <div class="everydayCleanDeleteButton" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                      <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeEverydayClean(index)">
                        <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                      </PrimaryButton>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'日常清掃追加'" @click="addEverydayClean">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>

                <InputGroupSubLabel
                  name="managerWorkLabel"
                  caption="管理員業務"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="managerWorkMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.managerWorkMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full">
                  <div v-for="(managerWork, index) in requestPost.managerWorks" v-bind:key="managerWork.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                      <label
                        name="managerWorkLabel"
                        class="text-sm font-bold"
                      >
                        管理員業務{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex mt-mw">
                      <div class="managerWorkCount" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="managerWorkCount" 
                          caption="清掃希望回数（週〇回）"
                          placeholder="3"
                          :required="true"
                          :decimalPlace="deci"
                          v-model="managerWork.managerWorkCount"
                          :error="!validateManagerWorkCount(index).result"
                          :errorMessage="validateManagerWorkCount(index).message"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="managerWorkHours" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="managerWorkHours" 
                          caption="清掃希望時間（〇時間）"
                          placeholder="2"
                          :decimalPlace="deci"
                          :required="true"
                          v-model="managerWork.managerWorkHours"
                          :error="!validateManagerWorkHours(index).result"
                          :errorMessage="validateManagerWorkHours(index).message"
                          :disabled="referenceMode"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-full mt-mw" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                      <ManagerWorkTimeFlagSelect
                        name="managerWorkTimeFlag"
                        caption="作業時間の指定の有無(開始時間は7:30から)"
                        :required="true"
                        v-model="managerWork.managerWorkTimeFlag"
                        :error="!validateManagerWorkTimeFlag(index).result"
                        :errorMessage="validateManagerWorkTimeFlag(index).message"
                        :disabled="referenceMode"
                      />
                    </div>

                    <div class="flex mt-mw">
                      <div class="managerWorkTime" v-show="isShowManagerWorkTimeFlagItem('有', index) && isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                        <WmsTimeInput2
                          name="managerWorkTime" 
                          caption="指定作業開始時間"
                          :clearable="true"
                          :required="true"
                          v-model="managerWork.managerWorkTime"
                          :error="!validateManagerWorkTime(index).result"
                          :errorMessage="validateManagerWorkTime(index).message"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="managerWorkEndTime" v-show="isShowManagerWorkTimeFlagItem('有', index) && isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                        <WmsTimeInput2
                          name="managerWorkEndTime" 
                          caption="指定作業終了時間"
                          :clearable="true"
                          v-model="managerWork.managerWorkEndTime"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                          :disabled="referenceMode"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-full mt-mw" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                      <ManagerWorkHolidayFlagSelect
                        name="managerWorkHolidayFlag"
                        caption="祝日対応の有無"
                        v-model="managerWork.managerWorkHolidayFlag"
                        :disabled="referenceMode"
                      />
                    </div>

                    <div class="sm:col-span-full mt-mw" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼') && isManagerWorkHolidayFlag(index)">
                      <ManagerWorkHolidaySelect
                        name="managerWorkHoliday"
                        caption="祝日対応"
                        v-model="managerWork.managerWorkHoliday"
                        :disabled="referenceMode"
                      />
                    </div>

                    <div class="managerWorkDeleteButton" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                      <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeManagerWork(index)">
                        <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                      </PrimaryButton>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'管理員業務追加'" @click="addManagerWork">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>

                <InputGroupSubLabel
                  name="regularCleanLabel"
                  caption="定期清掃"
                  description="※水栓の使用は見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="regularCleanMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.regularCleanMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full">
                  <div v-for="(regularClean, index) in requestPost.regularCleans" v-bind:key="regularClean.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                      <label
                        name="regularCleanLabel"
                        class="text-sm font-bold"
                      >
                        定期清掃{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="regularCleanContent" 
                          caption="依頼内容" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="regItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateRegularClean(index, 'regularCleanContent').result"
                          :errorMessage="validateRegularClean(index, 'regularCleanContent').message"
                          v-model="regularClean.regularCleanContent"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="regularCleanCount" 
                          caption="希望回数"
                          :required="true"
                          v-model="regularClean.regularCleanCount"
                          :error="!validateRegularClean(index, 'regularCleanCount').result"
                          :errorMessage="validateRegularClean(index, 'regularCleanCount').message"
                          :disabled="referenceMode"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="regularCleanUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="unitNameItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateRegularClean(index, 'regularCleanUnitName').result"
                          :errorMessage="validateRegularClean(index, 'regularCleanUnitName').message"
                          v-model="regularClean.regularCleanUnitName"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="regularCleanDeleteButton" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                        <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeRegularClean(index)">
                          <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'定期清掃追加'" @click="addRegularClean">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(定期平面)
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="regularCleanImage" 
                        caption="図面(定期平面)"
                        :value="requestPost.regularCleanImage" 
                        style="max-width: none!important;"
                        @change="attachmentChange('regularCleanImage', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(定期平面)2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="regularCleanImage2" 
                        caption="図面(定期平面)2"
                        :value="requestPost.regularCleanImage2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('regularCleanImage2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(定期平面)3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="regularCleanImage3" 
                        caption="図面(定期平面)3"
                        :value="requestPost.regularCleanImage3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('regularCleanImage3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>
                
                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="regularCleanWorkWay" 
                    caption="作業仕様"
                    v-model="requestPost.regularCleanWorkWay"
                    :disabled="referenceMode"
                  />
                </div>

                <InputGroupSubLabel
                  name="glassCleanLabel"
                  caption="ガラス清掃"
                  description="※水栓の使用は見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="glassCleanMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.glassCleanMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="glassCleanCount" 
                    caption="清掃希望回数"
                    :required="true"
                    v-model="requestPost.glassCleanCount"
                    :error="!validateGlassCleanCount.result"
                    :errorMessage="validateGlassCleanCount.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(ガラス立面)
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="glassCleanImage" 
                        caption="図面(ガラス立面)"
                        :value="requestPost.glassCleanImage" 
                        style="max-width: none!important;"
                        @change="attachmentChange('glassCleanImage', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(ガラス立面)2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="glassCleanImage2" 
                        caption="図面(ガラス立面)2"
                        :value="requestPost.glassCleanImage2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('glassCleanImage2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(ガラス立面)3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="glassCleanImage3" 
                        caption="図面(ガラス立面)3"
                        :value="requestPost.glassCleanImage3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('glassCleanImage3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <GlassCleanWorkWaySelect
                    name="glassCleanWorkWay"
                    caption="作業仕様"
                    v-model="requestPost.glassCleanWorkWay"
                    :disabled="referenceMode"
                  />
                </div>

                <InputGroupSubLabel
                  name="estimationDorainPipeLabel"
                  caption="排水管清掃"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('排水管清掃') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('排水管清掃') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="estimationDorainPipeMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.estimationDorainPipeMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <InputGroupSubLabel
                  name="estimationWaterTankLabel"
                  caption="貯水槽清掃"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('貯水槽清掃') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('貯水槽清掃') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="estimationWaterTankMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.estimationWaterTankMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <InputGroupSubLabel
                  name="plantingLabel"
                  caption="植栽剪定"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="plantingMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.plantingMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="plantingCount" 
                    caption="希望回数"
                    :required="true"
                    v-model="requestPost.plantingCount"
                    :error="!validatePlantingCount.result"
                    :errorMessage="validatePlantingCount.message"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    対象箇所
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingPlaceFile" 
                        caption="対象箇所"
                        :value="requestPost.plantingPlaceFile" 
                        style="max-width: none!important;"
                        @change="attachmentChange('plantingPlaceFile', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    対象箇所2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingPlaceFile2" 
                        caption="対象箇所2"
                        :value="requestPost.plantingPlaceFile2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('plantingPlaceFile2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    対象箇所3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingPlaceFile3" 
                        caption="対象箇所3"
                        :value="requestPost.plantingPlaceFile3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('plantingPlaceFile3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingReportFile" 
                        caption="過去の報告書"
                        :value="requestPost.plantingReportFile" 
                        style="max-width: none!important;"
                        @change="attachmentChange('plantingReportFile', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingReportFile2" 
                        caption="過去の報告書2"
                        :value="requestPost.plantingReportFile2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('plantingReportFile2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingReportFile3" 
                        caption="過去の報告書3"
                        :value="requestPost.plantingReportFile3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('plantingReportFile3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="specialCleanLabel"
                  caption="特別清掃"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="specialCleanMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.specialCleanMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>
                
                <div class="sm:col-span-full">
                  <div v-for="(specialClean, index) in requestPost.specialCleans" v-bind:key="specialClean.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                      <label
                        name="specialCleanLabel"
                        class="text-sm font-bold"
                      >
                        特別清掃{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="specialCleanContent" 
                          caption="依頼内容 ※駐輪場清掃、照明器具清掃など" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="spItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateSpecialCleanContent(index).result"
                          :errorMessage="validateSpecialCleanContent(index).message"
                          v-model="specialClean.specialCleanContent"
                          @selected="selectedSp(index, $event)"
                          @change="changeSp(index)"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="specialCleanCount" 
                          caption="希望回数"
                          :required="true"
                          v-model="specialClean.specialCleanCount"
                          :error="!validateSpecialCleanCount(index).result"
                          :errorMessage="validateSpecialCleanCount(index).message"
                          :disabled="referenceMode"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="specialCleanUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="unitNameItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateSpecialCleanUnitName(index).result"
                          :errorMessage="validateSpecialCleanUnitName(index).message"
                          v-model="specialClean.specialCleanUnitName"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="specialCleanDeleteButton" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                        <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeSpecialClean(index)">
                          <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'特別清掃追加'" @click="addSpecialClean">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料 図面(平面図)・写真
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specialCleanImage" 
                        caption="添付資料 図面(平面図)・写真"
                        :value="requestPost.specialCleanImage" 
                        style="max-width: none!important;"
                        @change="attachmentChange('specialCleanImage', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料 図面(平面図)・写真2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specialCleanImage2" 
                        caption="添付資料 図面(平面図)・写真2"
                        :value="requestPost.specialCleanImage2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('specialCleanImage2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料 図面(平面図)・写真3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specialCleanImage3" 
                        caption="添付資料 図面(平面図)・写真3"
                        :value="requestPost.specialCleanImage3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('specialCleanImage3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>
                
                <InputGroupSubLabel
                  name="roundSubLabel"
                  caption="ラウンドサブメニュー"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="roundSubMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.roundSubMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <div class="sm:col-span-full">
                  <div v-for="(rs, index) in requestPost.roundSubs" v-bind:key="rs.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                      <label
                        name="roundSubs"
                        class="text-sm font-bold"
                      >
                        ラウンドサブメニュー{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="roundSubContent" 
                          caption="依頼内容" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="rsItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateRoundSub(index, 'roundSubContent').result"
                          :errorMessage="validateRoundSub(index, 'roundSubContent').message"
                          v-model="rs.roundSubContent"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="roundSubCount" 
                          caption="希望回数"
                          :decimalPlace="deci"
                          :required="true"
                          v-model="rs.roundSubCount"
                          :error="!validateRoundSub(index, 'roundSubCount').result"
                          :errorMessage="validateRoundSub(index, 'roundSubCount').message"
                          :disabled="referenceMode"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="roundSubUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="unitNameItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateRoundSub(index, 'roundSubUnitName').result"
                          :errorMessage="validateRoundSub(index, 'roundSubUnitName').message"
                          v-model="rs.roundSubUnitName"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="regularCleanDeleteButton" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                        <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeRoundSub(index)">
                          <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'ラウンドサブメニュー追加'" @click="addRoundSub()">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>

                <InputGroupSubLabel
                  name="estimationMRSabel"
                  caption="MRS"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('マンションローテーションサービス（MRS）') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('マンションローテーションサービス（MRS）') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="estimationMRSMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.estimationMRSMemo"
                          :disabled="referenceMode"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <InputGroupSubLabel
                  name="fireInspectLabel"
                  caption="消防設備点検"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="fireInspectReport" 
                        caption="過去の報告書"
                        :value="requestPost.fireInspectReport" 
                        style="max-width: none!important;"
                        @change="attachmentChange('fireInspectReport', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="fireInspectReport2" 
                        caption="過去の報告書2"
                        :value="requestPost.fireInspectReport2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('fireInspectReport2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="fireInspectReport3" 
                        caption="過去の報告書3"
                        :value="requestPost.fireInspectReport3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('fireInspectReport3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="constructionEquipmentInspectLabel"
                  caption="建築設備定期検査"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="constructionEquipmentInspectReport" 
                        caption="各階の平面図及び面積表及び確認済証"
                        :value="requestPost.constructionEquipmentInspectReport" 
                        style="max-width: none!important;"
                        @change="attachmentChange('constructionEquipmentInspectReport', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="constructionEquipmentInspectReport2" 
                        caption="各階の平面図及び面積表及び確認済証2"
                        :value="requestPost.constructionEquipmentInspectReport2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('constructionEquipmentInspectReport2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="constructionEquipmentInspectReport3" 
                        caption="各階の平面図及び面積表及び確認済証3"
                        :value="requestPost.constructionEquipmentInspectReport3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('constructionEquipmentInspectReport3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="specificInspectLabel"
                  caption="特定建築物定期調査"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specificInspectReport" 
                        caption="各階の平面図及び面積表及び確認済証"
                        :value="requestPost.specificInspectReport" 
                        style="max-width: none!important;"
                        @change="attachmentChange('specificInspectReport', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specificInspectReport2" 
                        caption="各階の平面図及び面積表及び確認済証2"
                        :value="requestPost.specificInspectReport2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('specificInspectReport2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specificInspectReport3" 
                        caption="各階の平面図及び面積表及び確認済証3"
                        :value="requestPost.specificInspectReport3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('specificInspectReport3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="evInspectLabel"
                  caption="エレベーター保守点検"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <EvInspectContentSelect
                    name="evInspectContent"
                    caption="点検内容"
                    v-model="requestPost.evInspectContent"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsSuggestInput
                    name="evQty" 
                    caption="回数" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="evQtyItems"
                    displayField="text"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    v-model="requestPost.evQty"
                    :disabled="referenceMode"
                    />
                </div>

                <div class="sm:col-span-3" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsSuggestInput
                    name="evUnitName" 
                    caption="単位" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="evUnitNameItems"
                    displayField="text"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    v-model="requestPost.evUnitName"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="evInspectFile" 
                        caption="過去の報告書"
                        :value="requestPost.evInspectFile" 
                        style="max-width: none!important;"
                        @change="attachmentChange('evInspectFile', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="evInspectFile2" 
                        caption="過去の報告書2"
                        :value="requestPost.evInspectFile2" 
                        style="max-width: none!important;"
                        @change="attachmentChange('evInspectFile2', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="evInspectFile3" 
                        caption="過去の報告書3"
                        :value="requestPost.evInspectFile3" 
                        style="max-width: none!important;"
                        @change="attachmentChange('evInspectFile3', $event)"
                        :disabled="referenceMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="evInspectMaker" 
                    caption="メーカー"
                    v-model="requestPost.evInspectMaker"
                    :disabled="referenceMode"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="evInspectMakeYear" 
                    caption="製造年"
                    v-model="requestPost.evInspectMakeYear"
                    :disabled="referenceMode"
                  />
                </div>

                <InputGroupSubLabel
                  name="otherWorkLabel"
                  caption="その他作業"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full">
                  <div v-for="(otherWork, index) in requestPost.otherWorks" v-bind:key="otherWork.id">
                    <div class="mt-3" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                      <label
                        name="otherWorkLabel"
                        class="text-sm font-bold"
                      >
                        その他作業{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="otherWorkContent" 
                          caption="依頼内容" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="otherWorkItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateOtherWorkContent(index).result"
                          :errorMessage="validateOtherWorkContent(index).message"
                          v-model="otherWork.otherWorkContent"
                          :disabled="referenceMode"
                          @selected="selectedOtherWork(index, $event)"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="otherWorkCount" 
                          caption="希望回数"
                          :required="true"
                          v-model="otherWork.otherWorkCount"
                          :error="!validateOtherWorkCount(index).result"
                          :errorMessage="validateOtherWorkCount(index).message"
                          :disabled="referenceMode"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="otherWorkUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="unitNameItems"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :error="!validateOtherWorkUnitName(index).result"
                          :errorMessage="validateOtherWorkUnitName(index).message"
                          v-model="otherWork.otherWorkUnitName"
                          :disabled="referenceMode"
                        />
                      </div>

                      <div class="specialCleanDeleteButton" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                        <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeOtherWork(index)">
                          <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')" v-if="!referenceMode">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'その他作業追加'" @click="addOtherWork">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>

              </div>
              
              <!-- 雇い入れ -->
              <EmploymentComponents
                ref="EmploymentComponents" 
                v-if="isShowCategoryItem('雇い入れ')"
                :requestPost="requestPost"
                :referenceMode="referenceMode"
                :clientLists="clientLists"
                @get-title2="getTitle2"
              />

              <!-- 面接関連 -->
              <InterviewsComponents
                ref="InterviewsComponents" 
                v-if="isShowCategoryItem('面接関連')"
                :requestPost="requestPost"
                :referenceMode="referenceMode"
                @get-title="getTitle"
                @change-client-require="changeClientRequire"
              />

              <!-- 過去の受注データ -->
              <OrderComponents
                ref="OrderComponents" 
                v-if="isOrder && editMode && !requestPost.orderNew"
                :estimateData="estimateData"
                :orderData="orderData"
                @toOrderData="additionOrderData"
                @changeValidateContractEndDate="changeValidateContractEndDate"
                @changeValidateSpecialCleanWorkItem="changeValidateSpecialCleanWorkItem"
                @changeValidateClassification="changeValidateClassification"
                @changeOrderSummary="changeOrderSummary"
                :referenceMode="referenceMode"
              />
              <!-- 新しい受注データ（全てのカテゴリーが配列）2023.11末 -->
              <OrderNewComponents
                ref="OrderNewComponents" 
                v-else-if="isOrder"
                :estimateData="estimateData"
                :orderData="orderData"
                :mainData="requestPost"
                :position="position"
                @toOrderData="additionOrderData"
                @changeValidateContractEndDate="changeValidateContractEndDate"
                @changeValidateSpecialCleanWorkItem="changeValidateSpecialCleanWorkItem"
                @changeValidateClassification="changeValidateClassification"
                :referenceMode="referenceMode"
              />
            </div>
          </div>

          <div v-if="!initialized" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Loading...</p>
          </div>

        </div>
      </div>
    </main>

    <ClientSelectorModal
      v-model="dataSelectorShow"
      :open="dataSelectorShow"
      @modalClientSelected="modalClientSelected"
    />
    
    <!-- 受注する見積を選択モーダル -->
    <SelectEstimateForOrderModal
      v-model="selectEstimateForOrder"
      @modalEstimateSelected="modalEstimateSelected"
      :open="selectEstimateForOrder"
    />

    <!-- 雇い入れ選択モーダル -->
    <SelectInterviewsForEmploymentModal
      v-model="selectInterviewsForEmployment"
      :open="selectInterviewsForEmployment"
      @modalInterviewsSelected="modalInterviewsSelected"
    />

    <!-- 複写する見積もり選択モーダル -->
    <SelectEstimateForAllCopyModal
      v-model="allCopyEstimateShow"
      :open="allCopyEstimateShow"
      :estimates="allCopyEstimateParam.estimates"
      :selectCallback="allCopyEstimateParam.selectCallback"
    />

    <!-- 物件選択モーダル（見積依頼で使用） -->
    <SiteSelectorForEstimateModal
      v-model="siteModal"
      :open="siteModal"
      @modalSiteSelected="siteSelected"
    />

  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'

import Category2Select from '../components/Category2Select.vue'
import CategorySelect from '../components/CategorySelect.vue'
import NewNowFlagSelect from '../components/NewNowFlagSelect.vue'
import NewClassSelect from '../components/NewClassSelect.vue'
import EstimationCleanCategorySelect from '../components/EstimationCleanCategorySelect.vue'
import EstimationInspectCategorySelect from '../components/EstimationInspectCategorySelect.vue'
import EstimationOtherCategorySelect from '../components/EstimationOtherCategorySelect.vue'
import SiteSelectorForEstimateModal from '../components/SiteSelectorForEstimateModal.vue'

import AddFileBtnSelect from '../components/AddFileBtnSelect.vue'

import InputGroupLabel from '../components/InputGroupLabel.vue'
import AvailablePowerFlagSelect from '../components/AvailablePowerFlagSelect.vue'
import ParkingFlagSelect from '../components/ParkingFlagSelect.vue'
import AvailableToiletFlagSelect from '../components/AvailableToiletFlagSelect.vue'
import DustRemovalSelect from '../components/DustRemovalSelect.vue'
import CleanCountSelect from '../components/CleanCountSelect.vue'
import DustCountSelect from '../components/DustCountSelect.vue'
import PlusDustRemovalSelect from '../components/PlusDustRemovalSelect.vue'
import EverydayStartTimeFlagSelect from '../components/EverydayStartTimeFlagSelect.vue'
import ManagerWorkTimeFlagSelect from '../components/ManagerWorkTimeFlagSelect.vue'
import ManagerWorkHolidayFlagSelect from '../components/ManagerWorkHolidayFlagSelect.vue'

import EverydayHolidayFlagSelect from '../components/EverydayHolidayFlagSelect.vue'
import EverydayHolidaySelect from '../components/EverydayHolidaySelect.vue'
import InputGroupSubLabel from '../components/InputGroupSubLabel.vue'

import ManagerWorkHolidaySelect from '../components/ManagerWorkHolidaySelect.vue'
import GlassCleanWorkWaySelect from '../components/GlassCleanWorkWaySelect.vue'
import EvInspectContentSelect from '../components/EvInspectContentSelect.vue'
import ResidenceTypeSelect from '../components/ResidenceTypeSelect.vue'
import ApartmentTypeSelect from '../components/ApartmentTypeSelect.vue'
import ToiletCleaningSelect from '../components/ToiletCleaningSelect.vue'

import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'

import WmsAttachment from '@wmscomponents/WmsAttachment.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import NumericTextInput2 from '@components/NumericTextInput2.vue'
import DentatsuCategorySelect from '../components/DentatsuCategorySelect.vue'
import ClientSelectorModal from '../components/ClientSelectorModal.vue'
import SelectEstimateForOrderModal from '../components/SelectEstimateForOrderModal.vue'
import SelectInterviewsForEmploymentModal from '../components/SelectInterviewsForEmploymentModal.vue'
import SelectEstimateForAllCopyModal from '../components/SelectEstimateForAllCopyModal.vue'

import HelpBtnSelect from '../components/HelpBtnSelect.vue'
// import PigeonFormModal from '@components/PigeonFormModal.vue'

import * as helper from '@libs/helper'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'

import * as requestPostManager from '@managers/requestPostManager'
import * as estimateManager from '@managers/estimateManager'
import * as taskRequestManager from '@managers/taskRequestManager'
import * as logManager from '@managers/logManager'
import * as orderManager from '@managers/orderManager'
import * as backend from '@libs/backend'

import LoadingIcon from '@assets/loading-circle.svg' 
// import { PIGEON_CONFIG } from '@/config'
import { REQUEST_POST_FEATURES } from '@/config'
import { REQUEST_POST_TRANSMISSION_FEATURES } from '@/config'
import * as chatwork from '@libs/chatwork'
import OrderComponents from '@apps/Order/components/OrderComponents.vue'
import OrderNewComponents from '@apps/Order/components/OrderNewComponents.vue'
import EmploymentComponents from '../components/EmploymentComponents.vue'
import InterviewsComponents from '../components/InterviewsComponents.vue'
import * as constants from '@libs/constants'
import * as purchaseManager from '@managers/purchaseManager'
import WmsSelect from '@wmscomponents/WmsSelect.vue'
import * as moment from 'moment'

import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion'
import '@components/VueSimpleAccordion.scss'
import _ from 'lodash'
import { PRIVATE_REQUEST_AUTH } from '@/config'

export default {
  components: {
    PageHeader,
    Icon,
    PrimaryButton,
    Category2Select,
    CategorySelect,
    NewNowFlagSelect,
    NewClassSelect,
    EstimationCleanCategorySelect,
    EstimationInspectCategorySelect,
    EstimationOtherCategorySelect,
    ManagerWorkHolidayFlagSelect,
    AddFileBtnSelect,
    InputGroupLabel,
    AvailablePowerFlagSelect,
    ParkingFlagSelect,
    AvailableToiletFlagSelect,
    DustRemovalSelect,
    CleanCountSelect,
    DustCountSelect,
    PlusDustRemovalSelect,
    EverydayStartTimeFlagSelect,
    EverydayHolidayFlagSelect,
    EverydayHolidaySelect,
    ManagerWorkTimeFlagSelect,
    InputGroupSubLabel,
    ManagerWorkHolidaySelect,
    GlassCleanWorkWaySelect,
    EvInspectContentSelect,
    ResidenceTypeSelect,
    ApartmentTypeSelect,
    ToiletCleaningSelect,
    WmsSuggestInput,
    WmsTextInput,
    WmsTimeInput2,
    NumericTextInput2,
    DentatsuCategorySelect,
    WmsAttachment,
    WmsDateInput,
    HelpBtnSelect,
    // PigeonFormModal,
    ClientSelectorModal,
    SelectEstimateForOrderModal,
    SelectInterviewsForEmploymentModal,
    SelectEstimateForAllCopyModal,
    SiteSelectorForEstimateModal,
    LoadingIcon,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
    OrderComponents,
    OrderNewComponents,
    WmsSelect,
    EmploymentComponents,
    InterviewsComponents
  },

  data() {
    return {
      requestPost: requestPostManager.create(),
      clientData: {},
      originalRequestPost: {},
      toDepartmentList: [],
      toStaffNameList: [],
      dataSelectorShow: false,
      isSaving: false,
      initialized: false,
      forCustomers: false,
      clientTaskId: null,
      clientRegistrationModalShow: false,
      displayHouseHolds: false,
      saved: false,
      estimateFlag: false,
      minLimitDate: '',
      isClientRequired: true,
      isDisabledNewClass: false,
      hourItems: [],
      minuteItems: [],
      // ゴミ出し搬出有無（ラウンド、ラウンドプラス）キャプション
      dustCaptionRls: 'ゴミ搬出有無',
      dustCaptionPlus: 'ゴミ搬出有無',
      evQtyItems: [],
      evUnitNameItems: [],
      spItems: [],
      unitNameItems: [],
      otherWorkItems: [],
      regItems: [],
      rsItems: [],
      isBuildingDetailsRequired: true,
      isBuildingEquipmentRequired: true,
      deci: 1,
      // 定期清掃
      copyFixed: [],
      // 特別清掃
      copySp: [],
      // ラウンドサブ
      copySubs: [],
      // ダブっているラウンドサービスの項目
      copySubsW: [],
      // その他作業
      copyOther: [],
      // ダブっているその他作業の項目
      copyOtherW: [],
      // 選択した部署のデータ
      departmentInfo: {},
      // 複写する見積もりを選択する画面表示
      allCopyEstimateShow: false,
      // 複写する見積もりを選択する画面に渡すパラム
      allCopyEstimateParam: {},
      isOrder: false,
      estimateData: {},
      isCategory: true,
      order: {},
      orderData: {},
      isCompleteButtunActive: true,
      contractEndDateResult: false,
      contractEndDateMessage: '',
      classificationResult: false,
      classificationMessage: '',
      specialCleanWorkItemResult: false,
      specialCleanWorkItemMessage: '',
      mode: 0,
      referenceMode: false,
      dispRequestDate: '',
      dispLimitDate: '',
      isOutsideRequired: true,
      isTransmission: false,
      // 得意先リスト
      clientLists: [],
      // 編集できるか
      canEdit: false,
      // 発注メールを一度でも送信したか
      isAlreadySent: false,
      // 受注する見積を選択するダイアログ表示
      selectEstimateForOrder: false,
      selectInterviewsForEmployment: false,
      // スタッフ  SuggestInput フラグ
      isStaffSuggest: true,
      selectedStaffValue: null,
      toStaffSelections: [],
      selectedByUserValue: null,
      byUserSelections: [],
      // サブ一覧から遷移した場合のスクロール位置
      position: '',
      // 雇い入れの元となる面接タスクのID
      interviewsId: null,
      // 面接関連のお客様会社名の必須フラグ
      interviewsClientRequire: false,
      // 全物件リスト（見積依頼で使用）
      allBuildingList: [],
      // 得意先に紐づく物件リスト（見積依頼で使用）
      buildingListByClient: [],
      // 物件検索モーダル（見積依頼で使用）
      siteModal: false
    }
  },

  watch: {
    'estimateFlag': {
      handler: function() {
        this.estimateItemRefresh()
      },
      deep: true
    }
  },

  computed: {
    isModileDevice() {
      return utils.deviceInfo.isMobile
    },

    /** 
     * 編集モード チェック
     */
    editMode() {
      return this.requestPost && this.requestPost._id
    },

    /**
     * メールアドレス 入力チェック
     */
    emailInputCheck() {
      let result
      if(this.requestPost.clientMail) {
        const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
        result = !reg.test(this.requestPost.clientMail)
      }
      return result
    },

    validateCategory2() {
      if (this.isOutsideRequired && (!this.requestPost.category2 || this.requestPost.category2.length == 0)) {
        return {
          result: false,
          message: '社内/お客様は選択必須です。社内/お客様を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateCategory() {
      if (!this.requestPost.category || this.requestPost.category.length == 0) {
        return {
          result: false,
          message: 'お問い合わせカテゴリーは選択必須です。お問い合わせカテゴリーを選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateInquiry_title() {
      if (this.isOutsideRequired && !this.requestPost.inquiry_title) {
        return {
          result: false,
          message: 'タイトルは必須入力です。タイトルを入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateClientName() {
      if (this.requestPost.temporarySaveFlag && !this.requestPost.clientName) {
        return {
          result: false,
          message: '仮登録の際は、お客様会社名は必須入力です。\r\nお客様会社名を入力してください。'
        }
      }

      // 面接関連の場合は、採用の時のみ必須
      if (this.isShowCategoryItem('面接関連')) {
        if (!this.interviewsClientRequire) {
          return {
            result: true,
            message: ''
          }
        } else if (this.interviewsClientRequire && !this.requestPost.clientName) {
          return {
            result: false,
            message: '「採用」なのでお客様会社名は必須入力です。お客様会社名を入力してください。'
          }
        }
      }

      if (((this.isOutsideRequired && this.isClientRequired) && !this.requestPost.clientName)) {
        return {
          result: false,
          message: 'お客様会社名は必須入力です。お客様会社名を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateClientPersonName() {
      if (this.isOutsideRequired && this.isClientRequired && !this.requestPost.clientPersonName) {
        return {
          result: false,
          message: 'お客様担当者名は必須入力です。お客様担当者名を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateTelephoneNumber() {
      if (!this.requestPost.telephoneNumber) {
        return {
          result: false,
          message: '電話番号は必須入力です。電話番号を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateClientMail() {
      if (this.isOutsideRequired && this.isClientRequired && !this.requestPost.clientMail) {
        return {
          result: false,
          message: 'メールアドレスは必須入力です。メールアドレスを入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateToDepartment() {
      if (!this.requestPost.toDepartment) {
        return {
          result: false,
          message: 'アポロ管財依頼先部署は必須入力です。アポロ管財依頼先部署を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateToStaffName() {
      if (!this.requestPost.toStaffName) {
        return {
          result: false,
          message: '担当者は必須入力です。担当者を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateByUser() {
      if (!this.requestPost.byUser) {
        return {
          result: false,
          message: '依頼主は必須入力です。依頼主を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateRequestDate() {
      if (!this.requestPost.requestDate) {
        return {
          result: false,
          message: '依頼日は必須入力です。依頼日を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateLimitDate() {
      if (!this.requestPost.limitDate) {
        return {
          result: false,
          message: '期限は必須入力です。期限を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateNewNowFlag() {
      if (!this.requestPost.newNowFlag || this.requestPost.newNowFlag.length == 0) {
        return {
          result: false,
          message: '契約無/契約有は選択必須です。契約無/契約有を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateNewClass() {
      if (!this.requestPost.newClass || this.requestPost.newClass.length == 0) {
        return {
          result: false,
          message: '新規区分は選択必須です。新規区分を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateBuildingName() {
      if (!this.requestPost.buildingName) {
        return {
          result: false,
          message: '建物名は必須入力です。建物名を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateState() {
      if (this.isBuildingDetailsRequired && !this.requestPost.state) {
        return {
          result: false,
          message: '都道府県は必須入力です。都道府県を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateCity() {
      if (this.isBuildingDetailsRequired && !this.requestPost.city) {
        return {
          result: false,
          message: '市区郡は必須入力です。市区郡を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateStairs() {
      if (this.isBuildingDetailsRequired && !this.requestPost.stairs) {
        return {
          result: false,
          message: '階数は必須入力です。階数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateHouseholds() {
      if (this.isBuildingDetailsRequired && !this.requestPost.households) {
        return {
          result: false,
          message: '戸数は必須入力です。戸数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateAvailablePowerFlag() {
      if (this.isBuildingEquipmentRequired && (!this.requestPost.availablePowerFlag || this.requestPost.availablePowerFlag.length == 0)) {
        return {
          result: false,
          message: '使用できる電源の有無は選択必須です。使用できる電源の有無を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateParkingFlag() {
      if (this.isBuildingEquipmentRequired && (!this.requestPost.parkingFlag || this.requestPost.parkingFlag.length == 0)) {
        return {
          result: false,
          message: '駐車スペースの有無は選択必須です。駐車スペースの有無を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateDustRemoval() {
      if (!this.requestPost.dustRemoval || this.requestPost.dustRemoval.length == 0) {
        return {
          result: false,
          message: 'ラウンドサービスのゴミ搬出有無は選択必須です。ゴミ搬出有無を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },
    
    validateDustCount() {
      if (!this.requestPost.dustCount || this.requestPost.dustCount.length == 0) {
        return {
          result: false,
          message: 'ラウンドサービスのゴミ搬出回数は選択必須です。ゴミ搬出回数を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validatePlusDustRemoval() {
      if (!this.requestPost.plusDustRemoval || this.requestPost.plusDustRemoval.length == 0) {
        return {
          result: false,
          message: 'ラウンドプラスのゴミ搬出有無は選択必須です。ゴミ搬出有無を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateRlsToiletTimes() {
      if (this.isShowtToilet(this.requestPost.rlsToiletCleaning, '有料トイレ清掃あり') && this.requestPost.rlsToiletTimes == '') {
        return {
          result: false,
          message: 'ラウンドサービスの有料トイレ清掃回数は必須です。有料トイレ清掃回数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateRlsToiletPlace() {
      if (this.isShowtToilet(this.requestPost.rlsToiletCleaning, '有料トイレ清掃あり') && this.requestPost.rlsToiletPlace == '') {
        return {
          result: false,
          message: 'ラウンドサービスの有料トイレ清掃箇所は必須です。有料トイレ清掃箇所を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validatePlusToiletTimes() {
      if (this.isShowtToilet(this.requestPost.plusToiletCleaning, '有料トイレ清掃あり') && this.requestPost.plusToiletTimes == '') {
        return {
          result: false,
          message: 'ラウンドプラス（巡回清掃）の有料トイレ清掃回数は必須です。有料トイレ清掃回数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validatePlusToiletPlace() {
      if (this.isShowtToilet(this.requestPost.plusToiletCleaning, '有料トイレ清掃あり') && this.requestPost.plusToiletPlace == '') {
        return {
          result: false,
          message: 'ラウンドプラス（巡回清掃）の有料トイレ清掃箇所は必須です。有料トイレ清掃箇所を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validatePlusToiletTimesNormal() {
      if (this.isShowtToilet(this.requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり') && this.requestPost.plusToiletTimesNormal == '') {
        return {
          result: false,
          message: 'ラウンドプラス（固定清掃）の有料トイレ清掃回数は必須です。有料トイレ清掃回数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validatePlusToiletPlaceNormal() {
      if (this.isShowtToilet(this.requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり') && this.requestPost.plusToiletPlaceNormal == '') {
        return {
          result: false,
          message: 'ラウンドプラス（固定清掃）の有料トイレ清掃箇所は必須です。有料トイレ清掃箇所を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },


    validatePlusDustCount() {
      if (!this.requestPost.plusDustCount || this.requestPost.plusDustCount.length == 0) {
        return {
          result: false,
          message: 'ラウンドプラスのゴミ搬出回数は選択必須です。ゴミ搬出回数を選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateCleanCount() {
      if (!this.requestPost.cleanCount || this.requestPost.cleanCount.length == 0) {
        return {
          result: false,
          message: 'ラウンドサービスの清掃希望回数は必須入力です。清掃希望回数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validatePlusCleanCount() {
      if (!this.requestPost.plusCleanCount || this.requestPost.plusCleanCount.length == 0) {
        return {
          result: false,
          message: 'ラウンドプラスの清掃希望回数は必須入力です。清掃希望回数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateEverydayCleanCount: function() {
      return function(index) {
        if (!this.requestPost.everydayCleans.length) {
          return false
        } else if (!this.requestPost.everydayCleans[index].everydayCleanCount) {
          return {
            result: false,
            message: '日常清掃の清掃希望回数は必須入力です。清掃希望回数を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateEverydayCleanHours: function() {
      return function(index) {
        if (!this.requestPost.everydayCleans.length) {
          return false
        } else if (!this.requestPost.everydayCleans[index].everydayCleanHours) {
          return {
            result: false,
            message: '日常清掃の清掃希望時間は必須入力です。清掃希望時間を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateEverydayStartTimeFlag: function() {
      return function(index) {
        if (!this.requestPost.everydayCleans.length) {
          return false
        } else if (!this.requestPost.everydayCleans[index].everydayStartTimeFlag || this.requestPost.everydayCleans[index].everydayStartTimeFlag.length == 0) {
          return {
            result: false,
            message: '日常清掃の作業時間の指定の有無は選択必須です。作業時間の指定の有無を選択してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateEverydayStartTime: function() {
      return function(index) {
        if (!this.requestPost.everydayCleans.length) {
          return false
        } else if (!utils.isTime(this.requestPost.everydayCleans[index].everydayStartTime)) {
          return {
            result: false,
            message: '日常清掃の指定作業開始時間は必須入力です。指定作業開始時間を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateToiletTimes: function() {
      return function(index) {
        if (!this.requestPost.everydayCleans.length) {
          return false
        } else if (!this.requestPost.everydayCleans[index].toiletTimes) {
          return {
            result: false,
            message: '日常清掃の有料トイレ清掃回数は必須入力です。有料トイレ清掃回数を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateToiletPlace: function() {
      return function(index) {
        if (!this.requestPost.everydayCleans.length) {
          return false
        } else if (!this.requestPost.everydayCleans[index].toiletPlace) {
          return {
            result: false,
            message: '日常清掃の有料トイレ清掃箇所数は必須入力です。有料トイレ清掃箇所数を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateManagerWorkCount: function() {
      return function(index) {
        if (!this.requestPost.managerWorks.length) {
          return false
        } else if (!this.requestPost.managerWorks[index].managerWorkCount) {
          return {
            result: false,
            message: '管理員業務の清掃希望回数は必須入力です。清掃希望回数を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateManagerWorkHours: function() {
      return function(index) {
        if (!this.requestPost.managerWorks.length) {
          return false
        } else if (!this.requestPost.managerWorks[index].managerWorkHours) {
          return {
            result: false,
            message: '管理員業務の清掃希望時間は必須入力です。清掃希望時間を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateManagerWorkTimeFlag: function() {
      return function(index) {
        if (!this.requestPost.managerWorks.length) {
          return false
        } else if (!this.requestPost.managerWorks[index].managerWorkTimeFlag || this.requestPost.managerWorks[index].managerWorkTimeFlag.length == 0) {
          return {
            result: false,
            message: '管理員業務の作業時間の指定の有無は選択必須です。作業時間の指定の有無を選択してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateManagerWorkTime: function() {
      return function(index) {
        if (!this.requestPost.managerWorks.length) {
          return false
        } else if (!utils.isTime(this.requestPost.managerWorks[index].managerWorkTime)) {
          return {
            result: false,
            message: '管理員業務の指定作業開始時間は必須入力です。指定作業開始時間を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateRegularClean: function() {
      return function(index, item) {
        if (!this.requestPost.regularCleans.length) {
          return false
        } else if (!this.requestPost.regularCleans[index][item]) {
          if (item.indexOf('Content') != -1) {
            return {
              result: false,
              message: '定期清掃の依頼内容は必須入力です。依頼内容を入力してください。'
            }
          } else if (item.indexOf('Count') != -1) {
            return {
              result: false,
              message: '定期清掃の回数は必須入力です。回数を入力してください。'
            }
          } else {
            return {
              result: false,
              message: '定期清掃の単位は必須入力です。単位を入力してください。'
            }
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateGlassCleanCount() {
      if (!this.requestPost.glassCleanCount) {
        return {
          result: false,
          message: 'ガラス清掃の清掃希望回数は必須入力です。清掃希望回数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validatePlantingCount() {
      if (!this.requestPost.plantingCount) {
        return {
          result: false,
          message: '植栽剪定の希望回数は必須入力です。希望回数を入力してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    validateSpecialCleanCount: function() {
      return function(index) {
        if (!this.requestPost.specialCleans.length) {
          return false
        } else if (!this.requestPost.specialCleans[index].specialCleanCount) {
          return {
            result: false,
            message: '特別清掃の希望回数は必須入力です。希望回数を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateSpecialCleanContent: function() {
      return function(index) {
        if (!this.requestPost.specialCleans.length) {
          return false
        } else if (!this.requestPost.specialCleans[index].specialCleanContent) {
          return {
            result: false,
            message: '特別清掃の依頼内容は必須入力です。依頼内容を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateSpecialCleanUnitName: function() {
      return function(index) {
        if (!this.requestPost.specialCleans.length) {
          return false
        } else if (!this.requestPost.specialCleans[index].specialCleanUnitName) {
          return {
            result: false,
            message: '特別清掃の単位は必須入力です。単位を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },
    
    validateRoundSub: function() {
      return function(index, item) {
        if (!this.requestPost.roundSubs.length) {
          return false
        } else if (!this.requestPost.roundSubs[index][item]) {
          if (item.indexOf('Content') != -1) {
            return {
              result: false,
              message: 'ラウンドサブメニューの依頼内容は必須入力です。依頼内容を入力してください。'
            }
          } else if (item.indexOf('Count') != -1) {
            return {
              result: false,
              message: 'ラウンドサブメニューの回数は必須入力です。回数を入力してください。'
            }
          } else {
            return {
              result: false,
              message: 'ラウンドサブメニューの単位は必須入力です。単位を入力してください。'
            }
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateOtherWorkContent: function() {
      return function(index) {
        if (!this.requestPost.otherWorks.length) {
          return false
        } else if (!this.requestPost.otherWorks[index].otherWorkContent) {
          return {
            result: false,
            message: 'その他作業の依頼内容は必須入力です。依頼内容を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateOtherWorkCount: function() {
      return function(index) {
        if (!this.requestPost.otherWorks.length) {
          return false
        } else if (!this.requestPost.otherWorks[index].otherWorkCount) {
          return {
            result: false,
            message: 'その他作業の希望回数は必須入力です。希望回数を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateOtherWorkUnitName: function() {
      return function(index) {
        if (!this.requestPost.otherWorks.length) {
          return false
        } else if (!this.requestPost.otherWorks[index].otherWorkUnitName) {
          return {
            result: false,
            message: 'その他作業の単位は必須入力です。単位を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    dustDisabledRls() {
      if (this.requestPost.category2[0] && this.requestPost.category2[0] == 'お客様直' && ['月 1 回', '週 1 回', '週 2 回'].includes(this.requestPost.cleanCount[0])) {
        return true
      } else {
        return false
      }
    },

    dustDisabledPlus() {
      if (this.requestPost.category2[0] && this.requestPost.category2[0] == 'お客様直' && ['月 1 回', '週 1 回', '週 2 回'].includes(this.requestPost.plusCleanCount[0])) {
        return true
      } else {
        return false
      }
    },

    /**
     * メインタスク権限
     */
    mainAuth() {
      return this.$store.getters.mainAuth
    },

    /**
     * 発注書PDF 活性or非活性判定
     */
    activePurchaseBtn() {
      // 受注が決まったもの
      if (this.$route.query.mode == 6) {
        return true
      } else {
        // 保存されたもの
        if (this.requestPost && this.requestPost._id && this.requestPost._id != '') {
          return true
        } else {
          return false
        }
      }
    },

    /**
     * 発注ボタンツールチップ
     */
    purchaseTooltip() {
      // 編集権限なし
      if (!this.canEdit) {
        return ''
      }
      if (!this.requestPost || !this.requestPost._id || this.requestPost._id == '') {
        return '発注処理に進むには、一度登録し、受注を確定してください。'
      }
      return ''
    }
  },

  async created() {
    this.initialized = false
    try {
      await this.initialize()
    } finally {
      this.initialized = true
    }
  },

  methods: {
    async initialize() {
      // this.$route.query.modeの区分一覧
      //   1：編集（メイン）
      //   2：コピー
      //   3：複写
      //   4：受注仮登録
      //   5：受注処理
      //   6：編集（受注）

      // パラメータにIDがある場合は、編集モード
      // リクエストポストのデータを取得
      const requestPostId = this.$route.query.requestPostId
      if (requestPostId) {
        const requestPost = await requestPostManager.getRequestPost(this, requestPostId)
        if (!requestPost) {
          await dialogs.showErrorDialog('エラー', 'メインタスクを開くことができません。')
          this.requestPost = this.createNewrequestPost()
        } else {
          // お問い合わせカテゴリーを過去の文言を新しくする
          if (requestPost.category) {
            requestPost.category = this.setNewCategory(requestPost.category)
          }
          // 見積りカテゴリーを過去の文言を新しくする
          if (requestPost.estimationCleanCategory) {
            requestPost.estimationCleanCategory = this.setNewEstimateCategory(requestPost.estimationCleanCategory)
          }
          // modeパラメータを取得
          const mode = this.$route.query.mode
          // modeが1の場合は編集
          if(mode == 1){
            this.requestPost = requestPost

            // リクエストポストIDを条件にサブタスクを全件取得
            let mainSubTasks = await taskRequestManager.getByRequestPostId(this, this.requestPost._id, null)

            // 「非公開依頼」関係者以外はメニューへリダイレクト
            if (requestPost.category.includes('非公開依頼') && !this.isPrivateAuthorized(requestPost, mainSubTasks)
              && !PRIVATE_REQUEST_AUTH.includes(this.$store.getters.user.id)) {
              dialogs.showInfoDialog('非公開依頼', '非公開依頼のため表示出来ません。メニューへ戻ります。', 'OK')  
              this.$router.push('/')
            }

            // サブタスクに未完了タスクがある場合、完了ボタンは非アクティブ
            let result = mainSubTasks.every(function(val){
              return val.status == 'done'
            })
            if (!result) {
              this.isCompleteButtunActive = false
            }

            // ステータスが完了の場合、参照モード
            if (this.requestPost.status == 'done') {
              this.referenceMode = true
              this.dispLimitDate = utils.formatDateJa(this.requestPost.limitDate)
              this.dispRequestDate = utils.formatDateJa(this.requestPost.requestDate)
            }
          // modeが2の場合はコピー、3の場合はRP、サブ依頼タスク、見積を全てコピー
          } else if (mode == 2 || mode == 3) {

            this.requestPost = requestPost
            this.requestPost._id = null
            this.requestPost.requestNo = null
            this.requestPost.createDate = null
            this.requestPost.upDate = null
            this.requestPost.status = 'open'

            if (mode == 3) {
              this.requestPost.requestDate = utils.getToday()
              this.requestPost.limitDate = utils.getToday()
              this.requestPost.toStaffName = this.$store.getters.user.user_name
              this.requestPost.toStaffId = this.$store.getters.user.id
              this.requestPost.byUser = this.$store.getters.user.user_name
              this.requestPost.byUserId = this.$store.getters.user.id

              // 定期清掃
              if (this.requestPost.regularCleans) {
                this.copyFixed = utils.clone(this.requestPost.regularCleans)
              }
              // 特別清掃
              if (this.requestPost.specialCleans) {
                this.copySp = utils.clone(this.requestPost.specialCleans)
              }
              // ラウンドサブ
              if (this.requestPost.roundSubs) {
                this.copySubs = utils.clone(this.requestPost.roundSubs)

                let subName = []
                this.copySubsW = []
                for (let i = 0; i < this.copySubs.length; i++) {
                  const cs = this.copySubs[i];
                  if (subName.includes(cs.roundSubContent)) {
                    this.copySubsW.push(cs.roundSubContent)
                  } else {
                    subName.push(cs.roundSubContent)
                  }
                }
              }
              // その他作業
              if (this.requestPost.otherWorks) {
                this.copyOther = utils.clone(this.requestPost.otherWorks)

                let oName = []
                this.copyOtherW = []
                for (let i = 0; i < this.copyOther.length; i++) {
                  const cs = this.copyOther[i];
                  if (oName.includes(cs.otherWorkContent)) {
                    this.copyOtherW.push(cs.otherWorkContent)
                  } else {
                    oName.push(cs.otherWorkContent)
                  }
                }
              }

            }
          }

          // フラグ 設定
          this.category2Changed()
          this.newNowFlagChanged()
          this.categoryChanged(this.requestPost.category, false)
        }
      } else {
        // 新規登録
        this.requestPost = this.createNewrequestPost()
      }

      // modeが6の場合は受注編集
      if (this.$route.query.mode == 6) {
        this.mode = 6
        const orderId = this.$route.query.orderId
        if (orderId) {
          let order = await orderManager.getOrderData(this, orderId)
          if (!order) {
            await dialogs.showErrorDialog('エラー', '受注タスクを開くことができません。')
          } else {
            // 過去のデータにないプロパティを入れる（昔の仕様の受注のみ）
            if (!order.orderNew) {
              order = this.setNewProperty(order)
            }

            // お問い合わせカテゴリーを過去の文言を新しくする
            if (order.category) {
              order.category = this.setNewCategory(order.category)
            }
            this.requestPost = order
            this.orderData = order
            this.estimateData = await estimateManager.getEstimate(this, order.estimateNo)
            this.isOrder = true
            this.isCategory = false
            this.isClientRequired = false
            // ステータスが完了の場合、参照モード
            if (this.requestPost.status == 'done') {
              this.referenceMode = true
              this.dispLimitDate = utils.formatDateJa(this.requestPost.limitDate)
              this.dispRequestDate = utils.formatDateJa(this.requestPost.requestDate)
            }
          }
        }
        // サブ一覧の「配信作業」から遷移した場合
        if (this.$route.query.position) {
          this.position = this.$route.query.position
        }
      }

      // pathがお客様用フォームであるか確認
      let path = location.pathname
      if (path.indexOf('/customersRequestPost') > -1 ) {
        // お客様用フォームフラグ
        this.forCustomers = true
        this.requestPost.category2 = ['お客様直']

        // お客様コード有無 確認
        let clientCode = this.$route.query.clientCode
        if (clientCode) {
          const client = await estimateManager.getClientByCode(clientCode)
          if (!client) {
            await dialogs.showErrorDialog('エラー', 'メインタスクを開くことができません。')
          } else {
            this.clientData = client

            // お客様コードよりお客様会社名、お客様部署名 取得
            this.requestPost.clientName = this.clientData.clientName + ' ' + this.clientData.clientDepartmentName
          }
        } else {
          await dialogs.showErrorDialog('エラー', 'メインタスクを開くことができません。')
        }

        // リクエストポストのデータにお客様コードを追加
        this.requestPost.clientCode = clientCode
      }
      
      // デンタツ以外の場合、部署のリスト取得
      if (!this.requestPost.category.includes('デンタツ') && !this.requestPost.category.includes('AKBS休み登録')) {
        let departmentList = this.$store.getters.departmentList
        this.toDepartmentList = departmentList
      }

      this.minLimitDate = utils.getToday()

      if (path.indexOf('/customersRequestPost') == -1 && !requestPostId && !this.requestPost.orderNo) {
        // 担当者・依頼主にログインユーザーをセット
        this.setDefaultStaff()
      }

      // 得意先リスト
      this.clientLists = await estimateManager.getClient(null)
      // 担当者リスト 生成
      if (this.requestPost.toDepartmentId) {
        this.getStaffNameList(this.requestPost.toDepartmentId, false)
      } else {
        this.getStaffNameList()
      }

      this.hourItems = [
        { text: '7' },
        { text: '8' },
        { text: '9' },
        { text: '10' },
        { text: '11' },
        { text: '12' },
        { text: '13' },
        { text: '14' },
        { text: '15' },
        { text: '16' },
        { text: '17' },
        { text: '18' },
        { text: '19' },
        { text: '20' },
        { text: '21' },
        { text: '22' },
        { text: '23' }
      ]
      this.minuteItems = [
        { text: '00' },
        { text: '15' },
        { text: '30' },
        { text: '45' }
      ]
      
      this.evQtyItems = [
        { text: '1' },
        { text: '4' },
        { text: '6' },
        { text: '12' },
        { text: '24' }
      ]
      this.evUnitNameItems = [
        { text: 'ヶ月' },
        { text: '回' },
        { text: '回/年' },
        { text: '式' },
        { text: '回/2年' },
        { text: '回/3年' }
      ]

      // idは見積りで単価取得に必要！
      this.spItems = [
        { id:'wall',text:'腰壁清掃' },
        { id:'lighting',text:'照明器具清掃' },
        { id:'acid',text:'エントランス等石材酸洗浄' },
        { id:'coating',text:'エントランスコーティング' },
        { id:'garari',text:'ガラリ清掃' },
        { id:'gutter',text:'側溝清掃' },
        { id:'masu',text:'枡清掃' },
        { id:'parking',text:'駐車場ピット内清掃' },
        { id:'safety',text:'安全対策要員' },
        { id:'shovel',text:'釜場清掃（スコップ）' },
        { id:'sludge',text:'汚泥処理' },
        { id:'inter',text:'インターロッキング洗浄' },
        { id:'drain',text:'屋上ドレン清掃' },
        { id:'bicycle',text:'駐輪場清掃' },
        { id:'roof',text:'駐輪場屋根' },
        { id:'wipe',text:'壁面拭き上げ' },
        { id:'filter',text:'エアコンフィルター清掃' },
        { id:'louver',text:'ルーバー' },
        { id:'pallet',text:'機械式駐車場パレット清掃（掃き）' },
        { id:'fence',text:'クーラーフェンス' },
        { id:'ceiling',text:'天井清掃(ステンレス）' },
        { id:'pond',text:'水景(池)清掃' },
        { id:'lattice',text:'外廊下面格子・網戸清掃' }
      ]

      // 単位
      this.unitNameItems = [
        { text: '回' },
        { text: '回/年' },
        { text: '式' },
        { text: 'ヶ月' },
        { text: '回/2年' },
        { text: '回/3年' }
      ]

      // その他作業
      this.otherWorkItems = [
        { text: '管球対応' },
        { text: '粗大ごみ対応' },
        { text: '物品販売' },
        { text: '緊急対応' },
        { text: '防火対象物点検' },
        { text: 'スポット清掃' }
      ]

      // 定期清掃内容
      this.regItems = [
        { text:'エントランス' },
        { text:'廊下' },
        { text:'階段' },
        { text:'管理室' }
      ]

      // ラウンドサブメニュー内容
      this.rsItems = [
        { text:'フロントサポート' },
        { text:'フロントサポートプラス' },
        { text:'ラウンドトラッシュ' },
        { text:'クオリティレポート' }
      ]

      // 新規登録の場合のみ、配列の要素の追加を行う
      if(!requestPostId) {
        this.addSpecialClean()
        this.addRegularClean()
        this.addRoundSub()
        this.addOtherWork()
        this.addEverydayClean()
        this.addManagerWork()
      }

      // 担当者から担当者（非公開依頼）へ反映
      this.staffNameToToStaff()
      // 依頼者から依頼者（非公開依頼）へ反映
      this.byUserToSByUser()

      // 依頼主（非公開依頼）選択肢生成
      this.byUserSelections = this.$store.getters.userList.map(function(item){
        return {
          value: item.id,
          text: item.user_name
        };
      });

      // console.log(this.byUserSelections)
      // console.log(this.$store.getters.userList)

      const self = this
      self.$nextTick(() => {
        self.storeOriginal()
        this.canEdit = this.checkCanEdit()
        this.checkAlreadySent()
      })
    },

    /**
     * 編集の有無 取得
     */
    isDirty() {
      // 参照モードでない場合、変更の有無を確認
      if (!this.referenceMode) {
        let before = utils.clone(this.originalRequestPost)
        // <p>タグ後ろの半角スペースを無視して比較
        if (this.requestPost.type == 'order' && before.template) {
          before.template = before.template.replace(/<p> /g, '<p>').replace(/ <\/p>/g, '</p>')
        }
        return !utils.isEquals(utils.toPlainObject(before), utils.toPlainObject(this.requestPost))
      } else {
        return false
      }
    },

    /**
     * 過去のデータで追加になったプロパティが無ければ追加
     * @param order オーダーデータ
     */
    setNewProperty(order) {
      // カテゴリコードがないもの
      if (!order.orderOthers) {
        order.orderOthers = []
      }
      // EV点検の作業開始年、月
      if (!order.evInspectFirstWorkYear) {
        order.evInspectFirstWorkYear = ''
      }
      if (!order.evInspectFirstWorkMonth) {
        order.evInspectFirstWorkMonth = []
      }
      return order
    },

    /**
     * 一覧へ遷移
     */
    async toList() {
      if (!this.isDirty()) {
        this.$router.push({ name: 'RequestPostList' })
      } else {
        // 編集権限がある場合のみダイアログ表示
        if (this.canEdit) {
          const dialogResult = await dialogs.showWarningConfirmDialog('保存の確認', '変更が保存されませんがよろしいですか？')
          if (dialogResult === 'YES') {
            this.$router.push({ name: 'RequestPostList' })
          }
        } else {
          this.$router.push({ name: 'RequestPostList' })
        }
      }
    },

    storeOriginal() {
      const tempRequestPost = utils.clone(this.requestPost)
      this.originalRequestPost = tempRequestPost
    },

    /**
     * 新規リクエストポスト生成
     */
    createNewrequestPost() {
      return requestPostManager.create()
    },

    /**
     * 保存
     */
    async save(isTemporarySave) {
      console.log('Save.')
      
      this.requestPost.temporarySaveFlag = isTemporarySave

      const validationResult = await this.validation(isTemporarySave)
      if (!validationResult) {
        return
      }

      try {
        this.isSaving = true

        let result
        let newFlag = false

        // 受注の場合
        if (this.isOrder) {
          // 過去の受注データ
          if (!this.requestPost.orderNew && this.editMode) {
            this.$refs.OrderComponents.orderDataEmit()
            this.requestPost.orderSummary = this.getOrderSummary()
          // 新受注データ（全て配列）
          } else {
            this.$refs.OrderNewComponents.orderDataEmit()
            if (!await this.orderNewValidate()) {
              return
            }
            this.requestPost.orderSummary = this.getOrderSummaryNew()
          }
          
          this.requestPost.isConfirmationCommissionFlag = true

          // 更新
          if (this.requestPost.orderNo) {
            result = await orderManager.updateOrderData(this, this.requestPost)

          // 新規登録
          } else {
            this.requestPost.orderNew = true
            result = await orderManager.registerOrder(this, this.requestPost)
            newFlag = true

            if (result) {
              // 見積りステータスを受注にして保存
              this.estimateData.estimateStatus = '受注'

              // ログインユーザー名
              let name = this.$store.getters.user.user_name
              name = utils.deleteKana(name)
              
              // 受注の状態、登録者を保存
              this.estimateData.orderStatus = {
                status: '受注登録中',
                create: name,
                createDate: moment().format('YYYY-MM-DD HH:mm:ss')
              }
              // 更新
              let esResult = await estimateManager.updateEstimate(this, this.estimateData)
              if (!esResult) {
                await dialogs.showErrorDialog('更新エラー', 'ステータスを受注に切替できませんでした。')
                return false
              } else {
                logManager.recording(this, logManager.Loglevel.INFO, '見積', '受注', '見積（見積番号：' + this.requestPost.estimateNo + '）を受注しました。')
              }
            }
          }

          if (result) {
            if (this.requestPost.requestNo) {
              logManager.recordingByRegist(this, newFlag, '受注タスク', '受注タスク（お問い合わせ番号：' + this.requestPost.requestNo + '）')
            } else {
              logManager.recordingByRegist(this, newFlag, '受注タスク', '受注タスク')
            }

            await dialogs.showSuccessDialog('受注タスクの保存', '受注タスクを保存しました。')

            const dialogResult = await dialogs.showConfirmDialog('タスク依頼', '続けてタスク依頼を行いますか？', 'はい', 'いいえ')
            if (dialogResult === 'YES') {
              this.$router.push({ name: 'TaskRequest', query: { orderId: result, mode: '1' }})
            } else {
              this.$router.push({ name: 'RequestPostList' })
            }
          } else {
            await dialogs.showErrorDialog('受注タスクの保存', '受注タスクを保存できませんでした。')
          }

        // メインタスク
        } else {
          // 更新
          if (this.requestPost.requestNo && this.requestPost._id) {
            result = await requestPostManager.update(this, this.requestPost)
          // 新規
          } else {
            result = await requestPostManager.register(this, this.requestPost)
            newFlag = true
          }

          if (result.id) {
            logManager.recordingByRegist(this, newFlag, 'メインタスク', 'メインタスク（お問い合わせ番号：' + result.requestNo + '）')
            if (!this.forCustomers) {
              if (isTemporarySave) {
                // 仮登録の場合はリクエストポスト一覧へ遷移
                await dialogs.showSuccessDialog('メインタスクの仮登録', 'メインタスクを仮登録しました。')
                this.$router.push({ name: 'RequestPostList' })
              } else {
                await dialogs.showSuccessDialog('メインタスクの保存', 'メインタスクを保存しました。')
                try {
                  await this.sendChatWorkMessage(this.requestPost, result.id)
                } catch (e) {
                  console.log(e)
                }

                // 複写の場合は強制的にタスク依頼に遷移
                if (this.$route.query.mode == 3) {
                  await this.allCopy(result)

                // 複写でなければ選択させる
                } else {
                  // 雇い入れは、面接データを更新＆強制的にサブタスクへ
                  if (this.isShowCategoryItem('雇い入れ')) {
                    // 新規登録の場合、面接データを更新
                    if (newFlag) {
                      await requestPostManager.upDateInterviews(this, this.interviewsId)
                    }
                    this.$router.push({ name: 'TaskRequest', query: { requestPostId: result.id }})
                  // 雇い入れ以外
                  } else {
                    const dialogResult = await dialogs.showConfirmDialog('サブタスク登録', '続けてサブタスクを登録しますか？', 'はい', 'いいえ')
                    if (dialogResult === 'YES') {
                      this.$router.push({ name: 'TaskRequest', query: { requestPostId: result.id }})
                    } else {
                      this.$router.push({ name: 'RequestPostList' })
                    }
                  }
                }
              }
            } else {
              await dialogs.showSuccessDialog('メインタスクの保存', 'メインタスクを保存しました。フォームを終了いたします。')
              // お客様RPフォーム の場合は閉じる
              this.$pigeon.signout()
              window.close()
              this.saved = true
              await dialogs.showSuccessDialog('メインタスクの保存', 'タブを閉じてください。')
            }
          } else {
            // alert('保存できませんでした。')
            if (isTemporarySave) {
              await dialogs.showErrorDialog('メインタスクの仮登録', 'メインタスクを仮登録できませんでした。')
            } else {
              await dialogs.showErrorDialog('メインタスクの保存', 'メインタスクを保存できませんでした。')
            }
          }
        }

        this.storeOriginal()

        return result
      } finally {
        this.isSaving = false
      }
    },

    /**
     * 入力チェック
     */
    async validation(isTemporarySave) {
      // 社内/お客様
      if (!isTemporarySave && !this.validateCategory2.result) {
        await dialogs.showInfoDialog('入力確認', this.validateCategory2.message, 'OK')
        return false
      }

      // お問い合わせカテゴリー
      if (!isTemporarySave && !this.validateCategory.result) {
        await dialogs.showInfoDialog('入力確認', this.validateCategory.message, 'OK')
        return false
      }

      // 電話番号
      if(this.requestPost.category.indexOf('デンタツ') != -1) {
        if (!isTemporarySave && !this.validateTelephoneNumber.result) {
          await dialogs.showInfoDialog('入力確認', this.validateTelephoneNumber.message, 'OK')
          return false
        }
      }

      // タイトル
      if (this.forCustomers == false) {
        if (!this.validateInquiry_title.result) {
          await dialogs.showInfoDialog('入力確認', this.validateInquiry_title.message, 'OK')
          return false
        }
      }

      // お客様会社名
      if (!this.validateClientName.result) {
        await dialogs.showInfoDialog('入力確認', this.validateClientName.message, 'OK')
        return false
      }

      // お客様担当者名
      if (!isTemporarySave && !this.validateClientPersonName.result) {
        await dialogs.showInfoDialog('入力確認', this.validateClientPersonName.message, 'OK')
        return false
      }

      // メールアドレス
      if (!isTemporarySave && !this.validateClientMail.result) {
        await dialogs.showInfoDialog('入力確認', this.validateClientMail.message, 'OK')
        return false
      }

      if (this.emailInputCheck) {
        await dialogs.showInfoDialog('入力確認', '入力されたメールアドレスが正しくありません。', 'OK')
        return false
      }

      // アポロ管財依頼先部署
      if (!isTemporarySave && !this.validateToDepartment.result) {
        await dialogs.showInfoDialog('入力確認', this.validateToDepartment.message, 'OK')
        return false
      }

      // 担当者
      if (this.forCustomers == false) {
        if (!isTemporarySave && !this.validateToStaffName.result) {
          await dialogs.showInfoDialog('入力確認', this.validateToStaffName.message, 'OK')
          return false
        }
      }

      // 依頼主
      if (this.forCustomers == false) {
        if (!isTemporarySave && !this.validateByUser.result) {
          await dialogs.showInfoDialog('入力確認', this.validateByUser.message, 'OK')
          return false
        }
      }

      // 依頼日
      if (!this.validateRequestDate.result) {
        await dialogs.showInfoDialog('入力確認', this.validateRequestDate.message, 'OK')
        return false
      }

      // 期限
      if (!this.validateLimitDate.result) {
        await dialogs.showInfoDialog('入力確認', this.validateLimitDate.message, 'OK')
        return false
      }

      // 契約無/契約有
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateNewNowFlag.result) {
          await dialogs.showInfoDialog('入力確認', this.validateNewNowFlag.message, 'OK')
          return false
        }
      }

      // 新規区分
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateNewClass.result) {
          await dialogs.showInfoDialog('入力確認', this.validateNewClass.message, 'OK')
          return false
        }
      }

      // 建物名
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateBuildingName.result) {
          await dialogs.showInfoDialog('入力確認', this.validateBuildingName.message, 'OK')
          return false
        }
      }

      // 都道府県
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateState.result) {
          await dialogs.showInfoDialog('入力確認', this.validateState.message, 'OK')
          return false
        }
      }

      // 市区郡
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateCity.result) {
          await dialogs.showInfoDialog('入力確認', this.validateCity.message, 'OK')
          return false
        }
      }

      // 階数
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateStairs.result) {
          await dialogs.showInfoDialog('入力確認', this.validateStairs.message, 'OK')
          return false
        }
      }

      // 戸数
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateHouseholds.result) {
          await dialogs.showInfoDialog('入力確認', this.validateHouseholds.message, 'OK')
          return false
        }
      }

      // 使用できる電源の有無
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateAvailablePowerFlag.result) {
          await dialogs.showInfoDialog('入力確認', this.validateAvailablePowerFlag.message, 'OK')
          return false
        }
      }

      // 駐車スペースの有無
      if(this.requestPost.category.indexOf('見積依頼') != -1) {
        if (!isTemporarySave && !this.validateParkingFlag.result) {
          await dialogs.showInfoDialog('入力確認', this.validateParkingFlag.message, 'OK')
          return false
        }
      }

      // 清掃希望回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドサービス') != -1) {
        if (!isTemporarySave && !this.validateCleanCount.result) {
          await dialogs.showInfoDialog('入力確認', this.validateCleanCount.message, 'OK')
          return false
        }
      }

      // ゴミ搬出有無
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドサービス') != -1) {
        if (!isTemporarySave && !this.validateDustRemoval.result) {
          await dialogs.showInfoDialog('入力確認', this.validateDustRemoval.message, 'OK')
          return false
        }
      }

      // ゴミ搬出回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドサービス') != -1 && this.requestPost.dustRemoval && this.requestPost.dustRemoval.length && this.requestPost.dustRemoval[0]=='有') {
        if (!isTemporarySave && !this.validateDustCount.result) {
          await dialogs.showInfoDialog('入力確認', this.validateDustCount.message, 'OK')
          return false
        }
      }

      // RLSトイレ回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドサービス') != -1 && this.isShowtToilet(this.requestPost.rlsToiletCleaning, '有料トイレ清掃あり')) {
        if (!isTemporarySave && !this.validateRlsToiletTimes.result) {
          await dialogs.showInfoDialog('入力確認', this.validateRlsToiletTimes.message, 'OK')
          return false
        }
      }

      // RLSトイレ回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドサービス') != -1 && this.isShowtToilet(this.requestPost.rlsToiletCleaning, '有料トイレ清掃あり')) {
        if (!isTemporarySave && !this.validateRlsToiletPlace.result) {
          await dialogs.showInfoDialog('入力確認', this.validateRlsToiletPlace.message, 'OK')
          return false
        }
      }

      // 清掃希望回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドプラス') != -1) {
        if (!isTemporarySave && !this.validatePlusCleanCount.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlusCleanCount.message, 'OK')
          return false
        }
      }

      // ゴミ搬出有無
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドプラス') != -1) {
        if (!isTemporarySave && !this.validatePlusDustRemoval.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlusDustRemoval.message, 'OK')
          return false
        }
      }

      // ゴミ搬出回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドプラス') != -1  && this.requestPost.plusDustRemoval && this.requestPost.plusDustRemoval.length && this.requestPost.plusDustRemoval[0]=='有') {
        if (!isTemporarySave && !this.validatePlusDustCount.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlusDustCount.message, 'OK')
          return false
        }
      }

      // RLSプラストイレ回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドプラス') != -1 && this.isShowtToilet(this.requestPost.plusToiletCleaning, '有料トイレ清掃あり')) {
        if (!isTemporarySave && !this.validatePlusToiletTimes.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlusToiletTimes.message, 'OK')
          return false
        }
      }

      // RLSプラストイレ回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドプラス') != -1 && this.isShowtToilet(this.requestPost.plusToiletCleaning, '有料トイレ清掃あり')) {
        if (!isTemporarySave && !this.validatePlusToiletPlace.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlusToiletPlace.message, 'OK')
          return false
        }
      }

      // RLSプラストイレ回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドプラス') != -1 && this.isShowtToilet(this.requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり')) {
        if (!isTemporarySave && !this.validatePlusToiletTimesNormal.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlusToiletTimesNormal.message, 'OK')
          return false
        }
      }

      // RLSプラストイレ回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドプラス') != -1 && this.isShowtToilet(this.requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり')) {
        if (!isTemporarySave && !this.validatePlusToiletPlaceNormal.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlusToiletPlaceNormal.message, 'OK')
          return false
        }
      }

      // 日常清掃
      if (this.requestPost.category.indexOf('見積依頼') != -1) {
        for (let index = 0; index < this.requestPost.everydayCleans.length; index++) {
          // 清掃希望回数
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('日常清掃') != -1) {
            if (!isTemporarySave && !this.validateEverydayCleanCount(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateEverydayCleanCount(index).message, 'OK')
              return false
            }
          }

          // 清掃希望時間
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('日常清掃') != -1) {
            if (!isTemporarySave && !this.validateEverydayCleanHours(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateEverydayCleanHours(index).message, 'OK')
              return false
            }
          }

          // 作業時間の指定の有無
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('日常清掃') != -1) {
            if (!isTemporarySave && !this.validateEverydayStartTimeFlag(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateEverydayStartTimeFlag(index).message, 'OK')
              return false
            }
          }

          // 指定作業開始時間
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.everydayCleans[index].everydayStartTimeFlag.indexOf('有') != -1) {
            if (!isTemporarySave && !this.validateEverydayStartTime(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateEverydayStartTime(index).message, 'OK')
              return false
            }
          }

          // 有料トイレ清掃回数
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.everydayCleans[index].toiletCleaning.indexOf('有料トイレ清掃あり') != -1 && this.requestPost.estimationCleanCategory.indexOf('日常清掃') != -1) {
            if (!isTemporarySave && !this.validateToiletTimes(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateToiletTimes(index).message, 'OK')
              return false
            }
          }

          // 有料トイレ清掃箇所数
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.everydayCleans[index].toiletCleaning.indexOf('有料トイレ清掃あり') != -1 && this.requestPost.estimationCleanCategory.indexOf('日常清掃') != -1) {
            if (!isTemporarySave && !this.validateToiletPlace(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateToiletPlace(index).message, 'OK')
              return false
            }
          }
        }
      }

      // 管理員業務
      if (this.requestPost.category.indexOf('見積依頼') != -1) {
        for (let index = 0; index < this.requestPost.managerWorks.length; index++) {
          // 清掃希望回数
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('管理員業務') != -1) {
            if (!isTemporarySave && !this.validateManagerWorkCount(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateManagerWorkCount(index).message, 'OK')
              return false
            }
          }

          // 清掃希望時間
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('管理員業務') != -1) {
            if (!isTemporarySave && !this.validateManagerWorkHours(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateManagerWorkHours(index).message, 'OK')
              return false
            }
          }

          // 作業時間の指定の有無
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('管理員業務') != -1) {
            if (!isTemporarySave && !this.validateManagerWorkTimeFlag(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateManagerWorkTimeFlag(index).message, 'OK')
              return false
            }
          }

          // 指定作業開始時間
          if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.managerWorks[index].managerWorkTimeFlag.indexOf('有') != -1) {
            if (!isTemporarySave && !this.validateManagerWorkTime(index).result) {
              await dialogs.showInfoDialog('入力確認', this.validateManagerWorkTime(index).message, 'OK')
              return false
            }
          }
        }
      }

      // 定期清掃
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('定期清掃') != -1) {
        for (let index = 0; index < this.requestPost.regularCleans.length; index++) {
          if (!isTemporarySave && !this.validateRegularClean(index, 'regularCleanContent').result) {
            await dialogs.showInfoDialog('入力確認', this.validateRegularClean(index, 'regularCleanContent').message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateRegularClean(index, 'regularCleanCount').result) {
            await dialogs.showInfoDialog('入力確認', this.validateRegularClean(index, 'regularCleanCount').message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateRegularClean(index, 'regularCleanUnitName').result) {
            await dialogs.showInfoDialog('入力確認', this.validateRegularClean(index, 'regularCleanUnitName').message, 'OK')
            return false
          }
        }
      }

      // 清掃希望回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ガラス清掃') != -1) {
        if (!isTemporarySave && !this.validateGlassCleanCount.result) {
          await dialogs.showInfoDialog('入力確認', this.validateGlassCleanCount.message, 'OK')
          return false
        }
      }

      // 希望回数
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('植栽剪定') != -1) {
        if (!isTemporarySave && !this.validatePlantingCount.result) {
          await dialogs.showInfoDialog('入力確認', this.validatePlantingCount.message, 'OK')
          return false
        }
      }

      // 特別清掃
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('特別清掃') != -1) {
        for (let index = 0; index < this.requestPost.specialCleans.length; index++) {
          if (!isTemporarySave && !this.validateSpecialCleanCount(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateSpecialCleanCount(index).message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateSpecialCleanContent(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateSpecialCleanContent(index).message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateSpecialCleanUnitName(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateSpecialCleanUnitName(index).message, 'OK')
            return false
          }
        }
      }
      
      // ラウンドサブメニュー
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationCleanCategory.indexOf('ラウンドサブメニュー') != -1) {
        for (let index = 0; index < this.requestPost.roundSubs.length; index++) {
          if (!isTemporarySave && !this.validateRoundSub(index, 'roundSubContent').result) {
            await dialogs.showInfoDialog('入力確認', this.validateRoundSub(index, 'roundSubContent').message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateRoundSub(index, 'roundSubCount').result) {
            await dialogs.showInfoDialog('入力確認', this.validateRoundSub(index, 'roundSubCount').message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateRoundSub(index, 'roundSubUnitName').result) {
            await dialogs.showInfoDialog('入力確認', this.validateRoundSub(index, 'roundSubUnitName').message, 'OK')
            return false
          }
        }
      }

      // その他作業
      if(this.requestPost.category.indexOf('見積依頼') != -1 && this.requestPost.estimationOtherCategory.indexOf('その他作業') != -1) {
        for (let index = 0; index < this.requestPost.otherWorks.length; index++) {
          if (!isTemporarySave && !this.validateOtherWorkContent(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateOtherWorkContent(index).message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateOtherWorkCount(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateOtherWorkCount(index).message, 'OK')
            return false
          }
          if (!isTemporarySave && !this.validateOtherWorkUnitName(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateOtherWorkUnitName(index).message, 'OK')
            return false
          }
        }
      }

      // 受注画面の入力チェック（過去の受注データの場合）
      if (this.isOrder && !this.requestPost.orderNew && this.editMode) {

        // 契約終了日
        if (!this.contractEndDateResult) {
          await dialogs.showInfoDialog('入力確認', this.contractEndDateMessage, 'OK')
          return false
        }
      
        // 区分
        if (!this.classificationResult) {
          await dialogs.showInfoDialog('入力確認', this.classificationMessage, 'OK')
          return false
        }

        // 特別清掃 作業項目
        if(this.requestPost.estimationDetailsCategoryCode.indexOf('14') != -1 && this.requestPost.orderSpecialCleans && this.requestPost.orderSpecialCleans.length > 0) {
          if (!this.specialCleanWorkItemResult) {
            await dialogs.showInfoDialog('入力確認', this.specialCleanWorkItemMessage, 'OK')
            return false
          }
        }
      }

      if (this.requestPost.category.includes('受注処理（新規・金額変更）')) {
        // 新規登録で見積が選択されていない
        if (this.mode != 6 && !Object.keys(this.estimateData).length) {
          await dialogs.showErrorDialog('見積を選択してください', 'どの見積を受注するか選択されていません。')
          return
        }
      }

      // 雇い入れ入力チェック
      if (this.isShowCategoryItem('雇い入れ') && !await this.$refs.EmploymentComponents.validation()) return

      // 面接関連入力チェック
      if(this.requestPost.category.indexOf('面接関連') != -1) {
        // 必須項目入力チェック
        if (!await this.$refs.InterviewsComponents.validation()) {
          return
        }
        //初期生年月日
        const initBirthDate = this.getInitBirthDate()
        //生年月日入力チェック
        if(this.requestPost.interviewsBirthDay == initBirthDate){
          const dialogResult = await dialogs.showConfirmDialog('入力確認', '生年月日が変更されておりませんがよろしいでしょうか？', 'はい', 'いいえ')
          if (dialogResult === 'CANCEL') {
            return false
          } 
        }
      }
      return true
    },

    /**
     * 新受注データの登録チェック処理
     */
    async orderNewValidate() {
      if (!this.requestPost.contractDate) {
        await dialogs.showInfoDialog('入力確認', '契約開始日を選択してください。', 'OK')
        return false
      }

      if (!this.requestPost.contractEndDate) {
        await dialogs.showInfoDialog('入力確認', '契約終了日を選択してください。', 'OK')
        return false
      }

      if (Number(this.requestPost.contractDate.replace(/-/g, '')) > Number(this.requestPost.contractEndDate.replace(/-/g, ''))) {
        await dialogs.showInfoDialog('入力確認', '契約日が不正です。', 'OK')
        return false
      }

      if (!this.requestPost.classification || this.requestPost.classification.length == 0 || this.requestPost.classification[0] == '2') {
        await dialogs.showInfoDialog('入力確認', '区分を選択してください。', 'OK')
        return false
      }

      // 特別清掃がある場合
      if (this.requestPost.orderSpecialCleans && this.requestPost.orderSpecialCleans.length) {
        for (let i = 0; i < this.requestPost.orderSpecialCleans.length; i++) {
          let sp = this.requestPost.orderSpecialCleans[i]
          for (let j = 0; j < sp.details.length; j++) {
            const det = sp.details[j]
            // 調整費以外で作業項目を選択していない場合は、アラートを出す
            if (det.content.indexOf('調整費') == -1 && det.workItem == null) {
              await dialogs.showInfoDialog('入力確認', '特別清掃 【' + det.content +'】の作業項目は必須入力です。業務項目を入力してください。', 'OK')
              return false
            }
          }
        }
      }

      // テンプレート未反映の場合
      if (!this.requestPost.template) {
        const log = await dialogs.showConfirmDialog('配信テンプレート', '配信テンプレートが未反映です。よろしいでしょうか？')
        if (log != 'YES') {
          return false
        }
      }
      return true
    },

    /**
     * 担当者選択イベント
     */
    selectedStaffName(e) {
      this.$nextTick(function() {
        this.requestPost.toStaffName = e.userName
      })
      if (e.userId) {
        this.requestPost.toStaffId = e.userId
      } else {
        this.requestPost.toStaffId = ''
      }

      // 担当者を選択した場合、担当者の部署名をアポロ管財依頼先部署にセット
      if (this.requestPost.category.includes('デンタツ') || this.requestPost.category.includes('AKBS休み登録')) {
        // デンタツの場合
        if (e.transDepId) {
          let transDepartmentList = this.$store.getters.transDepartmentList
          let dep = transDepartmentList.find((t) => {
            return t.departmentId == e.transDepId
          })
          this.requestPost.toDepartment = dep.name
          this.requestPost.toDepartmentId = e.transDepId
          this.getStaffNameList(e.transDepId, false)
        }
      } else {
        // デンタツ以外の場合
        if (e.departmentId) {
          let departmentList = this.$store.getters.departmentList
          let dep = departmentList.find((d) => {
            return d.departmentId == e.departmentId
          })
          this.requestPost.toDepartment = dep.name
          this.requestPost.toDepartmentId = e.departmentId
          this.getStaffNameList(e.departmentId, false)
        }
      }
    },

    /**
     * 担当者変更イベント
     */
    changeStaffName() {
      if (this.requestPost.toStaffName == '') {
        this.requestPost.toStaffId = ''
      }
    },

    /**
     * 担当者（非公開依頼）変更イベント
     */
     changeToStaff(e) {
      if (e) {
        this.requestPost.toStaffId = e.value
        this.requestPost.toStaffName = e.text
      }

      // 担当者選択 → 部署指定
      const staff = this.toStaffNameList.find(user => user.userId == this.requestPost.toStaffId);
      if (staff && staff.departmentId) {
        let departmentList = this.$store.getters.departmentList
        let dep = departmentList.find((d) => {
          return d.departmentId == staff.departmentId
        })
        if (dep) {
          this.requestPost.toDepartment = dep.name
          this.requestPost.toDepartmentId = staff.departmentId
          this.getStaffNameList(this.requestPost.toDepartmentId, false)
        }
      }
    },
    
    /**
     * 依頼主（非公開依頼）変更イベント
     */
     changeByStaff(e) {
      if (e) {
        this.requestPost.byUserId = e.value
        this.requestPost.byUser = e.text
      }
    },

    /**
     * 担当者から担当者（非公開依頼）へ反映
     */
    staffNameToToStaff() {
      if (this.requestPost && this.requestPost.toStaffId) {
        let val = this.toStaffNameList.find(st => st.userId == this.requestPost.toStaffId)
        if (val) {
          this.selectedStaffValue = {
            value: val.userId,
            text: val.userName
          }
        }
      } else {
        this.selectedStaffValue = null
      }
    },

    /**
     * 担当者（非公開依頼）から担当者へ反映
     */
     toStaffToStaffName() {
      if (this.selectedStaffValue && this.selectedStaffValue.value) {
        let val = this.toStaffNameList.find(st => st.userId == this.selectedStaffValue.value)
        if (val) {
          this.requestPost.toStaffId = val.userId
          this.requestPost.toStaffName = val.userName
        }
      } else {
        this.requestPost.toStaffId = ''
        this.requestPost.toStaffName = ''
      }
    },

    /**
     * 依頼者から依頼者（非公開依頼）へ反映
     */
     byUserToSByUser() {
      if (this.requestPost && this.requestPost.byUserId) {
        let val = this.$store.getters.userList.find(st => st.id == this.requestPost.byUserId)
        if (val) {
          this.selectedByUserValue = {
            value: val.id,
            text: val.user_name
          }
        }
      } else {
        this.selectedByUserValue = null
      }
    },

    /**
     * 依頼者（非公開依頼）から依頼者へ反映
     */
     sByUserToByUser() {
      if (this.selectedByUserValue && this.selectedByUserValue.value) {
        let val = this.$store.getters.userList.find(st => st.id == this.selectedByUserValue.value)
        if (val) {
          this.requestPost.byUser = val.user_name
          this.requestPost.byUserId = val.id
        }
      } else {
        this.requestPost.byUser = ''
        this.requestPost.byUserId = ''
      }
    },

    /**
     * 依頼主プルダウン 表示
     */
    async byUserSelected(selectedUser) {
      this.requestPost.byUserId = selectedUser.id
    },

    byUserNameChanged(byUserName) {
      if (byUserName === '') {
        this.requestPost.byUserId = ''
      }
    },

    /**
     * アポロ管財依頼先部署選択イベント
     */
    toDepartmentSelected(value) {
      if(value.departmentId){
        // 所属部署データ取得
        this.getStaffNameList(value.departmentId, true)
        this.requestPost.toDepartmentId = value.departmentId
        this.departmentInfo = value
      } else {
        this.toStaffNameList = []
        this.toStaffSelections = []
      }
    },

    /**
     * アポロ管財依頼先部署 変更イベント
     */
    toDepartmentChanged() {
      if (this.requestPost.toDepartment == '') {
        this.requestPost.toDepartmentId = ''
        this.getStaffNameList()
      }
    },

    /**
     * 添付ファイル
     */
    attachmentChange(targetAttachmentName, attachmentInfo) {
      if (!this.requestPost[targetAttachmentName]) {
        this.requestPost[targetAttachmentName] = helper.createEmptyAttachment()
      }
      if (attachmentInfo) {
        this.requestPost[targetAttachmentName].originalName = attachmentInfo.filename
        this.requestPost[targetAttachmentName].content = attachmentInfo.content
      } else {
        this.requestPost[targetAttachmentName] = helper.createEmptyAttachment()
      }
    },

    /** 
     * お問い合わせカテゴリー 表示フラグ
     */
    isShowCategoryItem(category) {
      if (this.requestPost.category && this.requestPost.category.length > 0) {
        if (category == '見積依頼') {
          this.estimateFlag = this.requestPost.category.indexOf(category) > -1
        }
        return this.requestPost.category.indexOf(category) > -1
      } else {
        this.estimateFlag = false
        return false
      }
    },

    /** 
     * 添付資料追加 表示フラグ
     */
    isShowAddFileBtnItem(category) {
      if (this.requestPost.addFileBtn) {
        return this.requestPost.addFileBtn.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 御見積_清掃カテゴリー 表示フラグ
     */
    isShowEstimationCleanCategoryItem(category) {
      if (this.requestPost.estimationCleanCategory && this.requestPost.estimationCleanCategory.length > 0) {
        return this.requestPost.estimationCleanCategory.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 日常清掃 祝日対応の有無
     */
    isEverydayHolidayFlag(index) {
      if (this.requestPost.everydayCleans[index].everydayHolidayFlag && this.requestPost.everydayCleans[index].everydayHolidayFlag.length > 0) {
        return (this.requestPost.everydayCleans[index].everydayHolidayFlag.indexOf('有') > -1 || this.requestPost.everydayCleans[index].everydayHolidayFlag.indexOf('不明') > -1)
      } else {
        return false
      }
    },

    /** 
     * 管理業務 祝日対応の有無
     */
    isManagerWorkHolidayFlag(index) {
      if (this.requestPost.managerWorks[index].managerWorkHolidayFlag && this.requestPost.managerWorks[index].managerWorkHolidayFlag.length > 0) {
        return (this.requestPost.managerWorks[index].managerWorkHolidayFlag.indexOf('有') > -1 || this.requestPost.managerWorks[index].managerWorkHolidayFlag.indexOf('不明') > -1)
      } else {
        return false
      }
    },

    /** 
     * 作業時間の指定の有無 表示フラグ
     */
    isShowEverydayStartTimeFlagItem(category, index) {
      if (this.requestPost.everydayCleans[index].everydayStartTimeFlag && this.requestPost.everydayCleans[index].everydayStartTimeFlag.length > 0) {
        return this.requestPost.everydayCleans[index].everydayStartTimeFlag.indexOf(category) > -1
      } else {
        return false
      }
    },

    isShowManagerWorkTimeFlagItem(category, index) {
      if (this.requestPost.managerWorks[index].managerWorkTimeFlag && this.requestPost.managerWorks[index].managerWorkTimeFlag.length > 0) {
        return this.requestPost.managerWorks[index].managerWorkTimeFlag.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 御見積_点検カテゴリー 表示フラグ
     */
    isShowEstimationInspectCategoryItem(category) {
      if (this.requestPost.estimationInspectCategory && this.requestPost.estimationInspectCategory.length > 0) {
        return this.requestPost.estimationInspectCategory.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 有料トイレ清掃あり/有料トイレ清掃無し 表示フラグ_RLS,RLSプラス
     */
    isShowtToilet(rp, category) {
      if (rp && rp.length > 0) {
        return rp[0].indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 有料トイレ清掃あり/有料トイレ清掃無し 表示フラグ
     */
    isShowtToiletCleaningItem(category, index) {
      if (this.requestPost.everydayCleans[index].toiletCleaning && this.requestPost.everydayCleans[index].toiletCleaning.length > 0) {
        return this.requestPost.everydayCleans[index].toiletCleaning.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 御見積_その他カテゴリー 表示されているかのチェック
     */
    isShowEstimationOtherCategoryItem(category) {
      if (this.requestPost.estimationOtherCategory && this.requestPost.estimationOtherCategory.length > 0) {
        return this.requestPost.estimationOtherCategory.indexOf(category) > -1
      } else {
        return false
      }
    },

    /**
     * 戸数 変更イベント 
     */
    houseHoldsChange() {
      if (this.requestPost.category2[0] && this.requestPost.category2[0] == 'お客様直') {
        // 清掃希望回数をクリア
        this.requestPost.cleanCount = []
        this.requestPost.plusCleanCount = []
        // ゴミ搬出をクリア
        this.requestPost.dustRemoval = []
        this.requestPost.plusDustRemoval = []
        this.requestPost.dustCount = []
        this.requestPost.plusDustCount = []
      }
    },

    /**
     * 得意先モーダル検索イベント
     */
    openClientModal() {
      this.dataSelectorShow = true
    },

    /**
     * 「得意先の選択」モーダルでの得意先選択イベント
     */
    modalClientSelected(target) {
      this.dataSelectorShow = false
      this.clientSelected(target)
    },

    /**
     * 得意先選択イベント
     */
    async clientSelected(selectedClient) {
      // 選択したお客様情報を表示
      this.requestPost.clientName = selectedClient.clientName + ' ' + selectedClient.clientDepartmentName

      // リクエストポストのデータにお客様コードを追加
      this.requestPost.clientCode = selectedClient.clientCode

      // 住所
      this.requestPost.clientAddress1 = selectedClient.address1
      this.requestPost.clientAddress2 = selectedClient.address2
      
      // 得意先に紐づく物件を取得
      this.getBuildingListByClient()
    },

    /**
     * 得意先変更イベント
     * @param clientName 入力値
     */
    clientNameChanged(clientName) {
      if (clientName === '') {
        this.requestPost.clientName = ''
        this.requestPost.clientCode = ''
        this.requestPost.clientAddress1 = ''
        this.requestPost.clientAddress2 = ''
      }
    },
    
    /**
     * 郵便番号より住所検索
     */
    addressSearch(){
      var geocoder = new window.google.maps.Geocoder()
      var pattern = /^[0-9]{3}-?[0-9]{4}$/

      if(this.requestPost.postalCode) {
        var postalCode = this.requestPost.postalCode
        if (postalCode) {
          //入力値が正しいか
          if (pattern.test(postalCode)) {
            //住所を取得
            geocoder.geocode ({
              address:postalCode,
              language:"ja",
              region:"jp" 
            }, this.addressSearchCb)
          } else {
            alert("入力が3桁-4桁の形式であるか確認してください。")
          }
        } else {
          alert("郵便番号を入力をしてください。")
        }
      }
    },

    /**
     * 郵便番号より住所検索 CallBack
     */
    addressSearchCb(results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results.length > 0){
          if (results[0].geometry) {
            if(results[0].address_components[4].long_name == '日本') {
              this.requestPost.state = results[0].address_components[3].long_name
              this.requestPost.city = results[0].address_components[2].long_name
              this.requestPost.street = results[0].address_components[1].long_name
            } else {
              this.requestPost.state = results[0].address_components[4].long_name
              this.requestPost.city = results[0].address_components[3].long_name + results[0].address_components[2].long_name
              this.requestPost.street = results[0].address_components[1].long_name
            }
          }
        }
      } else {
        if (status == window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          alert("郵便番号 " + this.requestPost.postalCode + "は見つかりませんでした。")
        } else {
          alert("システムの管理者にお問い合わせください。ステータスコード：" + status)
        }
      }
    },

    /**
     * 住所より郵便番号検索
     */
    postalCodeSearch(){
      var geocoder = new window.google.maps.Geocoder()

      var state = this.requestPost.state
      var city = this.requestPost.city
      var street = this.requestPost.street

      if (state && city && street) {
        var unionAddress = state + city + street
        geocoder.geocode({
          address:unionAddress,
          language:"ja",
          region:"jp"
        }, this.postalCodeSearchCb)
      } else {
        if (state && city){
          alert("町名・番地を入力してください")
        } else if (state) {
          alert("市区郡,町名・番地を入力してください")
        } else if (street && city){
          alert("都道府県を入力してください")
        } else if (street) {
          alert("都道府県,市区郡を入力してください")
        } else if (city) {
          alert("都道府県,町名・番地を入力してください")
        } else {
          alert("都道府県,市区郡,町名・番地を入力してください")
        }
      }
    },

    /**
     * 住所より郵便番号検索 CallBack
     */
    postalCodeSearchCb(results, status) {
      //アラート用フラグ
      var flag = false
      var ret = this.requestPost

      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          if (results[0].geometry) {
            var result = results[0].address_components;
            result.forEach(function(returnData) {
              returnData.types.forEach(function(getType) {
                if (getType == 'postal_code') {
                  ret.postalCode = returnData.long_name
                  flag = true
                }
              })
            })
          } 
        }
        if (flag == false) {
          alert("郵便番号が見つかりませんでした。")
        }
      } else {
        if (status == window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          alert(this.requestPost.state + this.requestPost.city + this.requestPost.street + "の郵便番号は見つかりませんでした。")
        } else {
          alert("システムの管理者にお問い合わせください。ステータスコード：" + status)
        }
      }
    },

    /**
     * 見積依頼の選択を外した際に、非表示なる項目の中を初期値に戻す
     */
    estimateItemRefresh() {

      if (!this.estimateFlag) {
        this.requestPost.newNowFlag = []
        this.requestPost.newClass = []
        this.requestPost.estimationCleanCategory = []
        this.requestPost.estimationInspectCategory = []
        this.requestPost.estimationOtherCategory = []
        this.requestPost.buildingName = ''
        this.requestPost.stairs = ''
        this.requestPost.households = ''
        this.requestPost.estimationReason = ''
        this.requestPost.availablePowerFlag = []
        this.requestPost.apartmentType = []
        this.requestPost.parkingFlag = []
        this.requestPost.availableToiletFlag = []
        this.requestPost.cleanCount = []
        this.requestPost.dustRemoval = []
        this.requestPost.dustCount = []
        this.requestPost.rlsToiletCleaning = []
        this.requestPost.rlsToiletTimes = ''
        this.requestPost.rlsToiletPlace = ''
        this.requestPost.plusCleanCount = []
        this.requestPost.plusToiletCleaning = []
        this.requestPost.plusToiletTimes = ''
        this.requestPost.plusToiletPlace = ''
        this.requestPost.plusCleanCount = []
        this.requestPost.plusDustRemoval = []
        this.requestPost.plusDustCount = []
        this.requestPost.residenceType = []
        this.requestPost.everydayCleans = []
        this.requestPost.managerWorks = []
        this.requestPost.regularCleans = []
        this.requestPost.regularCleanImage = {}
        this.requestPost.regularCleanImage2 = {}
        this.requestPost.regularCleanImage3 = {}
        this.requestPost.regularCleanWorkWay = ''
        this.requestPost.glassCleanCount = ''
        this.requestPost.glassCleanImage = {}
        this.requestPost.glassCleanImage2 = {}
        this.requestPost.glassCleanImage3 = {}
        this.requestPost.glassCleanWorkWay = []
        this.requestPost.plantingCount = ''
        this.requestPost.plantingPlaceFile = {}
        this.requestPost.plantingPlaceFile2 = {}
        this.requestPost.plantingPlaceFile3 = {}
        this.requestPost.plantingReportFile = {}
        this.requestPost.plantingReportFile2 = {}
        this.requestPost.plantingReportFile3 = {}
        this.requestPost.specialCleans = []
        this.requestPost.specialCleanImage = {}
        this.requestPost.specialCleanImage2 = {}
        this.requestPost.specialCleanImage3 = {}
        this.requestPost.roundSubs = []
        this.requestPost.fireInspectReport = {}
        this.requestPost.fireInspectReport2 = {}
        this.requestPost.fireInspectReport3 = {}
        this.requestPost.constructionEquipmentInspectReport = {}
        this.requestPost.constructionEquipmentInspectReport2 = {}
        this.requestPost.constructionEquipmentInspectReport3 = {}
        this.requestPost.specificInspectReport = {}
        this.requestPost.specificInspectReport2 = {}
        this.requestPost.specificInspectReport3 = {}
        this.requestPost.evInspectContent = []
        this.requestPost.evInspectFile = {}
        this.requestPost.evInspectFile2 = {}
        this.requestPost.evInspectFile3 = {}
        this.requestPost.evInspectMaker = ''
        this.requestPost.evInspectMakeYear = ''
        this.requestPost.postalCode = ''
        this.requestPost.state = ''
        this.requestPost.city = ''
        this.requestPost.street = ''
        this.requestPost.building = ''
        this.requestPost.otherWorks = []
        this.requestPost.roundServiceMemo = ''
        this.requestPost.roundPlusMemo = ''
        this.requestPost.everydayCleanMemo = ''
        this.requestPost.managerWorkMemo = ''
        this.requestPost.regularCleanMemo = ''
        this.requestPost.glassCleanMemo = ''
        this.requestPost.estimationDorainPipeMemo = ''
        this.requestPost.estimationWaterTankMemo = ''
        this.requestPost.plantingMemo = ''
        this.requestPost.specialCleanMemo = ''
        this.requestPost.roundSubMemo = ''
      }
    },

    /**
     * カテゴリ２ change イベント
     */
    category2Changed() {
      // 社内の場合は、お客様項目必須解除
      this.isClientRequired = (this.requestPost.category2 != '社内')
    },

    /**
     * カテゴリ change イベント
     */
    async categoryChanged(category, isOperation) {
      if (category.includes('受注処理（新規・金額変更）')) {
        // 受注以外に他のカテゴリーを選択した場合、他のカテゴリーを消す
        if (category.length > 1) {
          await dialogs.showErrorDialog('受注処理を選択しています。', '受注処理を選択した場合は、他のカテゴリーは選択できません。')
          this.requestPost.category = ['受注処理（新規・金額変更）']
        }
        // 受注処理
        this.setOrderData()

      // 受注にしていたが、他のカテゴリに変更した場合
      } else if (this.isOrder) {
        this.requestPost = requestPostManager.create()
        this.estimateData = {}
        // 担当者・依頼主にログインユーザーをセット
        this.setDefaultStaff()
        this.isOrder = false
        this.isCategory = true
        this.isClientRequired = true
        // スタッフリスト生成
        this.getStaffNameList(this.requestPost.toDepartmentId, false)
      } else if (category.includes('雇い入れ')) {
        // 雇い入れ以外に他のカテゴリーを選択した場合、他のカテゴリーを消す
        if (category.length > 1) {
          await dialogs.showErrorDialog('雇い入れを選択しています。', '雇い入れを選択した場合は、他のカテゴリーは選択できません。')
          this.requestPost.category = ['雇い入れ']
        }
        // 雇い入れダイアログ
        if (isOperation) this.EmploymentData()
      }

      if (isOperation) {
        this.setTitle()
      }
      
      if (!this.isOrder) {
        if (category.includes('デンタツ') || category.includes('AKBS休み登録')) {
          if (category.includes('デンタツ')) {
            this.isTransmission = true
          } else {
            this.isTransmission = false
          }
          
          // 担当者、アポロ管財依頼先部署をクリア
          if (isOperation) {
            this.requestPost.toStaffName = ''
            this.requestPost.toStaffId = ''
            this.requestPost.toDepartment = ''
            this.requestPost.toDepartmentId = ''
          }
        } else {
          // デンタツを選択していたが、他のカテゴリに変更した場合、担当者・アポロ管財依頼先部署をクリア
          if (this.isTransmission) {
            this.requestPost.toStaffName = ''
            this.requestPost.toStaffId = ''
            this.requestPost.toDepartment = ''
            this.requestPost.toDepartmentId = ''
          }
          this.isTransmission = false

          // if (category.includes('雇い入れ')) {
          //   this.isCategory = false
          // }
        }
        // 社内/お客様、タイトル、お客様項目の必須設定
        this.isOutsideRequired = (this.requestPost.category != 'デンタツ')

        // デンタツの場合、アポロ管財依頼先部署はデンタツ用部署のみ表示
        // デンタツ以外、アポロ管財依頼先部署はデンタツ用部署は非表示
        let filterDepartmentList
        if (this.requestPost.category.includes('デンタツ') || this.requestPost.category.includes('AKBS休み登録')) {
          // デンタツ部署のリスト取得
          filterDepartmentList = this.$store.getters.transDepartmentList
        } else {
          // デンタツ以外の部署のリスト取得
          filterDepartmentList = this.$store.getters.departmentList
        }
        this.toDepartmentList = filterDepartmentList
        // 担当者リスト 生成
        this.getStaffNameList()
      }

      // 見積依頼の場合、社内/お客様は「お客様」を選択
      if (isOperation) {
        if (category.includes('見積依頼') && !this.forCustomers) {
          this.requestPost.category2 = ['お客様']
          this.category2Changed()
        }
      }

      // 「非公開依頼」の場合は、Suggest OFF
      this.isStaffSuggest = !this.requestPost.category.includes('非公開依頼')
      
      // 選択担当者連携
      if (isOperation) {
        if (this.requestPost.category.includes('非公開依頼')) {
          this.staffNameToToStaff()
          this.byUserToSByUser()
        } else {
          this.toStaffToStaffName()
          this.sByUserToByUser()
        }
      }

      if (category.includes('面接関連')){
        if(!this.requestPost.interviewsBirthDay || this.requestPost.interviewsBirthDay == ''){
          this.requestPost.interviewsBirthDay = this.getInitBirthDate()
        }
      } else {
        this.requestPost.interviewsBirthDay = ''
        this.requestPost.interviewsAge = ''
      }

      // 見積り依頼の場合、物件リストを取得
      if (category.includes('見積依頼')) {
        await this.getBuildingList()
      }
    },

    /**
     * 物件リストを取得
     */
    async getBuildingList() {
      this.buildingListByClient = []
      this.allBuildingList = []
      let res = await backend.searchData('akbsTable/getSiteListForEstimate')
      if (res.data && res.data.data && res.data.data.length) {
        this.allBuildingList = res.data.data
      }
      // 得意先に紐づく物件を取得
      this.getBuildingListByClient()
    },

    /**
     * 得意先に紐づく物件を取得
     */
    getBuildingListByClient() {
      this.buildingListByClient = []
      if (this.allBuildingList && this.allBuildingList.length) {
        if (this.requestPost.clientCode) {
          this.buildingListByClient = this.allBuildingList.filter((all) => {
            return all.clientCode == this.requestPost.clientCode
          })
        } else {
          this.buildingListByClient = utils.clone(this.allBuildingList)
        }
      }
    },

    /**
     * 物件選択モーダルopen
     */
    openSiteModal() {
      this.siteModal = true
    },

    /**
     * 物件選択
     * @param e 選択したデータ
     */
    siteSelected(e) {
      this.requestPost.siteCode = e.siteCode
      this.requestPost.buildingName = e.site1 + ' ' + e.site2
      this.siteNameChanged()
      this.requestPost.postalCode = e.zip

      let state = ''
      if (e.address1.indexOf('東京都') > -1) {
        state = '東京都'
      } else if (e.address1.indexOf('県') > -1) {
        state = e.address1.substr(0, e.address1.indexOf('県') + 1)
      }
      this.requestPost.state = state
      this.requestPost.city = e.address1.replace(state, '')
      this.requestPost.street = e.address2

      this.siteModal = false
    },

    /**
     * 建物名 change イベント
     */
    siteNameChanged() {
      this.requestPost.building = this.requestPost.buildingName
      this.setTitle()

      if (!this.requestPost.buildingName) {
        this.requestPost.siteCode = ''
      }
    },

    /**
     * 住所の建物名 change イベント
     */
    buildingChanged() {
      this.requestPost.buildingName = this.requestPost.building
      this.setTitle()
    },

    /**
     * タイトル設定
     */
    setTitle() {
      if (!this.isOrder) {
        var categoryStr = ''
        if(this.requestPost.category.indexOf('見積依頼') > -1) {
          var newCategory = this.requestPost.category.filter(function(value) {
            return (value == '見積依頼')
          })
          categoryStr = newCategory.join()
        }

        var cepa = ''
        if (this.requestPost.buildingName && this.requestPost.buildingName.length > 0
          && categoryStr && categoryStr.length > 0) cepa = ' '

        this.requestPost.inquiry_title = this.requestPost.buildingName + cepa + categoryStr
      }
    },

    /**
     * 契約無/契約有 Changed イベント
     */
    newNowFlagChanged() {
      // 契約有の場合、既存固定
      if (this.requestPost.newNowFlag && this.requestPost.newNowFlag.indexOf('契約有') > -1) {
        // 「既存」を選択して、
        this.requestPost.newClass = ['既存']
        this.isDisabledNewClass = true
      } else {
        this.isDisabledNewClass = false
      }
      this.setBuildingDetailsRequired()
      this.setBuildingEquipmentRequired()
    },

    /**
     * 清掃回数（RLS）変更イベント
     */
    changeCleanCountRls(rp) {
      this.dustCaptionRls = 'ゴミ搬出有無'
      if (this.requestPost.category2[0] && this.requestPost.category2[0] == 'お客様直' && rp.length && rp[0]) {
        if (['月 1 回', '週 1 回', '週 2 回'].includes(rp[0])) {
          if (this.isShowEstimationCleanCategoryItem('ラウンドサービス')) {
            this.requestPost.dustRemoval = []
            this.requestPost.dustCount = []
            this.requestPost.dustRemoval[0] = '無'
            this.dustCaptionRls = 'ゴミ搬出有無（清掃回数が「' + rp[0] + '」の場合はゴミ搬出はありません。）'
          }
        }
      }
    },

    /**
     * 清掃回数（RLSプラス）変更イベント
     */
    changeCleanCountPlus(rp) {
      this.dustCaptionPlus = 'ゴミ搬出有無'
      if (this.requestPost.category2[0] && this.requestPost.category2[0] == 'お客様直' && rp.length && rp[0]) {
        if (['月 1 回', '週 1 回', '週 2 回'].includes(rp[0])) {
          if (this.isShowEstimationCleanCategoryItem('ラウンドプラス')) {
            this.requestPost.plusDustRemoval = []
            this.requestPost.plusDustCount = []
            this.requestPost.plusDustRemoval[0] = '無'
            this.dustCaptionPlus = 'ゴミ搬出有無（清掃回数が「' + rp[0] + '」の場合はゴミ搬出はありません。）'
          }
        }
      }
    },

    /**
     * 特別清掃追加ボタン
     */
    addSpecialClean() {
      let specialClean = {
        specialCleanCount: '',
        specialCleanContentId: '',
        specialCleanContent: '',
        specialCleanUnitName: ''
      }
      this.requestPost.specialCleans.push(specialClean)
    },

    /**
     * 特別清掃削除ボタン
     */
    removeSpecialClean(idx) {
      if (this.requestPost.specialCleans.length == 1) {
        this.requestPost.specialCleans[0].specialCleanCount = ''
        this.requestPost.specialCleans[0].specialCleanContentId = ''
        this.requestPost.specialCleans[0].specialCleanContent = ''
        this.requestPost.specialCleans[0].specialCleanUnitName = ''
        return
        }
      this.requestPost.specialCleans.splice(idx, 1)
    },

    /**
     * ラベル表示用index変換
     */
    outputIndex(index) {
      let idxNumber = String(index + 1)
      const zenkaku = utils.zenkaku(idxNumber)
      return zenkaku
    },

    /**
     * 特別清掃 選択イベント
     * @index 番号
     * @e 選択内容
     */
    selectedSp(index, e) {
      let target = this.requestPost.specialCleans[index]
      target.specialCleanContentId = e.id
    },

    /**
     * 特別清掃 変更イベント
     * @index 番号
     */
    changeSp(index) {
      let target = this.requestPost.specialCleans[index]
      if (target.specialCleanContent == '') {
        target.specialCleanContentId = ''
      }
    },
    
    /**
     * ラウンドサブ追加ボタン
     */
    addRoundSub() {
      let r = {
        roundSubCount: '',
        roundSubContent: '',
        roundSubUnitName: ''
      }
      this.requestPost.roundSubs.push(r)
    },

    /**
     * 定期清掃追加ボタン
     */
    addRegularClean() {
      let regularClean = {
        regularCleanCount: '',
        regularCleanContent: '',
        regularCleanUnitName: ''
      }
      this.requestPost.regularCleans.push(regularClean)
      
      for (let index = 0; index < this.requestPost.regularCleans.length; index++) {
        // 定期清掃１のみの場合、依頼内容の初期値は「定期清掃」
        if (!this.requestPost.regularCleans[index].regularCleanContent && this.requestPost.regularCleans.length == 1) {
          this.requestPost.regularCleans[index].regularCleanContent = '定期清掃'
        // プラスボタンで定期清掃を増やした場合、依頼内容の初期値は「定期清掃 + 数字」
        } else if (!this.requestPost.regularCleans[index].regularCleanContent && this.requestPost.regularCleans.length > 1) {
          if (this.requestPost.regularCleans[0] && this.requestPost.regularCleans[0].regularCleanContent == '定期清掃') {
            let number = this.outputIndex(0)
            this.requestPost.regularCleans[0].regularCleanContent = '定期清掃' + number
          }
          let indexNumber = this.outputIndex(index)
          this.requestPost.regularCleans[index].regularCleanContent = '定期清掃' + indexNumber
        }
      }
    },

    /**
     * ラウンドサブメニュー削除ボタン
     */
    removeRoundSub(idx) {
      // 複写の場合
      if (this.$route.query.mode == 3) {
        if (this.copySubs && this.copySubs[idx]) {
          this.copySubs.splice(idx, 1)
        }
      }

      if (this.requestPost.roundSubs.length == 1) {
        this.requestPost.roundSubs[0].roundSubCount = ''
        this.requestPost.roundSubs[0].roundSubContent = ''
        this.requestPost.roundSubs[0].roundSubUnitName = ''
        return
      }
      this.requestPost.roundSubs.splice(idx, 1)
    },

    /**
     * 定期清掃削除ボタン
     */
    removeRegularClean(idx) {
      if (this.requestPost.regularCleans.length == 1) {
        this.requestPost.regularCleans[0].regularCleanCount = ''
        this.requestPost.regularCleans[0].regularCleanContent = '定期清掃'
        this.requestPost.regularCleans[0].regularCleanUnitName = ''
        return
      }
      this.requestPost.regularCleans.splice(idx, 1)
    },

    /**
     * その他作業追加ボタン
     */
    addOtherWork() {
      let otherWork = {
        otherWorkContent: '',
        otherWorkCount: '',
        otherWorkUnitName: '',
      }
      this.requestPost.otherWorks.push(otherWork)
    },

    /**
     * その他作業削除ボタン
     */
    removeOtherWork(idx) {
      // 複写の場合
      if (this.$route.query.mode == 3) {
        if (this.copyOther && this.copyOther[idx]) {
          this.copyOther.splice(idx, 1)
        }
      }

      if (this.requestPost.otherWorks.length == 1) {
        this.requestPost.otherWorks[0].otherWorkContent = ''
        this.requestPost.otherWorks[0].otherWorkCount = ''
        this.requestPost.otherWorks[0].otherWorkUnitName = ''
        return
      }
      this.requestPost.otherWorks.splice(idx, 1)
    },

    /**
     * 御見積_その他カテゴリー change イベント
     */
    estimationOtherCategoryChanged() {
      this.setBuildingDetailsRequired()
      this.setBuildingEquipmentRequired()
    },

    /**
     * 新規区分 change イベント
     */
    newClassChanged() {
      this.setBuildingDetailsRequired()
      this.setBuildingEquipmentRequired()
    },

    /**
     * 建物詳細 必須設定
     */
    setBuildingDetailsRequired() {
      if ((this.requestPost.estimationOtherCategory == 'その他作業' && this.requestPost.newNowFlag == '契約有' && this.requestPost.newClass == '既存')
        || (this.requestPost.newNowFlag == '契約有' && this.requestPost.newClass == '既存' && !this.forCustomers)) {
        this.isBuildingDetailsRequired = false
      } else {
        this.isBuildingDetailsRequired = true
      }
    },

    /**
     * 建物設備 必須設定
     */
     setBuildingEquipmentRequired() {
      if (this.requestPost.estimationOtherCategory == 'その他作業' && this.requestPost.newNowFlag == '契約有' && this.requestPost.newClass == '既存') {
        this.isBuildingEquipmentRequired = false
      } else {
        this.isBuildingEquipmentRequired = true
      }
    },

    /**
     * 日常清掃追加ボタン
     */
    addEverydayClean() {
      let everydayClean = {
        everydayCleanCount: '',
        everydayCleanHours: '',
        everydayStartTimeFlag: [],
        everydayStartTime: '',
        everydayEndTime: '',
        everydayHolidayFlag: [],
        everydayHoliday: [],
        toiletCleaning: [],
        toiletTimes: '',
        toiletPlace: ''
      }
      this.requestPost.everydayCleans.push(everydayClean)
    },

    /**
     * 日常清掃削除ボタン
     */
     removeEverydayClean(idx) {
      if (this.requestPost.everydayCleans.length == 1) {
        this.requestPost.everydayCleans[0].everydayCleanCount = ''
        this.requestPost.everydayCleans[0].everydayCleanHours = ''
        this.requestPost.everydayCleans[0].everydayStartTimeFlag = []
        this.requestPost.everydayCleans[0].everydayStartTime = ''
        this.requestPost.everydayCleans[0].everydayEndTime = ''
        this.requestPost.everydayCleans[0].everydayHolidayFlag = []
        this.requestPost.everydayCleans[0].everydayHoliday = []
        this.requestPost.everydayCleans[0].toiletCleaning = []
        this.requestPost.everydayCleans[0].toiletTimes = ''
        this.requestPost.everydayCleans[0].toiletPlace = ''
        return
      }
      this.requestPost.everydayCleans.splice(idx, 1)
    },

    /**
     * 管理員業務追加ボタン
     */
     addManagerWork() {
      let ManagerWork = {
        managerWorkCount: '',
        managerWorkHours: '',
        managerWorkTimeFlag: [],
        managerWorkTime: '',
        managerWorkEndTime: '',
        managerWorkHolidayFlag: [],
        managerWorkHoliday: []
      }
      this.requestPost.managerWorks.push(ManagerWork)
    },

    /**
     * 管理員業務削除ボタン
     */
    removeManagerWork(idx) {
      if (this.requestPost.managerWorks.length == 1) {
        this.requestPost.managerWorks[0].managerWorkCount = ''
        this.requestPost.managerWorks[0].managerWorkHours = ''
        this.requestPost.managerWorks[0].managerWorkTimeFlag = []
        this.requestPost.managerWorks[0].managerWorkTime = ''
        this.requestPost.managerWorks[0].managerWorkEndTime = ''
        this.requestPost.managerWorks[0].managerWorkHolidayFlag = []
        this.requestPost.managerWorks[0].managerWorkHoliday = []
        return
      }
      this.requestPost.managerWorks.splice(idx, 1)
    },

  /**
   * 担当者リスト生成
   * @param id 部署ID
   * @param clearSelected 選択済み担当者をクリアするか否か
   */
    getStaffNameList(id, clearSelected) {
      // 部署IDがあれば部署に所属する担当者のリストを生成
      if (id) {
        // 所属部署データ取得
        const ud = this.$store.getters.userDepartment

        // デンタツの場合
        if (this.requestPost.category.includes('デンタツ') || this.requestPost.category.includes('AKBS休み登録')) {
          // アポロ管財依頼先部署で部署を選択した場合は担当者を空にする
          // 編集、コピー、複写の場合は担当者を空にしない
          if (clearSelected) {
            this.requestPost.toStaffName = ''
            this.requestPost.toStaffId = ''
          }

          let trId = ''
          let list2 = []
          let trd = this.$store.getters.transDepartmentList
          let trans = trd.find((t) => {
            return t.departmentId == id
          })
          if (trans.transDepartment) {
            trId = trans.transDepartment
          }
          for (let j = 0; j < ud.length; j++) {
            const u2 =  ud[j]
            if (u2.departmentId == trId && u2.transmission) {
              list2.push(u2)
            }
          }
          list2 = this.createTransStaffNameList(list2)
          this.toStaffNameList = list2
          this.toStaffSelections = list2.map(function(item){
            return {
              value: item.userId,
              text: item.userName
            };
          });

        // デンタツ以外
        } else {
          let list = []
          for (let i = 0; i < ud.length; i++) {
            const u = ud[i]
            if (u.departmentId == id) {
              list.push(u)
            }
          }

          // 受注モードの場合は、担当者「未定」は非表示
          if (this.isOrder) {
            list = list.filter((u) => {
              return u.userName != '未定'
            })
          }
          this.toStaffNameList = list
          this.toStaffSelections = list.map(function(item){
            return {
              value: item.userId,
              text: item.userName
            };
          });
        }
      } else {
        // 部署IDが無ければ、全担当者のリストを生成
        if (!this.isOrder) {
          let staffNameList
          // デンタツの場合
          if (this.requestPost.category.includes('デンタツ') || this.requestPost.category.includes('AKBS休み登録')) {
            staffNameList = this.$store.getters.transUserList
            staffNameList = this.createTransStaffNameList(staffNameList)
          } else {
            // デンタツ以外の場合
            staffNameList = this.$store.getters.userDepartment
            for (let index = 0; index < staffNameList.length; index++) {
              let dep = this.getDepartment(staffNameList[index].departmentId, false)
              staffNameList[index].dispName = dep.name + '　' + staffNameList[index].userName
            }
          }
          this.toStaffNameList = staffNameList
          this.toStaffSelections = staffNameList.map(function(item){
            return {
              value: item.userId,
              text: item.userName
            };
          });
        }
      }
    },

    /**
     * 部署IDを元に担当者が所属する部署を特定
     */
    getDepartment(id, isTrans) {
      let dep
      if (isTrans) {
        let transDepartmentList = this.$store.getters.transDepartmentList
        dep = transDepartmentList.find((d) => {
          return d.transDepartment == id
        })
      } else {
        let departmentList = this.$store.getters.departmentList
        dep = departmentList.find((d) => {
          return d.departmentId == id
        })
      }
      return dep
    },

    /**
     * デンタツ用の担当者リスト 生成
     * @param {*} list 
     */
    createTransStaffNameList (list) {
      for (let index = 0; index < list.length; index++) {
        let dep = this.getDepartment(list[index].departmentId, true)
        if (dep) {
          list[index].dispName = dep.name + '　' + list[index].userName
          list[index].transDepName = dep.name
          list[index].transDepId = dep.departmentId
        } else {
          list[index].dispName = list[index].userName
        }
      }
      return list
    },

    /**
     * 編集・完了できるか確認
     */
    checkCanEdit() {
      // 編集の場合に限る
      if (this.editMode) {
        let name = this.$store.getters.user.user_name
        // ログインユーザー名
        name = utils.deleteSpace(utils.deleteKana(name))

        if (!this.originalRequestPost.category.includes('非公開依頼')) {
          // 非公開依頼でなければ、管理者権限はOK
          const auth = this.$store.getters.auth
          if (auth.adminAuth) {
            return true
          }
        } else {
          // 非公開依頼の場合、特定ユーザーのみOK
          if (PRIVATE_REQUEST_AUTH.includes(this.$store.getters.user.id)) return true
        }

        // 編集できる段階で他の人を割り当てたりする場合に備えて、originalRequestPostのデータで判定

        // ログインユーザーが担当者の場合、OK
        let tanto = utils.deleteSpace(utils.deleteKana(this.originalRequestPost.toStaffName))
        if (name == tanto) {
          return true
        }

        // ログインユーザーが依頼主の場合、OK
        let irai = utils.deleteSpace(utils.deleteKana(this.originalRequestPost.byUser))
        if (name == irai) {
          return true
        }

        // 担当者に関係なく、同じ部署の人はOK
        // 兼任を含めたログインユーザーの前所属部署
        let d = this.$store.getters.dualPosition
        for (let i = 0; i < d.length; i++) {
          const tar = d[i]
          if (tar.departmentName == this.originalRequestPost.toDepartment || tar.departmentId == this.originalRequestPost.toDepartmentId) {
            return true
          }
        }
        // デンタツの場合
        // デンタツの全データ取得
        let resDT = this.$store.getters.transDepartmentList
        // 該当のデンタツ部署のデータを取得
        let dentatsu = resDT.find((r) => {
          return (r.name == this.originalRequestPost.toDepartment || r.departmentId == this.originalRequestPost.toDepartmentId)
        })
        // 該当のデンタツに紐づく部署の人はOK（RLS_デンタツならRLS全員OK）
        if (dentatsu && dentatsu.transDepartment) {
          for (let i = 0; i < d.length; i++) {
            const tar = d[i]
            if (tar.departmentId == dentatsu.transDepartment) {
              return true
            }
          }
        }
        return false
      } else {
        return true
      }
    },

    /**
     * その他作業選択イベント
     */
    selectedOtherWork(i, e) {
      // 防火対象物点検・スポット清掃の場合は、デフォルトで回数、単位を入れる
      if (e.text == '防火対象物点検' || e.text == 'スポット清掃') {
        this.requestPost.otherWorks[i].otherWorkCount = '1'
        this.requestPost.otherWorks[i].otherWorkUnitName = '回/年'
      }
    },

    /**
     * RP、サブ依頼タスク、見積を全てコピー
     * @param key キーとなる項目
     */
    async allCopy(key) {
      // 定期清掃で変更があった場合
      let changeFixed = false
      for (let i = 0; i < this.copyFixed.length; i++) {
        const cf = this.copyFixed[i];
        if (this.requestPost.regularCleans && this.requestPost.regularCleans[i]) {
          if (!_.isEqual(this.requestPost.regularCleans[i], cf)) {
            changeFixed = true
            break
          }
        } else {
          changeFixed = true
        }
      }
      
      // 特別清掃で変更があった場合
      let changeSp = false
      for (let i = 0; i < this.copySp.length; i++) {
        const cs = this.copySp[i];
        if (this.requestPost.specialCleans) {
          if (!_.isEqual(this.requestPost.specialCleans[i], cs)) {
            changeSp = true
            break
          }
        } else {
          changeSp = true
        }
      }
      
      // ラウンドサブで変更があった項目
      let subCode = []
      if (this.requestPost.roundSubs) {
        for (let i = 0; i < this.copySubs.length; i++) {
          const sub = this.copySubs[i];
          let code = sub.roundSubContent

          if (this.requestPost.roundSubs[i]) {
            // 項目名が違う場合は、削除
            if (this.requestPost.roundSubs[i].roundSubContent != sub.roundSubContent) {
              subCode.push({code: code, delete: true})

            // 単位が違う
            } else if (this.requestPost.roundSubs[i].roundSubUnitName != sub.roundSubUnitName) {
              // 同じ項目が複数ある場合は、削除
              if (this.copySubsW.includes(sub.roundSubContent)) {
                subCode.push({code: code, delete: true})

              // 対象項目が1つしかない場合は、単位の書き換え
              } else {
                subCode.push({code: code, delete: false, qty: this.requestPost.roundSubs[i].roundSubCount, uName: this.requestPost.roundSubs[i].roundSubUnitName})
              }

            // 数量が違う
            } else if (this.requestPost.roundSubs[i].roundSubCount != sub.roundSubCount) {
              // 同じ項目が複数ある場合は、削除
              if (this.copySubsW.includes(sub.roundSubContent)) {
                subCode.push({code: code, delete: true})

              // 対象項目が1つしかない場合は、数量の書き換え
              } else {
                subCode.push({code: code, delete: false, qty: this.requestPost.roundSubs[i].roundSubCount, uName: this.requestPost.roundSubs[i].roundSubUnitName})
              }
            }
          } else {
            subCode.push({code: code, delete: true})
          }
        }

        // 項目が追加されている場合、もし、同じ項目が既にあったらその項目は作り直しするため、削除
        if (this.copySubs.length < this.requestPost.roundSubs.length) {
            for (let j = this.copySubs.length; j < this.requestPost.roundSubs.length; j++) {
            subCode.push({code: this.requestPost.roundSubs[j].roundSubContent, delete: true})
          }
        }

        for (let k = 0; k < subCode.length; k++) {
          let sCode = subCode[k].code;
          if (sCode == 'フロントサポート') {
            subCode[k].code = '6'
          } else if (sCode == 'フロントサポートプラス') {
            subCode[k].code = '7'
          } else if (sCode == 'ラウンドトラッシュ') {
            subCode[k].code = '8'
          } else if (sCode == 'クオリティレポート') {
            subCode[k].code = '9'
          }
        }
        
      } else if (this.copySubs.length) {
        subCode = [{code: '6', delete: true}, {code: '7', delete: true}, {code: '8', delete: true}, {code: '9', delete: true}]
      }

      // その他作業で変更があった項目
      let otherCode = []
      if (this.requestPost.otherWorks) {
        for (let i = 0; i < this.copyOther.length; i++) {
          const ot = this.copyOther[i];
          let code = ot.otherWorkContent

          if (this.requestPost.otherWorks[i]) {
            // 項目名が違う場合は、削除
            if (this.requestPost.otherWorks[i].otherWorkContent != ot.otherWorkContent) {
              otherCode.push({code: code, delete: true})

            // 単位が違う
            } else if (this.requestPost.otherWorks[i].otherWorkUnitName != ot.otherWorkUnitName) {
              // 同じ項目が複数ある場合は、削除
              if (this.copyOtherW.includes(ot.otherWorkContent)) {
                otherCode.push({code: code, delete: true})

              // 対象項目が1つしかない場合は、単位の書き換え
              } else {
                otherCode.push({code: code, delete: false, qty: this.requestPost.otherWorks[i].otherWorkCount, uName: this.requestPost.otherWorks[i].otherWorkUnitName})
              }

            // 数量が違う
            } else if (this.requestPost.otherWorks[i].otherWorkCount != ot.otherWorkCount) {
              // 同じ項目が複数ある場合は、削除
              if (this.copyOtherW.includes(ot.otherWorkContent)) {
                otherCode.push({code: code, delete: true})

              // 対象項目が1つしかない場合は、数量の書き換え
              } else {
                otherCode.push({code: code, delete: false, qty: this.requestPost.otherWorks[i].otherWorkCount, uName: this.requestPost.otherWorks[i].otherWorkUnitName})
              }
            }
          } else {
            otherCode.push({code: code, delete: true})
          }
        }

        // 項目が追加されている場合、もし、同じ項目が既にあったらその項目は作り直しするため、削除
        if (this.copyOther.length < this.requestPost.otherWorks.length) {
            for (let j = this.copyOther.length; j < this.requestPost.otherWorks.length; j++) {
            otherCode.push({code: this.requestPost.otherWorks[j].otherWorkContent, delete: true})
          }
        }

        for (let k = 0; k < otherCode.length; k++) {
          let sCode = otherCode[k].code;
          if (sCode == '管球対応') {
            otherCode[k].code = '23'
          } else if (sCode == '粗大ごみ対応') {
            otherCode[k].code = '24'
          } else if (sCode == '物品販売') {
            otherCode[k].code = '25'
          } else if (sCode == '緊急対応') {
            otherCode[k].code = '26'
          } else {
            otherCode[k].code = ''
          }
        }
        
      } else if (this.copyOther.length) {
        otherCode = [{code: '23', delete: true}, {code: '24', delete: true}, {code: '25', delete: true}, {code: '26', delete: true}]
      }

      // タスク
      if (key.id && key.requestNo && this.$route.query.requestPostId) {
        // コピー元に紐づくサブタスク取得
        const tr = await taskRequestManager.getByRequestPostId(this, this.$route.query.requestPostId, null)
        let estimates = []
        if (tr.length) {
          for (let i = 0; i < tr.length; i++) {
            let oldTaskId = tr[i]._id

            delete tr[i]._id
            tr[i].requestPostId = key.id
            tr[i].taskRequestRequestNo = key.requestNo
            // 空のままで通す人がいるので、とにかく日付を入れる
            tr[i].limitDate = this.requestPost.limitDate
            tr[i].status = 'open'
            tr[i].allCopy = true

            let taskNewId = await taskRequestManager.register(this, tr[i])
            if (!taskNewId) {
              await dialogs.showErrorDialog('タスク依頼の複写', 'タスク依頼の複写中にエラーが発生しました。')
              return false
            }

            // 「見積依頼」でなければ見積りの複写はしない
            if (!this.requestPost.category.includes('見積依頼')) {
              continue
            }

            // コピー元に紐づく見積り取得
            let es = await estimateManager.getEstimateByTaskRequestId(this, oldTaskId)
            es.forEach((e) => {
              e.taskRequestId = taskNewId
            })
            estimates = estimates.concat(es)
          }

          // 複写もとに見積もりがあれば、どれを複写するか選択
          if (estimates && estimates.length) {
            this.allCopyEstimateShow = true
            const estimateNos = []
            this.allCopyEstimateParam = {
              estimates,

              // 選択後
              selectCallback: async (selectedEstimates) => {
                this.allCopyEstimateShow = false
                estimates = selectedEstimates

                if (estimates && estimates.length) {  
                  for (let j = 0; j < estimates.length; j++) {
                    let e = estimates[j]
                    e = estimateManager.formatCopy(e)
                    e.requestPostId = key.id
                    e.requestPostName = this.requestPost.inquiry_title
                    // e.taskRequestId = taskNewId
                    e.clientPersonName = this.requestPost.clientPersonName
                    e.subject = this.requestPost.note

                    // 得意先
                    e.clientName = this.requestPost.clientName
                    e.clientPostalCode = this.requestPost.postalCode
                    e.clientAddress1 = this.requestPost.clientAddress1
                    e.clientAddress2 = this.requestPost.clientAddress2
                    // メインにない得意先のデータをマスタから取得
                    if (this.requestPost.clientCode) {
                      const client = await estimateManager.getClientByCode(this.requestPost.clientCode)
                      if (client) {
                        e.clientHonorific = client.honorific
                        e.clientPhoneNumber = client.clientPhoneNumber
                        e.clientFax = client.clientFax
                      }
                    }
                    // 複写フラグ
                    e.allCopy = true
                    // 変更フラグ
                    e.isChangeRegular = changeFixed
                    e.isChangeSp = changeSp
                    // ラウンドサブの変更された内容
                    e.isChangeSubs = subCode
                    // ラウンドサブの変更された内容
                    e.isChangeOther = otherCode

                    let eResult = await estimateManager.registerEstimate(this, e)
                    if (!eResult) {
                      await dialogs.showErrorDialog('見積りの複写', '見積りの複写中にエラーが発生しました。')
                      return
                    } else {
                      estimateNos.push(eResult)
                    }
                  }
                }

                if (estimateNos && estimateNos.length) {
                  await dialogs.showInfoDialog('見積り複写完了', `見積Noは以下の通りです。\r\n${estimateNos.join('\r\n')}`)
                }
                // サブタスクに強制遷移
                this.$router.push({ name: 'TaskRequest', query: { requestPostId: key.id }})
              }
            }
          } else {
            // サブタスクに強制遷移
            this.$router.push({ name: 'TaskRequest', query: { requestPostId: key.id }})
          }
        // 複写もとにサブタスクがない
        } else {
          const dialogResult = await dialogs.showConfirmDialog('サブタスク登録', '複写元にサブタスクがありませんでした。\r\n続けてサブタスクを登録しますか？', 'はい', 'いいえ')
          if (dialogResult === 'YES') {
            this.$router.push({ name: 'TaskRequest', query: { requestPostId: key.id }})
          } else {
            this.$router.push({ name: 'RequestPostList' })
          }
        }
      }
    },

    /**
     * 複写時にどの見積もりをコピーするか選択する
     * @param estimates 見積り
     */
    // async allCopyEstimate (estimates, key, taskNewId, changeFixed, changeSp, subCode, otherCode) {
    //   if (estimates && estimates.length) {
    //     this.allCopyEstimateShow = true
    //     this.allCopyEstimateParam = {
    //       estimates,

    //       selectCallback: async (selectedEstimates) => {
    //         this.allCopyEstimateShow = false
    //         estimates = selectedEstimates

            
    //         for (let j = 0; j < estimates.length; j++) {
    //           let e = estimates[j]
    //           e._id = null
    //           e.estimateNo = ''
    //           e.estimateDate = utils.getToday()
    //           e.expireDate = utils.addMonths(utils.getToday(), 3)
    //           e.requestPostId = key.id
    //           e.requestPostName = this.requestPost.inquiry_title
    //           e.taskRequestId = taskNewId
    //           e.clientPersonName = this.requestPost.clientPersonName
    //           e.subject = this.requestPost.note

    //           // 得意先
    //           e.clientName = this.requestPost.clientName
    //           e.clientPostalCode = this.requestPost.postalCode
    //           e.clientAddress1 = this.requestPost.clientAddress1
    //           e.clientAddress2 = this.requestPost.clientAddress2
    //           // メインにない得意先のデータをマスタから取得
    //           if (this.requestPost.clientCode) {
    //             const client = await estimateManager.getClientByCode(this.requestPost.clientCode)
    //             if (client) {
    //               e.clientHonorific = client.honorific
    //               e.clientPhoneNumber = client.clientPhoneNumber
    //               e.clientFax = client.clientFax
    //             }
    //           }
    //           e.estimateStatus = '作成中'
    //           // 1番目の承認ルート
    //           e.approvalFlow1 = [{
    //             sequentialOrder: '1',
    //             userId: '',
    //             userName: '',
    //             chatworkAccountId: '',
    //             chatworkRoomId: '',
    //             chatworkApiToken: '',
    //             status: '未承認',
    //             stamp: ''
    //           }]
    //           // 2番目の承認ルート
    //           e.approvalFlow2 = [{
    //             sequentialOrder: '2',
    //             userId: '',
    //             userName: '',
    //             chatworkAccountId: '',
    //             chatworkRoomId: '',
    //             chatworkApiToken: '',
    //             status: '未承認',
    //             stamp: ''
    //           }]
    //           // 承認1ステータス
    //           e.approval1 = '---'
    //           // 承認2ステータス
    //           e.approval2 = '---'
    //           // 複写フラグ
    //           e.allCopy = true
    //           // 変更フラグ
    //           e.isChangeRegular = changeFixed
    //           e.isChangeSp = changeSp
    //           // ラウンドサブの変更された内容
    //           e.isChangeSubs = subCode
    //           // ラウンドサブの変更された内容
    //           e.isChangeOther = otherCode

    //           let eResult = await estimateManager.registerEstimate(this, e)
    //           if (!eResult) {
    //             await dialogs.showErrorDialog('見積りの複写', '見積りの複写中にエラーが発生しました。')
    //             return false
    //           }
    //         }
    //       }
    //     }
    //   }
    // },

    /*
     * chatworkへ通知 送信
     */
    async sendChatWorkMessage(requestPost, requestPostId) {
      if (requestPost.category.includes('デンタツ') || requestPost.category.includes('AKBS休み登録')) {
        if (REQUEST_POST_TRANSMISSION_FEATURES.REQUEST_POST_TRANSMISSION.SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_TRANSMISSION && REQUEST_POST_TRANSMISSION_FEATURES.REQUEST_POST_TRANSMISSION.SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_TRANSMISSION.enable) {
          let mension = ''
          let viewUrl = `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}RequestPost?requestPostId=${requestPostId}&mode=1`
          const userList = this.$store.getters.userList

          // ログインしているユーザー名を取得
          const loginUser = this.$store.getters.user
          // ログインユーザーIDと担当者IDが同じ場合はchatwork通知は行わない
          if (requestPost.toStaffId && loginUser.id == requestPost.toStaffId) {
            return
          }
          // 担当者IDがないデータは、名前で比較。同じ場合はchatwork通知は行わない
          if (utils.deleteKana(loginUser.user_name).replace(/\s+/g, '') == utils.deleteKana(requestPost.toStaffName).replace(/\s+/g, '')) {
            return
          }

          let toStaffId = ''
          let chat = null
          if (requestPost.toStaffName != '') {        
            if (requestPost.toStaffId && requestPost.toStaffId != '') {
              toStaffId = requestPost.toStaffId
              
            // 担当者IDがない場合、担当者名から担当者Id取得
            } else {
              toStaffId = utils.getUserIdByName(requestPost.toStaffName, userList)
            }
          }

          // チャットワーク情報を取得
          if (toStaffId && toStaffId != '') {
            let c = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId: toStaffId})
            chat = c.data.data
          }

          if (requestPost.toStaffName == '未定') {
            mension = `[toall]${requestPost.toStaffName}さん`
          } else if (chat.chatworkAccountId && chat.chatworkAccountId != '') {
            mension = `[To:${chat.chatworkAccountId}]${requestPost.toStaffName}さん`
          } else {
            mension = `[To:]${requestPost.toStaffName}さん`
          }

          // デンタツの場合は、デンタツ部署roomIDに送信
          let dentatsu = {}
          dentatsu = this.$store.getters.transDepartmentList.find((t) => {
            return t.departmentId == this.requestPost.toDepartmentId
          })

          if (!dentatsu.chatworkRoomId || dentatsu.chatworkRoomId == '') {
            await dialogs.showErrorDialog('チャット送信不可', 'ルームIDが取得できなかったため、チャット送信できませんでした。')
            return
          } else if (!dentatsu.chatworkApiToken || dentatsu.chatworkApiToken == '') {
            await dialogs.showErrorDialog('チャット送信不可', 'APIトークンが取得できなかったため、チャット送信できませんでした。')
            return
          }

          if (!requestPost.employeeName) {
            requestPost.employeeName = ''
          }

          let context = ''
          let expression = ''

          if (requestPost.category.includes('デンタツ')) {
            context = {
              mension,
              inquiry_title: requestPost.inquiry_title,
              clientPersonName: requestPost.clientPersonName,
              telephoneNumber: requestPost.telephoneNumber,
              dentatsuCategory: requestPost.dentatsuCategory,
              viewUrl: viewUrl,
              sender: requestPost.byUser,
              clientName: requestPost.clientName,
              employeeName: requestPost.employeeName
            }
            expression = '`' + REQUEST_POST_TRANSMISSION_FEATURES.REQUEST_POST_TRANSMISSION.SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_TRANSMISSION.messageTemplate + '`' 
          } else {
            context = {
              mension,
              category: requestPost.category,
              inquiry_title: requestPost.inquiry_title,
              viewUrl: viewUrl,
              sender: requestPost.byUser
            }
            expression = '`' + REQUEST_POST_TRANSMISSION_FEATURES.REQUEST_POST_TRANSMISSION.SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_TRANSMISSION.messageTemplateAKBS + '`' 
          }

          const message = utils.evaluate(context, expression)
          console.log(message)

          await chatwork.sendChatWorkMssage(
            dentatsu.chatworkRoomId, 
            message,
            dentatsu.chatworkApiToken
          )
        }
      } else {
        if (REQUEST_POST_FEATURES.REQUEST_POST.SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST && REQUEST_POST_FEATURES.REQUEST_POST.SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST.enable) {
          let mension = ''
          let viewUrl = `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}RequestPost?requestPostId=${requestPostId}&mode=1`
          const userList = this.$store.getters.userList

          // ログインしているユーザー名を取得
          const loginUser = this.$store.getters.user
          // ログインユーザーIDと担当者IDが同じ場合はchatwork通知は行わない
          if (requestPost.toStaffId && loginUser.id == requestPost.toStaffId) {
            return
          }
          // 担当者IDがないデータは、名前で比較。同じ場合はchatwork通知は行わない
          if (utils.deleteKana(loginUser.user_name).replace(/\s+/g, '') == utils.deleteKana(requestPost.toStaffName).replace(/\s+/g, '')) {
            return
          }

          let toStaffId = ''
          let chat = null
          if (requestPost.toStaffName != '') {        
            if (requestPost.toStaffId && requestPost.toStaffId != '') {
              toStaffId = requestPost.toStaffId
              
            // 担当者IDがない場合、担当者名から担当者Id取得
            } else {
              toStaffId = utils.getUserIdByName(requestPost.toStaffName, userList)
            }
          }

          // チャットワーク情報を取得
          if (toStaffId && toStaffId != '') {
            let c = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId: toStaffId})
            chat = c.data.data
          }

          if (chat.chatworkAccountId && chat.chatworkAccountId != '') {
            mension = `[To:${chat.chatworkAccountId}]${requestPost.toStaffName}さん`
          } else {
            if (requestPost.toStaffName == '未定') {
              mension = `[toall]${requestPost.toStaffName}さん`
            } else {
              mension = `[To:]${requestPost.toStaffName}さん`
            }
          }

          if (!chat.chatworkRoomId || chat.chatworkRoomId == '') {
            await dialogs.showErrorDialog('チャット送信不可', 'ルームIDが取得できなかったため、チャット送信できませんでした。')
            return
          } else if (!chat.chatworkApiTokenDepartment || chat.chatworkApiTokenDepartment == '') {
            await dialogs.showErrorDialog('チャット送信不可', 'APIトークンが取得できなかったため、チャット送信できませんでした。')
            return
          }

          const context = {
            mension,
            category: requestPost.category,
            inquiry_title: requestPost.inquiry_title,
            estimationCleanCategory: requestPost.estimationCleanCategory || [],
            estimationInspectCategory: requestPost.estimationInspectCategory || [],
            estimationOtherCategory: requestPost.estimationOtherCategory || [],
            viewUrl: viewUrl,
            sender: requestPost.byUser
          }

          // 非公開依頼の場合、URL削除
          let expression = '`' + REQUEST_POST_FEATURES.REQUEST_POST.SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST.messageTemplate + '`' 
          if (this.requestPost.category.includes('非公開依頼')) expression = expression.replace('● メインタスク：${viewUrl}', '')

          const message = utils.evaluate(context, expression)
          console.log(message)

          await chatwork.sendChatWorkMssage(
            chat.chatworkRoomId, 
            message,
            chat.chatworkApiTokenDepartment
          )
        }
      }
    },

    /**
     * 受注データを保存するために結合
     * @param data 受注データ
     */
    additionOrderData(data) {
      this.requestPost = Object.assign(this.requestPost, data)
    },

    // メインタスクを完了に変更
    async statusDone() {
      const dialogResult = await dialogs.showConfirmDialog('タスクの完了', 'タスクを完了しますか？変更した内容も保存されます。', 'はい', 'キャンセル')
      if (dialogResult === 'YES') {
        if (this.requestPost.type == 'apollo_request_post') {
          this.requestPost.status = 'done'
          const result = await requestPostManager.update(this, this.requestPost)
          if (result) {
            await dialogs.showSuccessDialog('タスクの完了', '完了にしました。')
            logManager.recording(this, logManager.Loglevel.INFO, 'メインタスク', 'ステータス完了', 'メインタスク（お問い合わせ番号：' + this.requestPost.requestNo + '）のステータスを完了しました。')
          } else {
            await dialogs.showErrorDialog('エラー', '完了にできませんでした。')
          }
        }
        this.$router.push({ name: 'RequestPostList' })
      }
    },

    // changeValidateContractDate(result, message) {
    //   this.contractDateResult = result
    //   this.contractDateMessage = message
    // },

    changeValidateContractEndDate(result, message) {
      this.contractEndDateResult = result
      this.contractEndDateMessage = message
    },

    changeValidateClassification(result, message) {
      this.classificationResult = result
      this.classificationMessage = message
    },

    changeValidateSpecialCleanWorkItem(result, message) {
      this.specialCleanWorkItemResult = result
      this.specialCleanWorkItemMessage = message
    },
    
    /**
     * 登録内容 取得（過去の受注データ）
     */
    getOrderSummary() {
      let ret = ''
      // 郵便番号
      let postalCode = '---'
      if (this.estimateData.buildingPostalCode && this.estimateData.buildingPostalCode != '') {
        postalCode = this.estimateData.buildingPostalCode
      }

      ret += '物件名：' + this.getOutputSummaryText(this.estimateData.buildingName)
      ret += '、　郵便番号：' + this.getOutputSummaryText(postalCode)
      ret += '、　物件住所：' + this.getOutputSummaryText(this.estimateData.buildingAddress2)
      ret += '、　契約日：' + this.getOutputSummaryText(this.requestPost.contractDate)
      ret += '、　契約終了日：' + this.getOutputSummaryText(this.requestPost.contractEndDate) + '\r\n'
      ret += '---------------------------------------------\r\n'

      // ラウンドサービス
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('1') > -1) {
        ret += '作業内容：ラウンドサービス'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.estimationRoundCount)
        ret += '、　開始日：' + this.getOutputSummaryText(this.requestPost.estimationRoundStartDate)
        ret += '、　作業曜日：' + this.getOutputSummaryText(this.requestPost.roundServiceWorkDays)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationRoundUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.estimationRoundSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationRoundPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.estimationRoundCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationRoundCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // ラウンドプラス
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('2') > -1) {
        let details = ''
        if (this.requestPost.estimationRoundPlusCategorySelect) {
          details = '（' + this.requestPost.estimationRoundPlusCategorySelect + '）'
        }
        ret += '作業内容：ラウンドプラス' + details
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.estimationRoundPlusCount, true)
        ret += '、　開始日：' + this.getOutputSummaryText(this.requestPost.estimationRoundPlusStartDate)
        if (this.requestPost.estimationRoundPlusWorkRemarks && this.requestPost.estimationRoundPlusWorkRemarks.length > 0) {
          this.requestPost.estimationRoundPlusWorkRemarks.forEach((sss, index) => {
            let idx = index + 1
            ret += '、　作業曜日【' + idx + '】：' + this.getOutputSummaryText(sss.workDays)
            ret += '、　作業開始時間【' + idx + '】：' + this.getOutputSummaryText(sss.startTime)
            ret += '、　作業終了時間【' + idx + '】：' + this.getOutputSummaryText(sss.endTime)
          });
        }
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationRoundPlusUnitPrice))

        // 発注明細が新仕様の場合
        if (this.requestPost.estimationRoundPlusPurchase && this.requestPost.estimationRoundPlusPurchase.length) {
          this.requestPost.estimationRoundPlusPurchase.forEach((p) => {
            let name = utils.deleteKana(p.typeName)
            // eslint-disable-next-line no-irregular-whitespace
            ret += `、　${name}（発注先名：${p.supplier}、 発注金額：${utils.numberToLocale(p.price)}）`
          })
        } else {
          let supplier = ''
          this.requestPost.estimationRoundPlusSupplier.forEach(sss => {
            if (supplier != '') {
              supplier += '、'
            }
            supplier += this.getOutputSummaryText(sss.supplier)
          });
          ret += '、　発注先名：' + supplier
          ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationRoundPlusPrice))
        }
        
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.estimationRoundPlusCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationRoundPlusCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 日常清掃
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('3') > -1) {
        ret += '作業内容：日常清掃'
        if (this.requestPost.orderEstimationEverydays) {
          this.requestPost.orderEstimationEverydays.forEach(sss => {
            ret += '、　作業内容：日常清掃【' + this.getOutputSummaryText(sss.everydayCleanContents) + '】'
            ret += '、　清掃回数：' + this.getOutputSummaryText(sss.estimationEverydayCount)
            ret += '、　開始日：' + this.getOutputSummaryText(sss.everydayStartDate)
            if (sss.everydayCleanWorkDays) {
              let remarks = this.getSummaryWorkRemarks(sss.everydayCleanWorkDays)
              ret += remarks
            }
          });
        }
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationEverydayUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.estimationEverydaySupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationEverydayPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.estimationEverydayCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationEverydayCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 管理員業務
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('4') > -1) {
        ret += '作業内容：管理員業務'
        if (this.requestPost.orderManagerWorks) {
          this.requestPost.orderManagerWorks.forEach(sss => {
            ret += '、　作業内容：管理員業務【' + this.getOutputSummaryText(sss.managerWorkContents) + '】'
            ret += '、　清掃回数：' + this.getOutputSummaryText(sss.managerWorkCount)
            ret += '、　開始日：' + this.getOutputSummaryText(sss.managerWorkStartDate)
            if (sss.managerWorkDays) {
              let remarks = this.getSummaryWorkRemarks(sss.managerWorkDays)
              ret += remarks
            }
          });
        }
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.managerWorkUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.managerWorkSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.managerWorkPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.managerWorkCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.managerWorkCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 定期清掃
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('5') > -1) {
        ret += '作業内容：定期清掃'
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.regularCleanSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.regularCleanPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.regularCleanCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.regularCleanCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'

        if (this.requestPost.orderRegularCleans) {
          this.requestPost.orderRegularCleans.forEach(sss => {
            ret += '作業内容：定期清掃【' + this.getOutputSummaryText(sss.regularCleanContents) + '】'
            ret += '、　清掃回数：' + this.getOutputSummaryText(sss.regularCleanCount)
            ret += '、　初回作業年：' + this.getOutputSummaryText(sss.regularCleanFirstWorkYear)
            ret += '、　初回作業月：' + this.getOutputSummaryText(sss.regularCleanFirstWorkMonth)
            ret += '、　実施月：' + this.getOutputSummaryText(sss.regularCleanImplementationMonth)
            ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(sss.regularCleanUnitPrice))
            ret += '、　対象箇所：' + this.getOutputSummaryText(sss.regularCleanTargetArea)
            ret += '、　作業内容：' + this.getOutputSummaryText(sss.regularCleanDescriptionWork)
            ret += '、　カーペット：' + this.getOutputSummaryText(sss.regularCleanCarpet) + '\r\n'
            ret += '---------------------------------------------\r\n'
          });
        }
      }

      // フロントサポート
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('6') > -1) {
        ret += '作業内容：フロントサポート'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.frontSupportCount)
        ret += '、　開始日：' + this.getOutputSummaryText(this.requestPost.frontSupportStartDate)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.frontSupportFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.frontSupportFirstWorkMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.frontSupportUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.frontSupportSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.frontSupportPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.frontSupportCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.frontSupportCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // フロントサポートプラス
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('7') > -1) {
        ret += '作業内容：フロントサポートプラス'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.frontSupportPlusCount)
        ret += '、　開始日：' + this.getOutputSummaryText(this.requestPost.frontSupportPlusStartDate)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.frontSupportPlusFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.frontSupportPlusFirstWorkMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.frontSupportPlusUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.frontSupportPlusSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.frontSupportPlusPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.frontSupportPlusCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.frontSupportPlusCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // ラウンドトラッシュ
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('8') > -1) {
        ret += '作業内容：ラウンドトラッシュ'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.roundTrashCount)
        ret += '、　開始日：' + this.getOutputSummaryText(this.requestPost.roundTrashStartDate)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.roundTrashFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.roundTrashFirstWorkMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.roundTrashUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.roundTrashSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.roundTrashPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.roundTrashCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.roundTrashCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // クオリティレポート
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('9') > -1) {
        ret += '作業内容：クオリティレポート'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.qualityReportCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.qualityReportFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.qualityReportFirstWorkMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.qualityReportUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.qualityReportSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.qualityReportPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.qualityReportCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.qualityReportCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // ガラス清掃
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('10') > -1) {
        ret += '作業内容：ガラス清掃'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.glassCleanCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.glassCleanFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.glassCleanFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.glassCleanImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.glassCleanUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.glassCleanSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.glassCleanPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.glassCleanCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.glassCleanCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 排水管清掃
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('11') > -1) {
        ret += '作業内容：排水管清掃'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.estimationDorainPipeCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.estimationDorainPipeFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.estimationDorainPipeFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.estimationDorainPipeImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationDorainPipeUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.estimationDorainPipeSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationDorainPipePrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.estimationDorainPipeCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationDorainPipeCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 貯水槽清掃
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('12') > -1) {
        ret += '作業内容：貯水槽清掃'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.estimationWaterTankCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.estimationWaterTankFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.estimationWaterTankFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.estimationWaterTankImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationWaterTankUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.estimationWaterTankSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationWaterTankPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.estimationWaterTankCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.estimationWaterTankCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 植栽剪定
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('13') > -1) {
        ret += '作業内容：植栽剪定'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.plantingCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.plantingFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.plantingFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.plantingImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.plantingUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.plantingSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.plantingPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.plantingCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.plantingCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 特別清掃
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('14') > -1) {
        if (this.requestPost.orderSpecialCleans) {
          this.requestPost.orderSpecialCleans.forEach(sss => {
            ret += '作業内容：特別清掃【' + constants.getSpecialCleanWorkItemName(this.getOutputSummaryText(sss.specialCleanWorkItem)) + '】'
            ret += '、　清掃回数：' + this.getOutputSummaryText(sss.specialCleanCount)
            ret += '、　初回作業年：' + this.getOutputSummaryText(sss.specialCleanFirstWorkYear)
            ret += '、　初回作業月：' + this.getOutputSummaryText(sss.specialCleanFirstWorkMonth)
            ret += '、　実施月：' + this.getOutputSummaryText(sss.specialCleanImplementationMonth)
            ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(sss.specialCleanUnitPrice))
            ret += '、　発注先名：' + this.getOutputSummaryText(sss.specialCleanSupplier)
            ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(sss.specialCleanPrice))
            ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(sss.specialCleanCommissionFlag, true))
            ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(sss.specialCleanCommissionPrice, true)) + '\r\n'
            ret += '---------------------------------------------\r\n'
          });
        }
      }

      // 消防設備点検
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('15') > -1) {
        ret += '作業内容：消防設備点検'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.fireInspectCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.fireInspectFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.fireInspectFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.fireInspectMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.fireInspectUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.fireInspectSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.fireInspectPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.fireInspectCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.fireInspectCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 建築設備定期検査
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('16') > -1) {
        ret += '作業内容：建築設備定期検査'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.constructionEquipmentFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.constructionEquipmentInspectCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 特定建築物定期調査
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('17') > -1) {
        ret += '作業内容：特定建築物定期調査'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.specificInspectCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.specificInspectFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.specificInspectFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.specificInspectImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.specificInspectUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.specificInspectSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.specificInspectPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.specificInspectCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.specificInspectCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 共用部設備点検
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('18') > -1) {
        ret += '作業内容：共用部設備点検'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionFirstWorkMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.commonAreaFacilityInspectionCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // エレベーター保守点検
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('19') > -1) {
        ret += '作業内容：エレベーター保守点検'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.evInspectCount)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.evInspectImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.evInspectUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.evInspectSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.evInspectPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.evInspectCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.evInspectCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 増圧ポンプ点検
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('20') > -1) {
        ret += '作業内容：増圧ポンプ点検'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.pressPumpInspectCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.pressPumpInspectFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.pressPumpInspectFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.pressPumpInspectImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.pressPumpInspectUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.pressPumpInspectSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.pressPumpInspectPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.pressPumpInspectCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.pressPumpInspectCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 給排水ポンプ点検
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('21') > -1) {
        ret += '作業内容：給排水ポンプ点検'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.waterSupplyDrainagePumpInspectionCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 簡易専用水道
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('22') > -1) {
        ret += '作業内容：簡易専用水道'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.simpleWaterSupplyCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.simpleWaterSupplyFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.simpleWaterSupplyFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.simpleWaterSupplyImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.simpleWaterSupplyUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.simpleWaterSupplySupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.simpleWaterSupplyPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.simpleWaterSupplyCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.simpleWaterSupplyCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 管球対応
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('23') > -1) {
        ret += '作業内容：管球対応'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.tubeBulbSupportCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.tubeBulbSupportFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.tubeBulbSupportFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.tubeBulbSupportImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.tubeBulbSupportUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.tubeBulbSupportSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.tubeBulbSupportPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.tubeBulbSupportCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.tubeBulbSupportCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 粗大ごみ対応
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('24') > -1) {
        ret += '作業内容：粗大ごみ対応'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.bulkyWasteSupportCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.bulkyWasteSupportFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.bulkyWasteSupportFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.bulkyWasteSupportImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.bulkyWasteSupportUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.bulkyWasteSupportSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.bulkyWasteSupportPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.bulkyWasteSupportCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.bulkyWasteSupportCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 物品販売
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('25') > -1) {
        ret += '作業内容：物品販売'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.merchandiseSalesCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.merchandiseSalesFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.merchandiseSalesFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.merchandiseSalesImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.merchandiseSalesUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.merchandiseSalesSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.merchandiseSalesPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.merchandiseSalesCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.merchandiseSalesCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 緊急対応
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('26') > -1) {
        ret += '作業内容：緊急対応'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.emergencyResponseCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.emergencyResponseFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.emergencyResponseFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.emergencyResponseImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.emergencyResponseUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.emergencyResponseSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.emergencyResponsePrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.emergencyResponseCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.emergencyResponseCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 防火対象物点検
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('27') > -1) {
        ret += '作業内容：防火対象物点検'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.firePreventionObjectInspectionCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 汚水槽清掃
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('101') > -1) {
        ret += '作業内容：汚水槽清掃'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.sewageTankCleaningCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.sewageTankCleaningFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.sewageTankCleaningFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.sewageTankCleaningImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.sewageTankCleaningUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.sewageTankCleaningSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.sewageTankCleaningPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.sewageTankCleaningCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.sewageTankCleaningCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 防火設備定期検査
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('102') > -1) {
        ret += '作業内容：防火設備定期検査'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.fireProtectionInspectionCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.fireProtectionInspectionFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.fireProtectionInspectionFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.fireProtectionInspectionImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.fireProtectionInspectionUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.fireProtectionInspectionSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.fireProtectionInspectionPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.fireProtectionInspectionCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.fireProtectionInspectionCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // 連結送水管耐圧性能点検
      if (this.requestPost.estimationDetailsCategoryCode.indexOf('103') > -1) {
        ret += '作業内容：連結送水管耐圧性能点検'
        ret += '、　清掃回数：' + this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionCount)
        ret += '、　初回作業年：' + this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionFirstWorkYear)
        ret += '、　初回作業月：' + this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionFirstWorkMonth)
        ret += '、　実施月：' + this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionImplementationMonth)
        ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionUnitPrice))
        ret += '、　発注先名：' + this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionSupplier)
        ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionPrice))
        ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionCommissionFlag, true))
        ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(this.requestPost.connectingWaterPipePressureInspectionCommissionPrice, true)) + '\r\n'
        ret += '---------------------------------------------\r\n'
      }

      // スポット清掃
      if (this.requestPost.orderSpot && this.requestPost.orderSpot.length) {
        for (let i = 0; i < this.requestPost.orderSpot.length; i++) {
          const o = this.requestPost.orderSpot[i]
          ret += '作業内容：' + this.getOutputSummaryText(o.name)
          ret += '、　清掃回数：' + this.getOutputSummaryText(o.count)
          ret += '、　初回作業年：' + this.getOutputSummaryText(o.firstWorkYear)
          ret += '、　初回作業月：' + this.getOutputSummaryText(o.firstWorkMonth)
          ret += '、　実施月：' + this.getOutputSummaryText(o.implementationMonth)
          ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(o.unitPrice))
          ret += '、　発注先名：' + this.getOutputSummaryText(o.supplier)
          ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(o.orderPrice))
          ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(o.commissionFlag, true))
          ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(o.commissionPrice, true))
          ret += '、　対象箇所：' + this.getOutputSummaryText(o.spotArea)
          ret += '、　作業内容：' + this.getOutputSummaryText(o.spotContent)
          ret += '、　カーペット：' + this.getOutputSummaryText(o.spotCarpet) + '\r\n'
          ret += '---------------------------------------------\r\n'
        }
      }

      // カテゴリコードがないもの
      if (this.requestPost.orderOthers && this.requestPost.orderOthers.length) {
        for (let i = 0; i < this.requestPost.orderOthers.length; i++) {
          const o = this.requestPost.orderOthers[i]
          ret += '作業内容：' + this.getOutputSummaryText(o.name)
          ret += '、　清掃回数：' + this.getOutputSummaryText(o.count)
          ret += '、　初回作業年：' + this.getOutputSummaryText(o.firstWorkYear)
          ret += '、　初回作業月：' + this.getOutputSummaryText(o.firstWorkMonth)
          ret += '、　実施月：' + this.getOutputSummaryText(o.implementationMonth)
          ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(o.unitPrice))
          ret += '、　発注先名：' + this.getOutputSummaryText(o.supplier)
          ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(o.orderPrice))
          ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(o.commissionFlag, true))
          ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(o.commissionPrice, true)) + '\r\n'
          ret += '---------------------------------------------\r\n'
        }
      }

      return ret
    },
    
    /**
     * 登録内容 取得（新受注データ）全て配列
     */
    getOrderSummaryNew() {
      let ret = ''
      // 郵便番号
      let postalCode = '---'
      if (this.estimateData.buildingPostalCode && this.estimateData.buildingPostalCode != '') {
        postalCode = this.estimateData.buildingPostalCode
      }

      ret += '物件名：' + this.getOutputSummaryText(this.estimateData.buildingName)
      ret += '、　郵便番号：' + this.getOutputSummaryText(postalCode)
      ret += '、　物件住所：' + this.getOutputSummaryText(this.estimateData.buildingAddress2)
      ret += '、　契約日：' + this.getOutputSummaryText(this.requestPost.contractDate)
      ret += '、　契約終了日：' + this.getOutputSummaryText(this.requestPost.contractEndDate) + '\r\n'
      ret += '---------------------------------------------\r\n'

      const PROPSET = constants.PROPSET

      for (let i = 0; i < PROPSET.length; i++) {
        const p = PROPSET[i]
        // 日常・管理・定期・特別
        if (['3', '4', '5', '14'].includes(p.code)) {
          ret = this.getSummaryVal2(p.val, ret)
        } else {
          ret = this.getSummaryVal(p.val, ret)
        }
      }
      return ret
    },

    /**
     * 登録内容生成
     * @param code カテゴリーコード
     * @param tar 対象プロパティー
     * @param ret 登録内容文字列
     */
    getSummaryVal(tar, ret) {
      // console.log(this.requestPost[tar])
      if (this.requestPost[tar] && this.requestPost[tar].length) {
        for (let i = 0; i < this.requestPost[tar].length; i++) {
          const r = this.requestPost[tar][i]

          if (['28', 'none'].includes(r.categoryCode)) {
            ret += '作業内容：' + r.content
          } else {
            ret += '作業内容：' + r.name
          }

          // RLSプラス
          if (['2'].includes(r.categoryCode)) {
            ret += '（' + r.content + '）'
          }

          // 作業内容コードがあるもの
          if (r.workCode) {
            ret += '、　作業コード：' + this.getOutputSummaryText(r.workCode.code, false)
          }

          if (r.count2) {
            ret += '、　清掃回数：' + this.getOutputSummaryText(r.count2, false)
          } else {
            ret += '、　清掃回数：' + this.getOutputSummaryText(r.dispCount, false)
          }

          if (r.startDate || r.startDate == '') {
            ret += '、　開始日：' + this.getOutputSummaryText(r.startDate, false)
          }

          if (r.workDays) {
            ret += '、　作業曜日：' + this.getOutputSummaryText(r.workDays, false)
          }

          if (r.firstWorkYear || r.firstWorkYear == '') {
            ret += '、　初回作業年：' + this.getOutputSummaryText(r.firstWorkYear, false)
          }

          if (r.firstWorkMonth) {
            ret += '、　初回作業月：' + this.getOutputSummaryText(r.firstWorkMonth, false)
          }

          if (r.implementationMonth) {
            ret += '、　実施月：' + this.getOutputSummaryText(r.implementationMonth, false)
          }

          // RLSプラスの作業曜日など
          if (['2'].includes(r.categoryCode)) {
            if (r.remarks) {
              for (let j = 0; j < r.remarks.length; j++) {
                const m = r.remarks[j]
                let num = j + 1
                ret += '、　\r\n作業曜日【' + num + '】：' + this.getOutputSummaryText(m.workDays)
                ret += '、　作業開始時間【' + num + '】：' + this.getOutputSummaryText(m.startTime)
                ret += '、　作業終了時間【' + num + '】：' + this.getOutputSummaryText(m.endTime)
              }
            }
          }

          // スポット清掃
          if (r.categoryCode == '28') {
            ret += '、　対象箇所：' + this.getOutputSummaryText(r.area)
            ret += '、　作業内容：' + this.getOutputSummaryText(r.workDetail)
          }

          if (['2', '28'].includes(r.categoryCode)) {
            ret += '、\r\n'
          } else {
            ret += '、　'
          }

          ret += '受注単価：' + utils.numberToLocale(this.getOutputSummaryText(r.unitPrice, false))

          // RLSプラス以外の発注データ
          if (r.categoryCode != '2') {
            ret += '、　発注先名：' + this.getOutputSummaryText(r.supplier, false)
            ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(r.orderPrice, false))
          // RLSプラス
          } else {
            if (r.purchase) {
              r.purchase.forEach((p) => {
                let name = ''
                if (p.typeName) {
                  name = utils.deleteKana(p.typeName)
                }
                // eslint-disable-next-line no-irregular-whitespace
                ret += `、　${name}（発注先名：${p.supplier}、 発注金額：${utils.numberToLocale(p.price)}）`
              })
            }
          }

          ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(r.commissionFlag, true))
          ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(r.commissionPrice, true)) + '\r\n'
          ret += '---------------------------------------------\r\n'
        }
      }
      return ret
    },
    
    /**
     * 登録内容生成（日常・管理・定期・特別）
     * @param code カテゴリーコード
     * @param tar 対象プロパティー
     * @param ret 登録内容文字列
     */
    getSummaryVal2(tar, ret) {
      // console.log(this.requestPost[tar])
      if (this.requestPost[tar] && this.requestPost[tar].length) {
        for (let i = 0; i < this.requestPost[tar].length; i++) {
          const r = this.requestPost[tar][i]

          ret += '作業内容：' + r.name

          // 作業内容コードがあるもの
          if (r.workCode) {
            ret += '、　作業コード：' + this.getOutputSummaryText(r.workCode.code, false)
          }

          for (let j = 0; j < r.details.length; j++) {
            const d = r.details[j]
            // 特別清掃で新しい仕様の調整費は表示しない
            if (r.categoryCode == '14' && d.newData && d.content.indexOf('調整費') > -1) {
              continue
            }

            if (r.categoryCode == '5') {
              ret += ''
            }
            else if (j >= 1) {
              ret += '、\r\n'
            } else {
              ret += '、　'
            }
            if (r.categoryCode == '5') {
              if (r.details.length > 1) {
                ret += '\r\n【' + (j + 1) + '】'
              }
              
            } else {
              ret += '作業内容：【' + d.content.replace(/\r\n/g, '') + '】'
            }

            // 日常・管理
            if (['3', '4'].includes(r.categoryCode)) {  
              ret += '、　清掃回数：' + this.getOutputSummaryText(d.count2, false)
              ret += '、　開始日：' + this.getOutputSummaryText(d.startDate, false)
            } else {
              // 特別清掃
              if (r.categoryCode == '14') {
                // 作業内容コードがあるもの（古い仕様の特別清掃）
                if (d.workCode) {
                  ret += '、　作業コード：' + this.getOutputSummaryText(d.workCode.code, false)
                }
              }
              if (d.dispCount || d.dispCount == '') {
                ret += '、　清掃回数：' + this.getOutputSummaryText(d.dispCount, false)
              }
              ret += '、　初回作業年：' + this.getOutputSummaryText(d.firstWorkYear, false)
              ret += '、　初回作業月：' + this.getOutputSummaryText(d.firstWorkMonth, false)
              ret += '、　実施月：' + this.getOutputSummaryText(d.implementationMonth, false)
              if (d.unitPrice || d.unitPrice == '') {
                ret += '、　受注単価：' + utils.numberToLocale(this.getOutputSummaryText(d.unitPrice, false))
              }
              // 定期清掃
              if (r.categoryCode == '5') {
                ret += '、　対象箇所：' + this.getOutputSummaryText(d.area)
                ret += '、　作業内容：' + this.getOutputSummaryText(d.workDetail)
              }
            }
            
            if (d.remarks) {  
              for (let k = 0; k < d.remarks.length; k++) {
                const m = d.remarks[k]
                let num = k + 1
                ret += '、\r\n作業曜日【' + num + '】：' + this.getOutputSummaryText(m.workDays)
                ret += '、　作業開始時間【' + num + '】：' + this.getOutputSummaryText(m.startTime)
                ret += '、　作業終了時間【' + num + '】：' + this.getOutputSummaryText(m.endTime)
              }
            }
          }

          ret += '、\r\n'
          // 日常・管理
          if (['3', '4'].includes(r.categoryCode) || (r.categoryCode == '14' && (r.unitPrice || r.unitPrice == ''))) {
            if (r.categoryCode == '14' && (r.dispCount || r.dispCount == '')) {
              ret += '清掃回数：' + this.getOutputSummaryText(r.dispCount, false)
              ret += '、　'
            }
            ret += '受注単価：' + utils.numberToLocale(this.getOutputSummaryText(r.unitPrice, false))
            ret += '、　'
          }
          ret += '発注先名：' + this.getOutputSummaryText(r.supplier, false)
          ret += '、　発注金額：' + utils.numberToLocale(this.getOutputSummaryText(r.orderPrice, false))

          ret += '、　手数料有無：' + this.getCommissionFlagVal(this.getOutputSummaryText(r.commissionFlag, true))
          ret += '、　手数料金額：' + utils.numberToLocale(this.getOutputSummaryText(r.commissionPrice, true)) + '\r\n'
          ret += '---------------------------------------------\r\n'
        }
      }
      return ret
    },

    /**
     * 手数料有無フラグの値 取得
     */
    getCommissionFlagVal(flag) {
      // 値が「---」の場合は値をそのまま返す
      if(flag == '---') return flag
      if (flag && flag.length) {
        let val = ''
        if (flag[0] == '1') {
          val = '有'
        } else if (flag[0] == '0') {
          val = '無'
        }
        return val
      }
    },

    /**
     * 登録内容 変更
     */
    changeOrderSummary() {
      this.requestPost.orderSummary = this.getOrderSummary()
    },

    /**
     * 登録内容 作業曜日、曜日の値を取得
     */
    getSummaryWorkRemarks(remarks) {
      let ret = ''
      remarks.forEach((val, index) => {
        let idx = index + 1
        ret += '、　作業曜日【' + idx + '】：' + val.workDays
        ret += '、　作業開始時間【' + idx + '】：' + val.startTime
        ret += '、　作業終了時間【' + idx + '】：' + val.endTime
      });
      return ret
    },

    /**
     * 登録内容に出力するテキストを取得
     * @param props プロパティ
     * @param isInputFlag 入力可否フラグ
     */
    getOutputSummaryText(props, isInputFlag) {
      let val
      if (!props) {
        // プロパティが無い場合
        if (isInputFlag) {
          // 入力不可の項目の場合
          val = '---'
        } else {
          val = ''
        }
      } else {
        // プロパティがある場合
        if (typeof props === 'string') {
          // 文字列の場合
          if (isInputFlag && props == '') {
            // 入力不可の項目の場合で、値が空の場合
            val = '---'
          } else {
            val = props
          }
        } else if (Array.isArray(props)) {
          // 配列の場合
          if (isInputFlag && props.length == 0) {
            // 入力不可の項目の場合で、配列が空の場合
            val = '---'
          } else {
            val = props
          }
        } else {
          // オブジェクトの場合
          if (isInputFlag && Object.keys(props).length == 0) {
            // 入力不可の項目の場合で、オブジェクトが空の場合
            val = '---'
          } else {
            val = props
          }
        }
      }
      return val
    },

    /**
     * 発注処理 別タブで表示
     */
    async purchaseMail() {

      // ラウンドプラス、もしくは定期清掃、もしくは特別清掃あり
      let rlsPlusNum = 0
      let fixed = []
      let fixedGName = {}
      let sp = []
      let spGName = {}

      // 過去の受注データ
      if (!this.orderData.orderNew) {
        // ラウンドプラスの発注先を確認
        let erpArr = []
        if (this.orderData.estimationDetailsCategoryCode.indexOf('2') > -1) {
          // 過去の発注明細の場合のみ
          for (let i = 0; i < this.orderData.estimationRoundPlusSupplier.length; i++) {
            const rps = this.orderData.estimationRoundPlusSupplier[i].supplier
            if (rps.indexOf('アポロ') == -1 && rps.indexOf('RLS') == -1 && rps.indexOf('ラウンド') == -1 && rps.indexOf('建物営業部') == -1) {
              erpArr.push(rps)
            }
          }
          if (erpArr && erpArr.length >= 2) {
            await dialogs.showErrorDialog('ラウンドプラスの発注先', 'アポロ管財以外の発注先を複数指定しています。\r\n' + erpArr.join(',') + '\r\n1つに絞ってください。')
            return
          }
        }
        
        if (this.orderData.estimationDetailsCategoryCode.indexOf('2') > -1 || this.orderData.estimationDetailsCategoryCode.indexOf('5') > -1 || this.orderData.estimationDetailsCategoryCode.indexOf('14') > -1) {
          console.log(this.estimateData.details)
          for (let i = 0; i < this.estimateData.details.length; i++) {
            const d = this.estimateData.details[i]
            // 回数
            let num = Number(d.categoryQty)
            if (isNaN(d.categoryQty)) {
              num = 0
            }
            // 見積金額 (手数料込)
            let estimatePrice = Number(d.categoryUnitPrice)
            if (isNaN(d.categoryUnitPrice)) {
              estimatePrice = 0
            }

            // 見積りデータからラウンドプラスの回数を取得
            if (this.orderData.estimationDetailsCategoryCode.indexOf('2') > -1) {
              if (d.categoryCode == '2' && d.rowspan > 0) {
                rlsPlusNum = num
              }
            }

            // 見積りデータから定期清掃を取得
            if (this.orderData.estimationDetailsCategoryCode.indexOf('5') > -1) {
              if (d.categoryCode == '5' && d.type != 'fixedCalc' && d.fixedRowspan > 0) {
                // 同じグループネームがいくつあるかカウント
                if (fixedGName[d.groupName]) {
                  fixedGName[d.groupName]++
                } else {
                  fixedGName[d.groupName] = 1
                }
                // 手数料率取得
                let comRate = 0
                if (this.estimateData.commission) {
                  for (let j = 0; j < this.estimateData.commission.length; j++) {
                    const cc = this.estimateData.commission[j]
                    if (cc.groupName == d.groupName) {
                      if (isNaN(cc.rate)) {
                        comRate = 0
                      } else {
                        comRate = Number(cc.rate)
                      }
                    }
                  }
                }
                // 受注単価も手数料率もあった場合、手数料抜きを計算
                if (estimatePrice && comRate) {
                    estimatePrice = estimatePrice * (1 - comRate / 100)
                    estimatePrice = Math.floor(estimatePrice)
                }
                let row = {
                  // 内容
                  category: '定期清掃',
                  // 発注先名
                  supplier: '',
                  // 回数
                  num,
                  // 見積り金額(手数料なし)
                  estimatePrice,
                  // 発注率
                  rate: '',
                  // カテゴリコード
                  code: '5',
                  // グループネーム
                  groupName: d.groupName
                }
                fixed.push(row)
              }
            }

            // 見積りデータから特別清掃を取得
            if (this.orderData.estimationDetailsCategoryCode.indexOf('14') > -1) {
              if (d.categoryCode == '14') {
                if (spGName[d.groupName]) {
                  spGName[d.groupName]++
                } else {
                  spGName[d.groupName] = 1
                }
                // 手数料率取得
                let comRate = 0
                if (this.estimateData.commission) {
                  for (let j = 0; j < this.estimateData.commission.length; j++) {
                    const cc = this.estimateData.commission[j]
                    if (cc.groupName == d.groupName) {
                      if (isNaN(cc.rate)) {
                        comRate = 0
                      } else {
                        comRate = Number(cc.rate)
                      }
                    }
                  }
                }
                // 受注単価も手数料率もあった場合、手数料抜きを計算
                if (estimatePrice && comRate) {
                    estimatePrice = estimatePrice * (1 - comRate / 100)
                    estimatePrice = Math.floor(estimatePrice)
                }
                let row = {
                  category: d.itemName,
                  supplier: '',
                  num,
                  estimatePrice,
                  rate: '',
                  code: '14',
                  groupName: d.groupName
                }
                sp.push(row)
              }
            }
          }

          let changeFix = false
          let changeSp = false

          // 発注先と発注率を取得
          for (let j = 0; j < this.estimateData.purchase.length; j++) {
            const p = this.estimateData.purchase[j]
            let rate = Number(p.rate)
            if (isNaN(p.rate)) {
              rate = 0
            }
            
            // 定期清掃
            for (let k = 0; k < fixed.length; k++) {
              const f = fixed[k]
              if (f.groupName == p.groupName) {
                f.supplier = p.supplier
                f.rate = rate
                // グループネームが1行しかない場合は、発注金額を再計算せずに、記載された金額を採用
                if (fixedGName[f.groupName] == 1) {
                  f.price = Number(p.price)
                }
                // 発注先が変更されていた場合
                if (f.supplier != this.orderData.regularCleanSupplier) {
                  changeFix = true
                }
              }
            }
            // 特別清掃
            for (let l = 0; l < sp.length; l++) {
              const s = sp[l]
              if (s.groupName == p.groupName) {
                s.supplier = p.supplier
                s.rate = rate
                if (spGName[s.groupName] == 1) {
                  s.price = Number(p.price)
                }
                // 発注先が変更されていた場合
                if (s.supplier != this.orderData.orderSpecialCleans[l].specialCleanSupplier) {
                  changeSp = true
                }
              }
            }
          }
          // console.log(fixed)
          // console.log(sp)

          if (changeFix || changeSp) {
            let msg = ''
            if (changeFix) {
              msg = '定期清掃'
            }
            if (changeSp) {
              if (msg) {
                msg = msg + '、特別清掃'
              } else {
                msg = '特別清掃'
              }
            }
            await dialogs.showInfoDialog(msg, msg + 'の発注先は見積りで入力した発注先を取得します。')
          }
        }
      }

      // 区分
      if (!this.orderData.classification || !this.orderData.classification.length || !['1', '4', '5', '3'].includes(this.orderData.classification[0])) {
        let resDlog = await dialogs.showConfirmDialog('区分', '区分が選択されていません。\r\nメールの添付ファイル名に区分が表示されませんがよろしいでしょうか？')
        if (resDlog != 'YES') {
          return
        }
      }

      // セッションストレージに保存
      const d = {...this.orderData, site: this.estimateData.buildingName, siteAddress: this.estimateData.buildingAddress2, requestNo: this.requestPost.requestNo, rlsPlusNum, fixed, sp }
      const o = JSON.stringify(d)
      sessionStorage.setItem('PURCHASE_ORDER', o)
      let route = this.$router.resolve({
        name: 'PurchaseMail'
      });
      window.open(route.href, '_blank');
    },

    /**
     * 一度でも発注メールを送信したか判定
     */
    async checkAlreadySent() {
      if (this.orderData && this.orderData.orderNo && this.orderData.orderNo != '') {
        const res = await purchaseManager.getHistoryByOrderNo(this, this.orderData.orderNo)
        if (res && res.length) {
          this.isAlreadySent = true
        }
      }
    },

    /*
     * お問い合わせカテゴリーを過去の文言を新しくする
     * @param category お問い合わせカテゴリー
     */
    setNewCategory(category) {
      if (category && category.length) {
        for (let i = 0; i < category.length; i++) {
          const ca = category[i]
          if (ca == '契約依頼（発注・解約・変更）') {
            category[i] = '変更処理（解約・変更）'
          } else if (ca == '受注処理一覧') {
            category[i] = '受注処理（新規・金額変更）'
          }
        }
      }
      return category
    },

    /*
     * お見積りカテゴリーを過去の文言を新しくする
     * @param category お見積りカテゴリー
     */
    setNewEstimateCategory(category) {
      if (category && category.length) {
        for (let i = 0; i < category.length; i++) {
          const ca = category[i]
          if (ca == '各種特別清掃') {
            category[i] = '特別清掃'
          }
        }
      }
      return category
    },

    /**
     * 担当者・依頼主にログインユーザーをセット
     */
    setDefaultStaff() {
      const loginUser = this.$store.getters.user
      this.requestPost.toStaffName = loginUser.user_name
      this.requestPost.toStaffId = loginUser.id
      this.requestPost.byUser = loginUser.user_name
      this.requestPost.byUserId = loginUser.id
    },

    /**
     * 受注データを生成
     */
    setOrderData() {
      // 見積の選択ダイアログ表示
      this.selectEstimateForOrder = true
    },
    
    /**
     * 面接ダイアログopen
     */
    EmploymentData() {
      // 雇い入れ選択ダイアログ表示
      this.selectInterviewsForEmployment = true
    },
    
    /**
     * 受注する見積を選択イベント
     * @param est 選択した見積データ
     */
    async modalEstimateSelected(est) {
      // 見積の選択ダイアログ非表示
      this.selectEstimateForOrder = false
      // 見積データ
      this.estimateData = est
      // 見積に紐づくメインを取得
      const main = await requestPostManager.getRequestPost(this, est.requestPostId)

      // 選択した見積のデータを反映
      this.requestPost.category2 = ['社内']
      this.requestPost.requestNo = main.requestNo
      this.requestPost.clientName = est.clientName
      this.requestPost.clientCode = est.clientCode
      this.requestPost.clientAddress1 = est.clientAddress1
      this.requestPost.clientAddress2 = est.clientAddress2
      this.requestPost.clientPersonName = est.clientPersonName
      // アポロ管財依頼先部署にログインユーザーの部署をセット
      const ud = this.$store.getters.loginUserData
      this.requestPost.toDepartment = ''
      if (ud.departmentName) {
        this.requestPost.toDepartment = ud.departmentName
      }
      this.requestPost.toDepartmentId = ''
      if (ud.departmentIdUser) {
        this.requestPost.toDepartmentId = ud.departmentIdUser
      }     
      
      this.requestPost.limitDate = utils.addDays(utils.getToday(), 7)
      if (est.buildingName && est.buildingName != '') {
        this.requestPost.inquiry_title = est.buildingName + ' ' + '受注処理'
      } else {
        this.requestPost.inquiry_title = '受注処理'
      }
      // 受注フラグ
      this.isOrder = true
      // CSS判定_画面幅
      this.isCategory = false
      // お客様項目必須解除
      this.isClientRequired = false
      // スタッフリスト生成
      this.getStaffNameList(ud.departmentIdUser, false)
    },

    /**
     * 面接関連を選択イベント
     * @param emp 選択した面接関連タスクデータ
     */
    modalInterviewsSelected(emp) {
      // 面接関連の選択ダイアログ非表示
      this.selectInterviewsForEmployment = false

      // 期限を10日後にする
      this.requestPost.limitDate = moment().add(10, 'days').format('YYYY-MM-DD')

      // 依頼先部署を人事総務経理
      this.requestPost.toDepartment = '人事総務経理'
      let depInfo = this.toDepartmentList.find((d) => {
        return d.name == this.requestPost.toDepartment
      })
      this.toDepartmentSelected(depInfo)

      // 担当者は未定
      let mitei = this.$store.getters.userDepartment.find(ud => ud.departmentId == this.requestPost.toDepartmentId && ud.userName == '未定')
      if (mitei) {
        this.requestPost.toStaffId = mitei.userId
        this.requestPost.toStaffName = mitei.userName
      }

      // 社内
      this.requestPost.category2 = ['社内']
      
      //選択した面接者のデータを反映
      if (emp) {
        // 面接を選択している場合は、期限を21日後にする
        this.requestPost.limitDate = moment().add(21, 'days').format('YYYY-MM-DD')
        // 面接選択した
        this.requestPost.notNewEnter = false
        // 種別を新規入社にする
        this.requestPost.employmentClass = ['新規入社']
        // 面接データを引き継ぐ
        this.requestPost.requestNo = emp.requestNo
        this.requestPost.category2 = emp.category2
        this.requestPost.inquiry_title = emp.inquiry_title
        this.requestPost.reason_note = emp.reason_note
        this.requestPost.clientName = emp.clientName
        this.requestPost.clientCode = emp.clientCode
        this.requestPost.clientAddress1 = emp.clientAddress1
        this.requestPost.clientAddress2 = emp.clientAddress2
        this.requestPost.clientPersonName = emp.clientPersonName
        this.requestPost.employmentHireDate = emp.interviewsHireDate
        this.requestPost.employeeName = emp.employeeName
        this.requestPost.telephoneNumber = emp.telephoneNumber
        this.requestPost.clientMail = emp.clientMail
        this.requestPost.employmentName = emp.interviewsName
        this.requestPost.employmentKana = emp.interviewsKana
        this.requestPost.employmentBirthDay = emp.interviewsBirthDay
        this.requestPost.employmentAge = emp.interviewsAge
        this.requestPost.employmentSex = emp.interviewsSex
        this.requestPost.employmentPostCode = emp.interviewsPostCode
        this.requestPost.employmentAddress1 = emp.interviewsAddress1
        this.requestPost.employmentAddress2 = emp.interviewsAddress2
        this.requestPost.employmentTel =  emp.interviewsTel
        this.requestPost.employmentMobile = emp.interviewsMobile
        this.requestPost.employmentEmergencyContactKana = emp.interviewsEmergencyContactKana
        this.requestPost.employmentEmergencyContact = emp.interviewsEmergencyContact
        this.requestPost.employmentEmergencyAddress = emp.interviewsEmergencyAddress
        this.requestPost.employmentEmergencyPhoneNumber = emp.interviewsEmergencyPhoneNumber
        this.requestPost.employmentEmergencyRelationship = emp.interviewsEmergencyRelationship
        this.requestPost.employmentCustomer = emp.clientName
        this.requestPost.employmentHourlyWage = emp.interviewsHourlyWage
        this.requestPost.employmentBuildingName = emp.interviewsAppliedWorkLocationAddress
        this.requestPost.employmentSiteCode = emp.interviewsSiteCode
        this.requestPost.employmentSiteAddress = emp.interviewsSiteAddress
        this.requestPost.employmentArea = emp.interviewsArea
        this.requestPost.employmentAppliedWork = utils.clone(emp.interviewsAppliedWork)
        this.requestPost.employmentMaterial = utils.clone(emp.interviewsMaterial)

        // 履歴書をコピーする
        this.requestPost.employmentDocument_display_file_name = emp.interviewsDocument_display_file_name
        this.requestPost.employmentDocument = utils.clone(emp.interviewsDocument)
        this.requestPost['employmentDocument_file_content'] = emp.interviewsDocument.url
        this.requestPost.employmentDocument_file_name = emp.interviewsDocument_display_file_name

        // リリース後、追加になったため、プロパティがない場合もある
        this.requestPost.employmentWorkTask = this.getInterviewsValue(emp.interviewsWorkTask, this.requestPost.employmentWorkTask)
        this.requestPost.employmentHolidayType = this.getInterviewsValue(emp.interviewsHolidayType, this.requestPost.employmentHolidayType)
        this.requestPost.employmentTrashRemoval = this.getInterviewsValue(emp.interviewsTrashRemoval, this.requestPost.employmentTrashRemoval)

        this.requestPost.employmentTrainType = this.getInterviewsValue(emp.interviewsTrainType, this.requestPost.employmentTrainType)
        this.requestPost.employmentTrainExpenses = this.getInterviewsValue(emp.interviewsTrainExpenses, this.requestPost.employmentTrainExpenses)
        this.requestPost.employmentBusType = this.getInterviewsValue(emp.interviewsBusType, this.requestPost.employmentBusType)
        this.requestPost.employmentBusExpenses = this.getInterviewsValue(emp.interviewsBusExpenses, this.requestPost.employmentBusExpenses)
        this.requestPost.employmentParkingType = this.getInterviewsValue(emp.interviewsParkingType, this.requestPost.employmentParkingType)
        this.requestPost.employmentParkingExpenses = this.getInterviewsValue(emp.interviewsParkingExpenses, this.requestPost.employmentParkingExpenses)

        // 得意先コードからFAX番号を取得する
        if (emp.clientCode && this.clientLists) {
          const client = this.clientLists.find((c) => {
            return c.clientCode == emp.clientCode
          })
          if (client && client.clientFax) {
            // 得意先名と得意先コードが合っている場合のみFAX番号取得
            if (this.checkClientCode(client)) {
              this.requestPost.clientFax = client.clientFax
            }
          }
        }

        // 新規登録の際に使用する面接のID
        this.interviewsId = emp._id
      }

      this.category2Changed()
    },

    /**
     * 得意先コードと得意先名が合っているか
     * @param selectedClient 得意先コードから取得した情報
     */
    checkClientCode(selectedClient) {
      const name = selectedClient.clientName + ' ' + selectedClient.clientDepartmentName
      if (name == this.requestPost.clientName) {
        return true
      }
      return false
    },

    /**
     * データがあれば、面接内容を取得
     * @param inter 面接対象データ
     * @param emp 雇い入れプロパティ
     */
    getInterviewsValue(inter, emp) {
      if (inter && inter.length) {
        return inter
      }
      // 配列の場合
      if (Array.isArray(emp)) {
        return []
      // 文字列の場合
      } else {
        return ''
      }
    },

    /**
     * 非公開依頼 参照権限 チェック
     * @param {*} requestPost リクエストポスト
     * @param {*} subTasks サブタスク
     */
    isPrivateAuthorized(requestPost, subTasks) {
      // 担当者もしくは依頼主
      if (requestPost.toStaffId == this.$store.getters.user.id || requestPost.byUserId == this.$store.getters.user.id) return true
      // サブ依頼担当者
      if (subTasks.find(task => task.toStaffId == this.$store.getters.user.id)) return true
      return false
    },

    /**
     * 生年月日の初期値を取得
     *  60年前の1月1日
     */
    getInitBirthDate(){
      return String(moment().format('YYYY') - 60 + '-01-01')
    },

    /**
     * 面接のタイトル設定
     * @param val 対象データ
     */
    getTitle(val) {
      // 採否結果
      let res = ''
      // 名前
      let name = ''
      // 物件名
      let site = ''

      if (val && val.interviewsResult && val.interviewsResult.length) {
        res = val.interviewsResult[0]
      }
      if (val && val.name) {
        name = val.name
      }
      if (val && val.site) {
        site = val.site
      }

      this.requestPost.inquiry_title = res + '　' + name + '　' + site
      if (this.requestPost.inquiry_title) {
        this.requestPost.inquiry_title = this.requestPost.inquiry_title.trim()
      }
    },

    /**
     * 新規入社以外の雇い入れタイトル設定
     * @param val 対象データ
     */
    getTitle2(val) {
      // 種別
      let cl = ''
      // 名前
      let name = ''
      // 物件名
      let site = ''

      if (val && val.employmentClass && val.employmentClass.length) {
        cl = val.employmentClass[0]
      }
      if (val && val.employmentName) {
        name = val.employmentName
      }
      if (val && val.employmentBuildingName) {
        site = val.employmentBuildingName
      }
      this.requestPost.inquiry_title = cl + '　' + name + '　' + site
      if (this.requestPost.inquiry_title) {
        this.requestPost.inquiry_title = this.requestPost.inquiry_title.trim()
      }
    },

    /**
     * 面接のお客様会社名必須か否か
     */
    changeClientRequire(val) {
      this.interviewsClientRequire = val
    }
  }
}
</script>

<style>
#estimation-clean-category div button[type=button] {
  width: 19% !important;
}

#inquiry-category div button[type=button] {
  width: 19% !important;
}

div.rp-estimate {
  flex: 1
}

div.rp-no-estimate {
  display: none !important
}

</style>