<template>
  <div class="flex-1 p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 self-start">
    <InputGroupLabel
      name="categoryTitle"
      caption="採否"
      class="mt-4"
    />

    <div class="sm:col-span-full">
      <SelectionButton2
        name="AcceptanceSelections" 
        v-model="requestPost.interviewsResult"
        :item ="AcceptanceSelections"
        :multiSelect="false"
        :disabled="referenceMode"
        @change="setTitle()"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsDateInput
        v-if="!referenceMode"
        name="interviewsHireDate"
        caption="入社日" 
        v-model="requestPost.interviewsHireDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        :required="isAdopt"
        :error="!requireValidateStr('interviewsHireDate', '入社日').result"
        :errorMessage="requireValidateStr('interviewsHireDate', '入社日').message"
      />
      <WmsTextInput
        v-else
        name="interviewsHireDate"
        caption="入社日" 
        :disabled="true"
        v-model="requestPost.interviewsHireDate"
      />
    </div>

    <InputGroupLabel
      name="categoryTitle"
      caption="面接シート"
      class="mt-4"
    />

    <div class="sm:col-span-full">
      <WmsDateInput
        v-if="!referenceMode"
        name="interviewsInputDay"
        caption="入力日" 
        v-model="requestPost.interviewsInputDay"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
      />
      <WmsTextInput
        v-else
        name="interviewsInputDay"
        caption="入力日" 
        :disabled="true"
        v-model="requestPost.interviewsInputDay"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsKana" 
        caption="フリガナ"
        v-model="requestPost.interviewsKana"
        :disabled="referenceMode"
        :required="true"
        :error="!requireValidateStr('interviewsKana', 'フリガナ').result"
        :errorMessage="requireValidateStr('interviewsKana', 'フリガナ').message"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsName" 
        caption="氏名"
        v-model="requestPost.interviewsName"
        :disabled="referenceMode"
        @change="setTitle()"
        :required="true"
        :error="!requireValidateStr('interviewsName', '氏名').result"
        :errorMessage="requireValidateStr('interviewsName', '氏名').message"
      />
    </div>

    <div class="sm:col-span-3">
      <label for="interviewsPostCode" class="block text-xs font-bold text-gray-700 relative">
        郵便番号
        <span class="absolute -top-0.5 ml-1 text-red-500"> * </span>
        <span v-if="errPostCode" class="text-xs font-bold text-red-500 ml-5">{{ errPostCode }}</span>
      </label>
      <div class="mt-1 flex relative">
        <InputIcon v-if="!requireValidateStr('interviewsPostCode', '郵便番号').result" iconName="ExclamationCircle" iconColor="red" :message="requireValidateStr('interviewsPostCode', '郵便番号').message" />
        <input
          type="tel" 
          name="interviewsPostCode"
          v-model="requestPost.interviewsPostCode"
          :disabled="referenceMode"
          class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
          :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
          @keypress="onKeyPressTel"
        />
      </div>
    </div>
    <div class="sm:col-span-3 adressbutton" v-if="!referenceMode">
      <PrimaryButton text="住所検索" class="w-40 adressbutton bg-green-500" @click="addressSearch()">
      </PrimaryButton>

      <PrimaryButton text="住所から取得" class="w-40 bg-green-500" @click="postalCodeSearch()">
      </PrimaryButton>
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsAddress1" 
        caption="住所１"
        v-model="requestPost.interviewsAddress1"
        :disabled="referenceMode"
        :required="true"
        :error="!requireValidateStr('interviewsAddress1', '住所１').result"
        :errorMessage="requireValidateStr('interviewsAddress1', '住所１').message"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsAddress2"
        caption="住所２"
        v-model="requestPost.interviewsAddress2"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <label for="interviewsTel" class="block text-xs font-bold text-gray-700">固定電話</label>
      <div class="mt-1 flex relative">
        <input
          type="tel" 
          name="interviewsTel"
          v-model="requestPost.interviewsTel"
          :disabled="referenceMode"
          class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
          :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
          @keypress="onKeyPressTel"
        />
      </div>
    </div>

    <div class="sm:col-span-full">
      <label for="interviewsMobile" class="block text-xs font-bold text-gray-700 relative">
        携帯電話
        <span class="absolute -top-0.5 ml-1 text-red-500"> * </span>
        <span v-if="errMobile" class="text-xs font-bold text-red-500 ml-5">{{ errMobile }}</span>
      </label>
      <div class="mt-1 flex relative">
        <InputIcon v-if="!requireValidateStr('interviewsMobile', '携帯電話').result" iconName="ExclamationCircle" iconColor="red" :message="requireValidateStr('interviewsMobile', '携帯電話').message" />
        <input
          type="tel" 
          name="interviewsMobile"
          v-model="requestPost.interviewsMobile"
          :disabled="referenceMode"
          class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
          :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
          @keypress="onKeyPressTel"
        />
      </div>
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="interviewsSex" 
        caption="性別"
        v-model="requestPost.interviewsSex"
        :item ="sexSelections"
        :multiSelect="false"
        :disabled="referenceMode"
        :required="isAdopt"
        :error="!requireValidateArr('interviewsSex', '性別').result"
        :errorMessage="requireValidateArr('interviewsSex', '性別').message"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsDateInput
        v-if="!referenceMode"
        name="interviewsBirthDay"
        caption="生年月日" 
        v-model="requestPost.interviewsBirthDay"
        @change="ageCalculation"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        :required="true"
        :error="!requireValidateStr('interviewsBirthDay', '生年月日').result"
        :errorMessage="requireValidateStr('interviewsBirthDay', '生年月日').message"
      />
      <WmsTextInput
        v-else
        name="interviewsBirthDay"
        caption="生年月日"  
        :disabled="true"
        v-model="requestPost.interviewsBirthDay"
      />
    </div>

    <div class="sm:col-span-full text-blue-800">
      <div v-if="requestPost.interviewsBirthDay" class="mt-auto mb-2 mx-3 text-sm">
        和暦： {{ japaneseDate(requestPost.interviewsBirthDay) }}
      </div>
    </div>

    <div class="sm:col-span-full">
      <NumericTextInput2 
        name="interviewsAge" 
        caption="年齢"
        maxLenght="3"
        v-model="requestPost.interviewsAge"
        :disabled="referenceMode"
        :required="true"
        :error="!requireValidateStr('interviewsAge', '年齢').result"
        :errorMessage="requireValidateStr('interviewsAge', '年齢').message"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsNearestStationFromHome"
        caption="御自宅からの最寄駅"
        v-model="requestPost.interviewsNearestStationFromHome"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full grid grid-cols-10 mt-10">
      <div class="sm:col-span-9">
        <InputGroupLabel
          name="familyTitle"
          caption="ご家族について"
        />
      </div>

      <!-- 家族追加ボタン -->
      <div class="addButton-contents sm:col-span-1" v-if="!referenceMode">
        <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'追加'" @click="addFamilyMember">
          <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
        </PrimaryButton>
      </div>
    </div>

    <div v-for="(FamilyMember, index) in requestPost.interviewsFamilyMembers" v-bind:key="FamilyMember.id"  class="sm:col-span-full">
      <div class="sm:col-span-full grid grid-cols-10">
        <div class="sm:col-span-1 pr-1">
          <WmsTextInput
            name="interviewsFamilyRelationship"
            caption="関係"
            v-model="FamilyMember.interviewsFamilyRelationship"
            :disabled="referenceMode"
            />
        </div>
      
        <div class="sm:col-span-2 pr-1">
          <WmsTextInput
            name="interviewsFamilyName"
            caption="氏名"
            v-model="FamilyMember.interviewsFamilyName"
            :disabled="referenceMode"
          />
        </div>
      
        <div class="sm:col-span-4 pr-1">
          <WmsTextInput
            name="interviewsFamilyAddress"
            caption="住所"
            v-model="FamilyMember.interviewsFamilyAddress"
            :disabled="referenceMode"
          />
        </div>
      
        <div class="sm:col-span-2 pr-1">
          <label for="interviewsFamilyPhoneNumber" class="block text-xs font-bold text-gray-700">電話番号</label>
          <div class="mt-1 flex relative">
            <input
              type="tel" 
              name="interviewsFamilyPhoneNumber"
              v-model="FamilyMember.interviewsFamilyPhoneNumber"
              :disabled="referenceMode"
              class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
              :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
              @keypress="onKeyPressTel"
            />
          </div>
        </div>

        <!-- 家族削除ボタン -->
        <div class="sm:col-span-1 deleteButton-contents mt-5" v-if="!referenceMode">
          <PrimaryButton class="bg-red-500" size="other" v-tooltip="'削除'" @click="removeFamilyMember(index)">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>

    <InputGroupLabel
      name="categoryTitle"
      caption="緊急連絡先"
      class="mt-10"
    />

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsEmergencyContactKana"
        caption="フリガナ"
        v-model="requestPost.interviewsEmergencyContactKana"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsEmergencyContact"
        caption="氏名"
        v-model="requestPost.interviewsEmergencyContact"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsEmergencyRelationship"
        caption="御関係"
        v-model="requestPost.interviewsEmergencyRelationship"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsEmergencyAddress"
        caption="住所"
        v-model="requestPost.interviewsEmergencyAddress"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <label for="interviewsEmergencyPhoneNumber" class="block text-xs font-bold text-gray-700">電話番号</label>
      <div class="mt-1 flex relative">
        <input
          type="tel" 
          name="interviewsEmergencyPhoneNumber"
          v-model="requestPost.interviewsEmergencyPhoneNumber"
          :disabled="referenceMode"
          class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
          :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
          @keypress="onKeyPressTel"
        />
      </div>
    </div>

    <InputGroupLabel class="mt-1"/>

    <div class="sm:col-span-full mt-10">
      <NumericTextInput2 
        name="interviewsHourlyWage" 
        caption="時給"
        v-model="requestPost.interviewsHourlyWage"
        @change = "limitHourlyWageInput"
        :disabled="referenceMode"
        :required="isAdopt"
        :error="!requireValidateStr('interviewsHourlyWage', '時給').result"
        :errorMessage="requireValidateStr('interviewsHourlyWage', '時給').message"
      />
    </div>

    <div class="sm:col-span-full mt-5">
      <WmsSuggestInput
        name="interviewsAppliedWorkLocationAddress" 
        caption="ご応募いただいた勤務物件"
        :selectionItems="buildingList"
        displayField="nameSite"
        v-model="requestPost.interviewsAppliedWorkLocationAddress"
        :disabled="referenceMode"
        :required="isAdopt"
        :error="!requireValidateStr('interviewsAppliedWorkLocationAddress', 'ご応募いただいた勤務物件').result"
        :errorMessage="requireValidateStr('interviewsAppliedWorkLocationAddress', 'ご応募いただいた勤務物件').message"
        @selected="siteSelected($event)"
        @change="siteChanged()"
        @blur="setTitle()"
      >
        <!-- 検索アイコン -->
        <template #addon-content>
          <Icon
            iconName="Search" 
            :clickable="true" 
            class="w-4 h-4 mr-0.5"
            @click="openSiteModal()"
          />
        </template>
      </WmsSuggestInput>
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsSiteCode" 
        caption="物件コード"
        v-model="requestPost.interviewsSiteCode"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full five-selection">
      <SelectionButton2
        name="interviewsWorkTask" 
        caption="作業内容"
        v-model="requestPost.interviewsWorkTask"
        :item="workSelections"
        :multiSelect="false"
        :disabled="referenceMode"
        :required="isAdopt"
        :error="!requireValidateArr('interviewsWorkTask', '作業内容').result"
        :errorMessage="requireValidateArr('interviewsWorkTask', '作業内容').message"
      />
    </div>

    <div class="sm:col-span-full five-selection">
      <SelectionButton2
        name="interviewsHolidayType" 
        caption="祝日区分"
        v-model="requestPost.interviewsHolidayType"
        :item="holidayType"
        :disabled="referenceMode"
        :required="isAdopt"
        :error="!requireValidateArr('interviewsHolidayType', '祝日区分').result"
        :errorMessage="requireValidateArr('interviewsHolidayType', '祝日区分').message"
      />
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="interviewsTrashRemoval" 
        caption="ゴミ搬出"
        v-model="requestPost.interviewsTrashRemoval"
        :item="trashRemoval"
        :multiSelect="false"
        :disabled="referenceMode"
        :required="isAdopt"
        :error="!requireValidateArr('interviewsTrashRemoval', 'ゴミ搬出').result"
        :errorMessage="requireValidateArr('interviewsTrashRemoval', 'ゴミ搬出').message"
      />
    </div>
    
    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsSiteAddress" 
        caption="物件住所"
        v-model="requestPost.interviewsSiteAddress"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full relative">
      <div class="text-xs text-gray-600 font-bold relative" style="margin-bottom:4px;">
        エリア <span v-if="isAdopt" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
      </div>
      <div class="relative">
        <InputIcon v-if="!requireValidateStr('interviewsArea', 'エリア').result" iconName="ExclamationCircle" iconColor="red" :message="requireValidateStr('interviewsArea', 'エリア').message" />
        <select
          class="w-full rounded border-gray-300 text-sm"
          v-model="requestPost.interviewsArea"
          :disabled="referenceMode"
          :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-800':''"
        >
          <option v-for="(row, index) in areaSelections" :value="row" :key="index">
            {{ row }}
          </option>
        </select>
      </div>
    </div>

      <InputGroupLabel
        name="categoryTitle"
        caption="交通費（不要であれば0を入力）"
        class="mt-10"
      />

      <div class="sm:col-span-full grid grid-cols-10">
        <div class="sm:col-span-5 mr-5">
          <SelectionButton2
            name="interviewsTrainType" 
            caption="電車 区分"
            v-model="requestPost.interviewsTrainType"
            :item="provisionSelections"
            :multiSelect="false"
            :disabled="referenceMode"
            @change="changeTrans('interviewsTrainType', 'interviewsTrainExpenses')"
          />
        </div>

        <div class="sm:col-span-5">
          <NumericTextInput2 
            name="interviewsTrainExpenses" 
            caption="電車 交通費"
            maxLenght="5"
            v-model="requestPost.interviewsTrainExpenses"
            :disabled="referenceMode"
          />
        </div>
      </div>

      <div class="sm:col-span-full grid grid-cols-10">
        <div class="sm:col-span-5 mr-5">
          <SelectionButton2
            name="interviewsBusType" 
            caption="バス 区分"
            v-model="requestPost.interviewsBusType"
            :item="provisionSelections"
            :multiSelect="false"
            :disabled="referenceMode"
            @change="changeTrans('interviewsBusType', 'interviewsBusExpenses')"
          />
        </div>

        <div class="sm:col-span-5">
          <NumericTextInput2 
            name="interviewsBusExpenses" 
            caption="バス 交通費"
            maxLenght="5"
            v-model="requestPost.interviewsBusExpenses"
            :disabled="referenceMode"
          />
        </div>
      </div>

      <div class="sm:col-span-full grid grid-cols-10">
        <div class="sm:col-span-5 mr-5">
          <SelectionButton2
            name="interviewsParkingType" 
            caption="駐輪 区分"
            v-model="requestPost.interviewsParkingType"
            :item="provisionSelections"
            :multiSelect="false"
            :disabled="referenceMode"
            @change="changeTrans('interviewsParkingType', 'interviewsParkingExpenses')"
          />
        </div>

        <div class="sm:col-span-5">
          <NumericTextInput2 
            name="interviewsParkingExpenses" 
            caption="駐輪 交通費"
            maxLenght="5"
            v-model="requestPost.interviewsParkingExpenses"
            :disabled="referenceMode"
          />
        </div>
      </div>

      <InputGroupLabel class="mt-1 mb-10" />

    <div v-for="(aw, i) in requestPost.interviewsAppliedWork" v-bind:key="'AppliedWork' + i"  class="sm:col-span-full">
      <div class="flex mb-2">
        <div id="interviewsAppliedWorkDays" class=" mr-3">
          <SelectionButton2
            name="interviewsAppliedWorkDays"
            caption="ご応募いただいた勤務曜日"
            v-model="aw.days"
            :item ="daysSelection"
            :multiSelect="true"
            :disabled="referenceMode"
            :required="isAdopt"
            :error="!requireValidateArr2(aw.days, 'ご応募いただいた勤務曜日').result"
            :errorMessage="requireValidateArr2(aw.days, 'ご応募いただいた勤務曜日').message"
          />
        </div>

        <div class="">
          <div class="flex">
            <div class="">
              <WmsTimeInput2
                name="interviewsAppliedWorkStartTime" 
                caption="勤務時間"
                v-model="aw.startTime"
                class="time"
                :clearable="true"
                :hourItems="hourItems"
                :minuteItems="minuteItems"
                :disabled="referenceMode"
                :required="isAdopt"
                :error="!requireValidateArr2(aw.startTime, '勤務時間').result"
                :errorMessage="requireValidateArr2(aw.startTime, '勤務時間').message"
                @change="changeWorkingTime(i)"
              />
            </div>
            <div class="mt-6 mx-1 font-bold">～</div>
            <div class="mt-4">
              <WmsTimeInput2
                name="interviewsAppliedWorkEndTime" 
                caption=""
                v-model="aw.endTime"
                class="time"
                :clearable="true"
                :hourItems="hourItems"
                :minuteItems="minuteItems"
                :disabled="referenceMode"
                :error="!requireValidateArr2(aw.endTime, '勤務時間').result"
                :errorMessage="requireValidateArr2(aw.endTime, '勤務時間').message"
                @change="changeWorkingTime(i)"
              />
            </div>

            <!-- 勤務時間 -->
            <div class="text-sm ml-2 mt-auto mb-1 min-w-fit">
              ( {{ workingHours[i] }} h )
            </div>
          </div>

          <div class="text-xs mt-1 text-gray-700 font-bold">休憩時間 ※6時間以上の場合のみ</div>
          <div class="flex">
            <div class="">
              <WmsTimeInput2
                name="interviewsAppliedWorkStartBreakTime" 
                caption=""
                v-model="aw.startBreakTime"
                class="time"
                :clearable="true"
                :hourItems="hourItems"
                :minuteItems="minuteItems"
                :disabled="referenceMode"
              />
            </div>
            <div class="mt-4 mx-1 font-bold">～</div>
            <div class="">
              <WmsTimeInput2
                name="interviewsAppliedWorkEndBreakTime" 
                caption=""
                v-model="aw.endBreakTime"
                class="time"
                :clearable="true"
                :hourItems="hourItems"
                :minuteItems="minuteItems"
                :disabled="referenceMode"
              />
            </div>
          </div>
        </div>

        <!-- 勤務曜日・時間 追加ボタン -->
        <div class="addButton-contents mt-auto ml-auto" v-if="!referenceMode && i==0">
          <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'追加'" @click="addAppliedWork()">
            <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
        <!-- 勤務曜日・時間 削除ボタン -->
        <div class=" mt-auto text-right ml-auto" v-else-if="!referenceMode">
          <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeAppliedWork(i)">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>

    <div class="sm:col-span-full mt-5">
      <SelectionButton2
        name="interviewsOtherPropertyConsultation"
        caption="ご応募の物件ではなく、別の物件の相談は可能ですか？"
        v-model="requestPost.interviewsOtherPropertyConsultation"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsDesiredDateAndTime"
        caption="その際の希望日数時間"
        v-model="requestPost.interviewsDesiredDateAndTime"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="interviewsSubstituteWork"
        caption="代行勤務は可能ですか？"
        v-model="requestPost.interviewsSubstituteWork"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsPossibleDateAndTime"
        caption="可能日数時間"
        v-model="requestPost.interviewsPossibleDateAndTime"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <WmsDateInput
        v-if="!referenceMode"
        name="interviewsEarliestStartDate"
        caption="最短の勤務開始可能日" 
        v-model="requestPost.interviewsEarliestStartDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
      />
      <WmsTextInput
        v-else
        name="interviewsEarliestStartDate"
        caption="最短の勤務開始可能日" 
        :disabled="true"
        v-model="requestPost.interviewsEarliestStartDate"
      />
    </div>


    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="interviewsSmoking"
        caption="タバコは吸われますか？"
        v-model="requestPost.interviewsSmoking"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="interviewsSmokingBan"
        caption="はいの場合は、通勤経路、就業時間中、職場内での禁煙出来ますか？"
        v-model="requestPost.interviewsSmokingBan"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="alcoholConsumption"
        caption="飲酒はされますか？"
        v-model="requestPost.interviewsAlcoholConsumption"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="interviewsPersonalHygiene"
        caption="身だしなみ、髪、爪等は清潔に保てますか。"
        v-model="requestPost.interviewsPersonalHygiene"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="transferAfterProbation"
        caption="試用期間終了後の異動可否"
        v-model="requestPost.interviewsTransferAfterProbation"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>


    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="monthlyLeaveForFamily"
        caption="ご家庭等の都合で月1回以上お休みしなければならないことがありますか？"
        v-model="requestPost.interviewsMonthlyLeaveForFamily"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsReasonForLeave"
        caption="はいの場合理由"
        v-model="requestPost.interviewsReasonForLeave"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="interviewsResignationNotice"
        caption="止むを得ない理由により退職する際は、退職希望日の1ヶ月前に申し入れできますか？"
        v-model="requestPost.interviewsResignationNotice"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="interviewsContactBeforeLeave"
        caption="お休みの場合は最低でも2週間前までに必ず会社に連絡ができますか？(急病や不幸は除きます。)"
        v-model="requestPost.interviewsContactBeforeLeave"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="interviewsCleaningExperience"
        caption="清掃に関する仕事の経験はありますか？"
        v-model="requestPost.interviewsCleaningExperience"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="interviewsChronicDisease"
        caption="持病はありますか？"
        v-model="requestPost.interviewsChronicDisease"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>
    <div id="diseaseDiv" class="sm:col-span-full">
      <SelectionButton2
        name="interviewsApplicableDiseases"
        caption="はいの方は当てはまるものに〇を付けてください。"
        v-model="requestPost.interviewsApplicableDiseases"
        :item="diseaseSelections"
        :multiSelect="true"
        :disabled="referenceMode"
      />
    </div>
    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsOtherConditions"
        caption="その他"
        v-model="requestPost.interviewsOtherConditions"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="interviewsCurrentMedication"
        caption="現在服用のお薬はありますか？"
        v-model="requestPost.interviewsCurrentMedication"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>
    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsMedicationDetails"
        caption="はいの方はお薬の種類を記載ください。"
        v-model="requestPost.interviewsMedicationDetails"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <SelectionButton2
        name="interviewsHealthConcerns"
        caption="上記ご病気以外において体調で気になる部分はありますか？"
        v-model="requestPost.interviewsHealthConcerns"
        :item ="yesnoSelections"
        :multiSelect="false"
        :disabled="referenceMode"
      />
    </div>
    <div class="sm:col-span-full">
      <WmsTextInput
        name="interviewsHealthConcernsDetails"
        caption="はいの場合"
        v-model="requestPost.interviewsHealthConcernsDetails"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <NumericTextInput2 
        name="interviewsCognitiveTestScore" 
        caption="認知テスト点数"
        maxLenght="3"
        v-model="requestPost.interviewsCognitiveTestScore"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <SelectionButton2
        name="interviewsMaterial"
        caption="必要な書類"
        v-model="requestPost.interviewsMaterial"
        :item="materialSelections"
        :multiSelect="true"
        :disabled="referenceMode"
        :required="isAdopt"
        :error="!requireValidateArr('interviewsMaterial', '必要な書類').result"
        :errorMessage="requireValidateArr('interviewsMaterial', '必要な書類').message"
      />
    </div>

    <div class="sm:col-span-full mt-7">
      <WmsTextInput 
        name="interviewsInterviewNotes" 
        caption="面接管記入欄（評価・寸評）"
        v-model="requestPost.interviewsInterviewNotes"
        :multiline="true"
        :rows="5"
        :disabled="referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <label class="block text-xs font-bold text-gray-700">
        認知症テスト結果
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="interviewsCognitiveRes" 
            style="max-width: none!important;"
            :value="requestPost.interviewsCognitiveRes"
            :disabled="referenceMode"
            @change="attachmentChange('interviewsCognitiveRes', $event)" 
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full">
      <label class="block text-xs font-bold text-gray-700 relative">
        添付資料（履歴書）<span v-if="isAdopt" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="interviewsDocument" 
            style="max-width: none!important;"
            :value="requestPost.interviewsDocument"
            :disabled="referenceMode"
            @change="attachmentChange('interviewsDocument', $event)" 
            :errorMessage="requireValidateAttach('interviewsDocument', '履歴書').message"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full">
      <label class="block text-xs font-bold text-gray-700 relative">
        添付資料（紹介状・他）
      </label>
      <div class="mt-1 flex">
        <div class="w-full">
          <WmsAttachment 
            name="commonFile" 
            style="max-width: none!important;"
            :value="requestPost.commonFile"
            :disabled="referenceMode"
            @change="attachmentChange('commonFile', $event)" 
          />
        </div>
        <!-- 添付資料（紹介状・他） 追加ボタン -->
        <div class="addButton-contents ml-5 my-auto" v-if="!referenceMode">
          <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'追加'" @click="addCommonFile()">
            <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>

    <div  v-if="requestPost.interviewsCommonFileList&&requestPost.interviewsCommonFileList.includes(2)" class="sm:col-span-full">
      <label class="block text-xs font-bold text-gray-700 relative">
        添付資料（紹介状・他）
      </label>
      <div class="mt-1 flex">
        <div class="w-full">
          <WmsAttachment 
            name="commonFile2" 
            style="max-width: none!important;"
            :value="requestPost.commonFile2"
            :disabled="referenceMode"
            @change="attachmentChange('commonFile2', $event)" 
          />
        </div>
        <!-- 添付資料（紹介状・他） 削除ボタン -->
        <div class="ml-5 my-auto" v-if="!referenceMode">
          <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeCommonFile(2)">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>
    <div v-if="requestPost.interviewsCommonFileList&&requestPost.interviewsCommonFileList.includes(3)" class="sm:col-span-full">
      <label class="block text-xs font-bold text-gray-700 relative">
        添付資料（紹介状・他）
      </label>
      <div class="mt-1 flex">
        <div class="w-full">
          <WmsAttachment 
            name="commonFile3" 
            style="max-width: none!important;"
            :value="requestPost.commonFile3"
            :disabled="referenceMode"
            @change="attachmentChange('commonFile3', $event)" 
          />
        </div>
        <!-- 添付資料（紹介状・他） 削除ボタン -->
        <div class="ml-5 my-auto" v-if="!referenceMode">
          <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeCommonFile(3)">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>
    <div v-if="requestPost.interviewsCommonFileList&&requestPost.interviewsCommonFileList.includes(4)" class="sm:col-span-full">
      <label class="block text-xs font-bold text-gray-700 relative">
        添付資料（紹介状・他）
      </label>
      <div class="mt-1 flex">
        <div class="w-full">
          <WmsAttachment 
            name="commonFile4" 
            style="max-width: none!important;"
            :value="requestPost.commonFile4"
            :disabled="referenceMode"
            @change="attachmentChange('commonFile4', $event)" 
          />
        </div>
        <!-- 添付資料（紹介状・他） 削除ボタン -->
        <div class="ml-5 my-auto" v-if="!referenceMode">
          <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeCommonFile(4)">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>
    <div v-if="requestPost.interviewsCommonFileList&&requestPost.interviewsCommonFileList.includes(5)" class="sm:col-span-full">
      <label class="block text-xs font-bold text-gray-700 relative">
        添付資料（紹介状・他）
      </label>
      <div class="mt-1 flex">
        <div class="w-full">
          <WmsAttachment 
            name="commonFile5" 
            style="max-width: none!important;"
            :value="requestPost.commonFile5"
            :disabled="referenceMode"
            @change="attachmentChange('commonFile5', $event)" 
          />
        </div>
        <!-- 添付資料（紹介状・他） 削除ボタン -->
        <div class="ml-5 my-auto" v-if="!referenceMode">
          <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeCommonFile(5)">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  <div class="text-transparent">{{reload}}</div>

  <!-- 物件選択モーダル -->
  <SiteSelectorModal
    v-model="siteModal"
    :open="siteModal"
    @modalSiteSelected="siteSelected"
  />
  </div>
</template>
  
<script>
  import Icon from '@components/Icon.vue'
  import InputIcon from '@components/InputIcon.vue'
  import PrimaryButton from '@components/PrimaryButton.vue'
  import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
  import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
  import * as utils from '@libs/utils'
  import * as dialogs from '@libs/dialogs'
  import * as constants from '@libs/constants'
  import * as helper from '@libs/helper'
  import * as backend from '@libs/backend'
  import moment from 'moment'
  import NumericTextInput2 from '@components/NumericTextInput2.vue'
  import SelectionButton2 from '@components/SelectionButton2.vue'
  import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
  import InputGroupLabel from '../components/InputGroupLabel.vue'
  import WmsAttachment from '@wmscomponents/WmsAttachment.vue'
  import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
  import SiteSelectorModal from '../components/SiteSelectorModal.vue'

  const ACCEPTANCE_SELECTIONS = [
    {
      value: '採用',
      text: '採用'
    },
    {
      value: '不採用',
      text: '不採用'
    }
  ]

  const SEX_SELECTIONS = [
    {
      value: '男性',
      text: '男性'
    },
    {
      value: '女性',
      text: '女性'
    }
  ]

  const YESNO_SELECTIONS = [
    {
      value: 'はい',
      text: 'はい'
    },
    {
      value: 'いいえ',
      text: 'いいえ'
    }
  ]

  const DISEASE_SELECTIONS = [
    {
      value: '心臓疾患',
      text: '心臓疾患'
    },
    {
      value: '糖尿病',
      text: '糖尿病'
    },
    {
      value: 'ヘルニア',
      text: 'ヘルニア'
    },
    {
      value: '腰痛',
      text: '腰痛'
    },
    {
      value: '高血圧',
      text: '高血圧'
    }
  ]

  export default {
    components: {
      Icon,
      InputIcon,
      PrimaryButton,
      WmsDateInput,
      WmsTextInput,
      InputGroupLabel,
      NumericTextInput2,
      SelectionButton2,
      WmsTimeInput2,
      WmsAttachment,
      WmsSuggestInput,
      SiteSelectorModal
    },

    props: {
      requestPost: {
        type: Object,
        default: () => {}
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      isTaskRequestMode: {
        type: Boolean,
        default: false
      },
      referenceMode: {
        type: Boolean,
        default: false
      }
    },
  
    data() {
      return {
        sexSelections: SEX_SELECTIONS,
        workSelections: constants.WORK_SELECTIONS,
        holidayType: constants.HOLIDAYTYPE_SELECTIONS,
        trashRemoval: constants.TRASHREMOVAL_SELECTIONS,
        areaSelections: constants.AREA_SELECTIONS,
        provisionSelections: constants.PROVISION_SELECTIONS,
        yesnoSelections: YESNO_SELECTIONS,
        diseaseSelections: DISEASE_SELECTIONS,
        AcceptanceSelections: ACCEPTANCE_SELECTIONS,
        daysSelection: constants.DAYS_SELECTIONS,
        materialSelections: constants.MATERIAL_SELECTIONS,
        hourItems: [],
        minuteItems: [],
        reload: 0,
        workingHours: [0],
        // 物件リスト
        buildingList: [],
        // 物件検索モーダル
        siteModal: false
      }
    },
  
    computed: {
      isModileDevice() {
        return utils.deviceInfo.isMobile
      },

      /**
       * 誕生日を和暦に変換
       */
      japaneseDate() {
        return (val)=> {
          if (val) {
            return utils.japaneseDate(val)
          } else {
            return ''
          }
        }
      },

      /**
       * 採用の場合
       */
      isAdopt() {
        if (this.requestPost.interviewsResult && this.requestPost.interviewsResult[0] && this.requestPost.interviewsResult[0] == '採用') {
          this.$emit('change-client-require', true)
          return true
        } else {
          this.$emit('change-client-require', false)
          return false
        }
      },

      /**
       * 必須項目入力チェック（文字列）
       */
      requireValidateStr() {
        return (item, name)=> {
          // 入社日、勤務物件、時給、エリアは、採用の場合のみ
          if (['interviewsHireDate', 'interviewsAppliedWorkLocationAddress', 'interviewsHourlyWage', 'interviewsArea'].includes(item)) {
            if (this.isAdopt) {
              if (!this.requestPost[item]) {
                return {
                  result: false,
                  message: `採用の場合、${name}は必須項目です。${name}を入力してください。`
                }
              }
            }
          // 入社日以外
          } else if (!this.requestPost[item]) {
            return {
              result: false,
              message: `${name}は必須項目です。${name}を入力してください。`
            }
          }
          return {
            result: true,
            message: ''
          }
        }
      },

      /**
       * 必須項目入力チェック（配列）
       */
      requireValidateArr() {
        return (item, name)=> {
          let required = true
          let saiyo = ''
          // 性別、作業内容、祝日区分、ゴミ搬出、必要な書類は採用の場合のみ
          if (['interviewsSex', 'interviewsWorkTask', 'interviewsHolidayType', 'interviewsTrashRemoval', 'interviewsMaterial'].includes(item)) {
            if (this.isAdopt) {
              required = true
              saiyo = '採用の場合、'
            } else {
              required = false
            }
          }

          if (required && (!this.requestPost[item] || !this.requestPost[item].length)) {
            return {
              result: false,
              message: `${saiyo + name}は必須項目です。${name}を選択してください。`
            }
          } else {
            return {
              result: true,
              message: ''
            }
          }
        }
      },

      /**
       * 必須項目入力チェック（連想配列）
       */
      requireValidateArr2() {
        return (item, name)=> {
          let input = '入力'
          if (Array.isArray(item)) {
            input = '選択'
          }
          // 採用の場合のみ
          if (this.isAdopt) {
            if (!item || !item.length) {
              return {
                result: false,
                message: `採用の場合、${name}は必須項目です。${name}を${input}してください。`
              }
            }
          }
          return {
            result: true,
            message: ''
          }
        }
      },

      /**
       * 必須項目入力チェック（添付ファイル）
       */
      requireValidateAttach() {
        return (item, name)=> {
          // 採用の場合に限る
          if (this.isAdopt && (!this.requestPost[item] || (!this.requestPost[item].content && !this.requestPost[item].url))) {
            return {
              result: false,
              message: `${name}は必須項目です。${name}を添付してください。`
            }
          } else {
            return {
              result: true,
              message: ''
            }
          }
        }
      },

      /**
       * 郵便番号入力形式確認
       */
      errPostCode() {
        if (this.requestPost.interviewsPostCode) {
          const pattern = /^[0-9]{3}-[0-9]{4}$/
          //入力値が正しいか
          if (!pattern.test(this.requestPost.interviewsPostCode)) {
            return '【3桁-4桁】の形式で入力してください。'
          }
        }
        return ''
      },

      /**
       * 携帯番号入力形式確認
       */
      errMobile() {
        if (this.requestPost.interviewsMobile) {
          const pattern = /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/
          //入力値が正しいか
          if (!pattern.test(this.requestPost.interviewsMobile)) {
            return '【3桁-4桁-4桁】の形式で入力してください。'
          }
        }
        return ''
      }
    },
  
    async created() {
      this.hourItems = constants.HOURS
      this.minuteItems = constants.MINUTES

      // 年齢が空であり、生年月日が設定されていた場合（初期値）⇒ 年齢検索
      if ((!this.requestPost.interviewsAge || this.requestPost.interviewsAge.length == 0)
            && this.requestPost.interviewsBirthDay && this.requestPost.interviewsBirthDay.length > 0) {
        this.ageCalculation()
      }

      // 途中から追加になった項目
      if (!this.requestPost.interviewsCommonFileList) {
        this.requestPost.interviewsCommonFileList = []
        this.requestPost.interviewsCommonFileList.push(1)
      }

      // 実働時間取得
      this.changeWorkingTime(0)
      // 物件リスト取得
      await this.getBuildingList()
    },
  
    methods: {
      onKeyPressTel(event) {
        // Allow numbers (charCode 48-57) and dash (charCode 45)
        if ((event.charCode >= 48 && event.charCode <= 57) ||  event.charCode === 45 ) {
          return true;
        } else {
          event.preventDefault(); // Prevent any other character from being entered
        }
      },

      /**
       * 生年月日から年齢を取得
       */
      ageCalculation(){
        if (!this.requestPost.interviewsBirthDay) {
          this.requestPost.interviewsAge = ''
        } else {
          var birthday = this.requestPost.interviewsBirthDay.split("-")
          var birth = moment().year(birthday[0]).month(birthday[1] - 1).date(birthday[2])
          this.requestPost.interviewsAge = String(moment().diff(birth, 'years'))
        }
      },

    /**
     * 郵便番号より住所検索
     */
    addressSearch(){
      var geocoder = new window.google.maps.Geocoder()
      var pattern = /^[0-9]{3}-?[0-9]{4}$/
      if(this.requestPost.interviewsPostCode) {
        var postalCode = this.requestPost.interviewsPostCode
        if (postalCode) {
          //入力値が正しいか
          if (pattern.test(postalCode)) {
            //住所を取得
            geocoder.geocode ({
              address:postalCode,
              language:"ja",
              region:"jp" 
            }, this.addressSearchCb)
          } else {
            alert("入力が3桁-4桁の形式であるか確認してください。")
          }
        } 
      } else {
          alert("郵便番号を入力をしてください。")
      }
    },

    /**
     * 郵便番号より住所検索 CallBack
     */
    addressSearchCb(results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results.length > 0){
          if (results[0].geometry) {
            if(results[0].address_components[4].long_name == '日本') {
              this.requestPost.interviewsAddress1 = results[0].address_components[3].long_name +
              results[0].address_components[2].long_name + results[0].address_components[1].long_name
              console.log(this.requestPost.interviewsAddress1)
            } else {
              this.requestPost.interviewsAddress1 = results[0].address_components[4].long_name +
              results[0].address_components[3].long_name + results[0].address_components[2].long_name +
              results[0].address_components[1].long_name
            }
          }
        }
      } else {
        if (status == window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          alert("郵便番号は見つかりませんでした。")
        } else {
          alert("システムの管理者にお問い合わせください。ステータスコード：" + status)
        }
      }
    },
    /**
     * 住所より郵便番号検索
     */
    postalCodeSearch(){
      var geocoder = new window.google.maps.Geocoder()
      if (!this.requestPost.interviewsAddress1 && !this.requestPost.interviewsAddress2) {
        alert("住所を入力してください")
        return
      }
      
        var unionAddress = this.requestPost.interviewsAddress1 + this.requestPost.interviewsAddress2
        geocoder.geocode({
          address:unionAddress,
          language:"ja",
          region:"jp"
        }, this.postalCodeSearchCb)
      },

    /**
     * 住所より郵便番号検索 CallBack
     */
      postalCodeSearchCb(results, status) {
      //アラート用フラグ
      var flag = false
      var ret = this.requestPost
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          if (results[0].geometry) {
            var result = results[0].address_components;
            result.forEach(function(returnData) {
              returnData.types.forEach(function(getType) {
                if (getType == 'postal_code') {
                  console.log( returnData.long_name)
                  ret.interviewsPostCode = returnData.long_name
                  flag = true
                }
              })
            })
          } 
        }
        if (flag == false) {
          alert("郵便番号が見つかりませんでした。")
        }
      } else {
        if (status == window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          alert("郵便番号は見つかりませんでした")
        } else {
          alert("システムの管理者にお問い合わせください。ステータスコード：" + status)
        }
      }
    },

    /**
     * 家族追加ボタン
     */
    addFamilyMember() {
      let add = {
        interviewsFamilyRelationship: '',
        interviewsFamilyAddress: '',
        interviewsFamilyName:'',
        interviewsFamilyPhoneNumber: ''
        }
      this.requestPost.interviewsFamilyMembers.push(add)
    },

    /**
     * 家族削除ボタン
     */
    removeFamilyMember(idx) {
      this.requestPost.interviewsFamilyMembers.splice(idx, 1)
    },

    /**
     * 勤務時間変更イベント
     * @param i index
     */
    changeWorkingTime(i) {
      const work =this.requestPost.interviewsAppliedWork
      if (this.workingHours.length != this.requestPost.interviewsAppliedWork.length) {
        this.workingHours = []
        for (let index = 0; index < work.length; index++) {
          const w = work[index]
          this.workingHours.push(utils.getHours(w.startTime, w.endTime))
        }
      } else {
        this.workingHours[i] = utils.getHours(work[i].startTime, work[i].endTime)
      }
    },

    /**
     * 勤務曜日・時間 追加ボタン
     */
    addAppliedWork() {
      let add = {
        days: [],
        startTime: '',
        endTime: '',
        startBreakTime: '',
        endBreakTime: ''
      }
      this.requestPost.interviewsAppliedWork.push(add)
      this.workingHours.push(0)
    },

    /**
     * 勤務曜日・時間 削除ボタン
     */
    removeAppliedWork(idx) {
      this.requestPost.interviewsAppliedWork.splice(idx, 1)
    },

      /**
       * 交通費区分変更イベント
       * @param type 区分
       * @param fee 交通費
       */
      changeTrans(type, fee) {
        if (this.requestPost[type] && this.requestPost[type][0] == '無') {
          this.requestPost[fee] = '0'
        } else if (this.requestPost[type] && this.requestPost[type][0] != '無') {
          if (this.requestPost[fee] == '0') {
            this.requestPost[fee] = ''
          }
        }
      },

    /**
     * タイトルをセット
     */
    setTitle() {
      // 採否結果＋名前＋物件名
      let val = {
        interviewsResult: this.requestPost.interviewsResult,
        name: this.requestPost.interviewsName,
        site: this.requestPost.interviewsAppliedWorkLocationAddress
      }
      this.$emit('get-title', val)
    },

    /**
     * 賃金の確認
     */
    async limitHourlyWageInput() {
      const err = utils.validateHourlyWage(this.requestPost.interviewsHourlyWage)
      if (err) {
        await dialogs.showErrorDialog('賃金', err)
        this.requestPost.interviewsHourlyWage = ''
      }
    },

    /**
     * 添付ファイル
     */
    attachmentChange(targetAttachmentName, attachmentInfo) {
      if (!this.requestPost[targetAttachmentName]) {
        this.requestPost[targetAttachmentName] = helper.createEmptyAttachment()
      }
      if (attachmentInfo) {
        this.requestPost[targetAttachmentName].originalName = attachmentInfo.filename
        this.requestPost[targetAttachmentName].content = attachmentInfo.content
      } else {
        this.requestPost[targetAttachmentName] = helper.createEmptyAttachment()
      }
    },

    /**
     * 必須項目入力チェック
     */
    async validation() {
      // 入力形式確認
      if (this.errPostCode) {
        await dialogs.showInfoDialog('郵便番号', '【3桁-4桁】の形式で入力してください。')
        return false
      }
      if (this.errMobile) {
        await dialogs.showInfoDialog('携帯番号', '【3桁-4桁-4桁】の形式で入力してください。')
        return false
      }

      // 文字列、配列、オブジェクト
      const valStr = [
        { item: 'interviewsHireDate', name: '入社日' }
        ,{ item: 'interviewsKana', name: 'フリガナ' }
        ,{ item: 'interviewsName', name: '氏名' }
        ,{ item: 'interviewsPostCode', name: '郵便番号' }
        ,{ item: 'interviewsAddress1', name: '住所１' }
        ,{ item: 'interviewsMobile', name: '携帯電話' }
        ,{ item: 'interviewsSex', name: '性別' }
        ,{ item: 'interviewsBirthDay', name: '生年月日' }
        ,{ item: 'interviewsAge', name: '年齢' }
        ,{ item: 'interviewsHourlyWage', name: '時給' }
        ,{ item: 'interviewsAppliedWorkLocationAddress', name: 'ご応募いただいた勤務物件' }
        ,{ item: 'interviewsWorkTask', name: '作業内容' }
        ,{ item: 'interviewsHolidayType', name: '祝日区分' }
        ,{ item: 'interviewsTrashRemoval', name: 'ゴミ搬出' }
        ,{ item: 'interviewsArea', name: 'エリア' }
        ,{ item: 'interviewsAppliedWork' }
        ,{ item: 'interviewsMaterial', name: '必要な書類' }
      ]
      for (let i = 0; i < valStr.length; i++) {
        const s = valStr[i]
        let res = {}
        // ご応募いただいた勤務曜日・時間の場合（連想配列）
        if (s.item == 'interviewsAppliedWork') {
          for (let j = 0; j < this.requestPost[s.item].length; j++) {
            const tar = this.requestPost[s.item][j]
            // 勤務曜日
            res = this.requireValidateArr2(tar.days, 'ご応募いただいた勤務曜日')
            if (!res.result) {
              await dialogs.showInfoDialog('入力確認', res.message, 'OK')
              return false
            }
            // 開始時間
            res = this.requireValidateArr2(tar.startTime, '勤務時間')
            if (!res.result) {
              await dialogs.showInfoDialog('入力確認', res.message, 'OK')
              return false
            }
            // 終了時間
            res = this.requireValidateArr2(tar.endTime, '勤務時間')
            if (!res.result) {
              await dialogs.showInfoDialog('入力確認', res.message, 'OK')
              return false
            }
          }
        } else {  
          if (Array.isArray(this.requestPost[s.item])) {
            res = this.requireValidateArr(s.item, s.name)
          } else {
            res = this.requireValidateStr(s.item, s.name)
          }
        }

        if (!res.result) {
          await dialogs.showInfoDialog('入力確認', res.message, 'OK')
          return false
        }
      }

      // 添付
      const valArr = [
        { item: 'interviewsDocument', name: '履歴書' }
      ]
      for (let i = 0; i < valArr.length; i++) {
        const a = valArr[i]
        let res = {}
        res = this.requireValidateAttach(a.item, a.name)
        if (!res.result) {
          await dialogs.showInfoDialog('入力確認', res.message, 'OK')
          return false
        }
      }

      // 休憩時間の確認
      const work = this.requestPost.interviewsAppliedWork
      for (let k = 0; k < work.length; k++) {
        const w = work[k]
        const wh = this.workingHours[k]
        const br = utils.getHours(w.startBreakTime || '', w.endBreakTime || '')
        // 実働時間が6時間以上の場合
        if (wh >= 6) {
          if (!br) {
            let ds = await dialogs.showConfirmDialog((k + 1) + '番目の休憩時間はよろしいでしょうか', '勤務時間が6時間以上になっていますが、休憩時間は0時間でよろしいでしょうか？')
            if (ds != 'YES') {
              return
            }
          }
        } else {
          if (br) {
            await dialogs.showErrorDialog((k + 1) + '番目の休憩時間', '勤務時間が6時間未満ですが、休憩時間が入力されています。')
            return
          }
        }
      }

      return true
    },

    /**
     * 添付資料（紹介状・他）追加ボタン
     */
    async addCommonFile() {
      const files = this.requestPost.interviewsCommonFileList
      if (files && files.length <= 4) {
        for (let i = 1; i <= 5; i++) {
          if (!files.includes(i)) {
            files.push(i)
            this.reload++
            return
          }
        }
      } else if (files.length >= 5) {
        await dialogs.showInfoDialog('添付資料（紹介状・他）は5つまでです', 'これ以上、添付資料（紹介状・他）は増やせません。')
      }
    },

    /**
     * 添付資料（紹介状・他）削除ボタン
     * @param i 添付資料（紹介状・他）番号
     */
    removeCommonFile(i) {
      const files = this.requestPost.interviewsCommonFileList
      const res = files.filter((f) => {
        return i != f
      })
      this.requestPost.interviewsCommonFileList = res
    },

    /**
     * 物件リスト取得
     */
    async getBuildingList() {
      const list = await backend.searchData('akbsTable/getSiteList', null)
      if (list && list.data.data && list.data.data.length) {
        this.buildingList = list.data.data
      } else {
        this.buildingList = []
      }
    },

    /**
     * 物件選択モーダルclose
     */
    siteSelected(target) {
      this.requestPost.interviewsSiteCode = target.cdSite
      this.requestPost.interviewsAppliedWorkLocationAddress = target.nameSite
      this.requestPost.clientName = target.nameCustomer
      if (target.nameCustomer2) {
        this.requestPost.clientName += ' ' + target.nameCustomer2
      }
      this.requestPost.interviewsSiteAddress = target.address

      // メインにもとからあるので、矛盾しないように書き直す
      this.requestPost.clientCode = ''
      // 得意先住所
      this.requestPost.clientAddress1 = ''
      this.requestPost.clientAddress2 = ''
      this.getArea()
      this.siteModal = false
      // タイトル編集
      this.setTitle()
    },

    /**
     * 物件変更イベント
     */
    siteChanged() {
      if (!this.requestPost.interviewsAppliedWorkLocationAddress) {
        this.requestPost.interviewsSiteCode = ''
        this.requestPost.interviewsAppliedWorkLocationAddress = ''
        this.requestPost.clientName = ''
        this.requestPost.interviewsSiteAddress = ''
        this.requestPost.interviewsArea = ''
      }
    },

    /**
     * 住所からエリアを取得
     */
    getArea() {
      if (this.requestPost.interviewsSiteAddress) {
        for (let i = 0; i < this.areaSelections.length; i++) {
          const a = this.areaSelections[i]
          if (a && this.requestPost.interviewsSiteAddress.indexOf(a) > -1) {
            this.requestPost.interviewsArea = a
            return
          }
        }
      }
      
      this.requestPost.interviewsArea = ''
    },

    /**
     * 物件選択モーダルopen
     */
    openSiteModal() {
      this.siteModal = true
    },

  }
}
</script>
  
<style>
  #diseaseDiv div button[type=button] {
    width: 19.8% !important;
  }

  .five-selection div button[type=button] {
    width: 19.8% !important;
  }

  #interviewsAppliedWorkDays div button[type=button] {
    width: 12% !important;
  }

  .time div #hour-input {
    width: 65px !important;
  }

  .time div #minute-input {
    width: 65px !important;
  }
</style>