<template>
  <div class="main-form">
    <PageHeader :title="report.id?`報告書No. ${report.id}` : ''" class="list">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <!-- 削除ボタン -->
          <div v-if = "report.id && !cannotEdit" class="mr-5 w-20">
            <PrimaryButton text="削除" size="font-bold bg-red-700 border border-red-800 rounded-md px-2 py-1.5 text-white cursor-pointer" :disabled="loading" @click="deleteReport()">
            </PrimaryButton>
          </div>

          <!-- テンプレート1 -->
          <div v-if="[1, 2].includes(type)" class="mr-4">
            <PrimaryButton text="Template" size="font-bold bg-blue-500 border border-blue-800 rounded-md px-2 py-1.5 text-white cursor-pointer" :disabled="loading" @click="changeTemplate1()">
            </PrimaryButton>
          </div>

          <!-- 出力ボタン -->
          <div v-if="!template1" class="mr-4">
            <DropdownButton text="出力" size="font-bold text-white rounded px-2 normal" :menus="downloadMenu" @select="downloadSelect">
              <LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
              <Icon v-if="!loading" iconName="Printer" slot="before" class="h-4 w-4" />
              <Icon iconName="ChevronDown" slot="after" class="h-4 w-4" />
            </DropdownButton>
          </div>

          <!-- PDF出力 -->
          <div v-if="template1" class="mr-4">
            <PrimaryButton text="PDF" size="font-bold border rounded-md text-white normal" :disabled="loading" :class="edit?'bg-gray-300 cursor-not-allowed' : ''" @click="pdfBtn()">
              <LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
              <Icon v-if="!loading" iconName="Printer" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>

          <!-- 一覧 -->
          <div class="min-w-fit ">
            <PrimaryButton class="bg-green-600 mr-2" text="一覧" :disabled="loading" @click="$router.push({ name: 'PhotoReportList' })">
              <Icon iconName="ViewGrid" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>
        </div>
      </template>
      <template #page-header-content>
        <div class="flex justify-between -mt-2 mx-auto">

          <!-- 新規作成開始ボタン -->
          <div v-if="!report.id" class="max-w-2xl w-full mx-auto">
            <PrimaryButton text="作成開始" :disabled="loading" class="w-full" @click="start()">
            </PrimaryButton>
          </div>
          
          <div v-else-if="!cannotEdit" class="flex justify-between max-w-2xl w-full mx-auto">  
            <!-- 編集ボタン -->
            <div class="w-full">
              <PrimaryButton text="編集" class="w-full max-w-md" :class="edit ? 'bg-gray-300 cursor-not-allowed border-gray-300' : ''" :disabled="edit || loading" @click="editBtn()">
              </PrimaryButton>
            </div>

            <!-- 完了ボタン -->
            <div class="w-full mx-2">
              <PrimaryButton text="完了" class="w-full max-w-md" :class="!edit ? 'bg-gray-300 cursor-not-allowed border-gray-300' : ''" :disabled="!edit || loading" @click="completeBtn()">
              </PrimaryButton>
            </div>

            <!-- 写真・コメント欄追加ボタン -->
            <div v-if="edit && !template1" class="w-80">
              <PrimaryButton text="項目追加" class="add-btn" @click="addItem()">
                <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>
            <div v-else-if="template1" class="w-96">
              <PrimaryButton text="色・位置変更" class="color-btn" @click="changeColor()">
              </PrimaryButton>
            </div>
          </div>

          <!-- 編集不可の説明 -->
          <div v-if="cannotEdit" class="font-bold text-white mx-auto">
            バイク点検では、作成者本人かつ今月分のみ編集できます。
          </div>
          
        </div>
      </template>
    </PageHeader>
    <main class="absolute w-full overflow-auto bg-white rounded main-form2" :class="isMobileDevice ? 'mobile-h' : ''" style="top: 115px; height: calc(100dvh - 139px); font-family: sans-serif;">
      <div v-if="!loadingFirst">
        <div class="flex justify-end mx-auto mt-3" style="width: 730px;">

          <!-- 最終更新者 -->
          <div class="text-xs font-bold text-gray-500 mt-auto mx-5" v-if="user">
            更新者：{{ user }}
          </div>

          <!-- 最終更新日 -->
          <div class="text-xs font-bold text-gray-500 mt-auto ml-5" v-if="updateDate">
            更新日：{{ updateDate }}
          </div>
        </div>

        <div id="output" class="" style="width: 750px; margin-left: auto; margin-right: auto;">
          <div v-if="!template1">
            <div class="mb-2" style="width: 100%;">
              <!-- 編集モード -->
              <div v-if="edit">
                <!-- タイトル -->
                <div class="mt-2 flex mb-1 mx-1">
                  <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                    タイトル
                  </div>
                  <input
                    type="text"
                    placeholder="こちらにタイトルを入力してください"
                    class="border border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full text-center font-bold"
                    v-model="report.title"
                    @change="changeText()"
                  />
                </div>
                <!-- 得意先名 -->
                <div v-if="[1, 2].includes(type)" class="flex mb-1 mx-1">
                  <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                    得意先名
                  </div>
                  <input
                    type="text"
                    class="border border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full font-bold"
                    v-model="report.clientName"
                    @change="changeText()"
                  />
                </div>
                <!-- 物件名 -->
                <div v-if="[1, 2].includes(type)" class="flex mb-1 mx-1">
                  <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                    物件名
                  </div>
                  <input
                    type="text"
                    class="border border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full font-bold"
                    v-model="report.siteName"
                    @change="changeText()"
                  />
                </div>

                <div v-if="[3].includes(type)" class="flex mb-1 mx-1">
                  <!-- 支店名 -->
                  <div class="flex mb-1 mr-1 w-1/2">
                    <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                      支店名
                    </div>
                    <select
                      name="officeName"
                      v-model="report.officeName"
                      class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                      @change="changeText()"
                    >
                      <option
                        v-for="(o, i) in officeList" :key="'office' + i"
                        :value="o.name"
                        class="bg-white">
                        {{ o.name }}
                      </option>
                    </select>
                  </div>
                  <!-- 部署名 -->
                  <div class="flex mb-1 ml-1 w-1/2">
                    <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                      部署名
                    </div>
                    <select
                      name="departmentName"
                      v-model="report.departmentName"
                      class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                      @change="changeText()"
                    >
                      <option
                        v-for="(d, i) in departmentList" :key="'department' + i"
                        :value="d.name"
                        class="bg-white">
                        {{ d.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div v-if="[3].includes(type)" class="flex mb-1 mx-1">
                  <!-- 名前 -->
                  <div style="display: flex; margin-right: 0.25rem; width: 50%;">
                    <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                      名前
                    </div>
                    <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                      {{ user }}
                    </div>
                  </div>
                  <!-- 日付 -->
                  <div style="display: flex; margin-left: 0.25rem; width: 50%;">
                    <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                      点検月
                    </div>
                    <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                      {{ updateDateMonth }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- 参照モード -->
              <div v-else @click="showAlert()">
                <!-- タイトル -->
                <div class="mt-4" style="width: 100%; font-weight: 700; text-align: center;">
                  {{ report.title }}
                </div>
                <!-- 得意先名 -->
                <div v-if="[1, 2].includes(type)" style="display: flex; margin: 0.25rem;">
                  <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                    得意先名
                  </div>
                  <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                    {{ report.clientName }}
                  </div>
                </div>
                <!-- 物件名 -->
                <div v-if="[1, 2].includes(type)" style="display: flex; margin: 0.25rem;">
                  <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                    物件名
                  </div>
                  <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                    {{ report.siteName }}
                  </div>
                </div>

                <div v-if="[3].includes(type)" style="display: flex; margin: 0.25rem; width: 100%;">
                  <!-- 支店名 -->
                  <div style="display: flex; margin-right: 0.25rem; width: 50%;">
                    <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                      支店名
                    </div>
                    <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                      {{ report.officeName }}
                    </div>
                  </div>
                  <!-- 部署名 -->
                  <div style="display: flex; margin-left: 0.25rem; width: 50%;">
                    <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                      部署名
                    </div>
                    <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                      {{ report.departmentName }}
                    </div>
                  </div>
                </div>

                <div v-if="[3].includes(type)" style="display: flex; margin: 0.25rem; width: 100%;">
                  <!-- 名前 -->
                  <div style="display: flex; margin-right: 0.25rem; width: 50%;">
                    <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                      名前
                    </div>
                    <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                      {{ user }}
                    </div>
                  </div>
                  <!-- 日付 -->
                  <div style="display: flex; margin-left: 0.25rem; width: 50%;">
                    <div style="background: lightgray; padding-top: 0.5rem; padding-bottom: 0.5rem; border: 1px solid lightgray; align-items: stretch; width: 7rem; text-align: center; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;">
                      点検月
                    </div>
                    <div style="width: 100%; font-weight: 700; padding: 0.5rem 1rem; border-width: 1px; border: 1px solid lightgray; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                      {{ updateDateMonth }}
                    </div>
                  </div>
                </div>
                
                <div v-if="[3].includes(type)" style="display: flex; margin: 0.25rem;">
                </div>
              </div>
            </div>

            <!-- 写真・コメント -->
            <div style="display: table;" class="flex flex-wrap">
              <div v-for="(row, i) in report.item" :key="i" style="display: inline-block; margin: 0.25rem; width: calc(50% - 8px); break-inside: avoid;">

                <!-- 編集モード -->
                <div v-if="edit" class="flex justify-between">
                  <div class="flex">
                    <!-- 写真削除 -->
                    <div v-if="row.photo">
                      <div v-if="!loading" @click="deletePhoto(row)" class="rounded-t py-1.5 px-5 font-bold text-teal-700 border border-teal-700 bg-teal-100">
                        写真削除
                      </div>
                      <div v-else class="rounded-t py-10px px-11 font-bold border border-teal-700 bg-teal-100 cursor-not-allowed">
                        <LoadingIcon class="h-4 w-4 text-teal-700" />
                      </div>
                    </div>
                  </div>

                  <!-- 項目削除 -->
                  <div v-if="!row.commonItem">
                    <div v-if="!loading" @click="deleteItem(i)" class="rounded-t ml-1 py-1.5 px-5 font-bold text-red-700 border border-red-700 bg-red-50">
                      項目削除
                    </div>
                    <div v-else class="rounded-t py-10px px-11 font-bold border border-red-700 bg-red-50 cursor-not-allowed">
                      <LoadingIcon class="h-4 w-4 text-red-700" />
                    </div>
                  </div>
                </div>
                <!-- 参照モード（空白） -->
                <div v-else style="height: 10px;"></div>

                <div style="width: 100%; border-radius: 0.25rem; border-width: 1px; border: 1px solid lightgray; margin-bottom: auto;" :class="edit&&!row.photo&&row.commonItem?'mt-10':''">
                  <!-- 写真 -->
                  <div class="photo cursor-pointer" style="width: 100%; height: 220px; position: relative; border-bottom: 1px solid lightgray; break-inside: avoid;" @click="showAlert()" v-tooltip="'PCの場合、カメラは起動しません。フォルダが表示されます。'">
                    <div v-if="!report.id" class="photo-not text-gray-400 font-bold text-center">作成開始を押してください</div>
                    
                    <!-- カメラ起動ボタン -->
                    <div v-if="report.id&&!row.photo&&edit" class="photo-info text-gray-400 font-bold text-center" v-tooltip="'PCの場合、カメラは起動しません。フォルダが表示されます。'">
                      <LoadingIcon v-if="loading" slot="before" class="h-6 w-6 m-auto" />
                      <Icon v-if="!loading" iconName="Camera" slot="before" class="h-6 w-6 m-auto" />
                      カメラ起動
                    </div>
                    <input v-if="report.id&&edit" class="file-type h-full right-0" type="file" capture="environment" accept="image/*" @change="imageToBase64(row, $event, false)" />
                    <!-- 写真選択ボタン -->
                    <div v-if="report.id&&!row.photo&&edit" class="photo-info2 text-gray-400 font-bold text-center">
                      <LoadingIcon v-if="loading" slot="before" class="h-6 w-6 m-auto" />
                      <Icon v-if="!loading" iconName="FolderDownload" iconType="outline" slot="before" class="h-6 w-6 m-auto" />
                      写真を添付
                    </div>
                    <input v-if="report.id&&edit" class="file-type h-full left-0" type="file" accept="image/*" @change="imageToBase64(row, $event, false)" />

                    <!-- 添付した写真 -->
                    <div v-if="row.photo" style="width: 100%; height: 100%;">
                      <img :src="row.photo" style="width: 100%; height: 100%;" :style="row.objectFit">
                    </div>
                  </div>

                  <!-- 編集モード -->
                  <div v-if="edit" @click="showAlert()">
                    <!-- タイトル -->
                    <div class="flex font-bold border-b" style="font-size: 0.8rem; line-height: 1.25rem;">
                      <div class="w-fit py-2.5 pl-3 mr-1">
                        {{ 'No.' + (i + 1) + ' ' }}
                      </div>
                      <div v-if="row.commonItem" class="whitespace-pre-line py-2.5 px-1 ">
                        {{ row.title }}
                      </div>
                      <textarea
                        v-else
                        class="border-0 w-full font-bold my-0.5 rounded"
                        v-model="row.title"
                        @change="changeItem(row)"
                      />
                    </div>
                    
                    <!-- 内容 -->
                    <textarea
                      :rows="!row.note?3:textHeight(row.note)"
                      placeholder="詳細"
                      class="border-0 w-full rounded  "
                      style="font-size: 0.8rem; line-height: 0.9rem;"
                      v-model="row.note"
                      @change="changeItem(row)"
                    />
                  </div>

                  <!-- 参照モード -->
                  <div v-else style="break-inside: avoid; margin-bottom: auto;" @click="showAlert()">
                    <!-- タイトル -->
                    <div style="width: 100%; font-weight: 700; padding-left: 0.75rem; padding-right: 0.75rem; margin: 6px 0px; white-space: pre-line; display: flex; font-size: 0.8rem; line-height: 1.25rem;">
                      <div style="padding-right: 10px; margin: auto 0 auto 0;">
                        {{ 'No.' + (i + 1) }}
                      </div>
                      <div style="width: 291px;">
                        {{ row.title }}
                      </div>
                    </div>
                    <!-- 内容 -->
                    <div style="display: table-cell; min-height: 54px; padding-left: 0.75rem; padding-right: 0.75rem; padding-bottom: 0.5rem; white-space: pre-line; font-size: 0.8rem;">
                      <div style="display: inline-block; min-height: inherit; width: 340px;">
                        {{ row.note }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 写真・コメント欄追加ボタン -->
            <div v-if="edit && !template1" class="w-fit mx-auto mt-2">
              <PrimaryButton text="項目追加" class="add-btn" @click="addItem()">
                <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <!-- 備考 -->
            <div class="mx-1" style="margin-top: 10px;">  
              <div v-if="edit">
                <textarea 
                  :rows="6"
                  placeholder="備考"
                  class="border border-gray-300 w-full rounded"
                  v-model="report.note"
                  @change="changeText()"
                />
              </div>
              <div v-else-if="report.note" style=" break-inside: avoid; white-space: pre-line; width: 100%; border: 1px solid lightgray; padding: 0.75rem; border-radius: 0.25rem;">
                {{ report.note }}
              </div>
            </div>
          </div>

          <!-- テンプレート1バージョン -->
          <div v-else>
            <div class="mb-2" style="width: 100%;" @click="showAlert()">
              <!-- 写真ボタン -->
              <div v-if="edit" class="flex">
                <!-- 写真削除 -->
                <div v-if="report.coverPhoto">
                  <div v-if="!loading" @click="deleteCoverPhoto(report)" class="rounded-t py-1.5 px-5 font-bold text-teal-700 border border-teal-700 bg-teal-100">
                    写真削除
                  </div>
                  <div v-else class="rounded-t py-10px px-11 font-bold border border-teal-700 bg-teal-100 cursor-not-allowed">
                    <LoadingIcon class="h-4 w-4 text-teal-700" />
                  </div>
                </div>
              </div>
              <!-- 表紙 -->
              <div style="position: relative; page-break-after: always; height: 1150px; overflow: hidden; border-radius: 10px;" :style="setBgColor()">
                <div class="cursor-pointer" style="position: absolute; background: white; break-inside: avoid; left: 50%; transform: translateX(-50%); width: 99.9%; height: 68%; ">
                  <div v-if="!report.id" class="photo-not text-gray-400 font-bold text-center">作成開始を押してください</div>
                  <div v-else-if="!report.coverPhoto&&edit" class="photo-info text-gray-400 font-bold text-center" style="left: 42%;">写真を添付</div>
                  <!-- 写真添付ボタン -->
                  <input v-if="report.id&&edit" class="file-type w-full h-full" type="file" accept="image/*" @change="imageToBase64(report, $event, true)" />

                  <!-- 添付した写真 -->
                  <div v-if="report.coverPhoto" style="width: 100%; height: 100%;">
                    <img :src="report.coverPhoto" style="width: 100%; height: 100%; object-fit: cover;">
                  </div>
                </div>

                <div style="width: 130%; height: 40%; transform-origin: left bottom; transform: rotate(-20deg); top: 70%; position: absolute;" :style="setBgColor()">
                </div>

                <!-- 得意先名 -->
                <div style="position: absolute; font-weight: bold; top: 55%; right: 0; width: 100%;">
                  <input
                    v-if="edit"
                    type="text"
                    style="border-style: none; font-weight: bold; font-size: xx-large; background: rgb(248 248 255 / 60%); width: 100%; padding-bottom: 0px;"
                    :style="clientColor()"
                    v-model="report.clientName"
                    @change="changeText()"
                  />
                  <div v-else style="font-size: xx-large; background: rgb(248 248 255 / 60%); width: 100%;" :style="clientColor()">
                    <div style="margin-right: 15px; margin-left: 15px; padding: 5px 0;">
                      {{ report.clientName }}
                    </div>
                  </div>
                </div>

                <!-- 物件名 -->
                <div style="position: absolute; font-weight: bold; top: 59.7%; right: 0; width: 100%; ">
                  <input
                    v-if="edit"
                    type="text"
                    style="border-style: none; font-weight: bold; font-size: x-large; width: 100%; background: rgb(248 248 255 / 40%);"
                    :style="textColor() + siteAlign()"
                    v-model="report.siteName"
                    @change="changeText()"
                  />
                  <div v-else style="font-size: x-large; width: 100%; background: rgb(248 248 255 / 40%);" :style="textColor() + siteAlign()">
                    <div style="margin-right: 15px; margin-left: 15px; padding: 9px 0;">
                      {{ report.siteName }}
                    </div>
                  </div>
                </div>

                <!-- タイトル -->
                <div style="position: absolute; font-weight: bold; top: 75%; width: 100%;">
                  <input
                    v-if="edit"
                    type="text"
                    style="border-style: none; font-weight: bold; font-size: x-large; background: transparent; width: 100%;"
                    :style="titleColor()"
                    class="text-center"
                    v-model="report.title"
                    @change="changeText()"
                  />
                  <div v-else style="margin: 6px auto; width: fit-content; font-size: x-large; background: transparent;" :style="titleColor()">
                    {{ report.title }}
                  </div>
                </div>

                <!-- ロゴ -->
                <div style="position: absolute; bottom: 0; right: 0; width: 100%;"  :style="setBgColor()">
                  <img :src="logo" style="height: 30px; width: 224px; margin: 5px 10px 5px auto; display: block;" />
                  <div style="color: white; margin: 0 10px 3px auto; width: fit-content;">TEL.03-5438-8804</div>
                </div>
              </div>
            </div>

            <!-- 写真・コメント -->
            <div style="">
              <div v-for="(row, i) in report.item" :key="i" >
                <div v-if="row.photo" style="display: inline-block; width: 100%; break-inside: avoid; height: 220px; margin: 35px 0;">
                  <!-- 編集モード -->
                  <div v-if="edit" class="flex justify-between">
                    <div class="flex">
                      <!-- 写真削除 -->
                      <div v-if="row.photo">
                        <div v-if="!loading" @click="deletePhoto(row)" class="rounded-t py-1.5 px-5 font-bold text-teal-700 border border-teal-700 bg-teal-100">
                          写真削除
                        </div>
                        <div v-else class="rounded-t py-10px px-11 font-bold border border-teal-700 bg-teal-100 cursor-not-allowed">
                          <LoadingIcon class="h-4 w-4 text-teal-700" />
                        </div>
                      </div>
                    </div>

                    <!-- 項目削除 -->
                    <div v-if="!row.commonItem">
                      <div v-if="!loading" @click="deleteItem(i)" class="rounded-t ml-1 py-1.5 px-5 font-bold text-red-700 border border-red-700 bg-red-50">
                        項目削除
                      </div>
                      <div v-else class="rounded-t py-10px px-11 font-bold border border-red-700 bg-red-50 cursor-not-allowed">
                        <LoadingIcon class="h-4 w-4 text-red-700" />
                      </div>
                    </div>
                  </div>
                  <!-- 参照モード（空白） -->
                  <!-- <div v-else style="height: 70px;"></div> -->

                  <div style="width: 100%; height: 100%; border-radius: 0.25rem; display: flex;" :style="edit?'border: 1px solid lightgray;':''">
                    <!-- 写真 -->
                    <div class="photo cursor-pointer" style="width: 40%; height: 100%; position: relative; break-inside: avoid; ">
                      <div v-if="!report.id" class="photo-not text-gray-400 font-bold text-center">作成開始を押してください</div>
                      <div v-else-if="!row.photo&&edit" class="photo-info text-gray-400 font-bold text-center">写真を添付</div>
                      <!-- 写真添付ボタン -->
                      <input v-if="report.id&&edit" class="file-type w-full h-full" type="file" accept="image/*" @change="imageToBase64(row, $event, false)" />

                      <!-- カメラ起動ボタン -->
                      <div v-if="report.id&&!row.photo&&edit" class="photo-info text-gray-400 font-bold text-center">
                        <LoadingIcon v-if="loading" slot="before" class="h-6 w-6 m-auto" />
                        <Icon v-if="!loading" iconName="Camera" slot="before" class="h-6 w-6 m-auto" />
                        カメラ起動
                      </div>
                      <input v-if="report.id&&edit" class="file-type h-full right-0" type="file" capture="environment" accept="image/*" @change="imageToBase64(row, $event, false)" />
                      <!-- 写真選択ボタン -->
                      <div v-if="report.id&&!row.photo&&edit" class="photo-info2 text-gray-400 font-bold text-center">
                        <LoadingIcon v-if="loading" slot="before" class="h-6 w-6 m-auto" />
                        <Icon v-if="!loading" iconName="FolderDownload" iconType="outline" slot="before" class="h-6 w-6 m-auto" />
                        写真を添付
                      </div>
                      <input v-if="report.id&&edit" class="file-type h-full left-0" type="file" accept="image/*" @change="imageToBase64(row, $event, false)" />

                      <!-- 添付した写真 -->
                      <div v-if="row.photo" style="width: 100%; height: 100%;">
                        <img :src="row.photo" style="width: 100%; height: 100%; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-bottom-right-radius: 0.25rem;" :style="row.objectFit">
                      </div>
                    </div>

                    <!-- 編集モード -->
                    <div v-if="edit" style="width: 60%;">
                      <!-- タイトル -->
                      <div class="font-bold border-b w-full text-sm flex">
                        <div class="w-fit py-2.5 pl-3 mr-1">
                          {{ 'No.' + (i + 1) + ' ' }}
                        </div>
                        <textarea
                          class="border-0 w-full font-bold my-0.5 rounded text-sm"
                          style="resize: none;"
                          v-model="row.title"
                          @change="changeItem(row)"
                        />
                      </div>
                      
                      <!-- 内容 -->
                      <textarea
                        :rows="!row.note?3:textHeight(row.note)"
                        placeholder="詳細"
                        class="border-0 w-full rounded"
                        style="font-size: 1rem; line-height: 1.7rem; resize: none; height: 152px;"
                        v-model="row.note"
                        @change="changeItem(row)"
                      />
                    </div>

                    <!-- 参照モード -->
                    <div v-else style="break-inside: avoid; width: 60%;" @click="showAlert()">
                      <!-- タイトル -->
                      <div style="display: flex; height: 46px; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; width: 100%; font-weight: 700; padding-left: 0.75rem; padding-right: 0.75rem; white-space: pre-line; font-size: 0.875rem; line-height: 1.25rem;"  :style="setBgColor() +  textColor()">
                        <div style="padding-right: 10px; margin: auto 0 auto 0;">
                          {{ 'No.' + (i + 1) }}
                        </div>
                        <div style="margin: auto 0; height: fit-content; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;">
                          {{ row.title }}
                        </div>
                      </div>
                      <!-- 内容 -->
                      <div style="display: table-cell; padding: 0.7rem 0.75rem; white-space: pre-line; font-size: 1rem; line-height: 1.7rem; width: 100%;">
                        <div style="display: inline-block; min-height: inherit; width: 100%;">
                          {{ row.note }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 備考 -->
            <div class="mx-1" style="margin-top: 10px;">  
              <div v-if="edit">
                <textarea 
                  :rows="6"
                  placeholder="備考"
                  class="border border-gray-300 w-full rounded"
                  v-model="report.note"
                  @change="changeText()"
                />
              </div>
              <div v-else-if="report.note" style=" break-inside: avoid; white-space: pre-line; width: 100%; border: 1px solid lightgray; padding: 0.75rem; border-radius: 0.25rem;">
                {{ report.note }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-gray-600 font-bold animate-pulse w-fit mt-80 m-auto" style="font-size: 30px">loading...</div>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </main>
    <ChangeColorModal
      v-model="openChangeColorModal"
      :open="openChangeColorModal"
      :color="report.color"
      :align="report.align"
      @close="closeChangeColor"
    />
  </div>
</template>
<script>

import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
// import { PlusCircleIcon } from '@vue-hero-icons/solid'
import DropdownButton from '@components/DropdownButton.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as moment from 'moment'
import ChangeColorModal from './ChangeColorModal.vue'

// 背景色および得意先名
const BGCOLOR = '#09158c'
// 文字色および物件名
const TEXTCOLOR = '#e3e4ff'
// タイトル
const TITLECOLOR = '#95abf7'

export default {
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    DropdownButton,
    LoadingIcon,
    ChangeColorModal
    // TrashIcon,
    // PlusCircleIcon
  },

  props: {
    // 区分名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：フリー入力、2：現地調査、3：バイク点検）
    type: {
      type: Number,
      default: null
    },
    // タイトル
    title: {
      type: String,
      default: null
    },
    // 項目固定あり
    commonItem: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // データ
      report: {
        id: null,
        title: null,
        clientName: null,
        siteName: null,
        officeName: null,
        departmentName: null,
        item: [],
        note: null,
        color: { bgColor: null, textColor: null, titleColor: null },
        align: null
      },
      // 最終更新者
      user: null,
      // 最終更新日
      updateDate: null,
      // 編集可能
      edit: true,
      // 即時画面表示
      reload: 0,
      // ローディング中
      loadingFirst: false,
      loading: false,
      // 表示順の最大値
      maxSortNo: 0,
      // 支店リスト（アポロ）
      officeList: [
        { name: '東京支店' },
        { name: '東京西支店' }
      ],
      // 部署リスト（アポロ）
      departmentList: [],
      // テンプレート1に変換
      template1: false,
      // 出力メニュー
      downloadMenu: [
        { value: 1, text: 'Excel' },
        { value: 2, text: 'PDF' },
      ],
      // アポロ管財ロゴ
      logo: `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}images/logo.png`,
      // バイク点検編集不可
      cannotEdit: false,
      // テンプレートの色変更モーダル
      openChangeColorModal: false
    }
  },

  async created() {
    // データ取得
    await this.getData()
    // 部署リスト取得
    this.departmentList = this.$store.getters.departmentList
    // 部署リストに「なし」を追加
    this.departmentList.unshift({ name: 'なし' })
  },

  computed: {
    /**
     * 項目の詳細内容の高さを自動で高くする（編集）
     */
    textHeight() {
      return (note) => {
        // 改行ごとに配列
        const arr = note.split('\n')
        let res = arr.length
        for (let i = 0; i < arr.length; i++) {
          const text = arr[i]
          // 30文字以上なら+1
          if (text.length > 30) {
            res++
          }
        }
        if (res < 3) {
          res = 3
        }
        return res
      }
    },

    /**
     * バイク点検の点検月
     */
    updateDateMonth() {
      if (this.type == 3) {
        if (this.updateDate) {
          let res = this.updateDate.substr(0, 7)
          return res.replace('/', '年') + '月'
        }
      }
      return null
    },

    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      // console.log(this.type)
      // console.log(reportID)
      this.loadingFirst = true
      window.scroll({ top: 0 })
      this.report = {}
      this.report.title = this.title
      for (let i = 0; i < 6; i++) {
        this.addItem()
      }

      let reportID

      // 一覧から開いた場合
      if (this.$route.query.id) {
        reportID = this.$route.query.id
      }

      // 編集の場合
      if (reportID) {
        const res = await backend.searchData('photoReport/get', { id: reportID })
        if (res.data.data) {
          this.report = res.data.data
          this.user = res.data.data.updateUserName
          this.updateDate = res.data.data.updateDate

          // 項目の最大表示順を取得
          if (this.report.item && this.report.item.length) {
            this.maxSortNo = Number(this.report.item.slice(-1)[0].sortNo)

            // 写真の大きさ判定
            for (let i = 0; i < this.report.item.length; i++) {
              const row = this.report.item[i]
              if (row.photo) {
                this.getPhotoObjectFit(row)
                this.reload++
              }
            }
          }
        }
        this.edit = false

        // バイク点検の場合
        if (this.type == 3) {
          // ユーザーが違う場合、編集不可
          if (this.user != utils.deleteKana(this.$store.getters.user.user_name)) {
            this.cannotEdit = true
          // ユーザーが同じ場合
          } else {
            // 年月が違えば編集不可
            let today = moment().format('YYYY年MM月')
            if (today != this.updateDateMonth) {
              this.cannotEdit = true
            } else {
              this.cannotEdit = false
            }
          }
        }

      // 新規で項目固定の場合
      } else if (this.commonItem) {
        const res = await backend.searchData('photoReport/getCommonItem', { type: this.type })
        if (res.data.data && res.data.data.length) {
          this.report.item = []
          for (let i = 0; i < res.data.data.length; i++) {
            const r = res.data.data[i]
            this.report.item.push({ title: r.title, commonItem: true, sortNo: r.sortNo })
          }
          this.maxSortNo = Number(this.report.item.slice(-1)[0].sortNo)
        }
      }

      // フリーもしくは現地調査の場合、色の設定
      if (this.type == 1 || this.type == 2) {
        if (!this.report.color) {
          this.report.color = { bgColor: BGCOLOR, textColor: TEXTCOLOR, titleColor: TITLECOLOR }
        } else {
          if (!this.report.color.bgColor) {
            this.report.color.bgColor = BGCOLOR
          }
          if (!this.report.color.textColor) {
            this.report.color.textColor = TEXTCOLOR
          }
          if (!this.report.color.titleColor) {
            this.report.color.titleColor = TITLECOLOR
          }
        }
      // フリー、現地調査以外
      } else {
        this.report.color = { bgColor: null, textColor: null, titleColor: null }
      }

      // バイク点検で新規作成の場合
      if (!reportID && this.type == 3 && !this.report.departmentName) {
        // 所属部署を初期値で入れる
        const userInfo = this.$store.getters.loginUserData
        this.report.departmentName = userInfo.departmentName
        if (!this.report.departmentName) {
          this.report.departmentName = 'なし'
        }
      }
      this.reload++
      this.loadingFirst = false
    },

    /**
     * 写真添付Base64に変換
     * @param row 対象項目
     */
    async imageToBase64 (row, event, cover) {
      const isAlert = await this.showAlert()
      if (!isAlert) {
        return
      }
      // 選択されたファイルの取得
      const file = event.target.files
      // Base64に変換する
      for (let j = 0; j < file.length; j++) {
        const reader = new FileReader()
        const f = file[j]
        reader.readAsDataURL(f)
        // 変換
        const compressedImage = await new Promise((resolve) => {
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            
            img.onload = () => {
              const compressedDataUrl = this.compressImage(img)
              resolve(compressedDataUrl)
            }
          }
        })

        // 表紙の場合
        if (cover) {
          row.coverPhoto = compressedImage
        } else {
          // 画像は1つのみ
          row.photo = compressedImage
          this.getPhotoObjectFit(row)
        }

        await this.savePhoto(row, cover)
        
        this.reload++
      }
    },

    /**
     * 画像圧縮
     * @param {dataURL} img 
     */
    compressImage(img) {
      // 画像のサイズを変更するためにリサイズ処理
      const maxWidth = 854 // リサイズ後の最大幅
      let width = img.width
      let height = img.height

      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
      // 新しいキャンバスを作成
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      // キャンバスに描画するためのコンテキストを取得
      const ctx = canvas.getContext('2d')
      // 画像をキャンバスに描画せずに直接リサイズ
      ctx.drawImage(img, 0, 0, width, height)
      // キャンバスの画像を再度DataURLに変換して圧縮率を下げる
      const compressedDataUrl = canvas.toDataURL('image/jpeg', 1.0) // 圧縮率を設定（0.7は例です）

      return compressedDataUrl
    },

    /**
     * 写真を横長か縦長か判定し、CSSを決める
     * @param row 対象行
     */
    getPhotoObjectFit(row) {
      if (row.photo) {
        const img = new Image()
        img.src = row.photo
        img.onload = () => {
          if (img.width < img.height) {
            row.objectFit = 'object-fit: contain;'
          } else {
            row.objectFit = 'object-fit: cover;'
          }
          this.reload++
        }
      }
    },

    /**
     * 写真保存
     * @param row 対象項目
     */
    async savePhoto(row, cover) {
      const isAlert = await this.showAlert()
      if (!isAlert) {
        return
      }
      const common = this.commonSaveData()
      try {
        this.loading = true
        if (cover) {
          await backend.postData('photoReport/updateCoverPhoto', { ...row, ...common })
        } else {
          await backend.postData('photoReport/upsertPhoto', { ...row, ...common })
        }
        this.user = common.insertUserName
        this.updateDate = moment().format('YYYY年MM月DD日')
        row.photoReportId = this.report.id

      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
        this.loading = false
      }
      this.loading = false
      this.reload++
    },

    /**
     * 表紙写真削除
     * @param row 対象項目
     */
    async deleteCoverPhoto(row) {
      const isAlert = await this.showAlert()
      if (!isAlert) {
        return
      }
      delete row.coverPhoto
      await this.savePhoto(row, true)
      this.reload++
    },

    /**
     * 写真削除
     * @param row 対象項目
     */
    async deletePhoto(row) {
      const isAlert = await this.showAlert()
      if (!isAlert) {
        return
      }
      delete row.photo
      await this.savePhoto(row, false)
      this.reload++
    },

    /**
     * 項目削除
     * @param i インデックス
     */
    async deleteItem(i) {
      const isAlert = await this.showAlert()
      if (!isAlert) {
        return
      }
      let title = ''
      if (this.report.item[i].title) {
        title = '「' + this.report.item[i].title + '」'
      }

      const res = await dialogs.showConfirmDialog('この項目を削除しますか？', `No.${(i + 1)} ${title}を削除してよろしいですか？`)
      if (res != 'YES') {
        return
      }

      if (this.report.item[i].photoReportId) {

        try {
          await backend.deleteData('photoReport/deleteItem', { id: this.report.item[i].photoReportId, sortNo: this.report.item[i].sortNo })
          this.user = utils.deleteKana(this.$store.getters.user.user_name)
          this.updateDate = moment().format('YYYY年MM月DD日')
        } catch (error) {
          alert('エラーが発生しました\r\n' + error)
        }
      }

      this.report.item.splice(i, 1)
      this.reload++
    },

    /**
     * 項目追加
     */
    addItem() {
      const row = { photo: null, title: '', note: null, sortNo: null }

      // 項目がない場合
      if (!this.report.item) {
        this.report.item = []
        row.sortNo = 1
        this.maxSortNo = 1

      // 項目がある場合
      } else {
        this.maxSortNo++
        row.sortNo = this.maxSortNo
      }
      this.report.item.push(row)

      this.reload++
    },

    /**
     * 保存時の共通項目
     */
    commonSaveData() {
      let id = null
      if (this.report.id) {
        id = this.report.id
      }
      return {
        id,
        insertUserName: utils.deleteKana(this.$store.getters.user.user_name),
        insertUser: this.$store.getters.user.id,
        type: this.type
      }
    },

    /**
     * 作成開始ボタン
     */
    async start() {
      const saveData = utils.clone(this.report)
      let common = this.commonSaveData()
      try {
        const res = await backend.postData('photoReport/newSave', { ...saveData, ...common })
        this.report.id = res.data.data
        this.user = common.insertUserName
        this.updateDate = moment().format('YYYY年MM月DD日')
        for (let i = 0; i < this.report.item.length; i++) {
          const it = this.report.item[i]
          it.photoReportId = this.report.id
        }
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 編集不可アラート表示
     */
    async showAlert() {
      if (this.cannotEdit) {
        return false
      }
      if (!this.report.id) {
        await dialogs.showErrorDialog('作成開始ボタンを押してください', '作成開始ボタンを押すとデータの保存が開始されます。')
        return false
      } else if (!this.edit) {
        await dialogs.showErrorDialog('編集ボタンを押してください。', '編集ボタンを押すとデータを更新できるようになります。')
        return false
      }
      return true
    },

    /**
     * テキスト変更イベント
     */
    async changeText() {
      const isAlert = await this.showAlert()
      if (!isAlert) {
        return
      }
      const saveData = utils.clone(this.report)
      delete saveData.item
      const common = this.commonSaveData()

      try {
        await backend.postData('photoReport/updateText', { ...saveData, ...common })
        this.user = common.insertUserName
        this.updateDate = moment().format('YYYY年MM月DD日')
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 項目変更イベント
     * @param row 対象行
     */
    async changeItem(row) {
      const isAlert = await this.showAlert()
      if (!isAlert) {
        return
      }
      const saveData = utils.clone(row)
      delete saveData.photo
      const common = this.commonSaveData()

      try {
        await backend.postData('photoReport/upsertItem', { ...saveData, ...common })
        this.user = common.insertUserName
        this.updateDate = moment().format('YYYY年MM月DD日')
        row.photoReportId = this.report.id
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 編集ボタン
     */
    editBtn() {
      this.edit = true
    },

    /**
     * 完了ボタン
     */
    async completeBtn() {
      // 入力確認
      const vali = await this.validate()
      if (!vali) {
        return
      }
      this.loading = true
      const common = this.commonSaveData()

      try {
      await backend.postData('photoReport/complete', { ...common })
        this.user = common.insertUserName
        this.updateDate = moment().format('YYYY年MM月DD日')
        
        let msg = 'タイトル「' + this.report.title + '」更新者：' + this.user + ' を完了しました。'
        logManager.recording(this, logManager.Loglevel.INFO, '報告書_' + this.typeName, '更新', msg)
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
      this.loading = false
      this.edit = false
    },

    /**
     * 報告書削除ボタン
     */
    async deleteReport() {
      const res = await dialogs.showWarningConfirmDialog('報告書を削除しますか？', 'この報告書を本当に削除してよろしいですか？')
      if (res != 'YES') {
        return
      }
      try {
        await backend.deleteData('photoReport/deleteReport', { id: this.report.id })
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
      let msg = 'タイトル「' + this.report.title + '」更新者：' + this.user + ' を削除しました。'
      logManager.recording(this, logManager.Loglevel.INFO, '報告書_' + this.typeName, '削除', msg)
      
      // 一覧へ遷移
      this.$router.push({ name: 'PhotoReportList' })
    },

    /**
     * 入力確認
     */
    async validate() {
      const rep = this.report
      // タイトルのチェック
      if (!rep.title) {
          await dialogs.showErrorDialog('タイトルが未入力です', 'タイトルを入力してください。')
          return false
      }
      // フリー入力、現地調査
      if (this.type == 1 || this.type == 2) {
        if (!rep.clientName) {
          await dialogs.showErrorDialog('得意先名が未入力です', '得意先名を入力してください。')
          return false
        } else if (!rep.siteName) {
          await dialogs.showErrorDialog('物件名が未入力です', '物件名を入力してください。')
          return false
        }
      // バイク点検
      } else if (this.type ==3) {
        if (!rep.officeName) {
          await dialogs.showErrorDialog('支店名が未入力です', '支店名を選択してください。')
          return false
        } else if (!rep.departmentName) {
          await dialogs.showErrorDialog('部署名が未入力です', '部署名を選択してください。')
          return false
        }
      }

      const nonPhoto = []
      // 写真変更したもの、共通項目で写真添付していないもの
      if (rep.item && rep.item.length) {
        for (let i = 0; i < rep.item.length; i++) {
          const item = rep.item[i]
          // 共通項目で写真添付無しのもの
          if (item.commonItem && !item.photo) {
            nonPhoto.push(i + 1)
          }
        }

        if (!this.template1 && nonPhoto && nonPhoto.length) {
          const res = await dialogs.showConfirmDialog(`写真がない項目があります`, `No.${nonPhoto.join('、')} の写真が添付されていません。\r\nよろしいでしょうか？`)
          if (res != 'YES') {
            return false
          }
        }
      }
      return true
    },

    /**
     * テンプレート変換
     */
    changeTemplate1() {
      this.template1 = !this.template1
      if (this.template1) {
        dialogs.showInfoDialog('写真のある項目のみ表示', '写真がある項目のみ表示します。\r\n表紙の写真は、建物全体や館銘板などを添付してください。')
      }
    },

    /**
     * 出力ボタン
     */
    async downloadSelect(select) {
      if (select.value == 1) {
        await this.excelBtn()
      } else if (select.value == 2) {
        await this.pdfBtn()
      }
    },

    /**
     * Excel出力
     */
    async excelBtn() {
      if (this.edit) {
        alert('完了ボタンを押してください。')
        return
      }
      this.loading = true
      const res = await backend.postBlob2('photoReportDownload/excel', { ...this.report, user: this.user, updateDateMonth: this.updateDateMonth, type: this.type })
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const a = document.createElement("a");
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let name = this.report.title
        if ([1, 2].includes(this.type)) {
          name += '_' + (this.report.siteName || '物件名未入力')
        } else if ([3].includes(this.type)) {
          name += '_' + this.updateDateMonth + '_' + this.user.replace('　', '') 
        }

        a.download = name + '.xlsx'
        a.click();
        a.remove();
        this.loading = false
      } catch (e) {
        console.log(e)
        alert(e)
        this.loading = false
      }
    },

    /**
     * PDF出力
     */
    async pdfBtn() {
      if (this.edit) {
        alert('完了ボタンを押してください。')
        return
      }
      this.loading = true
      const param = {
        html: document.querySelector("#output").outerHTML
      }
      const res = await backend.postBlob2('photoReportDownload/pdf', param)
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { type : "application/pdf" })
        const url = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        let name = this.report.title
        if ([1, 2].includes(this.type)) {
          name += '_' + (this.report.siteName || '物件名未入力')
        } else if ([3].includes(this.type)) {
          name += '_' + this.updateDateMonth + '_' + this.user.replace('　', '')
        }

        a.download = name + '.pdf'
        a.click()
        a.remove()
        // window.open(URL.createObjectURL(blob))
        this.loading = false
      } catch (e) {
        console.log(e)
        alert(e)
        this.loading = false
      }
    },

    /**
     * 色変更モーダルopen
     */
    async changeColor() {
      this.openChangeColorModal = true
    },

    /**
     * 色・位置変更決定後モーダルclose
     */
    closeChangeColor(val) {
      this.report.color = val.color
      this.report.align = val.align
      this.openChangeColorModal = false

      try {
        backend.postData('photoReport/updateColor', { id: this.report.id, ...val })
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 背景色
     */
    setBgColor() {
      if (this.report && this.report.color && this.report.color.bgColor) {
        return `background: ${this.report.color.bgColor};`
      } else {
        return `background: ${BGCOLOR};`
      }
    },

    /**
     * 得意先色（背景と同じ色）
     */
    clientColor() {
      let color = `color: ${BGCOLOR};`
      let align = 'text-align: right;'

      if (this.report && this.report.color && this.report.color.bgColor) {
        color = `color: ${this.report.color.bgColor};`
      }
      if (this.report && this.report.align) {
        align = `text-align: ${this.report.align};`
      }
      return color + ' ' + align
    },

    /**
     * 物件名色、清掃箇所色
     */
    textColor() {
      if (this.report && this.report.color && this.report.color.textColor) {
        return `color: ${this.report.color.textColor};`
      } else {
        return `color: ${TEXTCOLOR};`
      }
    },

    /**
     * 物件名の位置
     */
    siteAlign() {
      let align = 'text-align: right;'
      if (this.report && this.report.align) {
        align = `text-align: ${this.report.align};`
      }
      return ' ' + align
    },

    /**
     * タイトル色
     */
    titleColor() {
      if (this.report && this.report.color && this.report.color.titleColor) {
        return `color: ${this.report.color.titleColor};`
      } else {
        return `color: ${TITLECOLOR};`
      }
    },
  }
}
</script>

<style scoped>
/* .content {
  width: 750px;
} */

.main-form {
  /* min-height: 2000px !important; */
  min-width: 770px !important;
  /* height: 100vh !important; */
}

.main-form2 {
  min-width: 770px !important;
  /* min-height: 2000px !important; */
}

.add-btn {
  background: lightseagreen;
  border-color: #3a623e;
}

.add-btn:hover {
  background: lightseagreen;
  border-color: #3a623e;
}

.color-btn {
  background: #f68f42;
  border-color: #f68f42;
}

.color-btn:hover {
  background: #f68f42;
  border-color: #f68f42;
}

.file-type {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 49%;
}

.photo-not {
  position: absolute;
  left: 28%;
  top: 45%;
}

.photo-info {
  position: absolute;
  left: 65%;
  top: 42%;
}

.photo-info2 {
  position: absolute;
  left: 15%;
  top: 42%;
}

</style>